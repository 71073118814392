import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "../Constants";
import { useAuth } from "../Hooks";
import Layout from "../Layout/Layout";
import { USER_ROLE } from "../Types";
import { UserRoleWrapper } from "../views";
import BusinessDashboard from "../Components/Dashboard/Business";
import FreelancerDashboard from "../Components/Dashboard/Freelancer";
import Projects from "../Components/Projects";
import ProjectDetails from "../Components/ProjectDetails";
import Invoices from "../Components/Invoices";
import Clients from "../Components/Clients";
import Freelancers from "../Components/Freelancers";
import Finance from "../Components/Finance";
import Money from "../Components/Money";
import Payment from "../Components/Payment";
import PaymentAgreement from "../Components/PaymentAgreement";

// // Lazy load components
// const Invoices = lazy(() => import("../Components/Invoices"));
// const Clients = lazy(() => import("../Components/Clients"));
// const Finance = lazy(() => import("../Components/Finance"));
// const Money = lazy(() => import("../Components/Money"));
// const Projects = lazy(() => import("../Components/Projects"));
// const ProjectDetails = lazy(() => import("../Components/ProjectDetails"));
// const Freelancers = lazy(() => import("../Components/Freelancers"));
// const Payment = lazy(() => import("../Components/Payment"));
// const PaymentAgreement = lazy(() => import("../Components/Auth/Business/PaymentAgreement"));

export default function LayoutRoutes() {
    const { user } = useAuth();

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route
                    path={ROUTES.Dashboard}
                    element={
                        user?.role === USER_ROLE.Business
                            ? <BusinessDashboard />
                            : <FreelancerDashboard />
                    }
                />
                <Route
                    path={ROUTES.Project}
                    element={<Projects />}
                />
                <Route
                    path={ROUTES.ProjectDetails}
                    element={<ProjectDetails />}
                />
                <Route
                    path={ROUTES.Invoices}
                    element={<Projects />}
                />
                <Route
                    path={ROUTES.Invoice}
                    element={
                        <UserRoleWrapper role={USER_ROLE.Freelancer}>
                            <Invoices />
                        </UserRoleWrapper>
                    }
                />
                <Route
                    path={ROUTES.InvoiceEdit}
                    element={
                        <UserRoleWrapper role={USER_ROLE.Freelancer}>
                            <Invoices />
                        </UserRoleWrapper>
                    }
                />
                <Route
                    path={ROUTES.Client}
                    element={
                        <UserRoleWrapper role={USER_ROLE.Freelancer}>
                            <Clients />
                        </UserRoleWrapper>
                    }
                />
                <Route
                    path={ROUTES.Freelancer}
                    element={
                        <UserRoleWrapper role={USER_ROLE.Business}>
                            <Freelancers />
                        </UserRoleWrapper>
                    }
                />
                <Route
                    path={ROUTES.Finance}
                    element={<Finance />}
                />
                <Route
                    path={ROUTES.Money}
                    element={<Money />}
                />
                <Route
                    path={ROUTES.Payment}
                    element={
                        <UserRoleWrapper role={USER_ROLE.Business}>
                            <Payment />
                        </UserRoleWrapper>
                    }
                />
                <Route
                    path={ROUTES.PaymentAgreement}
                    element={
                        <UserRoleWrapper role={USER_ROLE.Business}>
                            <PaymentAgreement />
                        </UserRoleWrapper>
                    }
                />
            </Route>

            {/* Catch-all route for undefined paths */}
            <Route path="*" element={<Navigate to={ROUTES.Home} />} />
        </Routes>
    );
}
