import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { CommonTable, CommonSelect, ConfirmationDialog } from '../../CommonComponent';
import { Client } from '../../Types';
import { Image, SolidButton } from '../../AbstractElement';
import { mutations, queries } from '../../api';
import { useCoreData, useDropdown, useTableFilterHelper } from '../../Hooks';
import { TableClickedAction, TableConfig } from '../../Types/CommonComponent';
import { dynamicImage } from '../../utils';
import { useLayoutContext, useStateContext, useToast } from '../../views';

const Clients = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { isMobile, toggleState } = useLayoutContext()
  const { toggleAddNewCustomer, toggleClientDetails } = useStateContext();

  const [selectedOptions, setSelectedOptions] = useState<string[] | string>('all');
  const { pageNumber, pageSize, searchTerm, params, goToPage, handleSetPageSize, handleSetSearch, archiveOptions, handleSetArchiveStatus, toggleSortType, handleSetSortBy } = useTableFilterHelper({ archiveStatus: null });

  const { phoneCountryCodeOptions } = useCoreData({ loadCountries: true });

  const { data: customers } = queries.useGetCustomers(params);

  const { mutate: deleteCustomer } = mutations.useDeleteCustomer();
  const { mutate: archiveCustomer } = mutations.useArchiveCustomer();

  const clientSearchDropdown = useDropdown<HTMLDivElement>(toggleState);
  const searchBar = document.getElementById('clientSearch')

  // Table configuration state  
  const tableConfig = useMemo<TableConfig<Client>>(() => ({
    columns: [
      {
        title: "ClientName", dataField: [{
          field: 'name', class: 'top hover-link', type: '',
          renderer: (element: Client) => {
            return (
              <div className="user-name-box hover-link">
                <div className="initial-letter">
                  <span>{element?.name.split(' ')?.map((letter: string) => letter.charAt(0).toUpperCase())?.join('')?.slice(0, 2)}</span>
                </div>
                <div className="responsive-box">
                  <h6 className="hover-link">{element.name}{element.verified && <Image src={dynamicImage(`svg/blue-tick.svg`)} />}</h6>
                  <span>({element.email})</span>
                </div>
              </div>
            )
          }
        }], class: 'client-name-box', sortable: true, sortField: 'name', sortDirection: 'desc'
      },
      {
        title: "", dataField: [{
          field: 'archived', class: '', type: '', renderer: (element: Client) => {
            return element.archived
              ? <span className="archived-badge justify-content-center ms-5 badge bg-transparent rounded-pill">{t("Archived")}</span>
              : ''
          }
        }], sortable: true, sortField: 'archived', sortDirection: 'desc'
      },
      { title: "PhoneNumberId", dataField: [{ field: 'phone', class: 'text', type: '', renderer: (data: Client) => `${phoneCountryCodeOptions?.find(f => f.value === data.phoneCountryCode)?.label ?? ''} ${data.phone ?? ''}` }], sortable: false },
      { title: "Country", class: 'country-box', dataField: [{ field: 'countryName', class: 'text', type: '' }], sortable: false },
      { title: "RegistrationNumber", dataField: [{ field: 'registrationNumber', class: 'text', type: '' }], sortable: false, hidden: true },
      { title: "VatNumber", dataField: [{ field: 'vatNumber', class: 'text', type: '' }], sortable: false, hidden: true },
      { title: "TaxOffice", dataField: [{ field: 'taxOffice', class: 'text', type: '' }], sortable: false, hidden: true },
    ],
    actionsDropDown: [
      { label: "ClientDetails", actionToPerform: "view" },
      { label: "EditClientDetails", actionToPerform: "edit", conditional: { field: 'editable', condition: '===' } },
      //TODO: The archiving feature has been disabled and will be activated later.
      // { label: "Archive", actionToPerform: "archive", conditional: { field: 'archived', condition: '?', actionLabel: 'Unarchive : Archive' } },
      { label: "Delete", actionToPerform: "delete", conditional: { field: 'verified', condition: '!=' } },
    ],
    data: customers?.items ?? [],
    total: customers?.totalResults ?? 0,
    isColumnChooser: true,
  }), [customers, phoneCountryCodeOptions]);

  // Effect to handle search term updates
  useEffect(() => {
    handleSetArchiveStatus(Array.isArray(selectedOptions) ? selectedOptions[0] : selectedOptions);
  }, [selectedOptions]);

  const handleActionPerform = (action: TableClickedAction<Client>) => {
    switch (action.actionToPerform) {
      case 'edit':
        toggleAddNewCustomer(action.data?.id!)
        break;
      case 'view':
        toggleClientDetails(action.data?.id!);
        break;
      case 'delete':
        ConfirmationDialog({
          title: "Delete Client",
          text: "Are you sure you want to delete this client? This action cannot be undone and may affect associated data.",
          confirmButtonText: "Delete",
          onConfirm: (isConfirmed) => {
            if (isConfirmed) {
              deleteCustomer(action.data.id, {
                onSuccess: () => {
                  showToast("delete", "Clients.ClientDeletedSuccessfully");
                },
              });
            }
          }
        });
        break;

      case 'archive':
        const isArchived = action.data.archived;
        ConfirmationDialog({
          title: isArchived ? "Unarchive client" : "Archive client",
          text: isArchived ? "Are you sure you want to unarchive this client?" : "Are you sure you want to archive this client?",
          confirmButtonText: isArchived ? "Unarchive client" : "Archive client",
          onConfirm: (isConfirmed) => {
            if (isConfirmed) {
              if (action?.data)
                archiveCustomer({ id: action.data.id, archived: !isArchived }, {
                  onSuccess: () => {
                    if (!isArchived) {
                      showToast("delete", 'Clients.ClientArchivedSuccessfully');
                    }
                    else {
                      showToast("success", 'Clients.ClientUnarchivedSuccessfully');
                    }
                  },
                });
            }
          }
        });
        break;
      default:
        console.warn("Unknown action:", action.actionToPerform);
    }
  };

  return (
    <div className="customer-main client-page">
      <Row className="g-0">
        <Col className="horizontal-main-tab">
          <div className="client-new d-flex flex-sm-row flex-column align-items-sm-center align-items-start justify-content-between w-100">
            {!isMobile && <div className="total-data">
              <span>
                {customers?.totalResults} <small className="d-sm-flex d-none">{t("Clients")}</small>
              </span>
            </div>
            }
            <div className="tab-right-filter" >
              <div className="search-input-form" ref={clientSearchDropdown.ref}>
                <div className="mobile-search-img d-lg-none d-inline-flex" onClick={clientSearchDropdown.toggle}>
                  <Image src={dynamicImage("svg/search.svg")} alt="search" />
                </div>
                {(!isMobile || clientSearchDropdown.isOpen) && (
                  <div className={`search-input d-lg-inline-flex d-none ${clientSearchDropdown.isOpen ? "open" : ""}`} id="clientSearch">
                    <input
                      className="form-control"
                      placeholder={t("SearchClients")}
                      value={searchTerm}
                      onChange={(e) => handleSetSearch(e.target.value)}
                      onFocus={() => { searchBar?.classList.add('full-search') }}
                      autoComplete="off"
                    />
                    <Image src={dynamicImage("svg/search.svg")} alt="search" className="search-img" />
                    <Image src={dynamicImage("svg/search-red.svg")} alt="search" className="search-img-hover" />
                    {searchTerm.length > 0 && (
                      <div className="close-img" onClick={() => { handleSetSearch(""); searchBar?.classList.remove('full-search') }}>
                        <Image src={dynamicImage("svg/close-red.svg")} alt="close" />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* TODO: The archiving feature has been disabled and will be activated later.
               <CommonSelect
                placeholder={t('ShowAll')}
                options={archiveOptions}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                multiSelect={false}
              /> */}
              <SolidButton className="text-nowrap btn-solid" onClick={() => { toggleAddNewCustomer() }}>
                <Image src={dynamicImage("svg/plus-white.svg")} alt="arrow" />
                <span className="d-xxl-inline-flex d-none">{t("AddNewClient")}</span>
              </SolidButton>
            </div>
          </div>
          <div className="client-table responsive-client-table">
            {tableConfig.total > 0 ? (
              <CommonTable tableConfiguration={tableConfig} currentPage={pageNumber} itemsPerPage={pageSize} goToPage={goToPage} handleSetPageSize={handleSetPageSize} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} onActionPerform={handleActionPerform} />
            ) : (<div className="table-no-data">
              <Image src={dynamicImage("svg/no-data/no-data-client.svg")} alt="no data" className="img-fluid" />
              <h5 dangerouslySetInnerHTML={{ __html: t("NoData.Clients") }}></h5>
            </div>)}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Clients;