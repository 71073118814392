import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Image, InvoiceStatusBadge, LinkButton } from '../../../AbstractElement';
import { CommonSelect, CommonTable, ConfirmationDialog } from '../../../CommonComponent';
import { ROUTES } from '../../../Constants';
import { useAuth, useCoreData, useDropdown, useTableFilterHelper } from '../../../Hooks';
import { INVOICE_STATUS, InvoiceScope, USER_ROLE } from '../../../Types';
import { Action, Column, COLUMN_TYPE, TableClickedAction, TableConfig } from '../../../Types/CommonComponent';
import { mutations, queries } from '../../../api';
import { dynamicImage } from '../../../utils';
import { useLayoutContext, useStateContext, useToast } from '../../../views';

const ProjectInvoices = ({ archived }: { archived: boolean; }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { id: projectId } = useParams<{ id: string }>();

    const { isMobile, toggleState } = useLayoutContext()
    const { openInvoiceViewModal, openInvoiceDetailModal } = useStateContext();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { invoiceStatusOptions } = useCoreData({});

    const projectInvoiceSearchDropdown = useDropdown<HTMLDivElement>(toggleState);
    const searchBar = document.getElementById(`projectInvoiceSearch-${archived ? 'archived' : 'all'}`)

    const { pageNumber, pageSize, searchTerm, params, goToPage, handleSetPageSize, handleSetSearch, toggleSortType, handleSetSortBy } = useTableFilterHelper({ projectId: projectId, archived });
    const { data: invoices, refetch: refetchInvoices } = queries.useGetInvoiceScopes(selectedOptions.length ? { ...params, invoiceStatuses: selectedOptions } : params);
    const { mutate: refetchDownload } = mutations.useDownloadInvoiceScopePdf();
    const { mutate: refetchDuplicate } = mutations.useDuplicateInvoice();
    const { mutate: refetchSendReminder } = mutations.useSendReminderInvoice();
    const { mutate: refetchArchive } = mutations.useArchiveInvoiceScope();
    const { data: project } = queries.useGetProject(projectId);
    const { showToast } = useToast()

    const tableConfig = React.useMemo<TableConfig<InvoiceScope>>(() => ({
        columns:
            [
                ...(user?.role === USER_ROLE.Freelancer
                    ? [{
                        title: "Client", class: 'name-box', dataField: [{ field: "companyName", class: 'top', type: '' }, {
                            field: "companyEmail", class: '', type: '', renderer: (data: InvoiceScope) => {
                                return <>{data?.companyEmail && <span>({data?.companyEmail})</span>}</>;
                            }
                        }], sortable: true, sortField: 'companyName', sortDirection: 'desc', hidden: true
                    } as Column<InvoiceScope>]
                    : []),

                ...(user?.role === USER_ROLE.Business ? [{
                    title: "Freelancer", dataField: [{ field: "freelancerName", class: 'top', type: '' }, {
                        field: "freelancerEmail", class: '', type: '', renderer: (data: InvoiceScope) => {
                            return <>{data?.freelancerEmail && <span>({data?.freelancerEmail})</span>}</>;
                        }
                    }], sortable: true, sortField: 'freelancerName', sortDirection: 'desc'
                } as Column<InvoiceScope>] : []),
                { title: "CreationDate", dataField: [{ field: "createdAt", class: '', type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'createdAt', sortDirection: 'desc', hidden: true },
                { title: "InvoiceNumber", class: 'invoice-number-box', dataField: [{ field: "documentNo", class: 'top hover-link', type: '', renderer: (data: InvoiceScope) => { return <>{`#${data.documentNo}`}</> } }], sortable: true, sortField: 'documentNo', sortDirection: 'desc' },
                { title: "InvoiceDate", class: 'invoice-date-box', dataField: [{ field: "documentDate", class: '', type: COLUMN_TYPE.Date, params: { format: "medium" } }], sortable: true, sortField: 'documentDate', sortDirection: 'desc' },
                { title: "DueDate", dataField: [{ field: "dueDate", class: '', type: COLUMN_TYPE.Date, params: { format: "medium" } }], sortable: true, sortField: 'dueDate', sortDirection: 'desc', hidden: true },
                { title: "LastUpdate", class: 'last-update-box', dataField: [{ field: 'invoiceStatus', class: '', type: '', renderer: (data: InvoiceScope) => { return <InvoiceStatusBadge invoiceStatus={data.invoiceStatus} />; } }, { field: "modifiedAt", class: 'date', type: COLUMN_TYPE.Date, params: { format: "medium" } }], sortable: true, sortField: 'modifiedAt', sortDirection: 'desc' },
                { title: "InvoiceAmount", dataField: [{ field: "total", class: '', type: 'amount', params: { currency: "currencyCode" } }], sortable: true, sortField: 'total', sortDirection: 'desc', hidden: true },
                { title: "TotalTax", dataField: [{ field: "vatAmount", class: '', type: 'amount', params: { currency: "currencyCode" } }], sortable: true, sortField: 'vatAmount', sortDirection: 'desc', hidden: true },
                { title: "Amount", class: 'amount-header', dataField: [{ field: "totalIncludingTaxes", class: 'amount', type: 'amount', params: { currency: "currencyCode" } }], sortable: true, sortField: 'totalIncludingTaxes', sortDirection: 'desc' },
            ],
        actionsDropDown: [
            ...(user?.role === USER_ROLE.Business
                ? [
                    { label: "Approve", actionToPerform: "approve", conditional: { field: 'invoiceStatus', condition: 'include', conditionValue: [INVOICE_STATUS.WaitingForApproval] } } as Action<InvoiceScope>,
                    { label: "Pay", getNavigateUrl: (data: InvoiceScope) => `${ROUTES.Payment.replace(':id', data.id.toString())}`, conditional: { field: 'invoiceStatus', condition: 'include', conditionValue: [INVOICE_STATUS.WaitingForPayment] } } as Action<InvoiceScope>,
                    { label: "Continue The Process", getNavigateUrl: (data: InvoiceScope) => `${ROUTES.PaymentAgreement.replace(':id', data.id.toString())}`, conditional: { field: 'invoiceStatus', condition: 'include', conditionValue: [INVOICE_STATUS.WaitingForPaymentAgreement] } } as Action<InvoiceScope>
                ]
                : []),
            { label: "InvoiceDetails", actionToPerform: "invoiceDetails" },
            { label: "View", actionToPerform: "view" },
            {
                label: "Download",
                actionToPerform: "download",
                conditional: {
                    field: 'invoiceStatus' as keyof InvoiceScope,
                    condition: 'notInclude',
                    conditionValue: ['InvoiceCancelled', 'DeclineByBusiness', 'DeclineByJuuli']
                }
            },
            ...(user?.role === USER_ROLE.Freelancer
                ? [
                    {
                        label: "SendReminder",
                        actionToPerform: "sendReminder",
                        conditional: {
                            field: 'invoiceStatus' as keyof InvoiceScope,
                            condition: 'include',
                            conditionValue: ['WaitingForApproval', 'WaitingForPayment']
                        }
                    } as Action<InvoiceScope>,
                    { label: "Duplicate", actionToPerform: "duplicate" },
                ]
                : []),
            {
                label: "Archive",
                actionToPerform: "archive",
                conditional: {
                    field: 'archived' as keyof InvoiceScope,
                    condition: '?',
                    actionLabel: 'Unarchive : Archive'
                }
            },
        ],
        data: invoices?.items ?? [],
        total: invoices?.totalResults ?? 0,
        isColumnChooser: true,
    }), [invoices?.items, invoices?.totalResults, t, user?.role]);

    const handleActionPerform = async (action: TableClickedAction<InvoiceScope>) => {
        switch (action.actionToPerform) {
            case 'download':
                refetchDownload({ id: action.data.id },
                    {
                        onSuccess: () =>
                            showToast("success", "InvoiceDetailCanvas.DownloadedInvoice")
                    }
                );

                break;
            case 'duplicate':
                ConfirmationDialog({
                    title: "Duplicate Invoice",
                    text: "Are you sure you want to duplicate this invoice?",
                    confirmButtonText: "Duplicate",
                    confirmBtnClass: "orange",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            try {
                                refetchDuplicate(action.data.id, {
                                    onSuccess: (response) => {
                                        const newInvoiceId = response?.invoiceId;
                                        if (newInvoiceId) {
                                            navigate(`${ROUTES.InvoiceEdit.replace(':id', newInvoiceId)}`);
                                        } else {
                                            console.error('No invoice ID found in the duplicate response');
                                        }
                                    },
                                });
                            } catch (error: any) {
                                console.log(error)
                            }
                        }
                    }
                });
                break;
            case 'sendReminder':
                ConfirmationDialog({
                    title: "Send reminder",
                    text: "Are you sure you want to send a reminder for this invoice?",
                    confirmButtonText: "Send reminder",
                    confirmBtnClass: "orange",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            refetchSendReminder(action.data.id);
                            showToast("success", "InvoiceScope.SendReminderSuccessfully");
                        }
                    }
                });
                break;
            case 'archive':
                const isArchived = action.data.archived;
                ConfirmationDialog({
                    title: isArchived ? "Unarchive invoice" : "Archive invoice",
                    text: isArchived ? "Are you sure you want to unarchive this invoice?" : "Are you sure you want to archive this invoice?",
                    confirmButtonText: isArchived ? "Unarchive invoice" : "Archive invoice",
                    confirmBtnClass: isArchived ? "orange" : "black",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            refetchArchive({ id: action.data.id, archived: !isArchived }, {
                                onSuccess: (result) => {
                                    refetchInvoices()
                                    if (!isArchived) {
                                        showToast("delete", 'InvoiceDetailCanvas.InvoiceArchivedSuccessfully');
                                    }
                                    else {
                                        showToast("success", 'InvoiceDetailCanvas.InvoiceUnarchivedSuccessfully');
                                    }
                                },
                            });
                        }
                    }
                });
                break;
            case 'invoiceDetails':
                openInvoiceDetailModal(action.data.id);
                break;
            case 'view':
            case 'approve':
                openInvoiceViewModal(action.data.id);
                break;
            default:
                console.warn('Unknown action:', action.actionToPerform);
        }

    };

    return (
        <>
            <div className="project-head d-flex flex-sm-row flex-column align-items-sm-center align-items-start gap-sm-4 gap-0 justify-content-between w-100">
                {!isMobile &&
                    <div className="total-data">
                        <span>
                            {invoices?.totalResults} <small className="d-sm-flex d-none">{t("Total")}</small>
                        </span>
                    </div>
                }
                <div className="tab-right-filter">
                    <div className="search-input-form" ref={projectInvoiceSearchDropdown.ref}>
                        <div className="mobile-search-img d-lg-none d-inline-flex" onClick={projectInvoiceSearchDropdown.toggle}>
                            <Image src={dynamicImage("svg/search.svg")} alt="search" />
                        </div>
                        {(!isMobile || projectInvoiceSearchDropdown.isOpen) && (
                            <div className={`search-input d-lg-inline-flex d-none ${projectInvoiceSearchDropdown.isOpen ? "open" : ""}`} id={`projectInvoiceSearch-${archived ? 'archived' : 'all'}`}>
                                <input className="form-control" onFocus={() => { searchBar?.classList.add('full-search') }} placeholder={t("Search")} value={searchTerm} onChange={(e) => handleSetSearch(e.target.value)} autoComplete="off" />
                                <Image src={dynamicImage("svg/search.svg")} alt="search" className="search-img" />
                                <Image src={dynamicImage("svg/search-red.svg")} alt="search" className="search-img-hover" />
                                {searchTerm.length > 0 && (
                                    <div className="close-img" onClick={() => { handleSetSearch(""); searchBar?.classList.remove('full-search') }}>
                                        <Image src={dynamicImage("svg/close-red.svg")} alt="close" />
                                    </div>
                                )}
                            </div>)}
                    </div>
                    <CommonSelect selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} options={invoiceStatusOptions}
                        placeholder={t("FilterByStatus")} multiSelect={true} />
                    {user?.role === USER_ROLE.Freelancer &&
                        <>
                            <LinkButton className="text-nowrap btn-solid" to={`${ROUTES.Invoice}?projectId=${projectId}&customerId=${project?.billingId}`}>
                                <Image src={dynamicImage("svg/plus-white.svg")} alt="arrow" />
                                <span className="text-white d-xxl-inline-flex d-none">
                                    {t("NewInvoice")}
                                </span>
                            </LinkButton>
                        </>
                    }
                </div>
            </div>
            <div className="project-detail-invoice-table freelancer-project project-detail-invoice">
                {tableConfig.total > 0 ? (
                    <CommonTable tableConfiguration={tableConfig} currentPage={pageNumber} itemsPerPage={pageSize} goToPage={goToPage} handleSetPageSize={handleSetPageSize} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} onActionPerform={handleActionPerform} />
                ) : (
                    <div className="table-no-data">
                        <Image src={dynamicImage("svg/no-data/no-data-project-invoice.svg")} alt="no data" className="img-fluid" />
                        <h5 dangerouslySetInnerHTML={{
                            __html: t(
                                user?.role === USER_ROLE.Freelancer && !archived ? "NoData.FreelancerNoInvoiceInProject" :
                                    user?.role === USER_ROLE.Freelancer && archived ? "NoData.FreelancerNoArchivedInvoiceInProject" :
                                        user?.role === USER_ROLE.Business && !archived ? "NoData.BusinessNoInvoiceInProject" :
                                            user?.role === USER_ROLE.Business && archived ? "NoData.BusinessNoArchivedInvoiceInProject"
                                                : "")
                        }}></h5>
                    </div>
                )}
            </div>
        </>
    )
}
export default ProjectInvoices;