import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, InputGroup, Row } from 'reactstrap';

import { SubmitHandler, useForm } from 'react-hook-form';
import { SolidButton } from '../../../../../AbstractElement';
import { CommonSelect } from '../../../../../CommonComponent';
import { useCoreData } from '../../../../../Hooks';
import { FreelancerAccountInput } from '../../../../../Types';
import { mutations, queries } from '../../../../../api';
import { useLayoutContext, useToast, useStateContext } from '../../../../../views';

const Personal = () => {
    const { t } = useTranslation();

    const { toggleProfileSetting } = useStateContext();

    const { countryOptions, phoneCountryCodeOptions } = useCoreData({ loadCountries: true });

    const { data: userDetails } = queries.useGetUserDetails();
    const { mutate: updateAccount, isLoading: updateLoading } = mutations.useUpdateAccount();
    const { isMobile } = useLayoutContext()
    const { showToast } = useToast()

    const [selectedPhoneCountryCodeOption, setSelectedPhoneCountryCodeOption] = useState<string[] | string>('');

    const { register, handleSubmit, setValue } = useForm<FreelancerAccountInput>({
        defaultValues: {
            phone: '',
            phoneCountryCode: '',
            website: ''
        }
    });

    useEffect(() => {
        if (userDetails) {
            setValue('phone', userDetails.phone!);
            setValue('website', userDetails.website!);
            setValue('phoneCountryCode', userDetails.phoneCountryCode!);
            setSelectedPhoneCountryCodeOption(userDetails.phoneCountryCode!)
        }
    }, [userDetails, setValue]);

    const onSubmit: SubmitHandler<FreelancerAccountInput> = async (data) => {
        updateAccount({ ...userDetails, ...data }, {
            onSuccess: () => {
                showToast("success", "ProfileSettings.UpdatedSuccessfully")
            }
        })

    };

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="setting-main pb-3">
                <Row className='g-sm-4 g-3'>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("FirstName")}</label>
                            <input type="text" className="form-control" readOnly id="firstName" placeholder={t("EnterFirstName")} defaultValue={userDetails?.firstName} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("LastName")}</label>
                            <input type="text" className="form-control" readOnly id="lastName" placeholder={t("EnterLastName")} defaultValue={userDetails?.lastName} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <Row className='g-sm-4 g-3'>
                            {isMobile &&
                                <Col xs="12">
                                    <FormGroup className='mb-0'>
                                        <label>{t("EmailId")}</label>
                                        <input type="email" className="form-control" readOnly id="emailAddress" placeholder={t("EnterEmailAddress")} defaultValue={userDetails?.email} autoComplete="off" />
                                    </FormGroup>
                                </Col>
                            }
                            <Col sm="6">
                                <FormGroup className='mb-0'>
                                    <label>{t("Citizenship")}</label>
                                    <input type="text" className="form-control" readOnly id="citizenship" placeholder={t("Citizenship")} defaultValue={countryOptions?.find(f => f.value == userDetails?.citizenshipCode)?.label ?? ""} autoComplete="off" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    {!isMobile &&
                        <Col sm="6">
                            <FormGroup className='mb-0'>
                                <label>{t("Website")}</label>
                                <input type="text" className="form-control" id="website" {...register("website")} placeholder={t("EnterWebAddress")} autoComplete="off" />
                            </FormGroup>
                        </Col>
                    }
                    <Col sm="6">
                        <FormGroup className="phone-detail mb-0">
                            <label>{t("PhoneNumberId")}</label>
                            <InputGroup>
                                <div className="dropdown-box">
                                    <CommonSelect
                                        placeholder={t('Select')}
                                        options={phoneCountryCodeOptions!}
                                        selectedOptions={selectedPhoneCountryCodeOption}
                                        setSelectedOptions={(value) => {
                                            setSelectedPhoneCountryCodeOption(value);
                                            setValue('phoneCountryCode', value);
                                        }}
                                        multiSelect={false}
                                        search={true}
                                    />
                                    <input type="number" className="form-control" id="phone" {...register("phone")} placeholder={t("5005000000")} autoComplete="off" />
                                </div>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    {isMobile && <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("Website")}</label>
                            <input type="text" className="form-control" id="website" {...register("website")} placeholder={t("EnterWebAddress")} autoComplete="off" />
                        </FormGroup>
                    </Col>}
                </Row>
            </div>
            <div className="profile-setting-footer-main">
                <div className="profile-setting-footer">
                    <SolidButton className='btn-gray' onClick={toggleProfileSetting}>{t("Cancel")}</SolidButton>
                    <SolidButton type='submit' loading={updateLoading}>{t("Update")}</SolidButton>
                </div>
            </div>
        </Form>
    )
}

export default Personal;