import { useTranslation } from "react-i18next";
import { useStateContext, useLoader, useToast } from "../../../views";
import { Button, Form, FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { CommonSelect, ClientProjectsTable, LayoutLoader } from "../../../CommonComponent";
import { useCoreData } from "../../../Hooks";
import { useEffect, useState } from "react";
import { mutations, queries } from "../../../api";
import { FreelancerDetailsProps, FreelancerUpdateFormInput } from "../../../Types";
import { SolidButton } from "../../../AbstractElement";
import { SubmitHandler, useForm } from "react-hook-form";

const EditFreelancer: React.FC<FreelancerDetailsProps> = ({ freelancerId }) => {
    const { t } = useTranslation();
    const { freelancerModal, closeFreelancerModal } = useStateContext();
    const { professionCodeOptions } = useCoreData({ loadProfessions: true });

    const [selectedProfessionsOptions, setSelectedProfessionsOptions] = useState<string>();
    const { data: freelancerData } = queries.useGetFreelancer(freelancerId!);
    const { mutate: updateFreelancer, isLoading: updateLoading } = mutations.useUpdateFreelancer();

    const { isLoading } = useLoader()
    const { showToast } = useToast()

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FreelancerUpdateFormInput>({
        defaultValues: {
            professionCode: selectedProfessionsOptions,
            fullName: '',
            otherProfessionName: ''
        }
    });

    const onSubmit: SubmitHandler<FreelancerUpdateFormInput> = async (data) => {
        updateFreelancer({ id: freelancerId!, input: data }, {
            onSuccess: () => {
                closeFreelancerModal()
                showToast("success", "Freelancer.FreelancerUpdatedSucessfully")
            }
        })
    }

    useEffect(() => {
        if (freelancerData) {
            setValue('fullName', freelancerData.fullName);
            setValue('professionCode', freelancerData.professionCode);
            setValue('otherProfessionName', freelancerData.otherProfessionName);
            setSelectedProfessionsOptions(freelancerData.professionCode)
        }
    }, [freelancerData]);

    return (
        <Offcanvas isOpen={freelancerModal.toggle} toggle={closeFreelancerModal} direction="end" className="theme-offcanvas-2">
            <div className='theme-offcanvas-box '>
                <div className="w-100">
                    {isLoading && <LayoutLoader />}
                    <OffcanvasHeader toggle={closeFreelancerModal}>{t("EditFreelancerDetails")}</OffcanvasHeader>
                    <Button className="btn-close" onClick={closeFreelancerModal} color='transparent'></Button>
                    <OffcanvasBody className="custom-scroll">
                        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="w-100 form-mb-73">
                            <Row className="gy-sm-4 gy-3 gx-0 w-100">
                                <FormGroup className='mb-0 col-12'>
                                    <label>{t("FreelancerName")}</label>
                                    <input type="text" className="form-control" id="fullName" placeholder={t("EnterFreelancerName")} {...register("fullName")} autoComplete="off" />
                                </FormGroup>
                                <FormGroup className='mb-0 col-12'>
                                    <label>{t("JobTitle")}</label>
                                    <CommonSelect
                                        placeholder={t('SelectId')}
                                        options={professionCodeOptions}
                                        selectedOptions={selectedProfessionsOptions}
                                        setSelectedOptions={(value) => {
                                            setSelectedProfessionsOptions(value);
                                            setValue('professionCode', value)
                                        }}
                                        multiSelect={false}
                                    />
                                </FormGroup>
                                {professionCodeOptions?.find(res => res.value === selectedProfessionsOptions)?.data?.entryDescriptionIsRequired &&
                                    <FormGroup className='mb-0 col-12'>
                                        <input type="text" className="form-control" placeholder={t("JobTitle")} {...register("otherProfessionName")} autoComplete="off"></input>
                                    </FormGroup>
                                }
                                <FormGroup className='mb-0 col-12'>
                                    <div className="email-box">
                                        <label>{t("EmailId")}</label>
                                        <p>{freelancerData?.email}</p>
                                    </div>
                                </FormGroup>
                                <FormGroup className='mb-0 col-12 mt-0'>
                                    <h5>{t("ProjectsWithThisFreelancer")}</h5>
                                    <ClientProjectsTable freelancerId={freelancerId} />
                                </FormGroup>
                            </Row>
                            <div className="offcanvas-footer">
                                <SolidButton className="btn-gray justify-content-center" onClick={closeFreelancerModal}>
                                    {t("Cancel")}
                                </SolidButton>
                                <SolidButton color="success" type="submit" loading={updateLoading} className="w-100 justify-content-center">
                                    {t("Update")}
                                </SolidButton>
                            </div>
                        </Form>
                    </OffcanvasBody>
                </div>
            </div>
        </Offcanvas>
    )
}

export default EditFreelancer
