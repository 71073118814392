import { useTranslation } from 'react-i18next';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useStateContext } from '../../../../views';
import QuickInvoice from './QuickInvoice';
import { Status } from '../../Widgets/Status';
import Suggestion from '../../Widgets/Suggestion';

export const TabSection = () => {

    const { t } = useTranslation();
    const { activeDashboardTab, setActiveDashboardTab } = useStateContext();
    const activeTabPane = (id: number) => setActiveDashboardTab(id);

    return (
        <Col xs="12" className="min-h-content">
            <div className="card profile-card-box">
                <div className="profile-card">
                    <Nav pills className="horizontal-tab custom-scrollbar" id="pills-tab">
                        <NavItem><NavLink className={activeDashboardTab === 1 ? "active" : ""} onClick={() => activeTabPane(1)} id="pills-suggestion-tab">{t("QuickInvoice")}</NavLink></NavItem>
                        <NavItem><NavLink className={activeDashboardTab === 2 ? "active" : ""} onClick={() => activeTabPane(2)} id="pills-suggestion-tab">{t("Suggestions")}</NavLink></NavItem>
                        <NavItem><NavLink className={activeDashboardTab === 3 ? "active" : ""} onClick={() => activeTabPane(3)} id="pills-status-tab">{t("JuuliStatus")}</NavLink></NavItem>
                    </Nav>
                    <TabContent className="horizontal-tab-content position-relative" activeTab={activeDashboardTab} id="pills-tabContent">
                        <QuickInvoice />
                        <TabPane tabId={2} id="pills-suggestion" className="suggestion-tab-content"><Suggestion /></TabPane>
                        <TabPane className="pt-sm-4 status-tab" tabId={3} id="pills-status"><Status /></TabPane>
                    </TabContent>
                </div>
            </div>
        </Col>
    )
}
