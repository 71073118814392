import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap';
import { Image, SolidButton } from '../../../AbstractElement';
import { dynamicImage } from '../../../utils';
import { useAuth, useCoreData } from '../../../Hooks';
import { CommonSelect, LayoutLoader } from '../../../CommonComponent';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ProjectFormInput, USER_ROLE } from '../../../Types';
import { mutations, queries } from '../../../api';
import { useLoader, useStateContext, useToast } from '../../../views';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProjectSchema } from '../../../Schemas';

const ProjectCanvas: React.FC<{ id?: string }> = ({ id }) => {
    const { t } = useTranslation();
    const { user } = useAuth()
    const [projectId, setProjectId] = useState<string | undefined>(id);

    const { mutate: createProject, isLoading: createLoading } = mutations.useAddProject();
    const { toggleAddNewCustomer, toggleProfileSetting } = useStateContext();

    const { showToast } = useToast()
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileName, setFileName] = useState(t("SelectFilesFromYourComputer"));
    const [file, setFile] = useState<File | null>(null);

    const { toggleProjectModal, projectModal } = useStateContext();
    const { projectCategoriesOptions, customersSelect } = useCoreData({ loadProjectCategories: true, loadCustomers: true });
    const { ProjectFormSchema } = ProjectSchema()

    const { mutate: updateProject, isLoading: updateLoading } = mutations.useUpdateProject();
    const { mutate: uploadDocuments } = mutations.useUploadProjectDocument();
    const { mutate: archiveProject } = mutations.useArchiveProject();

    const [selectedCategory, setSelectedCategoryOptions] = useState<string>('');
    const [selectedCustomer, setSelectedCustomerOptions] = useState<string>('');
    const { isLoading } = useLoader();

    const { data: project } = queries.useGetProject(projectId);

    const allowedFileTypes = [
        ".txt", ".xml", ".md", ".rtf", ".pdf", ".doc", ".docx", ".odt",
        ".xls", ".xlsx", ".ods", ".csv", ".ppt", ".pptx", ".odp", ".zip",
        ".gz", ".png", ".jpg", ".jpeg", ".gif", ".bmp", ".tiff", ".svg",
        ".webp", ".ico"
    ];

    const maxFileSize = 10 * 1024 * 1024; // 10 MB

    const selectedPlaceholder = (
        <p className='placeholder-text m-0'>
            <span className='name'>{customersSelect?.find(data => data.value === selectedCustomer)?.label}
                {customersSelect?.find(data => data.value === selectedCustomer)?.data.verified && (
                    <Image src={dynamicImage('svg/blue-tick.svg')} alt="Verified" />
                )}</span>
            <span className="mail">({customersSelect?.find(data => data.value === selectedCustomer)?.data.email})</span>
        </p>
    );

    const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm<ProjectFormInput>({
        resolver: yupResolver(ProjectFormSchema),
        defaultValues: user?.role === USER_ROLE.Freelancer ? {
            customerId: '',
            projectName: '',
            projectDetails: '',
            projectCategoryCode: ''
        } : {
            projectName: '',
            projectDetails: '',
            projectCategoryCode: ''
        }
    });

    useEffect(() => {
        if (project) {
            setValue('projectName', project.projectName!);
            setValue('projectDetails', project.projectDetails!);
            setValue('projectCategoryCode', project.projectCategoryCode!);
            setSelectedCategoryOptions(project.projectCategoryCode!);
            if (user?.role === USER_ROLE.Freelancer) {
                setValue("customerId", project.billingId)
                setSelectedCustomerOptions(project.billingId!)
            }
        }
    }, [project, setValue]);

    const onSubmit: SubmitHandler<ProjectFormInput> = async (data) => {
        if (!projectId) {
            createProject(data, {
                onSuccess: (res) => {
                    setProjectId(res?.id);

                    if (file) {
                        uploadDocuments({ id: res?.id, file: file }, {
                            onSuccess: (res) => {
                                setFileName(t("SelectFilesFromYourComputer"));
                                setFile(null);
                                handleClose();
                            },
                        });
                    } else
                        handleClose();

                    showToast("success", "ProjectCanvas.ProjectAddedSuccessfully")
                },
                onError: (error) => {
                    if (Array.isArray(error.data) && user?.role === USER_ROLE.Business && error.data[0].code === "Customer.MissingRequiredField") {
                        toggleProfileSetting(2);
                    }
                }
            });
        } else if (!!projectId) {
            updateProject({ id: projectId, input: data }, {
                onSuccess: () => {

                    if (file) {
                        uploadDocuments({ id: projectId, file: file }, {
                            onSuccess: (res) => {
                                setFileName(t("SelectFilesFromYourComputer"));
                                setFile(null);
                                handleClose();
                            },
                        });
                    } else
                        handleClose();
                    showToast("success", "ProjectCanvas.ProjectUpdatedSuccessfully")
                },
            });
        }
    };

    const handleFileUpload = (event: any) => {
        const selectedFile = event.target.files[0];
        const fileExtension = `.${selectedFile?.name?.split('.').pop().toLowerCase()}`;
        const isValidFileType = allowedFileTypes?.includes(fileExtension);
        const isValidFileSize = selectedFile.size <= maxFileSize;

        if (!isValidFileType) {
            showToast("error", "Invalid file type. Please upload a valid file.");
            return;
        }

        if (!isValidFileSize) {
            showToast("error", `File size exceeds the ${maxFileSize / 1024 / 1024}MB limit. Please upload a smaller file.`);
            return;
        }

        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        } else {
            setFileName(t("SelectFilesFromYourComputer"));
        }
    };

    const handleArchive = () => {
        archiveProject({ id: projectId!, archived: !project?.archived }, {
            onSuccess: () => {
                showToast("success", "ProjectCanvas.StatusUpdated");
            },
        });
    }

    const handleClose = () => {
        toggleProjectModal(); // Close the offcanvas
    };

    return (
        <>
            <Offcanvas isOpen={projectModal.toggle} toggle={handleClose} direction="end" className="theme-offcanvas-2">
                <div className='theme-offcanvas-box '>
                    {isLoading &&
                        <LayoutLoader />
                    }
                    <div className='w-100'>
                        <OffcanvasHeader toggle={handleClose}>{!!projectId ? t('EditProject') : t('CreateNewProject')}</OffcanvasHeader>
                        <Button className="btn-close" onClick={handleClose} color='transparent'></Button>
                        <OffcanvasBody className="custom-scroll">
                            <Form className="form-mb-73" noValidate onSubmit={handleSubmit(onSubmit)}>
                                <Row className='g-sm-4 g-3'>
                                    <Col xs="12">
                                        <FormGroup className='mb-0'>
                                            <label>{t("ProjectName")}</label>
                                            <input type="text" className={`form-control ${errors.projectName ? "required-field" : ""}`} id="name" placeholder={t("AddProjectName")} {...register("projectName")} autoComplete='off' />
                                            {errors.projectName && (
                                                <span className="required-note">
                                                    <small>{t(errors.projectName.message || "")}</small>
                                                </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    {user?.role === USER_ROLE.Freelancer && !projectId &&
                                        <Col xs="12">
                                            <FormGroup className='mb-0'>
                                                <label>{t("Client")}</label>
                                                <CommonSelect
                                                    placeholder={selectedCustomer ? selectedPlaceholder : t('SelectClient')}
                                                    hidePlaceholder={true}
                                                    options={customersSelect!}
                                                    className={`max-dropdown ${errors.customerId ? "required-field" : ""}`}
                                                    selectedOptions={selectedCustomer}
                                                    setSelectedOptions={(value) => {
                                                        setSelectedCustomerOptions(value);
                                                        setValue('customerId', value);
                                                        trigger('customerId');
                                                    }}
                                                    buttonText={t('NewClient')}
                                                    buttonClass='btn create-btn'
                                                    multiSelect={false}
                                                    onClick={toggleAddNewCustomer}
                                                    search={true}
                                                >
                                                    {(option) => (
                                                        <p>{option.label} {option?.data?.verified && <Image src={dynamicImage(`svg/blue-tick.svg`)} />}</p>

                                                    )}
                                                </CommonSelect>
                                                {errors.customerId && (
                                                    <span className="required-note">
                                                        <small>{t(errors.customerId.message || "")}</small>
                                                    </span>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col xs="12">
                                        <FormGroup className='mb-0'>
                                            <label>{t("Category")}</label>
                                            <CommonSelect
                                                placeholder={t('Select')}
                                                options={projectCategoriesOptions!}
                                                selectedOptions={selectedCategory}
                                                className={`max-dropdown body outside-dropdown ${errors.projectCategoryCode ? "required-field" : ""}`}
                                                setSelectedOptions={(value) => {
                                                    setSelectedCategoryOptions(value);
                                                    setValue('projectCategoryCode', value);
                                                    trigger('projectCategoryCode');
                                                }}
                                                multiSelect={false}
                                            />
                                            {errors.projectCategoryCode && (
                                                <span className="required-note">
                                                    <small>{t(errors.projectCategoryCode.message || "")}</small>
                                                </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        <FormGroup className='mb-0'>
                                            <label>{t("ProjectDetails")}</label>
                                            <div className="textarea-box">
                                                <textarea rows={3} className="form-control note" id="notes" placeholder={t("AddYourProjectDetails")} {...register("projectDetails")} autoComplete='off' />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                        {
                                            !projectId &&
                                            <>
                                                <label>{t("ProjectDocuments")}</label>
                                                <FormGroup className='mb-0'>
                                                    <div className="custom-file-input w-100">
                                                        <Image src={dynamicImage("svg/paper.svg")} alt="paper" />
                                                        <input type="file" accept={allowedFileTypes.join()} ref={fileInputRef} className="form-control" id="file" style={{ display: "none" }} onChange={handleFileUpload} autoComplete='off' />
                                                        <button type="button" className="form-control text-start" onClick={() => fileInputRef?.current?.click()}>
                                                            <span>{fileName}</span>
                                                        </button>
                                                    </div>
                                                </FormGroup>
                                            </>
                                        }
                                        {/* { //TODO: The archiving feature has been disabled and will be activated later.
                                            !!projectId && <div className="box-flex">
                                                <div className="right-box">
                                                    <SolidButton className='d-inline-block justify-content-center' onClick={handleArchive}><Image src={dynamicImage("svg/archive.svg")} className='me-1' alt="paper" /> {project?.archived ? t("UnarchiveThisProject") : t("ArchiveThisProject")}</SolidButton>
                                                </div>
                                            </div>
                                        } */}
                                    </Col>
                                </Row>
                                <div className="offcanvas-footer">
                                    <SolidButton className='btn-gray justify-content-center' onClick={handleClose}>{t("Cancel")}</SolidButton>
                                    <SolidButton color="success" type='submit' loading={!projectId ? createLoading : updateLoading} className='justify-content-center'>
                                        {!!projectId ? t('UpdateProject') : t('CreateProject')}
                                    </SolidButton>
                                </div>
                            </Form>
                        </OffcanvasBody>
                    </div>
                </div>
            </Offcanvas>
        </>
    )
}
export default ProjectCanvas;