import { useTranslation } from 'react-i18next';
import { Image, DocumentStatusBadge } from '../../../AbstractElement';
import { convertToReadableDate, dynamicImage } from '../../../utils';
import { mutations, queries } from '../../../api';
import { useToast } from '../../../views';
import { CONTRACT_TYPE } from '../../../Types';
import { SITE_SETTINGS } from '../../../Constants';

const Documents = () => {
  const { t } = useTranslation();
  const { data: agreements } = queries.useGetUserAgreement();
  const { showToast } = useToast()
  const { mutate: refetchDownload, isLoading: loading } = mutations.useDownloadAgreement();

  const download = (id: string, fileName: string) => {
    refetchDownload({ id: id, fileName: fileName }, {
      onSuccess: () => {
        showToast("success", "Documents.DocumentDownloadedSuccessfully")
      }
    });
  };

  return (
    <div className="setting-main">
      <div className="document-detail">
        <ul className="document-list custom-scroll">
          {agreements?.items.map((item, i) => (
            <li className="document-item" key={i}>
              <div className="document-data">
                <div className="d-flex align-items-center gap-3">
                  <Image src={dynamicImage('svg/paper.svg')} className='d-sm-block d-none' alt="Document Icon" />
                  <div className="w-100">
                    <span className='text-capitalize'>{t(item.contractType)}</span>
                  </div>
                </div>
                <div className="document-action">
                  <h5>{convertToReadableDate(item.createdAt, "medium")}</h5>
                  <DocumentStatusBadge documentStatus={item.status} />
                  {item.fileName ? (
                    <button
                      onClick={() => download(item.id, item.fileName)}
                      className='document-btn'
                      disabled={loading}
                    >
                      {loading ? (
                        <div className="loader d-flex">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="animate-spin h-full">
                            <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z"></path>
                            <path d="M18 12a6 6 0 0 1 -6 6"></path>
                          </svg>
                        </div>
                      ) : (
                        <Image src={dynamicImage('svg/download.svg')} alt="Download Icon" className="download" />
                      )}
                    </button>
                  ) : item.contractType === CONTRACT_TYPE.PrivacyPolicy ? (
                    <a
                      href={SITE_SETTINGS.PRIVACY_POLICY} target='_blank' className='document-btn'
                    >
                      <Image src={dynamicImage('svg/link.svg')} alt="link" className="download" />
                    </a>
                  ) : item.contractType === CONTRACT_TYPE.TermsAndConditions ? (
                    <a href={SITE_SETTINGS.TERMS_CONDITIONS} target='_blank' className='document-btn'>
                      <Image src={dynamicImage('svg/link.svg')} alt="link" className="download" />
                    </a>
                  ) :
                    <button className='document-btn'>
                      <Image src={dynamicImage('svg/link.svg')} alt="link" />
                    </button>
                  }
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Documents;