import React, { useCallback } from 'react'
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from 'react-i18next'

import { Image, SolidButton } from '../../../AbstractElement'
import { dynamicImage } from '../../../utils'
import { GoogleLinkedinMediaProps } from '../../../Types/Auth/Auth';

const GoogleLinkedinMedia: React.FC<GoogleLinkedinMediaProps> = ({ googleText, linkedinText }) => {
    const { t } = useTranslation();

    const googleLogin = useGoogleLogin({
        flow: "auth-code",
        ux_mode: "redirect",
        redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
        select_account: false
    });

    const linkedinLogin = useCallback(() => {
        const linkedInUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URI}&scope=r_liteprofile%20r_emailaddress`;
        window.location.assign(linkedInUrl);
    }, []);

    return (
        <>
            <li>
                <SolidButton onClick={googleLogin} className="btn-white social-icon">
                    <Image src={dynamicImage("svg/google.svg")} className="auth-image" alt="google" />
                    <span>{t(googleText)}</span>
                </SolidButton>
            </li>
            <li>
                <SolidButton className="btn-white social-icon" onClick={linkedinLogin}>
                    <Image src={dynamicImage("svg/linkedin.svg")} className="auth-image" alt="linkedin" />
                    <span>{t(linkedinText)}</span>
                </SolidButton>
            </li>
        </>
    )
}
export default GoogleLinkedinMedia;