import { InvoiceStatus } from "./InvoiceStatus";

export type DraftInvoiceItem = {
    id: string;
    createdAt: string;
    modifiedAt: string;

    projectId: string;
    projectName: string;
    projectCategoryCode: string;

    documentNo: string;
    documentDate: Date;
    dueDate: Date;

    internationalBranchId: string | null;

    customerName: string;
    customerEmail: string;

    amount: number;
    total: number;
    vatRate: number;
    vatAmount: number;
    totalIncludingTaxes: number;
    currencyCode: string;
};

export type DraftInvoice = DraftInvoiceItem & {
    issuedByName: string;
    issuedByVatNumber: string;
    issuedByAddress: string;

    customerId: string | null;

    payOutBankAccountId: string | null;
    payOutCurrencyCode: string;

    serviceFeePayer: ServiceFeePayer;
    serviceFeeRate: number;
    serviceFeeAmount: number;
    
    notes: string;

    invoiceStatus: InvoiceStatus;
};

export type DraftInvoiceCreateRequest = {
    customerId: string;
    customerName: string;
    email: string;
    countryCode: string;
    projectId: string;
    projectName: string;
    currencyCode: string;
};

export type QuickInvoiceRequest = {
    customerId: string | null;
    customerName: string;
    email: string;
    countryCode: string
    itemName: string;
    price: number;
    currencyCode: string;
};

export type DraftInvoiceUpdateRequest = {
    projectId: string;
    documentDate: Date;
    dueDate: Date;
    currencyCode: string;
    payOutBankAccountId: string;
};

// export interface InvoiceFormInput {

//     customerId: string | null;
//     customerName: string;
//     email: string;
//     countryCode: string

//     projectId: string;
//     projectName: string;

//     currencyCode: string | null;

//     documentDate: Date | null;
//     dueDate: Date | null;
//     payOutBankAccountId: string | null;

//     // serviceFeePayer: string,
//     // payOutCurrencyCode: string,
// }

export interface InvoiceHistory {
    id: string;
    status: InvoiceStatus;
    descriptionCode: string;
    invoiceStatusReason: string;
    invoiceStatusReasonDescription: string;
    createdAt: string;
}

export interface CalculateAmount {
    amount: number;
    total: number;
    totalIncludingTaxes: number;
    currencyCode: string;
    estimatedPayoutCurrency: string;
    estimatedPayoutAmount: number;
    serviceFeePayer: ServiceFeePayer;
    serviceFeeRate: number;
    serviceFeeAmount: number;
    vatRate: number;
    vatAmount: number;
}

export interface TabItem {
    id: number;
    key: string;
    title: string;
    role?: string;
}

interface InvoiceFormProps {
    tabItems: TabItem[];

    setNavActive: (id: number) => void;
    navActive: number;

    invoiceCreate: Partial<DraftInvoiceCreateRequest>;

    loading: boolean;
}
interface InvoiceUpdateFormProps extends InvoiceFormProps {
    calculateAmount?: CalculateAmount

    updateInvoiceInput: Partial<DraftInvoice>;
    setUpdateInvoiceInput: (value: Partial<DraftInvoice>) => void;

    updateInvoice: (invoiceCreateValue: Partial<DraftInvoiceCreateRequest>, input: Partial<DraftInvoice>, callback?: (response: DraftInvoice) => void) => void;
}

export type InvoiceProjectProps = InvoiceFormProps & {
    invoiceId?: string;
    setInvoiceCreate: (value: Partial<DraftInvoiceCreateRequest>) => void;
}

export type WorkItemProps = InvoiceProjectProps & InvoiceUpdateFormProps & {
    createInvoice: (input: Partial<DraftInvoiceCreateRequest>) => void;
    refetchCalculate: any;
}

export type FinancialProps = InvoiceUpdateFormProps & {
}

export type SummaryProps = InvoiceUpdateFormProps & {
    refetchCalculate: any;
    setPromoCode: (promoCode: string) => void;
    promoCode: string
}

export const SERVICE_FEE_PAYER = {
    Company: "Company",
    Freelancer: "Freelancer"
} as const;

export type ServiceFeePayer = ObjectValues<typeof SERVICE_FEE_PAYER>;