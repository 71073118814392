import { useMemo } from 'react';
import { Image } from '../../../AbstractElement';
import { queries } from '../../../api';
import { useAuth, useCoreData } from '../../../Hooks';
import { USER_ROLE } from '../../../Types';
import { dynamicImage } from '../../../utils';
import { useStateContext } from '../../../views';
import ProfilePhotoChange from '../Freelancer/ProfileSettings/Modals/ProfilePhotoChange';

export const Details = () => {
  const { data: userDetails } = queries.useGetUserDetails();
  const { changeProfilePhotoModal, toggleProfilePhotoModal } = useStateContext()
  const { data: ProfileImageData } = queries.useGetProfileImage(userDetails?.hasProfileImage ?? false)
  const { user } = useAuth();

  const { fullName } = useCoreData({loadUserDetails: true});

  const email = useMemo(() => {
    return userDetails?.email || null;
  }, [userDetails]);

  return (
    <div className="profile-box">
      <div className={`profile-image-box ${user?.role === USER_ROLE.Business ? 'business-header' : ''}`}>
        {userDetails?.hasProfileImage && ProfileImageData ?
          <Image className='nav-profile-box user-profile-image img-fluid' src={`data:image/png;base64, ${ProfileImageData}`} alt="profile" />
          : (<>{fullName ?
            (<div className="initial-letter d-sm-flex d-none">
              <span>{fullName?.split(' ')?.map((letter: string) => letter.charAt(0).toUpperCase())?.join('')?.slice(0, 2)}</span>
            </div>) :
            (
            <Image src={dynamicImage("icon_user.png")} className="nav-profile-box profile-image" alt="logo" />
          )
          }</>)
        }
        <div className="upload-image" onClick={toggleProfilePhotoModal}>
          <Image src={dynamicImage("svg/camera.svg")} className="camera-image" alt="camera" />
        </div>
      </div>
      <div>
        {fullName && <h3>{`${fullName}`}</h3>}
        {email && <p>{email}</p>}
      </div>
      {changeProfilePhotoModal && <ProfilePhotoChange />}
    </div>
  )
}
