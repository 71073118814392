import { useState } from 'react';
import { ToastMessage, ToastType } from '../Types/CommonElement';

const useToast = () => {
  const [toast, setToast] = useState<ToastMessage>({ type: undefined, message: '', visible: false });

  const showToast = (type: ToastType, message: string) => {
    setToast({ type, message, visible: true });
    setTimeout(() => {
      setToast({ type: undefined, message: '', visible: false });
    }, 3500);
  };

  return [toast, showToast] as const;
};

export default useToast;
