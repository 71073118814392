import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { TabSection } from './TabSection';
import { LatestTransactions } from './LatestTransactions';
import { RecentInvoices } from './RecentInvoices';
import { Questionnaire } from './Questionnaire';
import { queries } from '../../../api';
import { useLayoutContext } from '../../../views';
import { STORAGE_KEYS } from '../../../Constants';
import { useStorage } from '../../../Hooks';
import LastInvoice from '../Widgets/LastInvoice';

const FreelancerDashboard = () => {
  const { questionnaireModal, toggleQuestionnaireModal } = useLayoutContext();
  const { data: accountInfo } = queries.useGetUserDetails(false);
  const [modalManuallyClosed, setModalManuallyClosed] = useState(false);
  const { setItem, getItem } = useStorage();

  const hasSubmittedQuestionnaire = getItem(STORAGE_KEYS.HAS_SEEN_QUESTIONNAIRE) === 'true';

  useEffect(() => {
    // Check if the modal should be shown
    if (!modalManuallyClosed && !hasSubmittedQuestionnaire &&
      (!accountInfo?.expertiseAreaCode &&
        !accountInfo?.invoiceFrequencyCode &&
        !accountInfo?.invoiceAmountEstimateCode &&
        !accountInfo?.usualCustomerCountCode)) {

      // Only toggle the modal if it isn't already open
      if (!questionnaireModal) {
        toggleQuestionnaireModal();
      }
    }
  }, [accountInfo, questionnaireModal, modalManuallyClosed, toggleQuestionnaireModal]);

  const handleCloseModal = () => {
    setModalManuallyClosed(true);
    toggleQuestionnaireModal();
  };

  // Handle submission of the questionnaire
  const handleSubmitQuestionnaire = () => {
    setItem(STORAGE_KEYS.HAS_SEEN_QUESTIONNAIRE, 'true');
    handleCloseModal();
  };

  return (
    <Container fluid className="order-dashboard">
      <Row className="g-2 pt-0 main-row custom-row">
        <Col xl="9">
          <Row className="d-block g-2 h-100 dashboard-custom-height">
            <TabSection />
            <RecentInvoices />
          </Row>
        </Col>
        <Col xl="3">
          <Row className="d-block g-2 h-100 dashboard-custom-height-2">
            <LastInvoice />
            <LatestTransactions />
          </Row>
        </Col>
      </Row>

      {questionnaireModal &&
        <Questionnaire onClose={handleSubmitQuestionnaire} userDetails={accountInfo!} />
      }
    </Container>
  )
}
export default FreelancerDashboard;