import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Image, InvoiceStatusBadge, SolidButton } from '../../../AbstractElement';
import { CommonTable } from '../../../CommonComponent';
import { useTableFilterHelper } from '../../../Hooks';
import { Column, COLUMN_TYPE, TableClickedAction, TableConfig } from '../../../Types/CommonComponent';
import { dynamicImage } from '../../../utils';
import { queries } from '../../../api';
import { FreelancerStatusBadge } from '../../../CommonElement/Badge/FreelancerStatusBadge';
import { useLayoutContext, useStateContext } from '../../../views';
import { ProjectFreelancerItem } from '../../../Types';

const Freelancers = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { isMobile } = useLayoutContext()
    const { openViewFreelancerModal, openEditFreelancerModal, openInviteFreelancerModal } = useStateContext();

    const { pageNumber, pageSize, searchTerm, params, goToPage, handleSetPageSize, handleSetSearch, toggleSortType, handleSetSortBy } = useTableFilterHelper();

    const { data: freelancers } = queries.useGetProjectFreelancers(id, params);
    const searchBar = document.getElementById('searchBar')

    const tableConfig = React.useMemo<TableConfig<ProjectFreelancerItem>>(() => ({
        columns: [
            {
                title: "FreelancerName", dataField: [{ field: "fullName", class: 'top hover-link', type: '' }, {
                    field: "email", class: '', type: '', renderer: (data: ProjectFreelancerItem) => {
                        return <>{data?.email && <span>({data?.email})</span>}</>
                    }
                }], class: 'freelancer-box', sortable: true, sortField: 'fullName', sortDirection: 'desc'
            },
            { title: "InvitationDate", class: "date", dataField: [{ field: "createdAt", class: '', type: COLUMN_TYPE.Date, params: { format: "medium" } }], sortable: true, sortField: 'createdAt', sortDirection: 'desc' },
            {
                title: "Status",
                dataField: [{ field: "status", class: '', type: '', renderer: (data: ProjectFreelancerItem) => <FreelancerStatusBadge inviteStatus={data.status} declineReason={data.declineReason} /> }],
                sortable: true, sortField: 'status', sortDirection: 'desc',
            },
            ...(isMobile ?
                [{
                    title: "OngoingInvoice", class: 'flex-invoice-box', dataField: [{
                        field: "awaitingInvoiceStatus", class: '', type: '', renderer: (data: ProjectFreelancerItem) => {
                            return <>
                                <div className='freelancer-flex'>
                                    {data?.lastInvoiceModifiedDate &&
                                        <div>
                                            <span className="responsive-span">{t("LastInvoice")}</span>
                                            <InvoiceStatusBadge invoiceStatus={data.awaitingInvoiceStatus} />
                                            <span>{data?.lastInvoiceModifiedDate}</span>
                                        </div>}
                                    {data?.awaitingInvoiceModifiedDate &&
                                        <div>
                                            <span className="responsive-span">{t("OngoingInvoice")}</span>
                                            <InvoiceStatusBadge invoiceStatus={data.awaitingInvoiceStatus} />
                                            <span>{data?.awaitingInvoiceModifiedDate}</span>
                                        </div>}
                                </div>
                            </>
                        }
                    }], sortable: true, sortField: 'awaitingInvoiceStatus', sortDirection: 'desc'
                } as Column<ProjectFreelancerItem>]
                :
                [
                    { title: "LastInvoice", class: 'last-invoice-box', dataField: [{ field: "lastInvoiceStatus", class: '', type: '', renderer: (data: ProjectFreelancerItem) => { return <InvoiceStatusBadge invoiceStatus={data.lastInvoiceStatus} /> } }, { field: "lastInvoiceModifiedDate", type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'lastInvoiceStatus', sortDirection: 'desc' } as Column<ProjectFreelancerItem>,
                    { title: "OngoingInvoice", class: 'ongoing-invoice-box', dataField: [{ field: "awaitingInvoiceStatus", class: '', type: '', renderer: (data: ProjectFreelancerItem) => { return <InvoiceStatusBadge invoiceStatus={data.awaitingInvoiceStatus} /> } }, { field: "awaitingInvoiceModifiedDate", type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'awaitingInvoiceStatus', sortDirection: 'desc' } as Column<ProjectFreelancerItem>,
                ])
        ],
        data: freelancers?.items ?? [],
        total: freelancers?.totalResults ?? 0,
        isColumnChooser: true,
        actionsDropDown: [
            { label: "ViewDetails", actionToPerform: "view" },
            { label: "Edit", actionToPerform: "edit" },
        ],
    }), [freelancers]);

    const handleActionPerform = (action: TableClickedAction<ProjectFreelancerItem>) => {
        switch (action.actionToPerform) {
            case 'view':
                openViewFreelancerModal(action.data.id)
                break;
            case 'edit':
                openEditFreelancerModal(action.data.id)
                break;
            default:
                console.warn("Unknown action:", action.actionToPerform);
        }
    };
    return (
        <>
            <div className="freelancer-new d-flex flex-sm-row flex-column align-items-sm-center align-items-start gap-sm-4 gap-0 justify-content-between w-100 mb-sm-0 mb-2">
                {!isMobile && <div className="total-data">
                    <span>{freelancers?.totalResults} <small className="d-sm-flex d-none">{t("Freelancer")}</small></span>
                </div>
                }
                <div className="tab-right-filter">
                    <div className="search-input-form">
                        <div className="search-input d-flex" id="searchBar">
                            <input onFocus={() => { searchBar?.classList.add('full-search') }} className="form-control" placeholder={t("SearchByName")} value={searchTerm} onChange={(e) => handleSetSearch(e.target.value)} autoComplete="off" />
                            <Image src={dynamicImage("svg/search.svg")} alt="search" className="search-img" />
                            <Image src={dynamicImage("svg/search-red.svg")} alt="search" className="search-img-hover" />
                            {searchTerm.length > 0 && (
                                <div className="close-img" onClick={() => { handleSetSearch(""); searchBar?.classList.remove('full-search') }}>
                                    <Image src={dynamicImage("svg/close-red.svg")} alt="close" />
                                </div>
                            )}
                        </div>
                    </div>
                    {/* 
                    //TODO:It can be connected later.
                    <CommonSelect selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} options={options} placeholder={t("FilterByStatus")} multiSelect={false} /> */}
                    <SolidButton className="text-nowrap btn-solid" onClick={() => { openInviteFreelancerModal(id) }}>
                        <Image src={dynamicImage("svg/plus-white.svg")} alt="arrow" />
                        <span className="d-xxl-inline-flex d-none">{t("InviteNewFreelancer")}</span>
                    </SolidButton>
                </div>
            </div>
            <div className="project-detail-invoice-table freelancer-project">
                <CommonTable tableConfiguration={tableConfig} currentPage={pageNumber} itemsPerPage={pageSize} goToPage={goToPage} handleSetPageSize={handleSetPageSize} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} onActionPerform={handleActionPerform} />
            </div>

        </>
    )
}
export default Freelancers;