import { useTranslation } from 'react-i18next'
import CommonTable from './Table'
import { ProjectLookupItem } from '../Types'
import { TableConfig } from '../Types/CommonComponent'
import { useMemo } from 'react'
import { useCoreData, useTableFilterHelper } from '../Hooks'
import { ROUTES } from '../Constants'
import { queries } from '../api'

const ClientProjectsTable: React.FC<{ freelancerId?: string | null, clientId?: string }> = ({ freelancerId, clientId }) => {
    const { t } = useTranslation()
    const { projectCategoriesOptions } = useCoreData({ loadProjectCategories: true });
    const { pageNumber, pageSize, params, goToPage, handleSetPageSize, toggleSortType, handleSetSortBy } = useTableFilterHelper({ archiveStatus: null });

    const { data: projects } = freelancerId ? queries.useGetFreelancerProject(freelancerId, params) : queries.useGetCustomerProject(clientId!, params);

    const tableConfig = useMemo<TableConfig<ProjectLookupItem>>(() => ({
        columns: [
            {
                title: "",
                dataField: [{
                    field: 'projectName',
                    class: 'top hover-link',
                    renderer: (data: ProjectLookupItem) => (
                        <>
                            <h6 className="hover-link">{data.projectName}</h6>
                            {projectCategoriesOptions && data.projectCategoryCode && <span>({projectCategoriesOptions.find(f => f.value === data.projectCategoryCode)?.label})</span>}
                        </>
                    ),
                }],
            },
            {
                title: "",
                dataField: [{
                    field: 'archived',
                    renderer: (data) => {
                        return data.archived
                            ? <span className="archived-badge justify-content-center ms-5 badge bg-transparent rounded-pill">{t("Archived")}</span>
                            : ''
                    }
                }]
            }
        ],
        data: projects?.items || [],
        isColumnChooser: false,
        actionsDropDown: [
            { label: "ProjectDetails", getNavigateUrl: (data: ProjectLookupItem) => { return ROUTES.ProjectDetails.replace(':id', data.id.toString()); } },
        ],
        total: projects?.totalResults ?? 0,
    }), [projects, projectCategoriesOptions]);

    return (
        <>
            <div>
                {tableConfig.data.length > 0 ? (
                    <CommonTable tableConfiguration={tableConfig} currentPage={pageNumber} itemsPerPage={pageSize} goToPage={goToPage} handleSetPageSize={handleSetPageSize} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} />
                ) : (
                    <div className="no-data-found">
                        <h5 className='mt-3'>{t("NoDataFound")}</h5>
                    </div>
                )}
            </div>
        </>
    )
}

export default ClientProjectsTable
