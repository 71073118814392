import React from "react";
import { useTranslation } from "react-i18next";

import { Image } from "../../AbstractElement";
import { convertToReadableDate, dynamicImage } from "../../utils";
import { useLayoutContext, useStateContext } from "../../views";
import { useDropdown, useAuth } from "../../Hooks";
import { queries } from "../../api";
import SVG from "../../CommonElement/SVG/Svg";

const NotificationSection: React.FC = () => {
  const { toggleState } = useLayoutContext();
  const { t } = useTranslation();
  const { data: activities } = queries.useGetActivities();
  const { user } = useAuth();
  const notificationDropdown = useDropdown<HTMLLIElement>(toggleState);
  const { openInvoiceDetailModal } = useStateContext();

  return (
    <>
      <li className="notification-box onhover-dropdown" ref={notificationDropdown.ref} onClick={notificationDropdown.toggle}>
        <div>
          <Image src={dynamicImage("svg/icon_notification.svg")} className="nav-icon" alt="logo" />
          {activities?.items && activities?.items?.length > 0 && <div className="notify-badge" />}
        </div>
        {notificationDropdown.isOpen && (
          <ul className="notification-box-menu onhover-dropdown-menu open">
            <h6><span>{activities?.totalResults}</span> {t("NewActivity")}</h6>
            <ul>
              {activities?.items.map((item, index) => (
                <li key={index}>
                  <p>
                    {t(`${user?.role}.Notification.${item.status}`, item)} {convertToReadableDate(item.createdAt, "long")}
                  </p>
                  {item.invoiceScopeId &&
                    <div className="view-more" onClick={() => { openInvoiceDetailModal(item.invoiceScopeId); }}>
                      {t("View")}
                      <SVG iconId="stroke-right-arrow" className="nav-icon" />
                    </div>
                  }
                </li>
              ))}
            </ul>
          </ul>
        )}
      </li>
    </>
  );
};

export default NotificationSection;
