import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";

import { Image, SolidButton } from "../../../../AbstractElement";
import { dynamicImage, getMessages } from "../../../../utils";
import { ROUTES, SITE_SETTINGS } from "../../../../Constants";
import { useAuth, usePasswordStrength } from "../../../../Hooks";
import { SignupFormValues } from "../../../../Types/Auth/Auth";
import { PassStrength } from "../../Widgets";
import { LoginSchema } from "../../../../Schemas";
import { useToast } from "../../../../views";
import { appInsights } from "../../../../api";

const SignupForm: React.FC = () => {
    const { t } = useTranslation();
    const [isSignUpSubmitted, setIsSignUpSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { signup, signin } = useAuth();
    const { FreelancerSignUpSchema } = LoginSchema();

    const { register, handleSubmit, formState: { errors }, watch } = useForm<SignupFormValues>({ resolver: yupResolver(FreelancerSignUpSchema), });

    const [passwordVisibility, setPasswordVisibility] = useState([false, false]);

    const togglePasswordVisibility = (index: number) => {
        setPasswordVisibility((prevState) => {
            const newVisibility = [...prevState];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };
    const { showToast } = useToast();

    const password = watch('password', '');
    const passwordStrength = usePasswordStrength(password);

    const onHandleSignup: SubmitHandler<SignupFormValues> = async (data) => {
        setLoading(true);
        try {
            await signup({ email: data.email, password: data.password },
                async () => {
                    try {
                        (window as any)?.dataLayer.push({ event: "ta_signup" });
                    } catch (ex) {
                        appInsights.trackException({
                            error: new Error('Could not trigger ta_signup event'),
                            properties: { error: ex }
                        });
                    }
                    await signin({ ...data })
                }
            );
        } catch (err: any) {
            showToast("error", getMessages(err));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="auth-card">
            <div className="auth-title">
                <h2>{t("CreateNewAccount")}</h2>
            </div>
            <div className="login-detail">
                <Form onSubmit={handleSubmit(onHandleSignup)} noValidate>
                    <FormGroup className="form-group">
                        <input {...register("email")} className={`form-control ${errors.email ? "required-field" : ""}`} id="email" placeholder={t("EmailAddress")} type="email" />
                        {errors.email && (
                            <span className="required-note">
                                <small>{t(errors.email.message || "")}</small>
                            </span>
                        )}
                    </FormGroup>
                    {/*
                     //TODO: It can be connected later according to figma.
                    <FormGroup>
                        <input {...register("phone")} className={`form-control ${errors.phone ? "required-field" : ""}`} id="phone" placeholder={t("PhoneNumbers")} type="text" />
                         {errors.phone && (<span className="required-note"><small>{t(errors.phone.message)}</small></span>)}
                     </FormGroup> */}
                    <FormGroup className="form-group">
                        <div className="position-relative">
                            <input  {...register("password")} className={`form-control pr-45 ${errors.password ? "required-field" : ""}`} placeholder={t("Passwords")} type={passwordVisibility[0] ? "text" : "password"} />
                            <div className="toggle-password" onClick={() => togglePasswordVisibility(0)}>
                                <Image src={dynamicImage(`svg/${passwordVisibility[0] ? "icon_showpasson.svg" : "icon_showpassoff.svg"}`)} className="auth-image" alt="show-password" />
                            </div>
                        </div>
                        {errors.password && (
                            <span className="required-note">
                                <small>{t(errors.password.message || "")}</small>
                            </span>
                        )}
                        {password.length > 0 && passwordStrength && <PassStrength passwordStrength={passwordStrength} />}
                    </FormGroup>
                    <FormGroup className="form-group">
                        <div className="position-relative">
                            <input {...register("confirmPassword")} className={`form-control pr-45 ${errors.confirmPassword ? "required-field" : ""}`} id="confirmPassword" placeholder={t("ConfirmPassword")} type={passwordVisibility[1] ? "text" : "password"} />
                            <div className="toggle-password" onClick={() => togglePasswordVisibility(1)}>
                                <Image src={dynamicImage(`svg/${passwordVisibility[1] ? "icon_showpasson.svg" : "icon_showpassoff.svg"}`)} className="auth-image" alt="show-password" />
                            </div>
                        </div>
                        {errors.confirmPassword && (
                            <span className="required-note">
                                <small>{t(errors.confirmPassword.message || "")}</small>
                            </span>
                        )}
                    </FormGroup>
                    <FormGroup className="form-group">
                        <div className="form-check mx-sm-4 mx-1 check-required">
                            <input type="checkbox" className={`form-check-input ${isSignUpSubmitted && errors.terms ? "check-required" : isSignUpSubmitted ? "text-success" : ""}`} id="terms" {...register("terms")} />
                            <label htmlFor="terms" className="cursor-pointer" dangerouslySetInnerHTML={{
                                __html: t('IAgreeToPrivacyPolicyAndTermsAndConditions', {
                                    privacyPolicy: SITE_SETTINGS.PRIVACY_POLICY,
                                    termsAndConditions: SITE_SETTINGS.TERMS_CONDITIONS,
                                })
                            }}></label>
                        </div>
                        {errors.terms && (
                            <span className="required-note">
                                <small>{t(errors.terms.message || "")}</small>
                            </span>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <SolidButton type="submit" loading={loading} onClick={() => setIsSignUpSubmitted(true)}>
                            {t("SignUp")}
                        </SolidButton>
                    </FormGroup>
                    <FormGroup className="form-group">
                        <div className="forgot-pass signup">
                            {t("AlreadyAMember")}
                            <Link to={ROUTES.FreelancerLogIn}>{t("LoginHere")}</Link>
                        </div>
                    </FormGroup>
                </Form>
            </div>
        </div>
    );
};

export default SignupForm;
