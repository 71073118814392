import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SolidButton } from '../../../../AbstractElement';
import { mutations } from '../../../../api';
import { ROUTES } from '../../../../Constants';
import Loader from '../../../../Layout/Loader';
import { DefaultErrorResponse } from '../../../../Types/ErrorResponse';
import { getMessages, getParam } from '../../../../utils';
import { useStateContext, useToast } from '../../../../views';

const StripeRedirect = () => {
    const { mutate: pay } = mutations.usePayWithCreditCard();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<DefaultErrorResponse["data"]>([]);
    const { showToast } = useToast();
    const { openInvoiceDetailModal, closeInvoiceDetailModal } = useStateContext();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false); // New state to track payment success

    useEffect(() => {
        const invoiceScopeId = getParam("invoiceScopeId");
        if (!invoiceScopeId) {
            navigate(ROUTES.Dashboard);
            return;
        }

        let attemptCount = 0;
        const maxAttempts = 20;
        const interval = 3000;

        const attemptPayment = () => {
            if (attemptCount >= maxAttempts || isPaymentSuccessful) {
                setIsLoading(false);
                openInvoiceDetailModal(invoiceScopeId);
                return;
            }
            attemptCount += 1;

            pay(invoiceScopeId, {
                onSuccess: () => {
                    setIsLoading(false);
                    setIsPaymentSuccessful(true);
                    navigate(ROUTES.PaymentAgreement.replace(':id', invoiceScopeId.toString()));
                },
                onError: (e) => {
                    setErrors((e as DefaultErrorResponse).data);
                    setTimeout(attemptPayment, interval);

                    if (Array.isArray(e)) {
                        showToast("error", getMessages(e));
                    }
                },
            });
        };

        attemptPayment();

        const timeoutId = setTimeout(() => {
            if (!isPaymentSuccessful) {
                setIsLoading(false);
                openInvoiceDetailModal(invoiceScopeId);
            }
        }, maxAttempts * interval);

        return () => clearTimeout(timeoutId);
    }, [pay, navigate, showToast, isPaymentSuccessful]);

    if (errors.length > 0)
        return (
            <div>
                <p>{t("SomethingWentWrongDuringStripeRedirection")}</p>:
                <SolidButton onClick={navigate(ROUTES.Dashboard)}>{t("GoToDashboard")}</SolidButton>
            </div>
        );
    return <>
        <>
            {isLoading &&
                <Loader />
            }
        </>
    </>
};

export default StripeRedirect;