import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Image, SolidButton } from '../../../AbstractElement';
import { mutations, queries } from '../../../api';
import { CommonTable, ConfirmationDialog } from '../../../CommonComponent';
import { useAuth, useTableFilterHelper } from '../../../Hooks';
import { ProjectDocument, USER_ROLE } from '../../../Types';
import { Column, COLUMN_TYPE, TableClickedAction, TableConfig } from '../../../Types/CommonComponent';
import { dynamicImage } from '../../../utils';
import { useLayoutContext, useStateContext, useToast } from '../../../views';

const Documents = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { id: projectId } = useParams<{ id: string }>();
    const { toggleProjectDocumentModal } = useStateContext();
    const { showToast } = useToast()
    const { isMobile } = useLayoutContext()

    const { mutate: deleteDocuments } = mutations.useDeleteProjectDocument();
    const { mutate: refetchDownload } = mutations.useDownloadProjectDocument();

    const searchBar = document.getElementById('documentSearch')

    const { pageNumber, pageSize, searchTerm, params, goToPage, handleSetPageSize, handleSetSearch, toggleSortType, handleSetSortBy } = useTableFilterHelper();
    const { data: documentsData } = queries.useGetProjectDocuments({ id: projectId!, params: params })

    const tableConfig = React.useMemo<TableConfig<ProjectDocument>>(() => ({
        columns: [
            { title: "Document", class: "document-box", dataField: [{ field: "fileName", class: 'top hover-link', type: '', renderer: (data: any) => { return <div className='d-flex gap-2 align-items-center'><Image src={dynamicImage('svg/paper.svg')} className='d-sm-block d-none' alt="Document Icon" />{data.fileName}</div> } }], sortable: true, sortField: 'fileName', sortDirection: 'desc' },
            ...(user?.role === USER_ROLE.Business ? [{
                title: "Shared freelancers", dataField: [
                    {
                        field: 'freelancers',
                        class: '', type: '',
                        renderer: (data) => {
                            const freelancers = data?.freelancers ?? [];
                            const displayFreelancers = freelancers.length > 4 ? freelancers.slice(0, 3) : freelancers.slice(0, 4);
                            const remainingCount = freelancers.length - 3;
                            return freelancers.length > 1 ?
                                (
                                    <div className='document-shared'>
                                        {displayFreelancers.map((res: any, index: number) => (
                                            <span key={index}>
                                                {res?.fullName?.split(' ').map((word: string) => word.charAt(0).toUpperCase()).join('')}
                                                {index < displayFreelancers.length - 1 && ''}
                                            </span>
                                        ))}
                                        {freelancers.length > 4 && (
                                            <span> +{remainingCount} </span>
                                        )}
                                    </div>
                                ) : (<div className='email-box'>{freelancers[0]?.fullName} {freelancers[0]?.email && <p className='mb-0'>({freelancers[0]?.email})</p>}</div>);
                        }
                    }], class: 'share-box', sortable: true, sortField: 'freelancers', sortDirection: 'desc'
            } as Column<ProjectDocument>] : []),
            { title: "CreationDate", class: 'date-box', dataField: [{ field: "createdAt", class: 'date', type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'createdAt', sortDirection: 'desc' },
        ],
        actionsDropDown:
            [
                ...(user?.role === USER_ROLE.Business ? [{ label: "EditDocument", actionToPerform: "edit" }] : []),
                { label: "Download", actionToPerform: "download" },
                { label: "Delete", actionToPerform: "delete" },
            ],
        data: documentsData?.items ?? [],
        total: documentsData?.totalResults ?? 0,
        isColumnChooser: true,
    }), [documentsData]);

    const handleActionPerform = (action: TableClickedAction<ProjectDocument>) => {
        switch (action.actionToPerform) {
            case 'delete':
                ConfirmationDialog({
                    title: "Delete Document",
                    text: "Are you sure you want to delete this document? This action cannot be undone.",
                    confirmButtonText: "Delete",
                    onConfirm: (isConfirmed) => {
                        deleteDocuments(({ projectId: projectId, documentId: action.data?.id }), {
                            onSuccess: () => showToast("delete", "Documents.DocumentDeletedSuccessfully")
                        });
                    }
                });

                break;

            case 'download':
                refetchDownload({ projectId: projectId!, documentId: action.data.id, fileName: action.data.fileName ?? 'document.pdf' }, {
                    onSuccess: () => showToast("success", "Documents.DocumentDownloadedSuccessfully")
                })
                break;

            case 'edit':
                toggleProjectDocumentModal(projectId, action.data.id, 'edit')
                break;
            default:
                console.warn("Unknown action", action.actionToPerform);
        }
    };

    return (
        <>
            <div className="document-head d-flex flex-sm-row flex-column align-items-sm-center align-items-start gap-sm-4 gap-0 justify-content-between w-100">
                {!isMobile && <div className="total-data">
                    <span>
                        {documentsData?.totalResults} <small className="d-sm-flex d-none">{t("ProjectDocuments")}</small>
                    </span>
                </div>
                }
                <div className="tab-right-filter">
                    <div className="search-input-form">
                        <div className="search-input d-flex" id="documentSearch">
                            <input className="form-control" placeholder={t("SearchDocument")} value={searchTerm} onFocus={() => { searchBar?.classList.add('full-search') }} onChange={(e) => handleSetSearch(e.target.value)} autoComplete="off" />
                            <Image src={dynamicImage("svg/search.svg")} alt="search" className="search-img" />
                            <Image src={dynamicImage("svg/search-red.svg")} alt="search" className="search-img-hover" />
                            {searchTerm.length > 0 && (
                                <div className="close-img" onClick={() => { handleSetSearch(""); searchBar?.classList.remove('full-search') }}>
                                    <Image src={dynamicImage("svg/close-red.svg")} alt="close" />
                                </div>
                            )}
                        </div>
                    </div>
                    <SolidButton className="text-nowrap btn-solid" onClick={() => { toggleProjectDocumentModal(projectId, undefined, 'upload') }}>
                        <Image src={dynamicImage("svg/upload.svg")} className="doc-upload" alt="arrow" />
                        <span className="text-white d-xxl-inline-flex d-none">{t("UploadDocument")}</span>
                    </SolidButton>
                </div>
            </div>
            <div className="project-detail-document-table">
                {tableConfig.total > 0 ? (
                    <CommonTable tableConfiguration={tableConfig} currentPage={pageNumber} itemsPerPage={pageSize} goToPage={goToPage} handleSetPageSize={handleSetPageSize} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} onActionPerform={handleActionPerform} />
                ) : (
                    <div className="table-no-data">
                        <Image src={dynamicImage("svg/no-data/no-data-project-invoice.svg")} alt="no data" className="img-fluid" />
                        <h5 dangerouslySetInnerHTML={{ __html: t("NoData.Documents") }}></h5>
                    </div>
                )}
            </div>
        </>
    )
}
export default Documents;