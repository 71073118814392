import { createContext, useContext, useState } from "react";
import { ContextProviderProps, FreelancerModalProps, InvoiceDetailCanvasProp, OpenModalProps, ProjectDocumentModalMode, ProjectDocumentModalProps, ProjectModalProps } from "../Types/CommonComponent";


type StateContextType = {
    activeDashboardTab: number;
    setActiveDashboardTab: React.Dispatch<React.SetStateAction<number>>;

    projectModal: ProjectModalProps;
    toggleProjectModal: (id?: string) => void;
    projectDocumentModal: ProjectDocumentModalProps;
    toggleProjectDocumentModal: (projectId?: string, id?: string, mode?: ProjectDocumentModalMode) => void;

    invoiceDetailModal: InvoiceDetailCanvasProp;
    openInvoiceDetailModal: (id: string) => void;
    openInvoiceFinalizeDetailModal: (id: string) => void;
    openInvoiceViewModal: (id: string) => void;
    openInvoiceDraftViewModal: (id: string) => void;
    closeInvoiceDetailModal: VoidFunction;

    accountDeleteReasonModal: boolean;
    setAccountDeleteReasonModal: React.Dispatch<React.SetStateAction<boolean>>;
    toggleAccountDeleteReasonModal: VoidFunction;
    accountDeleteConfirmReason: string;
    accountDeleteConfirmModal: boolean;
    setAccountDeleteConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    toggleAccountDeleteConfirmModal: (reason?: string) => void;

    freelancerModal: FreelancerModalProps;
    openInviteFreelancerModal: (projectId?: string) => void;
    openEditFreelancerModal: (id: string) => void;
    openViewFreelancerModal: (id: string) => void;
    openOnlyViewFreelancerModal: (id: string) => void;
    closeFreelancerModal: VoidFunction;

    invitationPopup: boolean;
    setInvitationPopup: React.Dispatch<React.SetStateAction<boolean>>;
    toggleInvitationPopup: VoidFunction;
    
    dismissAll: VoidFunction;
    confirmationDialog: boolean;
    toggleConfirmationDialog: VoidFunction;
    clientDetails: OpenModalProps;
    toggleClientDetails: (id?: string) => void;
    toggleAddNewCustomer: (id?: string) => void;
    profileSetting: { selectedTab: number, toggle: boolean };
    toggleProfileSetting: (selectedTab?: number) => void;
    sidebarSetting: boolean;
    toggleSidebarSetting: VoidFunction;
    emailChangeModal: boolean;
    toggleEmailChangeModal: VoidFunction;
    emailChangeOtpModal: { verificationToken?: string; email?: string; toggle: boolean };
    toggleEmailChangeOtpModal: (verificationToken?: string, email?: string) => void;
    emailChangeSuccessModal: boolean;
    toggleEmailChangeSuccessModal: VoidFunction;
    changeProfilePhotoModal: boolean;
    toggleProfilePhotoModal: VoidFunction;
};

const StateContext = createContext<StateContextType | undefined>(undefined);

export const StateProvider: React.FC<ContextProviderProps> = ({ children }) => {
    const [activeDashboardTab, setActiveDashboardTab] = useState<number>(1);

    //client

    const [clientDetails, setClientDetails] = useState<OpenModalProps>({ toggle: false });
    const toggleClientDetails = (id?: string) => setClientDetails((prev) => ({ id: id, mode: 'view', toggle: !!id || !prev.toggle }));
    const toggleAddNewCustomer = (id?: string) => setClientDetails((prev) => ({ id: id, mode: 'edit', toggle: !!id || !prev.toggle }));

    //Project
    const [projectModal, setProjectModal] = useState<ProjectModalProps>({ toggle: false });
    const toggleProjectModal = (id?: string) => setProjectModal((prev) => ({ id: id, toggle: !!id || !prev.toggle }));

    //upload doc
    const [projectDocumentModal, setProjectDocumentModal] = useState<ProjectDocumentModalProps>({ toggle: false });
    const toggleProjectDocumentModal = (projectId?: string, id?: string, mode?: ProjectDocumentModalMode) => setProjectDocumentModal((prev) => ({ projectId: projectId, id: id, mode: mode, toggle: !!id || !prev.toggle }));

    //modal
    const [invoiceDetailModal, setInvoiceDetailModal] = useState<InvoiceDetailCanvasProp>({ toggle: false });
    const openInvoiceDetailModal = (id: string) => setInvoiceDetailModal({ id: id, mode: "detail", toggle: true });
    const openInvoiceFinalizeDetailModal = (id: string) => setInvoiceDetailModal({ id: id, mode: "finalize", toggle: true });
    const openInvoiceViewModal = (id: string) => setInvoiceDetailModal({ id: id, mode: "view", toggle: true });
    const openInvoiceDraftViewModal = (id: string) => setInvoiceDetailModal({ id: id, mode: "draft_view", toggle: true });
    const closeInvoiceDetailModal = () => setInvoiceDetailModal({ toggle: false });

    // For Business User
    const [freelancerModal, setFreelancerModal] = useState<FreelancerModalProps>({ toggle: false })
    const openInviteFreelancerModal = (projectId?: string) => setFreelancerModal({ mode: "invite", toggle: true, projectId: projectId });
    const openEditFreelancerModal = (id: string) => setFreelancerModal({ id: id, mode: "edit", toggle: true });
    const openViewFreelancerModal = (id: string) => setFreelancerModal({ id: id, mode: "view", toggle: true });
    const openOnlyViewFreelancerModal = (id: string) => setFreelancerModal({ id: id, mode: "only_view", toggle: true });
    const closeFreelancerModal = () => setFreelancerModal({ toggle: false });

    //Account Delete
    const [accountDeleteReasonModal, setAccountDeleteReasonModal] = useState(false);
    const toggleAccountDeleteReasonModal = () => setAccountDeleteReasonModal(!accountDeleteReasonModal)

    const [accountDeleteConfirmModal, setAccountDeleteConfirmModal] = useState(false);
    const [accountDeleteConfirmReason, setAccountDeleteConfirmReason] = useState('');
    const toggleAccountDeleteConfirmModal = (reason?: string) => {
        if (!accountDeleteConfirmModal && reason) {
            setAccountDeleteConfirmReason(reason!);
            setAccountDeleteConfirmModal(true);
        }
        else
            setAccountDeleteConfirmModal(false)
    }

    //Email Change
    const [emailChangeModal, setEmailChangeModal] = useState(false)
    const toggleEmailChangeModal = () => setEmailChangeModal(!emailChangeModal)

    const [emailChangeOtpModal, setEmailChangeOtpModal] = useState<{ verificationToken?: string; email?: string; toggle: boolean }>({ toggle: false })
    const toggleEmailChangeOtpModal = (verificationToken?: string, email?: string) => setEmailChangeOtpModal({ verificationToken: verificationToken, email: email, toggle: !!verificationToken })

    const [emailChangeSuccessModal, setEmailChangeSuccessModal] = useState(false)
    const toggleEmailChangeSuccessModal = () => setEmailChangeSuccessModal(!emailChangeSuccessModal)

    //Change Profile Photo
    const [changeProfilePhotoModal, setChangeProfilePhotoModal] = useState(false)
    const toggleProfilePhotoModal = () => setChangeProfilePhotoModal(!changeProfilePhotoModal)

    //Invitation to project
    const [invitationPopup, setInvitationPopup] = useState(false)
    const toggleInvitationPopup = () => setInvitationPopup(!invitationPopup)

    //Confirmation Dialog
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const toggleConfirmationDialog = () => setConfirmationDialog(!confirmationDialog)

    //profile settings
    const [profileSetting, setSetting] = useState<{ selectedTab: number, toggle: boolean }>({ selectedTab: 1, toggle: false });
    const toggleProfileSetting = (selectedTab?: number) => setSetting((prev) => ({ selectedTab: selectedTab ?? 1, toggle: !prev.toggle }));

    //sidebar setting
    const [sidebarSetting, setSidebarSetting] = useState(false);
    const toggleSidebarSetting = () => setSidebarSetting(!sidebarSetting);

    const dismissAll = () => { setInvoiceDetailModal({ toggle: false }); setClientDetails({ toggle: false }); closeFreelancerModal(); setSetting({ selectedTab: 1, toggle: false }); setProjectModal({ toggle: false }); setSidebarSetting(false); }

    return (
        <StateContext.Provider value={{
            dismissAll, toggleAddNewCustomer, toggleClientDetails, clientDetails, profileSetting, toggleProfileSetting, toggleSidebarSetting, sidebarSetting,
            projectModal, toggleProjectModal, projectDocumentModal, toggleProjectDocumentModal,
            invoiceDetailModal, openInvoiceDetailModal, openInvoiceFinalizeDetailModal, openInvoiceViewModal, openInvoiceDraftViewModal, closeInvoiceDetailModal,
            accountDeleteReasonModal,
            setAccountDeleteReasonModal, toggleAccountDeleteReasonModal, accountDeleteConfirmModal, accountDeleteConfirmReason, setAccountDeleteConfirmModal, toggleAccountDeleteConfirmModal,
            freelancerModal, closeFreelancerModal, openEditFreelancerModal, openInviteFreelancerModal, openOnlyViewFreelancerModal, openViewFreelancerModal,
            invitationPopup, setInvitationPopup, toggleInvitationPopup, confirmationDialog, toggleConfirmationDialog, emailChangeModal, toggleEmailChangeModal, emailChangeOtpModal, toggleEmailChangeOtpModal, emailChangeSuccessModal, toggleEmailChangeSuccessModal, changeProfilePhotoModal, toggleProfilePhotoModal
            , activeDashboardTab, setActiveDashboardTab
        }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = (): StateContextType => {
    const context = useContext(StateContext);
    if (!context) {
        throw new Error("useStateContext must be used within a StateProvider");
    }
    return context;
};