import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'

import { Tabs } from './Tabs';
import { Details } from '../../Widgets/Details';
import { ProfileTabs } from '../../../../Data/Freelancer/Dashboard';
import { ProfileSettingsProps } from '../../../../Types';
import { useStateContext } from '../../../../views';

const ProfileSettings: React.FC<ProfileSettingsProps> = ({ defaultTab }) => {

  const { t } = useTranslation();
  const [navActive, setNavActive] = useState<number>(defaultTab);
  const [visitedTabs, setVisitedTabs] = useState<number[]>([]);

  const handleTabClick = (tabIndex: number) => {
    if (tabIndex >= 1 && tabIndex <= ProfileTabs.length) {
      setNavActive(tabIndex);
    }
  };

  const { profileSetting, toggleProfileSetting } = useStateContext();
  useEffect(() => {
    setNavActive(defaultTab);
  }, [defaultTab]);

  return (
    <Offcanvas isOpen={profileSetting.toggle} toggle={() => toggleProfileSetting()} direction="end" className="profile-setting offcanvas-profile-setting">
      <OffcanvasHeader toggle={() => toggleProfileSetting()}>{t("ProfileSettings")}</OffcanvasHeader>
      <Details />
      <div className="tab-border-tab">
        <Nav pills className="profile-tab custom-scrollbar" id="pills-tab">
          {ProfileTabs.map(tab => (
            <NavItem key={tab.id}>
              <NavLink
                className={`${navActive === tab.id ? "active" : ""} ${visitedTabs.includes(tab.id) ? "verified-tab" : ""}`}
                onClick={() => handleTabClick(tab.id)}
                id={`pills-${tab.title?.toLowerCase()}-tab`}
              >
                <span>{t(tab.title)}</span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <OffcanvasBody className="custom-scroll">
        <Tabs active={navActive} />
      </OffcanvasBody>
    </Offcanvas>
  )
}
export default ProfileSettings;