import { Image } from "../AbstractElement";
import { dynamicImage } from "../utils";
import { useTranslation } from "react-i18next";
import { ClientInformationProps } from "../Types/CommonComponent";
import { useCoreData } from "../Hooks";

const ClientInformation: React.FC<ClientInformationProps> = ({ clientData, title }) => {
  const { t } = useTranslation();
  const { phoneCountryCodeOptions } = useCoreData({ loadCountries: true });

  return (
    <>
      {title && <h5 className="billing-info-title">{t(title)}</h5>}
      <div>
        <p className="common-f-flex">
          <span className="initial-letter d-flex">
            <span>
              {clientData?.name
                ?.split(" ")
                ?.map((letter: string) => letter.charAt(0).toUpperCase())
                ?.join("")
                ?.slice(0, 2)}
            </span>
          </span>
          <div>
            <div className="d-flex gap-2">
              <span>{clientData?.name}</span>
              {clientData?.verified && <Image src={dynamicImage("svg/blue-tick.svg")} className="blue-tick-icon" alt="edit" />}
            </div>
            <p>({clientData?.email})</p>
          </div>
        </p>
      </div>
      <div className="invoice-finance">
        <ul className="invoice-data">
          {clientData?.name && (
            <li>
              <span>{t("InvoiceReceiverName")}</span>
              <p>{clientData?.name}</p>
            </li>
          )}
          {clientData?.phone && (
            <li>
              <span>{t("PhoneNumberId")}</span>
              <p>
                {phoneCountryCodeOptions?.find((f) => f.value === clientData?.phoneCountryCode)?.label ?? ""}
                {clientData?.phone}
              </p>
            </li>
          )}
          <li>
            <span>{t("Address")}</span>
            <p>
              {clientData?.addressLine1}, {clientData?.addressLine2}, {clientData?.city}, {clientData?.state}
            </p>
          </li>
          {clientData?.countryName && (
            <li>
              <span>{t("Country")}</span>
              <p>{clientData?.countryName}</p>
            </li>
          )}
          {clientData?.registrationNumber && (
            <li>
              <span>{t("RegistrationNumber")}</span>
              <p>{clientData?.registrationNumber}</p>
            </li>
          )}
          {clientData?.taxOffice && (
            <li>
              <span>{t("TaxOffice")}</span>
              <p>{clientData?.taxOffice}</p>
            </li>
          )}
          {clientData?.vatNumber && (
            <li>
              <span>{t("VatNumber")}</span>
              <p>{clientData?.vatNumber}</p>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default ClientInformation;
