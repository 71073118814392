import { FC } from 'react'
import { useTranslation } from 'react-i18next';
import { INVITE_STATUS, InviteStatus } from '../../Types';

export const FreelancerStatusBadge: FC<{ inviteStatus: InviteStatus; declineReason: string }> = ({ inviteStatus, declineReason }) => {
    const { t } = useTranslation();

    function getClassName(status: InviteStatus) {
        switch (status) {
            case INVITE_STATUS.Accepted:
                return "accepted-badge";
            case INVITE_STATUS.Declined:
                return "active-badge";
            case INVITE_STATUS.Invited:
                return "sent-badge";
            case INVITE_STATUS.NA:
                return "transparent";
            default:
                return "";
        }
    }

    return (
        <>
            <div className={`badge text-capitalize justify-content-center status-badge ${getClassName(inviteStatus)}`}
                title={inviteStatus === INVITE_STATUS.Declined && declineReason ? declineReason : ""}>
                {t(`InviteStatus.${inviteStatus}`)}
            </div>
        </>
    )
}
