import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Col, Form, FormGroup, InputGroup, Row, TabPane } from 'reactstrap';
import { Image, SolidButton } from '../../../../AbstractElement';
import { mutations } from '../../../../api';
import { CommonSelect } from '../../../../CommonComponent';
import { ROUTES } from '../../../../Constants';
import { useCoreData } from '../../../../Hooks';
import { QuickInvoiceSchema } from '../../../../Schemas';
import { QuickInvoiceRequest } from '../../../../Types';
import { dynamicImage } from '../../../../utils';

const QuickInvoice = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { customersSelect, currencyPayInOptions, countryOptions } = useCoreData({ loadCustomers: true, loadCountries: true, loadCurrenciesPayIn: true });
    const { QuickInvoice } = QuickInvoiceSchema(customersSelect);

    const [isLoading, setIsLoading] = useState(true);
    const [selectedCustomer, setSelectedCustomerOptions] = useState<string | null>(null);
    const [selectedCurrencyOptions, setSelectedCurrencyOptions] = useState<string[] | string>('');
    const [selectedCountryOptions, setSelectedCountryOptions] = useState<string[] | string>([]);

    const { mutate: quickMutate, isLoading: quickLoading } = mutations.useQuickInvoice();

    const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm<any>({
        resolver: yupResolver(QuickInvoice),
        defaultValues: {
            itemName: '',
            price: '',
            currencyCode: null,
            customerId: '',
            customerName: '',
            email: '',
            countryCode: null,
        }
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            if (customersSelect && currencyPayInOptions && countryOptions)
                setIsLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, [customersSelect, currencyPayInOptions, countryOptions]);

    const selectedPlaceholder = (
        <p className='placeholder-text m-0'>
            <span className='name'>{customersSelect!.find(data => data.value === selectedCustomer)?.label}
                {customersSelect!.find(data => data.value === selectedCustomer)?.data.verified && (
                    <Image src={dynamicImage('svg/blue-tick.svg')} alt="Verified" />
                )}</span>
            <span className="mail">({customersSelect!.find(data => data.value === selectedCustomer)?.data.email})</span>
        </p>
    );
    const onSubmitData: SubmitHandler<any> = async (data) => {
        const payload: QuickInvoiceRequest = {
            customerId: selectedCustomer,
            customerName: selectedCustomer != null ? null : data.customerName,
            email: selectedCustomer != null ? null : data.email,
            countryCode: selectedCustomer != null ? null : data.countryCode,

            itemName: data.itemName,
            price: data.price,
            currencyCode: data.currencyCode,
        };

        quickMutate(payload, {
            onSuccess: (response) => {
                navigate(`${ROUTES.InvoiceEdit.replace(':id', response.id)}?tab=financials`);
            },
        });
    };
    if (isLoading) {
        return (
            <div className='quick-invoice-tab skeleton-loader'>
                <Row className='g-3 placeholder-glow'>
                    <Col md="6">
                        <p className='placeholder placeholder-text mb-0'></p>
                    </Col>
                    <Col xs="12">
                        <Row>
                            <Col lg="7">
                                <span className='placeholder placeholder-input'></span>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl="7" lg="6">
                        <span className='placeholder placeholder-input'></span>
                    </Col>
                    <Col xxl="5" lg="6">
                        <span className='placeholder placeholder-input'></span>
                    </Col>
                    <Col xxl="2" lg="3" className='ms-auto'>
                        <button type='submit' className='placeholder btn-placeholder btn-solid btn btn-primary'>
                        </button>
                    </Col>
                </Row>

            </div>)
    }

    return (
        <TabPane className='quick-invoice-tab' tabId={1} id="pills-quickInvoice">
            <p>{t("YouCanEasilyCreateYourFirstInvoiceUsingTheFormBelow")}</p>
            <Form onSubmit={handleSubmit(onSubmitData)}>
                <Row className="g-3">
                    <Col xs="12">
                        <Row>
                            <Col lg={customersSelect && customersSelect.length > 0 ? '7' : '12'} className={customersSelect && customersSelect.length > 0 ? 'custom-create-width' : ''}>
                                {customersSelect && customersSelect.length > 0 ?
                                    (<CommonSelect
                                        placeholder={selectedCustomer ? selectedPlaceholder : t('SelectClient')}
                                        hidePlaceholder={true}
                                        options={customersSelect!}
                                        className={`max-dropdown ${errors.customerId ? "required-field" : ""}`}
                                        selectedOptions={selectedCustomer}
                                        setSelectedOptions={(value) => {
                                            setSelectedCustomerOptions(value);
                                            setValue('customerId', value)
                                            trigger('customerId')
                                        }}
                                        buttonText={t('CreateNew')}
                                        buttonClass='btn create-btn'
                                        multiSelect={false}
                                        search={true}
                                        onClick={() => navigate(ROUTES.Invoice)}
                                    >
                                        {(option) => (
                                            <p>{option.label} {option?.data?.verified && <Image src={dynamicImage(`svg/blue-tick.svg`)} />}</p>

                                        )}
                                    </CommonSelect>) : (
                                        <>
                                            <Row className='g-3'>
                                                <Col xxl="7" lg="6">
                                                    <FormGroup className='mb-0'>
                                                        <InputGroup className='quick-invoice-group-2'>
                                                            <div className="g-0 w-100">
                                                                <div className="dropdown-box">
                                                                    <input
                                                                        type="text"
                                                                        className={`form-control ${errors.customerName || errors.countryCode ? "required-field" : ""}`}
                                                                        placeholder={t("ClientNameId")}
                                                                        {...register("customerName")}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>
                                                                <CommonSelect
                                                                    placeholder={t('ClientCountry')}
                                                                    options={countryOptions!}
                                                                    selectedOptions={selectedCountryOptions}
                                                                    setSelectedOptions={(value) => {
                                                                        setSelectedCountryOptions(value);
                                                                        setValue('countryCode', value);
                                                                        trigger('countryCode')
                                                                    }}
                                                                    multiSelect={false}
                                                                    search={true}
                                                                />
                                                            </div>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col xxl="5" lg="6">
                                                    <FormGroup className='mb-0'>
                                                        <input
                                                            type="email"
                                                            className={`form-control ${errors.email ? "required-field" : ""}`}
                                                            placeholder={t("ClientEmailAddress")}
                                                            {...register("email")}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }
                            </Col>
                        </Row>
                    </Col>

                    <Col xxl="7" lg="6">
                        <FormGroup className='mb-0'>
                            <input
                                type="text"
                                className={`form-control ${errors.itemName ? "required-field" : ""}`}
                                placeholder={t("WorkItemName")}
                                {...register("itemName")}
                                autoComplete="off"
                            />
                        </FormGroup>
                    </Col>
                    <Col xxl="5" lg="6">
                        <FormGroup className='mb-0'>
                            <InputGroup className='quick-invoice-group'>
                                <div className="dropdown-box w-100">
                                    <input
                                        type="number"
                                        className={`form-control ${errors.price || errors.currencyCode ? "required-field" : ""}`}
                                        placeholder={t("PriceId")}
                                        {...register("price")}
                                        autoComplete="off"
                                    />
                                </div>
                                <CommonSelect
                                    placeholder={t('CurrencyId')}
                                    options={currencyPayInOptions!}
                                    selectedOptions={selectedCurrencyOptions}
                                    setSelectedOptions={(value) => {
                                        setSelectedCurrencyOptions(value);
                                        setValue('currencyCode', value);
                                        trigger('currencyCode')
                                    }}
                                    multiSelect={false}
                                    search={true}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <SolidButton type='submit' loading={quickLoading}>
                            <Image src={dynamicImage("svg/invoice.svg")} alt="invoice image" className='invoice-img' />
                            <span>{t("CreateInvoice")}</span>
                        </SolidButton>
                    </Col>
                </Row>
            </Form>
        </TabPane>
    )

}
export default QuickInvoice;