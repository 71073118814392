import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const FreelancerInviteSchema = () => {
  const { t } = useTranslation();

  const FreelancerInvite = Yup.object().shape({
    email: Yup.string().email().required(t("IsRequired", { name: t("EmailId") })),
    fullName: Yup.string().required(t("IsRequired", { name: t("FreelancerName") })),
    projectId: Yup.string().required(t("IsRequired", { name: t("Project") })),
  });

  return {
    FreelancerInvite
  };
};

export default FreelancerInviteSchema;
