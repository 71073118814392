import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { HTTP_STATUS, ROUTES } from "../../../../Constants";
import { mutations } from "../../../../api";
import { SolidButton } from "../../../../AbstractElement";
import { LoginSchema } from "../../../../Schemas";
import { useToast } from "../../../../views";
import { useEffect } from "react";
import { getCookie, setCookie } from "../../../../utils/cookie";

const BusinessSignInForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { EmailSchema } = LoginSchema();
  const { showToast } = useToast();

  type BusinessLoginFormData = Yup.InferType<typeof EmailSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors }, setValue
  } = useForm<BusinessLoginFormData>({
    resolver: yupResolver(EmailSchema),
  });

  const { mutate: requestPin, isLoading } = mutations.useSendPinEmail();

  const onSubmit = (data: BusinessLoginFormData) => {
    requestPin(data.email, {
      onSuccess: () => {
        setCookie("businessUserEmail", data.email, 30);
        showToast("success", "Business.LoginForm.WeSentANewPinToTheEmailAddressYouProvided");
        navigate(ROUTES.VerifyAccount, { state: { email: data.email } });
      },
      onError: (error) => {
        if (error.status === HTTP_STATUS.CONFLICT) {
          navigate(ROUTES.VerifyAccount, { state: { email: data.email } });
        }
      },
    });
  };

  useEffect(() => {
    const storedEmail = getCookie("businessUserEmail"); // Retrieve the email from localStorage
    if (storedEmail) {
      setValue("email", storedEmail); // Set the email in the form
    }
  }, []);

  return (
    <div className="auth-card">
      <div className="auth-title">
        <h2>{t("WelcomeAboard")}</h2>
        <div className="login-account">
          {t("LoginOr")}
          <Link to={ROUTES.BusinessSignUp}>{t("CreateAnAccount")}</Link>
        </div>
      </div>
      <div className="login-detail mb-0">
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormGroup>
            <input {...register("email")} className={`form-control ${errors.email ? "required-field" : ""}`} id="email" placeholder={t("BusinessEmailAddress")} type="email" />
            {errors.email && (
              <span className="required-note">
                <small>{t(errors.email.message || "")}</small>
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <SolidButton type="submit" loading={isLoading}>{t("Next")}</SolidButton>
          </FormGroup>
          <div className="forgot-pass justify-content-center justify-content-xl-end"></div>
        </Form>
      </div>
    </div>
  );
};

export default BusinessSignInForm;
