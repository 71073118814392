import { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { statusChartOptions as defaultChartOptions } from '../../../Data/Freelancer/Dashboard';
import { useTranslation } from 'react-i18next';
import { Badges } from '../../../AbstractElement';
import ReactApexChart from 'react-apexcharts';
import { queries } from '../../../api';
import { CommonSelect } from '../../../CommonComponent';
import { convertToReadableDate, formatPercentage } from '../../../utils';
import { useAuth } from '../../../Hooks';
import { USER_ROLE } from '../../../Types';

export const Status = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [chartOptions, setChartOptions] = useState(defaultChartOptions);
    const [statusType, setStatusType] = useState<any>([]);
    const [statusTypeChart, setStatusTypeChart] = useState<any>([]);
    const [selectedOptions, setSelectedOptions] = useState<string>('last 3 months');

    // Dynamically determine colors based on series types
    const determineColors = (series: Array<{ type: string }>) => {
        const colorMap: { [key: string]: string } = {
            Invoice: "var(--secondary-color-2)",
            Paid: "var(--success-medium-color)",
            Unpaid: "var(--gray)"
        };

        return series.map((item) => colorMap[item.type] || "var(--default-color)");
    };

    const dateRangeOptions = [
        { label: 'Last month', value: 'last month', data: { days: 30 } },
        { label: 'Last 3 months', value: 'last 3 months', data: { days: 90 } },
        { label: 'Last 6 months', value: 'last 6 months', data: { days: 180 } },
        { label: 'Last year', value: 'last year', data: { days: 365 } },
        { label: 'All time', value: 'all time', data: { days: 0 } },
    ];

    const dateBetween = useMemo(() => {
        const daysBefore = dateRangeOptions.find(f => f.value === selectedOptions)?.data?.days || 0;
        if (!daysBefore || daysBefore === 0) return { startDate: null, endDate: null };

        const startDate = new Date();
        startDate.setDate(startDate.getDate() - daysBefore);
        const formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;
        const endDate = new Date().toISOString().split('T')[0];

        return { startDate: formattedStartDate, endDate };
    }, [selectedOptions]);

    const { data: dashboardChart } = queries.useGetDashboardChart({ startDate: dateBetween.startDate, endDate: dateBetween.endDate });


    useEffect(() => {
        if (dashboardChart) {
            const { cards, chartData } = dashboardChart;
            const isFreelancer = user?.role === USER_ROLE.Freelancer;
            const isBusiness = user?.role === USER_ROLE.Business;

            // Set StatusType data from the API response
            const updatedStatusType = [
                isFreelancer && { id: 1, title: 'TotalProjects', total: cards.TotalProjects.count },
                isFreelancer && { id: 2, title: 'Clients', total: cards.Clients.count },
                isBusiness && { id: 3, title: "InvoicesReceivedFromFreelancers", total: cards.Freelancers.count },
                isBusiness && { id: 4, title: "PaymentMade", total: `${cards.Payments.value} ${cards.Payments.currencyCode}` },
            ].filter(Boolean);   //remove null or false entries

            // Set StatusTypeChart data from the API response
            const updatedStatusTypeChart = [
                {
                    id: 1,
                    title: isFreelancer ? 'PaidInvoices' : 'ApprovedInvoices',
                    total: isFreelancer ? cards.Paid.count : cards.Approved.count,
                    boxClass: 'primary',
                    percentage: formatPercentage((isFreelancer ? cards.Paid.percentageChange : cards.Approved.percentageChange) / 100),
                },
                isFreelancer && {
                    id: 2,
                    title: 'UnpaidInvoices',
                    total: cards.Unpaid.count,
                    boxClass: 'success',
                    percentage: formatPercentage(cards.Unpaid.percentageChange / 100),
                },
                {
                    id: 3,
                    title: 'TotalInvoices',
                    total: cards.Invoices.count,
                    boxClass: 'warning',
                    percentage: formatPercentage(cards.Invoices.percentageChange / 100),
                }
            ].filter(Boolean);

            setStatusType(updatedStatusType);
            setStatusTypeChart(updatedStatusTypeChart);

            // Update chart options
            const updatedChartOptions = {
                ...defaultChartOptions,
                series: chartData.series.map((item: any) => ({
                    name: item.type,
                    data: item.data,
                })),
                xaxis: {
                    ...defaultChartOptions.xaxis,
                    categories: chartData.categories.map((category: string) => {
                        return convertToReadableDate(category, "short", { month: "short", year: undefined, day: undefined });
                    }),
                },
                colors: determineColors(chartData.series),
            };

            setChartOptions(updatedChartOptions);
        }

    }, [dashboardChart, user]);



    return (
        <>
            <CommonSelect
                className={user?.role === USER_ROLE.Business ? 'outside-tab' : ''}
                placeholder={t('Last3Months')}
                options={dateRangeOptions}
                selectedOptions={selectedOptions}
                setSelectedOptions={(option) => {
                    setSelectedOptions(option);
                }}
                multiSelect={false}
            />
            <Row className="g-sm-4 g-0">
                <Col xxl="4" sm="6">
                    <ul className="status-list responsive-status-space">
                        {statusType.map((item: any) => (
                            <li key={item.id}>
                                <div className="d-flex align-items-center gap-2">
                                    <h6>{t(item.title)}</h6>
                                </div>
                                <div className="d-flex align-items-center gap-3">
                                    <span>{item.total}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Col>
                <Col xxl="4" sm="6">
                    <ul className="status-list">
                        {statusTypeChart.map((item: any) => (
                            <li key={item.id}>
                                <div className="d-flex align-items-center gap-2">
                                    <div className={`bg-${item.boxClass}`}></div>
                                    <h6>{t(item.title)}</h6>
                                </div>
                                <div className="d-flex align-items-center gap-3">
                                    <span>{item.total}</span>
                                    <Badges className={item.boxClass} pill>
                                        <small className="arrow"></small>{item.percentage}%
                                    </Badges>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Col>
                <Col xxl="4" className="d-xxl-flex d-none">
                    <div className="status-chart">
                        <div id="chart">
                            <ReactApexChart
                                options={chartOptions}
                                series={chartOptions.series}
                                type="bar"
                                height={140}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

