import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useStateContext } from './views';
import { useAuth } from './Hooks';
import { USER_ROLE } from './Types';
import { ROUTES } from './Constants';
import BusinessCanvas from "./Components/Profile/Business";
import FreelancerCanvas from "./Components/Profile/Freelancer/ProfileSettings";
import AddNewClient from './Components/Clients/Canvas/AddNewClient';
import ClientDetails from './Components/Clients/Canvas/ClientDetails';
import ProjectCanvas from './Components/Projects/Canvas/ProjectCanvas';
import UploadDocCanvas from './Components/Projects/Canvas/UploadDocCanvas';
import InviteFreelancer from './Components/Freelancers/Canvas/InviteFreelancer';
import FreelancerDetails from './Components/Freelancers/Canvas/FreelancerDetails';
import EditFreelancer from './Components/Freelancers/Canvas/EditFreelancer';
import ViewInvoiceModal from './Components/Projects/Canvas/ViewInvoiceModal';
import InvoiceDetailCanvas from './Components/Projects/Canvas/InvoiceDetailCanvas';

function App() {
  const { user } = useAuth();
  const { invoiceDetailModal, clientDetails, projectModal, projectDocumentModal, profileSetting, freelancerModal, openInvoiceDetailModal, openInvoiceFinalizeDetailModal } = useStateContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const invoiceRegex = /^\/invoices\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})$/;
    const finalizeInvoiceRegex = /^\/finalize-invoices\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})$/;

    const invoiceMatch = location.pathname.match(invoiceRegex);
    const finalizeInvoiceMatch = location.pathname.match(finalizeInvoiceRegex);

    if (invoiceMatch) {
      const invoiceId = invoiceMatch[1];
      navigate(ROUTES.Dashboard, { replace: true });
      openInvoiceDetailModal(invoiceId);
    } else if (finalizeInvoiceMatch) {
      const invoiceId = finalizeInvoiceMatch[1];
      navigate(ROUTES.Dashboard, { replace: true });
      openInvoiceFinalizeDetailModal(invoiceId);
    }
  }, [location.pathname, navigate, openInvoiceDetailModal, openInvoiceFinalizeDetailModal]);

  return (
    <>

      {profileSetting.toggle && (user?.role === USER_ROLE.Business ? <BusinessCanvas /> : <FreelancerCanvas defaultTab={profileSetting.selectedTab} />)}
      {clientDetails.toggle && clientDetails.mode === 'edit' && <AddNewClient customerId={clientDetails.id} />}
      {clientDetails.toggle && clientDetails.mode === 'view' && <ClientDetails customerId={clientDetails.id!} />}
      {projectModal.toggle && <ProjectCanvas id={projectModal.id} />}
      {projectDocumentModal.toggle && <UploadDocCanvas projectId={projectDocumentModal.projectId!} action={projectDocumentModal.mode!} documentId={projectDocumentModal.id} />}

      {freelancerModal.toggle && freelancerModal.mode === "invite" && (<InviteFreelancer projectId={freelancerModal.projectId} />)}
      {freelancerModal.toggle && (freelancerModal.mode === "only_view" || freelancerModal.mode === "view") && <FreelancerDetails freelancerId={freelancerModal.id!} />}
      {freelancerModal.toggle && freelancerModal.mode === "edit" && <EditFreelancer freelancerId={freelancerModal.id!} />}

      {invoiceDetailModal.toggle && (invoiceDetailModal.mode === "view" || invoiceDetailModal.mode === "draft_view") && <ViewInvoiceModal id={invoiceDetailModal.id!} draftInvoice={invoiceDetailModal.mode === "draft_view"} />}
      {invoiceDetailModal.toggle && (invoiceDetailModal.mode === "detail" || invoiceDetailModal.mode === "finalize") && <InvoiceDetailCanvas id={invoiceDetailModal.id!} finalizeView={invoiceDetailModal.mode === "finalize"} />}
      <Outlet />
    </>
  );
}

export default App;
