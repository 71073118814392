import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, Row } from 'reactstrap';

import { SolidButton } from '../../../../AbstractElement';
import { CommonSelect } from '../../../../CommonComponent';
import { useCoreData } from '../../../../Hooks';
import { BusinessAccountInput } from '../../../../Types';
import { mutations, queries } from '../../../../api';
import { useStateContext, useToast } from '../../../../views';

export const Profile = () => {

    const { t } = useTranslation();
    const { toggleProfileSetting } = useStateContext();

    const { countryOptions } = useCoreData({ loadCountries: true });
    const { showToast } = useToast()

    const { data: userDetails } = queries.useGetUserDetails();
    const { mutate: updateAccount, isLoading: updateLoading } = mutations.useUpdateAccount();

    const [selectedCountryOptions, setSelectedCountryOptions] = useState<string[] | string>([]);

    const { register, handleSubmit, setValue } = useForm<BusinessAccountInput>({
        defaultValues: {
            companyName: '',
            registrationNumber: '',
            taxOffice: '',
            vatNumber: '',
            addressLine1: '',
            addressLine2: '',
            countryCode: '',
            state: '',
            city: '',
            postalCode: '',
        }
    });

    useEffect(() => {
        if (userDetails) {
            setValue('companyName', userDetails.companyName!);
            setValue('registrationNumber', userDetails.registrationNumber!);
            setValue('taxOffice', userDetails.taxOffice!);
            setValue('vatNumber', userDetails.vatNumber!);
            setValue('addressLine1', userDetails.addressLine1!);
            setValue('addressLine2', userDetails.addressLine2!);
            setValue('state', userDetails.state!);
            setValue('city', userDetails.city!);
            setValue('postalCode', userDetails.postalCode!);
            setValue('countryCode', userDetails.countryCode!);
            setSelectedCountryOptions(userDetails.countryCode!)
        }
    }, [userDetails, setValue]);

    const onSubmit: SubmitHandler<BusinessAccountInput> = async (data) => {

        updateAccount({ ...userDetails, ...data }, {
            onSuccess: () => {
                showToast('success', "ProfileSettings.UpdatedSuccessfully");
            }
        })
    };

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="setting-main pb-3">
                <Row className='g-sm-4 g-3'>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("BusinessName")}</label>
                            <input type="text" className="form-control" id="companyName" {...register("companyName")} placeholder={t("EnterBusinessName")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("RegistrationNumber")}</label>
                            <input type="text" className="form-control" id="registrationNumber" {...register("registrationNumber")} placeholder={t("EnterRegistrationNumber")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("TaxOffice")}</label>
                            <input type="text" className="form-control" id="taxOffice" {...register("taxOffice")} placeholder={t("EnterTaxOffice")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("VatNumber")}</label>
                            <input type="text" className="form-control" id="vatNumber" {...register("vatNumber")} placeholder={t("EnterVATNumber")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("Country")}</label>
                            <CommonSelect
                                placeholder={t('SelectId')}
                                options={countryOptions!}
                                selectedOptions={selectedCountryOptions}
                                setSelectedOptions={(value) => {
                                    setSelectedCountryOptions(value);
                                    setValue('countryCode', value);
                                }}
                                multiSelect={false}
                                search={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("State")}</label>
                            <input type="text" className="form-control" id="state" {...register("state")} placeholder={t("EnterState")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("City")}</label>
                            <input type="text" className="form-control" id="city" {...register("city")} placeholder={t("EnterCityLong")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("PostalCode")}</label>
                            <input type="text" className="form-control" id="postalCode" {...register("postalCode")} placeholder={t("EnterPostalCode")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup className='mb-0'>
                            <label>{t("Address")}</label>
                            <input type="text" className="form-control" id="addressLine1" {...register("addressLine1")} placeholder={t("AddressLine1")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup className='mb-0'>
                            <input type="text" className="form-control" id="addressLine2" {...register("addressLine2")} placeholder={t("AddressLine2")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="profile-setting-footer-main">
                <div className="profile-setting-footer">
                    <SolidButton className='btn-gray' onClick={toggleProfileSetting}>{t("Cancel")}</SolidButton>
                    <SolidButton type='submit' loading={updateLoading}>{t("Update")}</SolidButton>
                </div>
            </div>
        </Form>
    )
}