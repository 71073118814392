import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useAuth } from '../../Hooks';
import { queries } from '../../api';
import { USER_ROLE } from '../../Types';
import { useLayoutContext } from '../../views';
import DraftInvoices from '../Projects/Tab/DraftInvoices';
import Billing from './Tab/Billing';
import Documents from './Tab/Documents';
import Freelancers from './Tab/Freelancers';
import ProjectInvoices from './Tab/ProjectInvoices';

const ProjectDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { user } = useAuth();
    const { isMobile } = useLayoutContext()
    const location = useLocation()
    const query = new URLSearchParams(location.search);


    const { data: project } = queries.useGetProject(id);
    const [navActive, setNavActive] = useState(1);
    const activeTabPane = (id: number) => setNavActive(id);

    useEffect(() => {
        const tab = query.get('tab');
        if (tab === 'billing') {
            setNavActive(5);
        }
    }, [query]);

    const tabItems = [
        { id: 1, key: "projects-invoices", title: isMobile ? "Invoices" : "ProjectInvoices", showAll: true },
        { id: 2, key: "draft", title: "Drafts", role: USER_ROLE.Freelancer },
        { id: 2, key: "freelancer", title: "Freelancers", role: USER_ROLE.Business },
        //TODO:It can be connected later
        // { id: 3, key: "transaction", title: "Transactions", },
        // { id: 4, key: "events", title: "Events" },
        { id: 5, key: "billing", title: "Billing" },
        { id: 6, key: "documents", title: "Documents" },
        { id: 7, key: "archived", title: isMobile ? "Archived" : "ArchivedInvoices", showAll: true }
    ]

    const getDropdownText = () => {
        const currentTab = tabItems.find((item) => item.id === navActive);
        return currentTab ? t(currentTab.title) : t('ProjectsInvoices');
    };

    return (
        <Container fluid className="project-detail-container">
            <div className="h-100">

                <div className="horizontal-main-tab">
                    <div className="project-details-box">
                        <Nav pills className="horizontal-tab custom-scrollbar d-sm-flex" id="pills-tab">
                            {tabItems.filter(f => (!f.role || f.role === user?.role) && (f.showAll === true || project?.inactive === false)).map((item) => (
                                <NavItem key={item.id}>
                                    <NavLink
                                        className={navActive === item.id ? "active" : ""}
                                        onClick={() => activeTabPane(item.id)}
                                        id={`${item.key}-tab`}
                                    >
                                        {t(item.title)}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </div>
                    <TabContent className="horizontal-tab-content project-detail-tab custom-scroll" activeTab={navActive} id="pills-tabContent">
                        <TabPane tabId={1} className='project-invoice'><ProjectInvoices archived={false} /></TabPane>
                        {user?.role === USER_ROLE.Freelancer && !project?.inactive && <TabPane tabId={2} className='draft-invoice'><DraftInvoices detailPage={true} /></TabPane>}
                        {user?.role === USER_ROLE.Business && <TabPane tabId={2} className='freelancer-invoice'><Freelancers /></TabPane>}
                        {/*
                        //TODO:It can be connected later
                        <TabPane tabId={3}><Transactions /></TabPane>
                        <TabPane tabId={4}><Events /></TabPane> */}
                        {!project?.inactive && <TabPane tabId={5} className='billing-project'><Billing /></TabPane>}
                        {!project?.inactive && <TabPane tabId={6} className='document-project'><Documents /></TabPane>}
                        <TabPane tabId={7} className='archived-invoice'><ProjectInvoices archived={true} /></TabPane>
                    </TabContent>
                </div>
            </div>
        </Container>
    )
}
export default ProjectDetails;


