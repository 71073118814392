import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form } from 'reactstrap';

import { useForm } from 'react-hook-form';
import { LinkButton, SolidButton } from '../../../../AbstractElement';
import { mutations } from '../../../../api';
import { ROUTES, SITE_SETTINGS } from '../../../../Constants';
import { useAuth } from '../../../../Hooks';
import { getMessages, getParam } from '../../../../utils';
import { useToast } from '../../../../views';
import { OTPInput } from '../../Widgets';

const VerifyForm = () => {
  const { t } = useTranslation();
  const [timer, setTimer] = useState<number>(SITE_SETTINGS.RESEND_TIMEOUT);
  const [val, setVal] = useState(Array(6).fill(""));
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();
  const { businessPinSignin } = useAuth();
  const returnUrl = getParam("returnUrl");
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const { handleSubmit } = useForm();
  const { showToast } = useToast();

  const isSubmitDisabled = val.some((value) => value === "" || value === undefined);

  const { mutate: requestPin } = mutations.useSendPinEmail();
  const errorMessage = location.state?.error;

  useEffect(() => {
    if (errorMessage) {
      showToast("error", errorMessage); // Display the error message if it's passed from the previous page
    }
  }, [errorMessage]);

  const submitHandler = () => {
    setLoading(true)
    const { email } = location.state || {};
    const otpCode = val.join("");

    if (!email) {
      showToast("error", "VerifyForm.EmailIsMissingPleaseCheckYourRequest");
      return;
    }
    if (isSubmitDisabled) {
      setIsError(true);
      showToast("error", "VerifyForm.PleaseEnterTheFullOtpCode");
      return;
    }
    setIsError(false);
    businessPinSignin(
      { email, pin: otpCode },
      () => {
        setLoading(false)
        navigate(returnUrl ? returnUrl : ROUTES.Dashboard)
      },
      (error) => {
        setLoading(false)
        setIsError(true)
        showToast("error", getMessages(error));
      }
    )
  };

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResendEnabled(true); // Enable resend button when timer hits 0
    }
  }, [timer]);


  const handleRequestPin = () => {
    const { email } = location.state || {};

    requestPin(email,
      {
        onSuccess: () => {
          setTimer(SITE_SETTINGS.RESEND_TIMEOUT);
          setIsResendEnabled(false); // Disable the resend button again
          showToast("success", "ForgotPasswordForm.InstructionsAreSentPleaseCheckYourMail");
        },
        onError: (error) => {
          setIsError(true)
          showToast("error", getMessages(error));
        }
      }
    );
  };

  return (
    <div className="auth-card business-auth-card">
      <div className="auth-title">
        <h2>{t("WelcomeAboard")}</h2>
        <p>{t("EnterThePinCodeWeJustSentYourEmail")}</p>
      </div>
      <div className="login-detail mb-0 verification-form">
        <Form noValidate onSubmit={handleSubmit(submitHandler)}>
          <OTPInput val={val} setVal={setVal} isError={isError} setIsError={setIsError} />
          <div className="action-btn custom-w">
            <SolidButton type="submit" disabled={isSubmitDisabled} loading={loading}>{t("LogIn")}</SolidButton>
            <LinkButton to={ROUTES.BusinessLogin}>{t("Cancel")}</LinkButton>
          </div>
          <div className="resend-code custom-w">
            <div>
              <p dangerouslySetInnerHTML={{ __html: t("TheCodeWeSentYouWillExpireInSeconds", { timer: timer }) }} />
              <p>{t("IfYourPinDoesNotWorkRequestNewOne")}</p>
            </div>
            <Button color="transparent" disabled={!isResendEnabled} className="resend" onClick={() => handleRequestPin()}>{t("ResendCode")}</Button>
          </div>
        </Form>
      </div>
    </div>
  )
}
export default VerifyForm;

