import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, InvoiceStatusBadge, SolidButton } from '../../../AbstractElement';
import { mutations, queries } from '../../../api';
import { CommonSelect, CommonTable, ConfirmationDialog } from '../../../CommonComponent';
import { useAuth, useDropdown, useTableFilterHelper } from '../../../Hooks';
import { Project, USER_ROLE } from '../../../Types';
import { COLUMN_TYPE, TableClickedAction, TableConfig, Column } from '../../../Types/CommonComponent';
import { dynamicImage } from '../../../utils';
import { useLayoutContext, useStateContext, useToast } from '../../../views';
import { ROUTES } from '../../../Constants';
import { Params } from '../../../Types/CommonElement';

const AllProjects = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { toggleProjectModal, toggleProjectDocumentModal } = useStateContext();
    const [selectedOptions, setSelectedOptions] = useState<string[] | string>([]);
    const { isMobile, toggleState } = useLayoutContext()
    const { pageNumber, pageSize, searchTerm, params, goToPage, handleSetPageSize, handleSetSearch, archiveOptions, handleSetArchiveStatus, toggleSortType, handleSetSortBy } = useTableFilterHelper({ archiveStatus: null });

    const { data: projects } = queries.useGetProjects({ ...params });
    const { mutate: archiveProject } = mutations.useArchiveProject();
    const { mutate: deleteProject } = mutations.useDeleteProject();
    const { showToast } = useToast()

    const projectSearchDropdown = useDropdown<HTMLDivElement>(toggleState);
    const searchBar = document.getElementById('projectSearch')

    const tableConfig = React.useMemo<TableConfig<Project>>(() => ({
        columns:
            [
                {
                    title: "ProjectName", dataField: [{ field: "projectName", class: 'top hover-link', type: '' }, {
                        field: "projectCategory", class: '', type: '', renderer: (data: Project) => {
                            return <>{data?.projectCategory && <span>({data?.projectCategory})</span>}</>;
                        }
                    }], class: '', sortable: true, sortField: 'projectName', sortDirection: 'desc'
                },
                ...(user?.role === USER_ROLE.Freelancer ?
                    [
                        {
                            title: "Client", dataField: [{
                                field: 'companyName', class: '', type: '',
                                renderer: (element: Project) => {
                                    return (
                                        <div className="d-flex align-items-center gap-lg-3 gap-2">
                                            <div className="responsive-box">
                                                <h6>{element.companyName}{element.companyVerified && <Image src={dynamicImage(`svg/blue-tick.svg`)} />}</h6>
                                                {element.companyEmail && <span>({element.companyEmail})</span>}
                                            </div>
                                        </div>
                                    )
                                }
                            }], class: 'client-box', sortable: true, sortField: 'companyName', sortDirection: 'desc'
                        } as Column<Project>
                    ] : [
                        { title: "Freelancer", dataField: [{ field: "freelancerName", class: 'top', type: '' }, { field: "freelancerEmail", class: '', type: '', renderer: (data: Project) => { return <>{data?.freelancerEmail && <span>({data?.freelancerEmail})</span>}</>; } }], sortable: true, sortField: 'freelancerName', sortDirection: 'desc' } as Column<Project>,
                        { title: "InvoiceReceiver", class: '', dataField: [{ field: "companyName", class: 'top', type: '' }, { field: "companyEmail", class: '', type: '' }], sortable: true, sortField: 'companyName', sortDirection: 'desc', hidden: true } as Column<Project>
                    ]
                ),
                { title: "CreationDate", dataField: [{ field: "createdAt", class: 'date', type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'createdAt', sortDirection: 'desc', hidden: true },
                { title: "LastInvoiceStatus", class: 'last-invoice-box', dataField: [{ field: "lastInvoiceStatus", class: '', type: '', renderer: (data: Project) => { return <InvoiceStatusBadge invoiceStatus={data.lastInvoiceStatus} />; } }, { field: "lastInvoiceModifiedDate", class: '', type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'lastInvoiceStatus', sortDirection: 'desc' },
            ],
        actionsDropDown: [
            { label: "ProjectDetails", getNavigateUrl: (data: Project) => `${ROUTES.ProjectDetails.replace(':id', data.id.toString())}` },
            { label: "Edit", actionToPerform: "edit", conditional: { field: 'editable', condition: '===' } },
            ...(user?.role === USER_ROLE.Freelancer ? [{ label: "CreateNewInvoice", getNavigateUrl: (data: Project) => `${ROUTES.Invoice}?projectId=${data.id}&customerId=${data.billingId}` }] : []),
            { label: "UploadDocument", actionToPerform: "uploadDocument" },
            //TODO: The archiving feature has been disabled and will be activated later.
            // { label: "Archive", actionToPerform: "archive", conditional: { field: 'archived', condition: '?', actionLabel: 'Unarchive : Archive' } },
            { label: "Delete", actionToPerform: "delete", conditional: { field: 'editable', condition: '===' } },
        ],
        data: projects?.items ?? [],
        total: projects?.totalResults ?? 0,
        isColumnChooser: true,
    }), [projects, user?.role]);


    const handleActionPerform = async (action: TableClickedAction<Project>) => {
        switch (action.actionToPerform) {
            case 'edit':
                toggleProjectModal(action.data.id);
                break;
            case 'uploadDocument':
                toggleProjectDocumentModal(action.data.id, undefined, 'upload');
                break;
            case 'archive':
                const isArchived = action.data.archived;
                ConfirmationDialog({
                    title: isArchived ? "Unarchive project" : "Archive project",
                    text: isArchived ? "Are you sure you want to unarchive this project?" : "Are you sure you want to archive this project?",
                    confirmButtonText: isArchived ? "Unarchive project" : "Archive project",
                    confirmBtnClass: isArchived ? "orange" : "black",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            archiveProject({ id: action.data.id, archived: !isArchived }, {
                                onSuccess: () => {
                                    showToast(isArchived ? "delete" : "success", "Projects.ProjectStatusUpdatedSuccessfully");
                                },
                            });
                        }
                    }
                });
                break;
            case 'delete':
                ConfirmationDialog({
                    title: "Delete Project",
                    text: "Are you sure you want to delete this project? This action cannot be undone and may affect associated data.",
                    confirmButtonText: "Delete",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            deleteProject({ id: action.data.id! }, {
                                onSuccess: () => {
                                    showToast("delete", "Projects.ProjectsDeletedSuccessfully")
                                },
                            });
                        }
                    }
                });
                break;
        }
    };

    // Effect to handle search term updates
    useEffect(() => {
        handleSetArchiveStatus(Array.isArray(selectedOptions) ? selectedOptions[0] : selectedOptions);
    }, [selectedOptions]);

    return (
        <>
            <div className="project-head d-flex flex-sm-row flex-column align-items-sm-center align-items-start gap-sm-4 gap-0 justify-content-between">
                {!isMobile && <div className="total-data">
                    <span>
                        {projects?.totalResults} <small className="d-sm-flex d-none">{t("Total")}</small>
                    </span>
                </div>
                }
                <div className="tab-right-filter">
                    <div className="search-input-form" ref={projectSearchDropdown.ref}>
                        <div className="mobile-search-img d-lg-none d-inline-flex" onClick={projectSearchDropdown.toggle}>
                            <Image src={dynamicImage("svg/search.svg")} alt="search" />
                        </div>
                        {(!isMobile || projectSearchDropdown.isOpen) && (
                            <div className={`search-input d-lg-inline-flex d-none ${projectSearchDropdown.isOpen ? "open" : ""}`} id="projectSearch">
                                <input className="form-control" onFocus={() => { searchBar?.classList.add('full-search') }} placeholder={t("SearchByProjectName")} value={searchTerm} onChange={(e) => handleSetSearch(e.target.value)} autoComplete="off" />
                                <Image src={dynamicImage("svg/search-dark.svg")} alt="search" className="search-img" />
                                <Image src={dynamicImage("svg/search-red.svg")} alt="search" className="search-img-hover" />
                                {searchTerm.length > 0 && (
                                    <div className="close-img" onClick={() => { handleSetSearch(""); searchBar?.classList.remove('full-search') }}>
                                        <Image src={dynamicImage("svg/close-red.svg")} alt="close" />
                                    </div>
                                )}
                            </div>)}
                    </div>
                    {/*TODO: The archiving feature has been disabled and will be activated later. <CommonSelect
                        placeholder={t('ShowAll')}
                        options={archiveOptions}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        multiSelect={false}
                    /> */}
                    <SolidButton className="text-nowrap btn-solid" onClick={() => toggleProjectModal()}>
                        <Image src={dynamicImage("svg/plus-white.svg")} alt="arrow" />
                        <span className="d-xxl-inline-flex d-none">{t("NewProject")}</span>
                    </SolidButton>
                </div>
            </div>
            <div className="all-project-table">
                {tableConfig.total > 0 ? (
                    <CommonTable tableConfiguration={tableConfig} currentPage={pageNumber} itemsPerPage={pageSize} goToPage={goToPage} handleSetPageSize={handleSetPageSize} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} onActionPerform={handleActionPerform} />
                ) : (
                    <div className="table-no-data">
                        <Image src={dynamicImage("svg/no-data/no-data-found-table.svg")} alt="no data" className="img-fluid" />
                        <h5 dangerouslySetInnerHTML={{ __html: t("NoData.Projects") }}></h5>
                    </div>
                )}
            </div>
        </>
    )
}
export default AllProjects;