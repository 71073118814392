import React, { useEffect } from 'react';
import { useNavigate, useLocation, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { appInsights } from '../api';

const ErrorPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const error = useRouteError();

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        // Log the error to Application Insights with additional details
        // cast error

        const errorCode = isRouteErrorResponse(error) ? error.status : 'Unknown Error';
        const errorMessage = `Error encountered: ${errorCode}. Path: ${location.pathname}`;
        var errorDetails = isRouteErrorResponse(error) ? error.statusText : 'No additional details available';
        appInsights.trackException({
            error: new Error(errorMessage),
            properties: {
                ErrorCode: errorCode,
                UrlPath: location.pathname,
                ErrorMessage: errorDetails
            }
        });
    }, [location]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center'
        }}>
            <h1>Oops! Something went wrong.</h1>
            <br />
            <p>We're sorry, but the page you were looking for doesn't exist or an unexpected error has occurred.</p>
            <button
                onClick={handleGoBack}
                className='btn btn-solid'
            >
                Go Back
            </button>
        </div>
    );
};

export default ErrorPage;