import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, FormGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Image, LinkButton, SolidButton } from "../../AbstractElement";
import { mutations, queries } from "../../api";
import { useCoreData } from "../../Hooks";
import { PAYMENT_TYPE, PaymentType } from "../../Types";
import { convertToReadableAmount, convertToReadableDate, dynamicImage, formatPercentage, getMessages } from "../../utils";
import { useLayoutContext, useStateContext, useToast } from "../../views";

const Payment = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [paymentType, setPaymentType] = useState<PaymentType>(PAYMENT_TYPE.Credit);
  const { showToast } = useToast();
  const { projectCategoriesOptions } = useCoreData({ loadProjectCategories: true });
  const { openInvoiceViewModal } = useStateContext();
  const { isMobile } = useLayoutContext()
  const navigate = useNavigate();

  // Fetching calculated amounts and invoice scope data
  const { data: calculatedAmounts } = queries.useCalculateInvoiceAmount({ id, paymentMethod: paymentType });
  const { data: invoiceScope } = queries.useGetInvoiceScope(id!);

  const { mutate: generateStripePaymentLink, isLoading: isGeneratingLink } = mutations.useGeneratePaymentLink();
  const { mutate: downloadInvoicePdf, isLoading: isDownload } = mutations.useDownloadInvoiceScopePdf();

  const handleDownloadInvoice = async () => {
    downloadInvoicePdf({ id: invoiceScope?.id!, fileName: `Invoice_${invoiceScope?.documentNo}.pdf` }, {
      onSuccess: () => showToast("success", "InvoiceDetailCanvas.DownloadedInvoice")
    });
  };

  const handlePayment = () => {
    generateStripePaymentLink(id!, {
      onSuccess: (e) => {
        window.location.assign(e.url);
      },
      onError: (e) => {
        if (Array.isArray(e)) {
          showToast("error", getMessages(e));
        }
      },
    });
  };

  const changeTabs = (type: string) => {
    setPaymentType(type);
    document.body.classList.add('no-loader');
  }

  const formattedBankAccountDetails = useMemo(() => {
    if (!invoiceScope?.issuedByBankAccountDetails) return null;

    return invoiceScope.issuedByBankAccountDetails.split("\r\n").map((line, index, array) => {
      const [label, value] = line.split(/:(.*)/);
      return (
        <Fragment key={index}>
          {line &&
            <li>
              <span>{label}</span> <small>{value?.trim()}</small> {/* Ensure value is trimmed to remove extra spaces */}
            </li>}
        </Fragment>
      )
    });
  }, [invoiceScope]);

  return (
    <>
      <Container fluid>
        <div className="payment-main">
          <Row className="g-0">
            <Col xxl="8" xl="7" className="payment-left order-xl-first order-last">
              <Row>
                <Col xs="12">
                  <FormGroup className="mb-0">
                    <div className="d-flex align-items-center gap-3">
                      <label>{t("SelectPaymentType")}</label>
                    </div>
                  </FormGroup>
                </Col>

                <Col xs="12" className="horizontal-main-tab-filled">
                  <Nav pills className="horizontal-tab-filled custom-scrollbar" id="pills-tab">
                    <NavItem>
                      <NavLink
                        className={paymentType === PAYMENT_TYPE.Credit ? "active" : ""}
                        onClick={() => changeTabs(PAYMENT_TYPE.Credit)}
                        id="pills-credit-card-tab"
                      >
                        <span className="circle"></span>
                        {t("CreditCard")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={paymentType === PAYMENT_TYPE.Transfer ? "active" : ""}
                        onClick={() => changeTabs(PAYMENT_TYPE.Transfer)}
                        id="pills-bank-transfer-tab"
                      >
                        <span className="circle"></span>
                        {t("BankTransfer")}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent className="horizontal-tab-filled-content" activeTab={paymentType} id="pills-tabContent">
                    <TabPane tabId={PAYMENT_TYPE.Credit} id="pills-credit-card">
                      <div className="tab-content-height custom-scroll">
                        <Row>
                          <Col xs="12">
                            <div>
                              <p>{t("YouCanPayTheInvoiceWithYourCard")}</p>
                              <p>{t("AdditionalVerificationDocumentsMayBeRequestedFromYourBusiness")}</p>
                              <p>{t("CardPaymentsWillIncurExtraFeesAndMayTakeBusinessDaysToReachYourFreelancer")}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <Row className="payment-setting-footer-main">
                        <Col xs="12" className="p-0">
                          <div className="payment-setting-footer">
                            <LinkButton onClick={() => navigate(-1)}>
                              <span>{t("Cancel")}</span>
                            </LinkButton>
                            <SolidButton onClick={handlePayment} disabled={isGeneratingLink}>
                              {t(`${isMobile ? "Continue" : "ContinueWithOnlinePayment"}`)}
                              <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt="arrow" className="next" />
                            </SolidButton>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId={PAYMENT_TYPE.Transfer} id="pills-bank-transfer">
                      <div className="tab-content-height bank-transfer custom-scroll">
                        <Row>
                          <Col xs="12">
                            <div className="sub-tab-content">
                              <p className="first-content">{t("YouHaveApprovedThisInvoiceAndYouCanMakeThePaymentUsingTheBankDetailsProvidedBelow")}.</p>
                              <ul className="bank-transfer-list">{formattedBankAccountDetails}</ul>
                              <p className="mt-5">{t("AfterMakingThePaymentYouDontNeedToTakeAnyFurtherAction")}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="bank-transfer-btn">
                        <LinkButton onClick={() => navigate(-1)}>{t("Close")}</LinkButton>
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Col>

            <Col xxl="4" xl="5" className="payment-right order-xl-last order-first">
              <h5 className="payment-title">{t("InvoiceDetails")}</h5>
              <ul className="invoice-data">
                <li className="d-xl-flex d-none">
                  <span>{t("Project")}</span>
                  <p>
                    {invoiceScope?.projectName}{" "}
                    <small>({projectCategoriesOptions?.find((f) => f.value === invoiceScope?.projectCategoryCode)?.label})</small>
                  </p>
                </li>
                <li>
                  <span>{t("Freelancer")}</span>
                  <p>
                    {invoiceScope?.freelancerName} <small>({invoiceScope?.freelancerEmail})</small>
                  </p>
                </li>
                <li>
                  <span>{t("InvoiceNumber")}</span>
                  <p>#{invoiceScope?.documentNo}</p>
                </li>
                <li>
                  <span>{t("InvoiceDate")}</span>
                  <p>{convertToReadableDate(invoiceScope?.documentDate!, "medium")}</p>
                </li>
                <li className="d-xl-flex d-none">
                  <span>{t("Amount")}</span>
                  <p>{convertToReadableAmount(invoiceScope?.totalIncludingTaxes!, invoiceScope?.currencyCode!)}</p>
                </li>

                {calculatedAmounts !== undefined && (
                  <li className="d-xl-flex d-none">
                    <span>{t("ServiceFeeValue", { fee: formatPercentage(calculatedAmounts?.commissionRate! * 100) })}</span>
                    <p>{convertToReadableAmount(calculatedAmounts?.commissionAmount ?? 0, invoiceScope?.currencyCode!)}</p>
                  </li>
                )}
                <li>
                  <span>{t("TotalAmount")}</span>
                  <p>{convertToReadableAmount(calculatedAmounts?.total ?? 0, invoiceScope?.currencyCode!)}</p>
                </li>
              </ul>
              <ul className="invoice-action">
                <li className="cursor-pointer" onClick={() => openInvoiceViewModal(id!)}><Image src={dynamicImage("svg/icon_preview.svg")} alt="eye" /> {t("View")}</li>
                <li className="cursor-pointer" onClick={handleDownloadInvoice}><Image src={dynamicImage("svg/download.svg")} alt="download" /> {isDownload ? t("Downloading") : t("Download")}</li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Payment;