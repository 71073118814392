import { QueryKey, useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import useAuth from "./useAuth";
import { HTTP_STATUS, ROUTES } from "../Constants";
import { CombinedErrorResponse } from "../Types/ErrorResponse";
import { useToast } from '../views';
import { getMessages } from "../utils";

function useApiDelete<TInput, TResponse>(
    keys: QueryKey[],
    callback: (input: TInput) => Promise<TResponse>,
    options?: UseMutationOptions<TResponse, CombinedErrorResponse, TInput>
) {
    const q = useQueryClient();
    const navigate = useNavigate();
    const { removeUser } = useAuth();
    const { showToast } = useToast()

    return useMutation<TResponse, CombinedErrorResponse, TInput>([...keys], callback, {
        ...options,
        onSuccess: (data) => {
            for (let i = 0; i < keys.length; i++) {
                q.invalidateQueries(keys[i]);
            }

            // By using a generic type (TInput), the useApiPost function can accept input data of any structure, depending on the specific use case.
            options?.onSuccess?.(data, {} as TInput, null);
        },
        onError: (error: CombinedErrorResponse) => {

            switch (error.status) {
                case HTTP_STATUS.UNAUTHORIZED:
                    removeUser();
                    navigate(ROUTES.FreelancerLogIn + `?returnUrl=${window.location.pathname}`, {
                        replace: true
                    });
                    break;
                default:
                    if (error.status === HTTP_STATUS.BAD_REQUEST || error.status === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
                        showToast("error", getMessages(error));
                    }
                    else if (error.status !== HTTP_STATUS.BAD_REQUEST && error.status !== HTTP_STATUS.CONFLICT) {
                        showToast("error", "OccurredUnknownError");
                    }
                    break;
            }
        },
    });
}
export default useApiDelete;