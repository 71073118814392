import { Col, Form, FormGroup, InputGroup, Row } from 'reactstrap'
import { Image, LinkButton, SolidButton } from '../../../../AbstractElement'
import { dynamicImage } from '../../../../utils'
import { useTranslation } from 'react-i18next'
import { ROUTES, SITE_SETTINGS } from '../../../../Constants'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginSchema } from '../../../../Schemas'
import { mutations } from '../../../../api'
import { BusinessSignupFormValues } from '../../../../Types/Auth/Auth'
import { useNavigate } from 'react-router-dom'
import { CommonSelect } from '../../../../CommonComponent'
import { useState } from 'react'
import { useCoreData } from '../../../../Hooks'

const BusinessSignup = () => {

  const { t } = useTranslation()
  const { BusinessSignUpSchema } = LoginSchema()
  const { mutate: businessSignUp, isLoading } = mutations.useBusinessSignUp();
  const navigate = useNavigate();
  const { countryOptions, phoneCountryCodeOptions } = useCoreData({ loadCountries: true });

  const [isChecked, setIsChecked] = useState(false);

  const [selectedPhoneCountryCodeOptions, setSelectedPhoneCountryCodeOptions] = useState<string>();
  const [selectedCountryOptions, setSelectedCountryOptions] = useState<string>();

  const { register, handleSubmit, trigger, setValue, formState: { errors } } = useForm<BusinessSignupFormValues>({
    resolver: yupResolver(BusinessSignUpSchema),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      phoneCountryCode: selectedPhoneCountryCodeOptions,
      addressLine1: '',
      city: '',
      state: '',
      countryCode: selectedCountryOptions,
      postalCode: '',
      addressLine2: '',
    },
  });

  const onSubmit: SubmitHandler<BusinessSignupFormValues> = async (data) => {
    businessSignUp(data, {
      onSuccess: (response) => {
        const verificationToken = response?.verificationToken;
        if (verificationToken) {
          navigate(ROUTES.BusinessVerificationPin, {
            state: { verificationToken, email: data.email },
          });
        }
      },
    });
  };

  const classNameError = (field: keyof BusinessSignupFormValues) => {
    return `form-control ${errors[field] ? "required-field" : ""}`;
  };

  const renderError = (field: keyof BusinessSignupFormValues) => (
    errors[field] && <span className="required-note"><small>{t(errors[field]?.message || "")}</small></span>
  );

  return (
    <div className='auth-business'>
      <Image src={dynamicImage("svg/body-bg.svg")} className="back-img" alt="logo" />
      <Image src={dynamicImage("svg/logo/logo.svg")} className="auth-image" alt="logo" />
      <Form onSubmit={handleSubmit(onSubmit)} className='auth-business-form custom-scroll'>
        <div className="main-form">
          <h1>{t("CreateYourBusinessAccount")} <span>{1}/{2}</span></h1>
          <div className="julli-height custom-scroll">
            <Row className='g-3'>
              <Col sm="6">
                <FormGroup className='mb-0'>
                  <label>{t("BusinessName")}</label>
                  <input type="text" {...register('name')} className={classNameError('name')} id="businessName" placeholder={t("EnterBusinessName")} />
                  {renderError('name')}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className='mb-0'>
                  <label>{t("BusinessEmailAddressShort")}</label>
                  <input type="email" {...register('email')} className={classNameError('email')} id="businessEmailAddress" placeholder={t("EnterBusinessEmailAddress")} />
                  {renderError('email')}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className="phone-detail mb-0">
                  <label>{t("BusinessPhoneNumber")}</label>
                  <InputGroup>
                    <div className="dropdown-box">
                      <CommonSelect
                        placeholder={t('Select')}
                        options={phoneCountryCodeOptions}
                        selectedOptions={selectedPhoneCountryCodeOptions}
                        search={true}
                        setSelectedOptions={(value) => {
                          setSelectedPhoneCountryCodeOptions(value);
                          setValue('phoneCountryCode', value)
                          trigger('phoneCountryCode');
                        }}
                        multiSelect={false}
                      />
                      <input type="number" className="form-control" id="phone" placeholder={t("5005000000")}  {...register("phone")} />
                    </div>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className='mb-0'>
                  <label>{t("RegistrationNumber")}</label>
                  <input type="text" {...register('registrationNumber')} className="form-control" id="registrationNumber" placeholder={t("EnterRegistrationNumber")} />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className='mb-0'>
                  <label>{t("TaxOffice")}</label>
                  <input type="text" {...register('taxOffice')} className="form-control" id="taxOffice" placeholder={t("EnterTaxOffice")} />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className='mb-0'>
                  <label>{t("VatNumber")}</label>
                  <input type="text" {...register('vatNumber')} className="form-control" id="vatNumber" placeholder={t("EnterVATNumber")} />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className='mb-0'>
                  <label>{t("Country")}</label>
                  <CommonSelect
                    placeholder={t('Select')}
                    options={countryOptions}
                    selectedOptions={selectedCountryOptions}
                    search={true}
                    setSelectedOptions={(value) => {
                      setSelectedCountryOptions(value)
                      setValue('countryCode', value)
                      trigger('countryCode');
                    }
                    }
                    multiSelect={false}
                    className={`${errors.countryCode ? "required-field" : ""}`}
                  />
                  {renderError('countryCode')}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className='mb-0'>
                  <label>{t("State")}</label>
                  <input type="text" {...register('state')} className={classNameError('state')} id="state" placeholder={t("EnterState")} />
                  {renderError('state')}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className='mb-0'>
                  <label>{t("City")}</label>
                  <input type="text" {...register('city')} className={classNameError('city')} id="city" placeholder={t("EnterCityLong")} />
                  {renderError('city')}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className='mb-0'>
                  <label>{t("PostalCode")}</label>
                  <input type="text" {...register('postalCode')} className={classNameError('postalCode')} id="postalCode" placeholder={t("EnterPostalCode")} />
                  {renderError('postalCode')}
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup className='mb-0'>
                  <label>{t("Address")}</label>
                  <input type="text" {...register('addressLine1')} className={classNameError('addressLine1')} id="addressLine1" placeholder={t("AddressLine1")} />
                  {renderError('addressLine1')}
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup className='mb-0'>
                  <input type="text" {...register('addressLine2')} className="form-control" id="addressLine2" placeholder={t("AddressLine2")} />
                </FormGroup>
              </Col>
              <Col xs="12" className='business-fill-box'>
                <FormGroup className="form-group mb-0">
                  <div className="form-check mx-sm-4 mx-1 check-required">
                    <input type="checkbox" className={`form-check-input ${classNameError('terms')}`} id="terms" {...register("terms")} checked={isChecked} onChange={() => setIsChecked((prev) => !prev)} />
                    <label htmlFor="terms" className="cursor-pointer" dangerouslySetInnerHTML={{
                      __html: t('IAgreeToPrivacyPolicyAndTermsAndConditions', {
                        privacyPolicy: SITE_SETTINGS.PRIVACY_POLICY,
                        termsAndConditions: SITE_SETTINGS.TERMS_CONDITIONS,
                      })
                    }}></label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
        <div className="footer-buttons">
          <LinkButton to={ROUTES.BusinessLogin} className='d-block'>
            {t("BackToLogin")}
          </LinkButton>
          <SolidButton type="submit" loading={isLoading} disabled={!isChecked}>
            {t("Continue")}
            <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt="arrow" className="next" />
          </SolidButton>
        </div>
      </Form>
    </div>
  )
}

export default BusinessSignup
