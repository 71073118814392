import { useEffect, useMemo, useState } from 'react'
import { Image, SolidButton } from '../../AbstractElement';
import { dynamicImage } from '../../utils';
import { CommonSelect, CommonTable, ConfirmationDialog } from '../../CommonComponent';
import { useTranslation } from 'react-i18next';
import { mutations, queries } from '../../api';
import { useCoreData, useDropdown, useTableFilterHelper } from '../../Hooks';
import { TableClickedAction, TableConfig } from '../../Types/CommonComponent';
import { Freelancer } from '../../Types';
import { useStateContext, useLayoutContext, useToast } from '../../views';

const Freelancers = () => {
    const { t } = useTranslation();
    const { openOnlyViewFreelancerModal, openEditFreelancerModal, openInviteFreelancerModal } = useStateContext();
    const { isMobile, toggleState } = useLayoutContext()

    const [selectedOptions, setSelectedOptions] = useState<string[] | string>([]);
    const { pageNumber, pageSize, searchTerm, params, goToPage, handleSetPageSize, handleSetSearch, archiveOptions, handleSetArchiveStatus, toggleSortType, handleSetSortBy } = useTableFilterHelper({ archiveStatus: false });

    const { data: freelancer, refetch: refetchFreelancer } = queries.useGetFreelancers(params);
    const { mutate: archiveFreelancer } = mutations.useArchiveFreelancer();
    const { professionCodeOptions } = useCoreData({ loadProfessions: true });
    const freelancerSearchDropdown = useDropdown<HTMLDivElement>(toggleState);
    const searchBar = document.getElementById('freelancerSearch')
    const { showToast } = useToast()

    const handleActionPerform = (action: TableClickedAction<Freelancer>) => {
        switch (action.actionToPerform) {
            case 'view':
                openOnlyViewFreelancerModal(action.data.id);
                break;
            case 'archive':
                const isArchived = action.data.archived;
                ConfirmationDialog({
                    title: isArchived ? "Unarchive freelancer" : "Archive freelancer",
                    text: isArchived ? "Are you sure you want to unarchive this freelancer?" : "Are you sure you want to archive this freelancer?",
                    confirmButtonText: isArchived ? "Unarchive freelancer" : "Archive freelancer",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            if (action?.data)
                                archiveFreelancer({ id: action.data.id, archived: !isArchived }, {
                                    onSuccess: () => {
                                        refetchFreelancer()
                                        if (!isArchived) {
                                            showToast("delete", 'Freelancers.FreelancerArchivedSuccessfully');
                                        }
                                        else {
                                            showToast("success", 'Freelancers.FreelancerUnarchivedSuccessfully');
                                        }
                                    },
                                });
                        }
                    }
                });
                break;
            case 'edit':
                openEditFreelancerModal(action.data.id);
                break;
            default:
                console.warn("Unknown action:", action.actionToPerform);
        }
    };

    // Table configuration state  
    const tableConfig = useMemo<TableConfig<Freelancer>>(() => ({
        columns: [
            {
                title: "FreelancerName", dataField: [{
                    field: 'fullName', class: 'top hover-link', type: '',
                    renderer: (element: Freelancer) => {
                        return (
                            <div className="user-name-box hover-link">
                                <div className="initial-letter">
                                    <span>{element?.fullName.split(' ')?.map((letter: string) => letter.charAt(0).toUpperCase())?.join('')?.slice(0, 2)}</span>
                                </div>
                                <div className="responsive-box">
                                    <h6 className="hover-link">{element.fullName}</h6>
                                    <span>({element.email})</span>
                                </div>
                            </div>
                        )
                    }
                }], class: '', sortable: true, sortField: 'fullName', sortDirection: 'desc'
            },
            {
                title: "JobTitle",
                dataField: [{
                    field: "professionCode",
                    class: '',
                    type: '',
                    renderer: (data: Freelancer) => {
                        const professionLabel = professionCodeOptions?.find(f => f.value === data.professionCode)?.label ?? "";
                        return <span>{data.otherProfessionName ? data.otherProfessionName : professionLabel}</span>;
                    }
                }],
                class: 'title',
                sortable: true,
                sortField: 'fullName',
                sortDirection: 'desc'
            },
        ],
        actionsDropDown: [
            { label: "ViewDetails", actionToPerform: "view" },
            //TODO: The archiving feature has been disabled and will be activated later.
            // { label: "Archive", actionToPerform: "archive", conditional: { field: 'archived', condition: '?', actionLabel: 'Unarchive : Archive' } },
            // do not allow to edit freelancer details because of freelancers are being listed as single entity
            // but they are not :)
            // { label: "Edit", actionToPerform: "edit" },
        ],
        data: freelancer?.items ?? [],
        total: freelancer?.totalResults ?? 0,
        isColumnChooser: true,
    }), [freelancer]);

    // Effect to handle search term updates
    useEffect(() => {
        handleSetArchiveStatus(Array.isArray(selectedOptions) ? selectedOptions[0] : selectedOptions);
    }, [selectedOptions]);

    return (
        <div className="customer-main freelancers-page not-tab-box">
            <div className="horizontal-main-tab">
                <div className="freelancers-new d-flex flex-sm-row flex-column align-items-sm-center align-items-start justify-content-between w-100">
                    {!isMobile && <div className="total-data">
                        <span>
                            {freelancer?.totalResults} <small className="d-sm-flex d-none">{t("Freelancer")}</small>
                        </span>
                    </div>
                    }
                    <div className="tab-right-filter">
                        <div className="search-input-form" ref={freelancerSearchDropdown.ref}>
                            <div className="mobile-search-img d-lg-none d-inline-flex" onClick={freelancerSearchDropdown.toggle}>
                                <Image src={dynamicImage("svg/search.svg")} alt="search" />
                            </div>
                            {(!isMobile || freelancerSearchDropdown.isOpen) && (
                                <div className={`search-input d-lg-inline-flex d-none ${freelancerSearchDropdown.isOpen ? "open" : ""}`} id="freelancerSearch">
                                    <input
                                        className="form-control"
                                        onFocus={() => { searchBar?.classList.add('full-search') }}
                                        placeholder={t("SearchByName")}
                                        value={searchTerm}
                                        onChange={(e) => handleSetSearch(e.target.value)}
                                    />
                                    <Image src={dynamicImage("svg/search.svg")} alt="search" className="search-img" />
                                    <Image src={dynamicImage("svg/search-red.svg")} alt="search" className="search-img-hover" />
                                    {searchTerm.length > 0 && (
                                        <div className="close-img" onClick={() => { handleSetSearch(""); searchBar?.classList.remove('full-search') }}>
                                            <Image src={dynamicImage("svg/close-red.svg")} alt="close" />
                                        </div>
                                    )}
                                </div>)}
                        </div>
                        {/*TODO: The archiving feature has been disabled and will be activated later. <CommonSelect
                            placeholder={t('ShowAll')}
                            options={archiveOptions}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            multiSelect={false}
                        /> */}
                        <SolidButton className="text-nowrap btn-solid" onClick={openInviteFreelancerModal}>
                            <Image src={dynamicImage("svg/plus-white.svg")} alt="arrow" />
                            <span className="d-xxl-inline-flex d-none">{t("InviteNewFreelancer")}</span>
                        </SolidButton>
                    </div>
                </div>
                <div className="client-table freelancer-client-table">
                    {tableConfig.total > 0 ? (
                        <CommonTable tableConfiguration={tableConfig} currentPage={pageNumber} itemsPerPage={pageSize} goToPage={goToPage} handleSetPageSize={handleSetPageSize} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} onActionPerform={handleActionPerform} />
                    ) : (<div className="table-no-data">
                        <Image src={dynamicImage("svg/no-data/no-data-client.svg")} alt="no data" className="img-fluid" />
                        <h5 dangerouslySetInnerHTML={{ __html: t("NoData.Freelancers") }}></h5>
                    </div>)}
                </div>
            </div>
        </div >
    )
}
export default Freelancers;