import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Form, FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap';
import { useStateContext, useToast } from '../../../views';
import { CommonSelect } from '../../../CommonComponent';
import { useCoreData } from '../../../Hooks';
import { SolidButton } from '../../../AbstractElement';
import { mutations } from '../../../api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FreelancerFormInput } from '../../../Types';
import { FreelancerInviteSchema } from '../../../Schemas';

interface InviteFreelancerType {
    projectId?: string | undefined
}

const InviteFreelancer: React.FC<InviteFreelancerType> = ({ projectId }) => {
    const { t } = useTranslation();
    const { freelancerModal, closeFreelancerModal, toggleProjectModal } = useStateContext();
    const { professionCodeOptions, projectsOptions } = useCoreData({ loadProfessions: true, loadProjects: true });

    const [selectedProjectOptions, setSelectedProjectOptions] = useState<string>('');
    const [selectedProfessionsOptions, setSelectedProfessionsOptions] = useState<string>();
    const { mutate: freelancerInvite, isLoading } = mutations.useFreelancerInvite();

    const { FreelancerInvite } = FreelancerInviteSchema();
    const { showToast } = useToast()

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FreelancerFormInput>({

        resolver: yupResolver(FreelancerInvite),
        defaultValues: {
            email: '',
            professionCode: selectedProfessionsOptions,
            fullName: '',
            projectId: selectedProjectOptions,
            otherProfessionName: ''
        }
    });

    useEffect(() => {
        if (projectId) {
            setSelectedProjectOptions(projectId);
            setValue('projectId', projectId)
        }
    }, [projectId]);

    const onSubmit: SubmitHandler<FreelancerFormInput> = async (data) => {
        freelancerInvite(data, {
            onSuccess: () => {
                closeFreelancerModal()
                showToast("success", "Freelancer.FreelancerAddedSuccessfully")
            }
        })
    }

    return (<>
        <Offcanvas isOpen={freelancerModal.toggle} toggle={closeFreelancerModal} direction="end" className="theme-offcanvas-2">
            <div className='theme-offcanvas-box '>
                <div className='w-100'>
                    <OffcanvasHeader toggle={closeFreelancerModal}>{t("InviteNewFreelancer")}</OffcanvasHeader>
                    <Button className="btn-close" onClick={closeFreelancerModal} color='transparent'></Button>
                    <OffcanvasBody className="custom-scroll">
                        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Row className='g-sm-4 g-3'>
                                <FormGroup className='mb-0 col-12'>
                                    <label>
                                        {t("EmailId")}<span className="required-data">{t("*Required")}</span>
                                    </label>
                                    <input type="text" className={`form-control ${errors.email ? "required-field" : ""}`} id="email" placeholder={t("EnterFreelancerEmail")} {...register("email")} autoComplete="off" />
                                    {errors.email && (
                                        <span className="required-note">
                                            <small>{errors.email.message || ""}</small>
                                        </span>
                                    )}
                                </FormGroup>
                                <FormGroup className='mb-0 col-12'>
                                    <label>{t("FreelancerName")}</label>
                                    <input type="text" className={`form-control ${errors.fullName ? "required-field" : ""}`} id="fullName" placeholder={t("EnterFreelancerName")} {...register("fullName")} autoComplete="off" />
                                    {errors.fullName && (
                                        <span className="required-note">
                                            <small>{errors.fullName.message || ""}</small>
                                        </span>
                                    )}
                                </FormGroup>
                                <FormGroup className='mb-0 col-12'>
                                    <label>{t("JobTitle")}</label>
                                    <CommonSelect
                                        placeholder={t('SelectId')}
                                        options={professionCodeOptions}
                                        selectedOptions={selectedProfessionsOptions}
                                        setSelectedOptions={(value) => {
                                            setSelectedProfessionsOptions(value);
                                            setValue('professionCode', value)
                                        }}
                                        multiSelect={false}
                                        search={true}
                                        className='body outside-dropdown'
                                    />
                                </FormGroup>
                                {professionCodeOptions?.find(res => res.value === selectedProfessionsOptions)?.data?.entryDescriptionIsRequired &&
                                    <FormGroup className='mb-0 col-12'>
                                        <input type="text" className="form-control" placeholder={t("JobTitle")} {...register("otherProfessionName")} autoComplete="off"></input>
                                    </FormGroup>
                                }
                                <FormGroup className='mb-0 col-12'>
                                    <label>{t("Project")}</label>
                                    <CommonSelect
                                        placeholder={t('SelectProject')}
                                        className={`body outside-dropdown ${errors.projectId ? "required-field" : ""}`}
                                        options={projectsOptions!}
                                        selectedOptions={selectedProjectOptions}
                                        setSelectedOptions={(value) => {
                                            setSelectedProjectOptions(value);
                                            setValue('projectId', value)
                                        }}
                                        buttonText={t('NewProject')}
                                        buttonClass='btn create-btn'
                                        multiSelect={false}
                                        search={true}
                                        onClick={() => { toggleProjectModal() }}
                                    />
                                    {errors.projectId && (
                                        <span className="required-note">
                                            <small>{t(errors.projectId.message || "")}</small>
                                        </span>
                                    )}
                                </FormGroup>
                            </Row>
                            <div className="offcanvas-footer">
                                <SolidButton className='btn-gray justify-content-center' onClick={closeFreelancerModal}>{t("Cancel")}</SolidButton>
                                <SolidButton className='justify-content-center' color="success" type="submit" loading={isLoading} >
                                    {t("SendInvitation")}
                                </SolidButton>
                            </div>
                        </Form>
                    </OffcanvasBody>
                </div>
            </div>
        </Offcanvas>
    </>
    )
}
export default InviteFreelancer;