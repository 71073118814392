import { Col, Row } from "reactstrap";

import { Image } from "../../../../AbstractElement";
import { dynamicImage, getParam } from "../../../../utils";
import { LogoWrapper, GoogleLinkedinMedia, ImageSection } from "../../Widgets";
import LoginForm from "./LoginForm";
import { useEffect } from "react";
import { useLayoutContext } from "../../../../views";
import { USER_ROLE } from "../../../../Types";

const FreelancerLogin = () => {
  const { isMobile } = useLayoutContext()
  const returnUrl = getParam("returnUrl");
  useEffect(() => {
    if (!returnUrl) {
      sessionStorage.removeItem("returnUrl");
      return;
    }
    sessionStorage.setItem("returnUrl", returnUrl);
  }, [returnUrl]);

  return (
    <div className="log-in-section">
      <div className="bg-image">
        <Image src={dynamicImage("svg/body-bg.png")} alt="background image" />
      </div>
      <Row className="login-content g-0">
        <Col xxl="5" xl="6" lg="8" md="11" className="auth-left">
          <div className="login-main">
            <LogoWrapper returnUrl={returnUrl} freelancer tabBadge />
            <LoginForm />
            <ul className="social-media">
              <GoogleLinkedinMedia googleText={isMobile ? "Google" : "LoginWithGoogle"} linkedinText={isMobile ? "LinkedIn" : "LoginWithLinkedIn"} />
            </ul>
          </div>
        </Col>
        <ImageSection userName={USER_ROLE.Freelancer} />
      </Row>
    </div>
  );
};

export default FreelancerLogin;
