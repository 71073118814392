import { KEYS, URL_KEYS } from "../Constants";
import { useApiGet } from "../Hooks";
import get from "./get";
import {
  Account, Agreement, BankAccount, InvoiceScope, Client, Transaction, DraftInvoice, DraftInvoiceItem, InvoiceHistory, Paginated, Project, DeclineReason, ProjectDocument, Freelancer, Activity, CalendarActivity,
  InvoiceLine,
  CalculateAmount,
  InvoiceFast,
  CalculateAmountResult,
  CurrencyPayoutType,
  ProjectLookupItem,
  ContractType,
  ProjectFreelancer, ProjectFreelancerItem
} from "../Types";
import { Params } from "../Types/CommonElement";

export const queries = {
  // Account
  useGetUserDetails: (enabled?: boolean) =>
    useApiGet<Partial<Account> & { email?: string }>([KEYS.ACCOUNT], () => get(URL_KEYS.Account.AccountInfo), {
      cacheTime: 0,
      enabled,
    }),

  useGetActivities: () => useApiGet<Paginated<Activity>>([KEYS.ACTIVITIES], () => get(URL_KEYS.Account.Activities)),

  useGetCalendarActivities: () =>
    useApiGet<CalendarActivity[]>([KEYS.CALENDAR_ACTIVITIES], () => get(URL_KEYS.Account.CalendarActivities)),

  useGetDashboardChart: (params: Params) => useApiGet<any>([KEYS.DASHBOARD_CHART, params], () => get(URL_KEYS.Account.DashboardChart, params), {}),

  // Debit card
  useGetDebitCardStatus: (enabled?: boolean) => useApiGet<{ applicationDate: string }>([KEYS.DEBIT_CARD], () => get(URL_KEYS.Account.DebitCardStatus), { enabled }),

  //Juuli IBAN
  useIbanApplicationStatus: (enabled?: boolean) => useApiGet<{ applicationDate: string }>([KEYS.IBAN_APPLICATION], () => get(URL_KEYS.Account.IbanApplicationStatus), { enabled }),

  // Agreements
  useGetMissingAgreements: (enabled?: boolean) => useApiGet<ContractType[]>([KEYS.AGREEMENTS], () => get(URL_KEYS.UserAgreement.MissingAgreements), { enabled }),

  //Lookups
  useGetLookup: <T>(lookup: string, enabled: boolean, params?: Params, key?: string) => useApiGet<Paginated<T>>([lookup, params], () => get(lookup, params), { enabled: enabled, refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: true, }),
  useGetLookupClient: (lookup: string, enabled: boolean, params?: Params, key?: string) => useApiGet<Paginated<Client>>([lookup, params], () => get(lookup, params), { enabled: enabled, }),

  useGetInvoiceDeclineReasons: (statusType: string, options?: any) => useApiGet<Paginated<DeclineReason>>([KEYS.INVOICE_DECLINE_REASONS, statusType], () => get(URL_KEYS.Lookup.InvoiceStatusReasons + `/${statusType}`), options),

  // Currency Payout Types
  useGetCurrencyPayoutTypes: (params?: Params, options?: any, enabled?: boolean) =>
    useApiGet<Paginated<CurrencyPayoutType>>(
      [KEYS.CURRENCY_PAYOUT_TYPES, params],
      () => get(`${URL_KEYS.Lookup.CurrencyPayoutTypes}`, params),
      { ...options, enabled: !!params?.currencyCode && enabled }  // Disable automatic fetching
    ),

  // Invoice Scope

  // Get invoice
  useGetInvoiceScopes: (params?: Params) => useApiGet<Paginated<InvoiceScope>>([KEYS.INVOICE_SCOPE, params], () => get(URL_KEYS.InvoiceScope.InvoiceScope, params), {}),

  // Get invoice by
  useGetInvoiceScope: (id?: string, enabled?: boolean,) => useApiGet<InvoiceScope>([, KEYS.INVOICE_SCOPE, id], () => get(`${URL_KEYS.InvoiceScope.InvoiceScope}/${id}`), { enabled: !!id && enabled }),

  // Latest invoices
  useGetLatestInvoiceScopes: () => {
    const params: Params = {
      PageNumber: "1",
      PageSize: "3",
      OrderBy: "modifiedAt desc",
    };
    return useApiGet<Paginated<InvoiceScope>>([KEYS.INVOICE_SCOPE, params], () => get(URL_KEYS.InvoiceScope.InvoiceScope, params), { cacheTime: 0, staleTime: 0 });
  },

  // Invoice Scope Log

  // Log
  useGetInvoiceScopeLog: (id?: string, options?: any, enabled?: boolean) => useApiGet<InvoiceHistory[]>([KEYS.INVOICE_SCOPE_LOG, id], () => get(`${URL_KEYS.InvoiceScopeLog.InvoiceScopeLogById}/${id}`), { ...options, enabled: !!id && enabled }),

  // Last Log
  useGetInvoiceScopeLastInvoice: () => useApiGet<{ invoiceScope: InvoiceScope; logs: InvoiceHistory[] }>([KEYS.INVOICE_SCOPE_LOG_LAST_INVOICE], () => get(URL_KEYS.InvoiceScopeLog.LastInvoiceLog), { cacheTime: 0, staleTime: 0 }),

  // Draft Invoice

  // Get
  useGetDraftInvoices: (params?: Params) => useApiGet<Paginated<DraftInvoiceItem>>([KEYS.INVOICES, params], () => get(URL_KEYS.DraftInvoice.Invoice, params), {}),

  // Calculate Amount
  useGetCalculateAmount: (id?: string, params?: Params, enabled?: boolean) =>
    useApiGet<CalculateAmount>([KEYS.INVOICE_CALCULATE_AMOUNT, id!, params], () => get(`${URL_KEYS.DraftInvoice.Invoice}/${id}/CalculateAmount`, params), {
      enabled: !!id && enabled,
    }),

  // Get draft invoice by ID
  useGetDraftInvoice: (id?: string) =>
    useApiGet<DraftInvoice>([KEYS.INVOICE, id!], () => get(`${URL_KEYS.DraftInvoice.Invoice}/${id}`), {
      enabled: !!id,
    }),

  // Transaction
  useGetTransactions: (params?: Params) => useApiGet<Paginated<Transaction>>([KEYS.TRANSACTION, params], () => get(URL_KEYS.Transaction.Transaction, params)),

  useGetLatestTransactions: (transactionType?: string) => {
    const params: Params = {
      PageNumber: "1",
      PageSize: "3",
      OrderBy: "createdAt desc",
      transactionType: transactionType,
    };
    return useApiGet<Paginated<Transaction>>([KEYS.TRANSACTION, params], () => get(URL_KEYS.Transaction.Transaction, params));
  },

  // Freelancers
  useGetFreelancers: (params?: Params) => useApiGet<Paginated<Freelancer>>([KEYS.FREELANCERS, params], () => get(URL_KEYS.Freelancer.Freelancer, params), {}),

  useGetFreelancer: (id?: string, options?: any) => useApiGet<ProjectFreelancer>([KEYS.FREELANCER, id!], () => get(`${URL_KEYS.Freelancer.Freelancer}/${id}`), {
    ...options,
    enabled: !!id,
  }),

  useGetProjectFreelancers: (id?: string, params?: Params) => useApiGet<Paginated<ProjectFreelancerItem>>([KEYS.FREELANCER_PROJECT, id!, params], () => get(`${URL_KEYS.Project.Project}/${id}/Freelancers`, params), {
    enabled: !!id
  }),

  useGetFreelancerProject: (id?: string, params?: Params, enabled?: boolean) => useApiGet<Paginated<ProjectLookupItem>>([KEYS.FREELANCER_PROJECT, id!, params], () => get(`${URL_KEYS.Freelancer.Freelancer}/${id}/Projects`, params), {
    enabled: !!id && enabled
  }),

  // Customers
  useGetCustomers: (params?: Params, options: any = null) => useApiGet<Paginated<Client>>([KEYS.CUSTOMER, params], () => get(URL_KEYS.Customer.Customer, params), options),

  useGetCustomer: (id?: string, options?: any, enabled?: boolean) =>
    useApiGet<Client>([KEYS.CUSTOMER, id!], () => get(`${URL_KEYS.Customer.Customer}/${id}`), {
      ...options,
      enabled: !!id && enabled,
    }),

  useGetCustomerProject: (id?: string, params?: Params, enabled?: boolean) => useApiGet<Paginated<ProjectLookupItem>>([KEYS.CUSTOMERS_PROJECT, id!, params], () => get(`${URL_KEYS.Customer.Customer}/${id}/Projects`, params), {
    enabled: !!id && enabled
  }),

  // Bank Account

  // All Bank Account
  useGetBankAccounts: () => useApiGet<Paginated<BankAccount>>([KEYS.BANK_ACCOUNT], () => get(URL_KEYS.BankAccount.BankAccount), {}),

  // Bank Account Detail
  useGetBankAccountDetails: (id?: string, options?: any, enabled?: boolean) =>
    useApiGet<BankAccount>(
      [KEYS.BANK_ACCOUNT, id],  // The query key, depending on `id`
      () => get(`${URL_KEYS.BankAccount.BankAccount}/${id}`),  // The API call
      {
        ...options,
        enabled: !!id && enabled  // Fetch only when `id` is valid and `enabled` is true
      }
    ),



  // User Agreement
  useGetUserAgreement: () => useApiGet<Paginated<Agreement>>([KEYS.USER_AGREEMENT], () => get(URL_KEYS.UserAgreement.UserAgreement), {}),

  // Projects

  // Get projects
  useGetProjects: (params?: Params, enabled?: boolean) => useApiGet<Paginated<Project>>([KEYS.PROJECT, params], () => get(URL_KEYS.Project.Project, params), { enabled: enabled }),

  // Get project by ID
  useGetProject: (id?: string, options?: any) => useApiGet<Project>([KEYS.PROJECT, id], () => get(`${URL_KEYS.Project.Project}/${id}`), { ...options, enabled: !!id }),

  // Get Document by project ID
  useGetProjectDocuments: (value: { id: string, params?: Params }, options?: any, enabled?: boolean) =>
    useApiGet<Paginated<ProjectDocument>>([KEYS.PROJECT_DOCUMENTS, value.id, value.params], () => get(`${URL_KEYS.Project.Project}/${value.id}/Document`, value.params), {
      ...options,
      enabled: !!value.id && enabled,
    }),

  // Get Document by projectId and documentId 
  useGetProjectDocument: (projectId?: string, id?: string, options?: any) =>
    useApiGet<ProjectDocument>([KEYS.PROJECT_DOCUMENT, projectId, id], () => get(`${URL_KEYS.Project.Project}/${projectId}/Document/${id}`), {
      enabled: !!id && !!projectId,
      ...options
    }),

  // Invoice Line

  // Get
  useGetInvoiceItem: (id: string | null, options?: any) => useApiGet<Paginated<InvoiceLine>>([KEYS.INVOICE_LINE, id], () => get(`${URL_KEYS.InvoiceLine.Invoice}/${id}/Lines`), { ...options, enabled: !!id }),

  //InvoiceScopeFast

  //Get
  useGetInvoiceScopeFast: (invoiceId?: string | null) =>
    useApiGet<InvoiceFast>([KEYS.INVOICE_SCOPE_FAST, invoiceId], () => get(`${URL_KEYS.InvoiceScopeFast.InvoiceScopeFast}/${invoiceId}`),
      {
        enabled: !!invoiceId
      }
    ),

  //payment
  useCalculateInvoiceAmount: ({ id, paymentMethod }: { id?: string; paymentMethod?: string }) =>
    useApiGet<CalculateAmountResult>(
      [KEYS.INVOICE_SCOPE, id, paymentMethod],
      () => get(`${URL_KEYS.InvoiceScope.InvoiceScope}/${id}/CalculateAmount/${paymentMethod}`),
      {
        enabled: !!id && !!paymentMethod
      }
    ),

  //Suggestion
  useGetSuggestion: (options?: any) =>
    useApiGet<any>([KEYS.SUGGESTION], () => get(URL_KEYS.Account.Suggestion), { staleTime: 0, cacheTime: 0, ...options }),

  //Profile Image
  useGetProfileImage: (enabled: boolean) =>
    useApiGet<any>([KEYS.PROFILE_IMAGE], () => get(`${URL_KEYS.Account.AccountInfo}/ProfileImage`), { enabled: enabled, refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: true }),

};



