import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SolidButton } from '../../../AbstractElement';
import { mutations } from '../../../api';
import CommonSelect from '../../../CommonComponent/CommonSelect';
import { useCoreData } from '../../../Hooks';
import { Account } from '../../../Types';
import { useLayoutContext } from '../../../views';
import { useToast } from '../../../views';

export const Questionnaire: React.FC<{ onClose: any, userDetails: Partial<Account> }> = ({ onClose, userDetails }) => {
  const { questionnaireModal } = useLayoutContext();
  const { t } = useTranslation();
  const { expertiseAreasOptions, invoiceFrequencyOptions, invoiceAmountEstimatesOptions } = useCoreData({ loadExpertiseAreas: true, loadInvoiceFrequency: true, loadInvoiceAmountEstimates: true });

  const [selectedExpertiseAreas, setSelectedExpertiseAreasOptions] = useState<string | undefined>(userDetails.expertiseAreaCode);
  const [selectedInvoiceFrequency, setSelectedInvoiceFrequencyOptions] = useState<string | undefined>(userDetails.invoiceFrequencyCode);
  const [selectedInvoiceAmount, setSelectedInvoiceAmountOptions] = useState<string | undefined>(userDetails.invoiceAmountEstimateCode);
  const [selectedUsualCustomerCount, setSelectedUsualCustomerCount] = useState<string | undefined>(userDetails.usualCustomerCountCode);
  const { mutate: questionnaire, isLoading } = mutations.useQuestionnaire();
  const { modalPosition } = useLayoutContext()
  const { showToast } = useToast()
  const { handleSubmit } = useForm();

  const handleAcceptAgreement = () => {
    questionnaire({
      expertiseAreaCode: selectedExpertiseAreas,
      invoiceFrequencyCode: selectedInvoiceFrequency,
      invoiceAmountEstimateCode: selectedInvoiceAmount,
      usualCustomerCountCode: selectedUsualCustomerCount
    }, {
      onSuccess: () => {
        onClose();
        showToast("success", "Questionnaire.QuestionnaireSubmittedSuccessfully")
      },
    })
  };

  return (
    <Modal centered dir={modalPosition} isOpen={questionnaireModal} toggle={onClose} className="theme-modal">
      <ModalHeader toggle={onClose}>{t("JuuliQuestionnaire")}</ModalHeader>
      <ModalBody>
        <Form noValidate onSubmit={handleSubmit(handleAcceptAgreement)}>
          <div className="h-100 invoice-content">
            <Row className='g-sm-4 g-3'>
              <Col xs="12">
                <FormGroup className='mb-0'>
                  <label>{t("WhatIsYourAreaOfExpertise")}</label>
                  <CommonSelect
                    placeholder={t('Select')}
                    options={expertiseAreasOptions!}
                    selectedOptions={selectedExpertiseAreas}
                    setSelectedOptions={setSelectedExpertiseAreasOptions}
                    multiSelect={false}
                  />
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup className='mb-0'>
                  <label>{t("DoYouHaveAnEstimateForTheFrequencyOfMonthlyInvoiceCreation")}</label>
                  <CommonSelect
                    placeholder={t('Select')}
                    options={invoiceFrequencyOptions!}
                    selectedOptions={selectedInvoiceFrequency}
                    setSelectedOptions={setSelectedInvoiceFrequencyOptions}
                    multiSelect={false}
                  />
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup className='mb-0'>
                  <label>{t("CanYouProvideAnEstimatedAnnualInvoiceAmountWithJuuli")}</label>
                  <CommonSelect
                    placeholder={t('Select')}
                    options={invoiceAmountEstimatesOptions!}
                    selectedOptions={selectedInvoiceAmount}
                    setSelectedOptions={setSelectedInvoiceAmountOptions}
                    multiSelect={false}
                  />
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup className='mb-0'>
                  <label>{t("HowManyClientsDoYouUsuallyWorkFor")}</label>
                  <CommonSelect
                    placeholder={t('Select')}
                    options={invoiceFrequencyOptions!}
                    selectedOptions={selectedUsualCustomerCount}
                    setSelectedOptions={setSelectedUsualCustomerCount}
                    multiSelect={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="modal-footer">
              <SolidButton className='btn-gray justify-content-center' onClick={onClose}>{t("Close")}</SolidButton>
              <SolidButton type="submit" loading={isLoading}>{t("Submit")}</SolidButton>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}
