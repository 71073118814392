/* Main Header */
import { Row } from "reactstrap";
import LogoWrapper from "./LogoWrapper";
import CommonHeader from "../../CommonComponent/Header";
import { useLocation } from "react-router-dom";
import { useLayoutContext } from "../../views";
interface HeaderProps {
  isVisible: boolean;
}

const Header: React.FC<HeaderProps> = ({ isVisible }) => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const { openSidebar } = useLayoutContext()

  return (
    <Row id="page-headers" className={`${path[1] === "project" ? "project-header" : ""} page-header ${isVisible ? 'page-header--visible' : 'page-header--hidden'} ${openSidebar ? 'close_icon' : ''}`}>
      <LogoWrapper />
      <CommonHeader isVisible={isVisible} />
    </Row>
  );
};
export default Header;
