import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";

import { LinkButton, SolidButton } from "../../../../AbstractElement";
import { mutations } from "../../../../api";
import { ROUTES, SITE_SETTINGS } from "../../../../Constants";
import { LoginSchema } from "../../../../Schemas";
import { EmailFormProps } from "../../../../Types/Auth/Auth";
import { getParam } from "../../../../utils";
import { useToast } from "../../../../views";

export const ForgotPasswordForm = () => {
    const [active, setActive] = useState(false);
    const [timer, setTimer] = useState<number>(SITE_SETTINGS.RESEND_TIMEOUT);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showToast } = useToast()

    const { EmailSchema } = LoginSchema();
    const { mutate: requestPin, isLoading } = mutations.useRequestForgotPassword();

    const { register, handleSubmit, formState: { errors } } = useForm<EmailFormProps>({
        resolver: yupResolver(EmailSchema),
    });

    const token = getParam("token");

    useEffect(() => {
        if (token) {
            navigate(`${ROUTES.CreatePassword}?token=${token}`);
        }
        let countdown: NodeJS.Timeout | undefined;
        if (active && timer > 0) {
            countdown = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        } else if (timer === 0) {
            setTimer(0);
        }
        return () => clearTimeout(countdown);
    }, [active, timer, token, navigate]);

    const handleRequestPin = (email: string) => {
        requestPin(
            { email },
            {
                onSuccess: () => {
                    setTimer(SITE_SETTINGS.RESEND_TIMEOUT);
                    setActive(true);
                    showToast("success", "ForgotPasswordForm.InstructionsAreSentPleaseCheckYourMail");
                },
            }
        );
    };

    const onSubmit = (data: EmailFormProps) => {
        handleRequestPin(data.email);
    };

    const handleResetLink = () => {
        if (timer === 0) {
            showToast("info", "ForgotPasswordForm.ANewCodeHasBeenSentToYourEmailAddress");
            handleRequestPin((document.getElementById("email") as HTMLInputElement).value);
        }
    };

    return (
        <Row>
            <Col xxl="6" lg="7" md="8" xs="12" className="mx-auto mb-4 custom-form-w">
                <div className="verification-form">
                    <div className="title">
                        <h3>{t("ForgotPasswordForm.EnterYourEmailAddressSoWeCanSendYouInstructionsToHelpResetYourPassword")}</h3>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)} noValidate className="custom-form-w">
                        <div className="reset-form-field">
                            <input
                                {...register("email")}
                                className={`form-control ${errors.email ? "required-field" : ""}`}
                                id="email"
                                placeholder={t("EmailId")}
                                type="email"
                            />
                            {errors.email && (
                                <span className="required-note">
                                    <small>{errors.email.message || ""}</small>
                                </span>
                            )}
                        </div>
                        {active && (
                            <div className="resend-code">
                                <div>
                                    <p>{t("ForgotPasswordForm.WeHaveSentInstructionsToTheEmailAddressYouProvided")}</p>
                                    <p dangerouslySetInnerHTML={{ __html: t("ForgotPasswordForm.IfYouHavenNotReceivedAnEmailFromJuuliPleaseDoubleCheckTheEmailEnteredAndRequestLinkAgain", { timer: timer }) }} />
                                </div>
                                <span
                                    className={`resend ${timer ? "disabled" : ""}`}
                                    onClick={handleResetLink}
                                >
                                    {t("RequestNewLink")}
                                </span>
                            </div>
                        )}
                        <div className="action-btn">
                            {!active && <SolidButton type="submit" loading={isLoading}>{t("Submit")}</SolidButton>}
                            <LinkButton to={ROUTES.FreelancerLogIn}>{t(active ? "Back" : "Cancel")}</LinkButton>
                        </div>
                    </Form>
                </div>
            </Col>
        </Row>
    );
};