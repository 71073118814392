import { useTranslation } from "react-i18next";
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useState } from "react";
import { Status } from "../../Widgets/Status";
import Suggestion from "../../Widgets/Suggestion";

const TabSection = () => {
  const { t } = useTranslation();
  const [navActive, setNavActive] = useState(1);

  const activeTabPane = (id: number) => setNavActive(id);

  return (
    <Col xs="12" className="min-h-content">
      <div className="card profile-card">
        <Nav pills className="horizontal-tab custom-scrollbar" id="pills-tab">
          <NavItem><NavLink className={navActive === 1 ? "active" : ""} onClick={() => activeTabPane(1)} id="pills-suggestion-tab">{t("Suggestions")}</NavLink></NavItem>
          <NavItem><NavLink className={navActive === 2 ? "active" : ""} onClick={() => activeTabPane(2)} id="pills-status-tab">{t("JuuliStatus")}</NavLink></NavItem>
        </Nav>
        <TabContent className="horizontal-tab-content position-relative" activeTab={navActive} id="pills-tabContent">
          <TabPane className="pt-sm-4 status-tab" tabId={1} id="pills-status"><Suggestion /></TabPane>
          <TabPane className="pt-sm-4 status-tab" tabId={2} id="pills-status"><Status /></TabPane>
        </TabContent>
      </div>
    </Col>
  );
};

export default TabSection;
