import { useTranslation } from 'react-i18next'
import { Card, CardBody, CardHeader, Col } from 'reactstrap'
import { Image } from '../../../AbstractElement';
import { convertToReadableAmount, convertToReadableDate, dynamicImage } from '../../../utils';
import { queries } from '../../../api';

export const LatestTransactions = () => {

    const { t } = useTranslation();
    const { data: transaction } = queries.useGetLatestTransactions("Withdrawal");
    const transactionContent = transaction?.items?.flat() || [];
    const hasTransactions = transactionContent.length > 0;

    return (
        <Col xs="12" className='col-height'>
            <Card className="radius-bottom-0 h-100">
                <div className="latest-transaction-card">
                    <CardHeader className='d-block'>
                        <h6>{t("ReceivedPayments")}</h6>
                        {/*
                        //TODO:Its not present in figma
                         <a href="javascript">
                            <Image src={dynamicImage("svg/right-up-arrow.svg")} alt="right up arrow image" />
                        </a> */}
                    </CardHeader>
                    <CardBody>
                        {hasTransactions ? (
                            <ul className="latest-transaction-list">
                                {transactionContent.map((transaction: any) => (
                                    <li key={transaction.id}>
                                        <h6>{transaction.businessName}</h6>
                                        <div className="time-cash-box">
                                            <span>{t("Invoice")}</span>
                                            <small>
                                                #{transaction.documentNo}
                                            </small>
                                        </div>
                                        <div className="time-cash-box">
                                            <span className='date'>{convertToReadableDate(transaction.createdAt, "short")}</span>
                                            <small>
                                                {convertToReadableAmount(Math.abs(transaction.amount), transaction.currencyCode)}
                                            </small>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="no-data-found">
                                <div>
                                    <Image src={dynamicImage("svg/no-data/no-data-transaction.svg")} alt="invoice image" className="img-fluid" />
                                    <h5 className='mt-3'>{t("YourLatestTransactionsWillBeDisplayedHere")}</h5>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </div>
            </Card>
        </Col>
    )
}
