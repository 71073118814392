import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Container, FormGroup, Row } from "reactstrap";
import { Image, SolidButton } from "../../../../AbstractElement";
import { CommonSelect, Info, ConfirmationDialog } from "../../../../CommonComponent";
import SVG from "../../../../CommonElement/SVG/Svg";
import { ROUTES } from "../../../../Constants";
import { useAuth } from "../../../../Hooks";
import Loader from "../../../../Layout/Loader";
import { INVOICE_STATUS } from "../../../../Types";
import { mutations, queries } from "../../../../api";
import { convertToReadableDate, dynamicImage, getMessages, getParam } from "../../../../utils";
import { useLayoutContext, useToast } from "../../../../views";
import { InvoiceDetails } from "./InvoiceDetails";

const STEP_STATE = {
  Approval: "approval",
  Decline: "decline",
  Final: "final",
} as const;

type StepState = ObjectValues<typeof STEP_STATE>;

const Approval: React.FC<{ handleSetState: (value: StepState) => void; handleApprove: VoidFunction; }> = ({ handleSetState, handleApprove }) => {
  const { mutate, isLoading } = mutations.useInvoiceFastApproval();
  const [isChecked, setIsChecked] = useState(false);
  const { showToast } = useToast();
  const { t } = useTranslation()

  return (
    <>
      <div className="form-check">
        <input type="checkbox" className="form-check-input" id="invoiceApprovalCheckbox" checked={isChecked} onChange={() => setIsChecked((prev) => !prev)} />
        <label htmlFor="invoiceApprovalCheckbox">{t("IConfirmThatIHaveReceivedTheCompletedWorkAndApproveTheInvoice")}</label>
        <Info text='' />
      </div>
      <Row className="pb-4 mt-5 verify-footer">
        <Col xs="12">
          <div className="auth-footer px-sm-5 px-3">
            <SolidButton className="custom-confirm-black btn-solid" onClick={() => handleSetState(STEP_STATE.Decline)}>
              {t("Reject")}
            </SolidButton>
            <SolidButton
              className="btn-success"
              loading={isLoading}
              disabled={!isChecked}
              onClick={() => {
                const invoiceScopeId = getParam("invoiceScopeId");
                if (!invoiceScopeId) return;
                mutate(invoiceScopeId, {
                  onSuccess: () => {
                    handleApprove();
                    handleSetState(STEP_STATE.Final);
                  },
                  onError: (e) => {
                    if (Array.isArray(e)) {
                      showToast("error", getMessages(e));
                    }
                  },
                });
              }}
            >
              {t("Approve")}
            </SolidButton>
          </div>
        </Col>
      </Row>
    </>
  );
};

const Decline: React.FC<{ handleSetState: (value: StepState) => void; statusType: string }> = ({ handleSetState, statusType }) => {
  const { data: paginated } = queries.useGetInvoiceDeclineReasons(statusType);
  const { mutate, isLoading } = mutations.useInvoiceFastDecline();
  const [selected, setSelected] = useState<string | null>(null);
  const [declineReason, setDeclineReason] = useState('');
  const { showToast } = useToast();
  const { t } = useTranslation();

  const data = useMemo(() => {
    if (!paginated || !paginated.items) return [];
    return paginated.items;
  }, [paginated]);

  const isValidSelection = !!selected;

  return (<>
    <Row>
      <Col xs="12">
        <div className="flex flex-col gap-4">
          <label>{t("PleaseSelectYourReasonToReject")}</label>
          <CommonSelect
            placeholder={t('Select')}
            options={data?.map(res => ({ value: res.id, label: t(res.name) }))}
            selectedOptions={selected}
            setSelectedOptions={(value) => {
              setSelected(value)
            }}
            multiSelect={false}
          />
        </div>
      </Col>

      {data.find(res => res.id === selected)?.entryDescriptionIsRequired &&
        <Col xs="12" className="mt-2">
          <div className="flex flex-col gap-4">
            <FormGroup className='mb-0 col-12'>
              <textarea className="form-control min-h-96" rows={3} placeholder={t("EnterAnotherReason")}
                value={declineReason}
                onChange={(e) => setDeclineReason(e.target.value)}></textarea>
            </FormGroup>
          </div>
        </Col>
      }
    </Row>
    <Row className="pb-4 mt-5 verify-footer">
      <Col xs="12">
        <div className="auth-footer px-sm-5 px-3">
          <SolidButton className="btn-gray" onClick={() => handleSetState(STEP_STATE.Approval)}>
            {t("Cancel")}
          </SolidButton>
          <SolidButton className="custom-confirm-black" color=""
            onClick={() => {
              ConfirmationDialog({
                title: "Reject Invoice",
                text: "You're about to reject the invoice. This is a permanent change that cannot be undone.Are you sure you want to reject this invoice?",
                confirmButtonText: "Reject",
                onConfirm: (isConfirmed) => {
                  if (isConfirmed) {
                    const invoiceScopeId = getParam("invoiceScopeId");
                    if (!invoiceScopeId) return;
                    mutate(
                      { id: invoiceScopeId, statusId: selected, declineReason: declineReason },
                      {
                        onSuccess: () => {
                          handleSetState(STEP_STATE.Final);
                        },
                        onError: (e) => {
                          if (Array.isArray(e)) {
                            showToast("error", getMessages(e));
                          }
                        },
                      }
                    );
                  }
                }
              });

            }}
            loading={isLoading}
            disabled={!isValidSelection}
          >
            {t("ConfirmRejection")}
          </SolidButton>
        </div>
      </Col>
    </Row>
  </>
  );
};

const Final: React.FC<{ isApproved: boolean, continueToJuuliUrl: string }> = ({ isApproved, continueToJuuliUrl }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="invoice-approved confirm-invoice">
      <span className="main-invoice custom-scrollbar">
        {isApproved ? (
          <>
            <div className="invoice-img">
              <Image src={dynamicImage("svg/draft-invoice.svg")} alt="draft invoice" />
            </div>
            <h2>{t("GreatToHear")}</h2>
            <div className="invoice-detail">
              <p>{t("ThankyouForAcceptingTheInvoiceWehaveInformedYourEmployeeYouCanLogInToTheDashboardForMoreDetailsOnYourInvoicesAndPaymentInfoWehaveAlsoSentYouAnEmailWithTheInvoiceAttached")}</p>
              <p>{t("IfYouWantToPayByCreditCardPleaseLogInToTheDashboard")}</p>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-10">
            <Image src={dynamicImage("svg/draft-invoice-reject.svg")} alt="draft invoice" />
            <h2>{t("WeAreSorryToHearThatYouRejectedTheInvoice")}</h2>
            <div className="invoice-detail">
              <p>{t("WeHaveNotifiedYourEmployeeAndTheyWillHandleTheMatterYouCanLogInToYourBusinessPanelToGetMoreDetailsAboutYourInvoices")}</p>
            </div>
          </div>
        )}
        <div className="invoice-approved-footer">
          <div className="auth-footer">
            <SolidButton onClick={() => navigate(continueToJuuliUrl)}>{t("ContinueToJuuli")}<SVG iconId="stroke-right-arrow" /></SolidButton>
          </div>
        </div>
      </span>
    </div>
  );
};

const Wrapper: React.FC<{ handleSetState: (value: StepState) => void; children: React.ReactNode; showContinueToSystemButton?: boolean, state?: StepState, ownerProject: boolean, continueToJuuliUrl: string }> = ({ handleSetState, children, showContinueToSystemButton = true, state, ownerProject, continueToJuuliUrl }) => {
  const { t } = useTranslation();
  const { isMobile } = useLayoutContext();
  const { signout } = useAuth();
  const navigate = useNavigate();

  const logOutHandler = () => {
    signout(() => navigate(continueToJuuliUrl));
  };

  return (
    <div className={`review-invoice custom-scrollbar`}>
      <div className="auth-main h-100 invoice-review approve-invoice1 custom-scrollbar">
        <div className="logo-wrapper">
          <SVG iconId="stroke-right-arrow" />
          {isMobile ?
            <>
              {state === STEP_STATE.Decline && <Button className="btn-close" onClick={() => handleSetState(STEP_STATE.Approval)} color="transparent"></Button>}
              {state !== STEP_STATE.Decline && <Image src={dynamicImage("svg/logo/favicon.svg")} className="h-16" alt="logo" />}
              {showContinueToSystemButton && <h1>{t("ReviewInvoice")}</h1>}
            </>
            : <Image src={dynamicImage("svg/logo/logo.svg")} alt="logo" />}
          <div className="owner-project">
            {showContinueToSystemButton && ownerProject && (<div className="invoice-warning">
              <div className="warning">
                <div className="img-box">
                  <SVG iconId="stroke-warning" className="nav-icon" />
                </div>
                <span>{t("YouAreAlreadyTheOwnerOfThisProject")}</span>
              </div>
            </div>)}
            <p className="mb-0 cursor-pointer" onClick={logOutHandler}>{t("SignOut")}</p>
            {showContinueToSystemButton && (
              <div>
                <Link to={ROUTES.BusinessPinLogin} className="btn btn-gray d-lg-flex d-none">
                  {t("ContinueToJuuli")}
                  <SVG iconId="stroke-right-arrow" />
                </Link>
              </div>
            )}
          </div>
          <Image src={dynamicImage("svg/leaf.svg")} className="invoice-r-img img-fluid" alt="background image" />
        </div>
        <div className="container-fluid d-flex justify-content-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export const VerifyInvoice: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<StepState>(STEP_STATE.Approval);
  const [isApproved, setIsApproved] = useState(false);
  const invoiceScopeId = getParam("invoiceScopeId");
  if (!invoiceScopeId || invoiceScopeId == null) {
    document.location.href = ROUTES.BusinessPinLogin;
    return null;
  }

  const continueToJuuliUrl = `${ROUTES.BusinessPinLogin}?invoiceScopeId=${invoiceScopeId}`;
  const { data: invoiceDetails } = queries.useGetInvoiceScopeFast(invoiceScopeId);

  function renderBottomPart() {
    switch (state) {
      case STEP_STATE.Approval:
        return <Approval handleSetState={(e) => setState(e)} handleApprove={() => setIsApproved(true)} />;
      case STEP_STATE.Decline:
        return <Decline handleSetState={(e) => setState(e)} statusType={INVOICE_STATUS.WaitingForPayment} />;
      default:
        return null;
    }
  }
  if (!invoiceDetails) {
    return <Loader />;
  }

  function getResponseText() {
    if (!invoiceDetails) return "";
    const date = convertToReadableDate(invoiceDetails.responseDate, "long");
    switch (invoiceDetails.responseStatus) {
      case INVOICE_STATUS.DeclineByBusiness:
        return (
          <>
            <div className="invoice-img">
              <Image src={dynamicImage("svg/draft-invoice-reject.svg")} alt="invoice" />
            </div>
            <h2>{t("YouRejectedThisInvoiceOnDate", { date: date })}</h2>
            <div className="invoice-detail">
              <p className="pt-2">{t("PleaseLogInToYourBusinessPanelToSeeTheReasonForTheRejectionIfThisWasAMistakeOrYouNeedMoreInfoContactYourEmployee")}</p>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="invoice-img">
              <Image src={dynamicImage("svg/draft-invoice.svg")} alt="invoice" />
            </div>
            <div className="invoice-contain">
              <h2>{t("YouApprovedThisInvoiceOnDate", { date: date })}</h2>
              <div className="invoice-detail">
                <p>{t("WeHaveAlsoNotifiedYourEmployeeLogInToTheDashboardForMoreDetailsWeHaveEmailedYouTheInvoiceEarlierAndPaymentDetailsAreAttachedIfYouHaveAlreadyPaidYouCanIgnoreThisMessage")}</p>
                <p>{t("IfYouWantToPayByCreditCardPleaseLogInToTheDashboard")}</p>
              </div>
            </div>
          </>
        );
    }
  }

  if (invoiceDetails?.responseStatus) {
    return (
      <Wrapper handleSetState={(e) => setState(e)} showContinueToSystemButton={false} ownerProject={invoiceDetails.ownerProject} continueToJuuliUrl={continueToJuuliUrl}>
        <div className="invoice-approved approve-verify-invoice">
          <div className="main-invoice custom-scrollbar">
            {getResponseText()}
            <div className="invoice-approved-footer">
              <div className="auth-footer">
                <SolidButton onClick={() => navigate(continueToJuuliUrl)}>
                  {t("ContinueToJuuli")}
                  <SVG iconId="stroke-right-arrow" />
                </SolidButton>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  if (state === STEP_STATE.Final) {
    return (
      <Wrapper handleSetState={(e) => setState(e)} showContinueToSystemButton={false} state={STEP_STATE.Final} ownerProject={invoiceDetails.ownerProject} continueToJuuliUrl={continueToJuuliUrl}>
        <Final isApproved={isApproved} continueToJuuliUrl={continueToJuuliUrl} />
      </Wrapper>
    );
  }

  return (
    <Wrapper handleSetState={(e) => setState(e)} state={state} ownerProject={invoiceDetails.ownerProject} continueToJuuliUrl={continueToJuuliUrl}>
      <div className="m-0 auth-body h-100 verify-invoice">
        <Container className="h-100">
          <div className="auth-body-title b-padding px-0">
            <div className="auth-sub-title">
              <div className="d-flex align-items-center gap-3">
                <div className="back-btn">
                  <SVG iconId="stroke-right-arrow" />
                </div>
                <h1>{t("ReviewInvoice")}</h1>
              </div>
            </div>
          </div>
          <div className="verify-invoice-content">
            <Row>
              <Col xs="12">
                <div className="verify-invoice-form m-0 custom-scrollbar">
                  <InvoiceDetails invoiceDetails={invoiceDetails} />
                  {renderBottomPart()}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Wrapper>
  );
};