import React, { useCallback, useEffect, useMemo, useState } from "react";
import { User } from "../Types";
import { STORAGE_KEYS, URL_KEYS } from "../Constants";
import { post, appInsights } from "../api";
import { AuthContext, AuthInput, useStorage } from "../Hooks";
import { getStorage } from "../utils";

type SigninResult = User & { invoiceScopeId?: string };
type SignupResult = User;

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const storage = getStorage();
    const { setItem } = useStorage();

    const [user, setUser] = useState<Partial<User> | null>(() => {
        const storedUser = storage.getItem(STORAGE_KEYS.USER);
        if (!storedUser || storedUser === "undefined") return null;

        const parsedUser: Partial<User> = JSON.parse(storedUser);
        if (handleExpiredToken(parsedUser)) {
            removeStorageKeys();
            return null;
        }

        return parsedUser;
    });

    const linkedInSignin = useCallback(
        async (codes: { code: string }, callback: VoidFunction, errCallback: (err: any) => void) => {
            try {
                const result = await post<unknown, SigninResult>(
                    `${URL_KEYS.Auth.SigninLinkedin}?code=${codes.code}`
                );
                setUser(result ?? null);
                storage.setItem(STORAGE_KEYS.USER, JSON.stringify(result));
                callback();
            } catch (err) {
                errCallback(err);
            }
        },
        []
    );

    const googleSignin = useCallback(
        async (codes: { code: string }, callback: VoidFunction, errCallback: (err: any) => void) => {
            try {
                const result = await post<unknown, SigninResult>(
                    `${URL_KEYS.Auth.SigninGoogle}?code=${codes.code}`
                );
                setUser(result ?? null);
                storage.setItem(STORAGE_KEYS.USER, JSON.stringify(result));
                callback();
            } catch (err) {
                console.error("Google sign-in error:", err);
                errCallback(err);
            }
        },
        []
    );

    const businessPinSignin = useCallback(
        async (input: { email: string; pin: string }, callback: VoidFunction, errCallback: (err: any) => void) => {
            try {
                const result = await post<{ email: string; pin: string }, SigninResult>(URL_KEYS.Auth.BusinessLogin, input);
                setUser(result ?? null);
                setItem(STORAGE_KEYS.USER, JSON.stringify(result));
                setTimeout(callback, 100);
            } catch (err) {
                console.error("Business PIN sign-in error:", err);
                errCallback(err);
            }
        },
        []
    );

    const businessSignin = useCallback(
        async (code: string, callback: (invoiceScopeId?: string) => void, errCallback: (err: any) => void) => {
            try {
                const result = await post<unknown, SigninResult>(`${URL_KEYS.Auth.SigninBusiness}?code=${code}`);
                setUser(result ?? null);
                setItem(STORAGE_KEYS.USER, JSON.stringify(result));
                setTimeout(() => {
                    callback(result.invoiceScopeId);
                }, 100);
            } catch (err) {
                errCallback(err);
            }
        },
        []
    );

    const pinSignin = useCallback(async (code: string, callback: VoidFunction, errCallback: (err: any) => void) => {
        try {
            const result = await post<unknown, SigninResult>(`${URL_KEYS.Auth.SigninWithExternalPin}?code=${code}`);
            setUser(result ?? null);
            setItem(STORAGE_KEYS.USER, JSON.stringify(result));
            setTimeout(callback, 100);
        } catch (err) {
            errCallback(err);
        }
    }, []);

    const verifyBusinessPin = useCallback(
        async (input: { pin: string; verificationToken: string }, callback: (response: any) => void, errCallback: (err: any) => void) => {
            try {
                const result = await post<{ pin: string; verificationToken: string }, any>(URL_KEYS.Auth.VerifySignUpForBusiness, input);
                setUser(result ?? null);
                setItem(STORAGE_KEYS.USER, JSON.stringify(result));
                setTimeout(() => callback(result), 100);
            } catch (err) {
                console.error("Business PIN sign-in error:", err);
                errCallback(err);
            }
        },
        []
    );

    const removeUser = useCallback(() => {
        setUser(null);
        removeStorageKeys();
    }, []);

    const signin = useCallback(
        async (input: AuthInput) => {
            const result = await post<AuthInput, SigninResult>(URL_KEYS.Auth.FreelancerLogin, input);
            setUser(result ?? null);
            storage.setItem(STORAGE_KEYS.USER, JSON.stringify(result));
        },
        []
    );

    const signup = useCallback(
        async (input: AuthInput, callback: VoidFunction) => {
            await post<AuthInput, SignupResult>(URL_KEYS.Auth.FreelancerSignUp, input);
            callback();
        },
        []
    );

    const signout = useCallback(
        async (callback?: VoidFunction) => {
            setUser({ isSigningOut: true, role: user?.role });
            removeStorageKeys();
            callback?.();
        },
        []
    );

    const value = useMemo(
        () => ({
            user,
            signin,
            signup,
            signout,
            googleSignin,
            linkedInSignin,
            businessPinSignin,
            removeUser,
            businessSignin,
            pinSignin,
            verifyBusinessPin
        }),
        [user, signin, signup, signout, googleSignin, linkedInSignin, businessPinSignin, removeUser, businessSignin, pinSignin, verifyBusinessPin]
    );

    useEffect(() => {
        if (!user || !user.expires) return;
        if (handleExpiredToken(user)) removeUser();
    }, [user, user?.expires]);

    useEffect(() => {
        if (user && user.userGuid) {
            appInsights.context?.user.setAuthenticatedUserContext(user.userGuid || "", user.role || "");
        } else {
            appInsights.context?.user.clearAuthenticatedUserContext();
        }
    }, [user]);


    function handleExpiredToken(user: Partial<User>): boolean {
        if (!user || !user.expires) return true;
        return new Date(user.expires).getTime() < new Date().getTime();
    }

    function removeStorageKeys() {
        storage.removeItem(STORAGE_KEYS.USER);
        storage.removeItem("returnUrl");
        storage.clear();

    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
