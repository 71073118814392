import { useLayoutContext } from '../../views';
import SidebarLogoWrapper from './SidebarLogoWrapper';
import SidebarMenu from './SidebarMenu';

const Sidebar = () => {
  const { openSidebar } = useLayoutContext()

  
  return (
    <div className={`sidebar-wrapper ${openSidebar ? 'close_icon' : ''}`} id="sidebar-wrappers" >
      <div>
        <SidebarLogoWrapper />
        <SidebarMenu />
      </div>
    </div>
  )
}
export default Sidebar;