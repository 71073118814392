import { Button, Col, Form, FormGroup, InputGroup, Row } from "reactstrap";
import { CommonSelect, ConfirmationDialog, ClientInformation, ClientProjectsTable } from "../../../CommonComponent";
import { useTranslation } from "react-i18next";
import { useCoreData } from "../../../Hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mutations, queries } from "../../../api";
import { SubmitHandler, useForm } from "react-hook-form";
import { ClientInput } from "../../../Types";
import { CustomerSchema } from "../../../Schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "../../../views";

const Billing = () => {
  const { Customer } = CustomerSchema();
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { id: projectId } = useParams<{ id: string }>();
  const [selectedCountryCodeOptions, setSelectedCountryCodeOptions] = useState<string>('');
  const [selectedCountryOptions, setSelectedCountryOptions] = useState<string>('');

  const { phoneCountryCodeOptions, countryOptions } = useCoreData({ loadCountries: true });
  const { data: projectData } = queries.useGetProject(projectId);
  const { data: clientData } = queries.useGetCustomer(projectData?.billingId);
  const { mutate: updateCustomer } = mutations.useUpdateCustomer();


  const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm<ClientInput>({
    resolver: yupResolver(Customer),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      registrationNumber: '',
      taxOffice: '',
      vatNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      phoneCountryCode: selectedCountryCodeOptions,
      countryCode: selectedCountryOptions,
    }
  });

  useEffect(() => {
    if (clientData) {
      setValue('name', clientData.name)
      setValue('email', clientData.email)
      setValue('phoneCountryCode', clientData.phoneCountryCode);
      setSelectedCountryCodeOptions(clientData.phoneCountryCode!);
      setValue('phone', clientData.phone)
      setValue('registrationNumber', clientData.registrationNumber!)
      setValue('taxOffice', clientData.taxOffice!)
      setValue('vatNumber', clientData.vatNumber!)
      setValue('addressLine1', clientData.addressLine1)
      setValue('addressLine2', clientData.addressLine2!)
      setValue('city', clientData.city)
      setValue('state', clientData.state)
      setValue('postalCode', clientData.postalCode)
      setValue('countryCode', clientData.countryCode);
      setSelectedCountryOptions(clientData.countryCode);
    }
  }, [clientData]);

  const handleBillingUpdate: SubmitHandler<ClientInput> = async (data) => {
    ConfirmationDialog({
      title: "Update client",
      text: "Are you sure you want to update this client? This update will affect new invoices to be issued in projects where this client is used. Previously sent invoices will not be affected by this change.",
      confirmButtonText: "Update",
      confirmBtnClass: "orange",
      onConfirm: (isConfirmed) => {
        if (isConfirmed) {
          updateCustomer({ id: projectData?.billingId!, input: data }, {
            onSuccess: () => {
              showToast("success", "Billing.BillingDataUpdatedSuccessfully")
            },
          });
        }
      }
    });
  }

  const classNameError = (field: keyof ClientInput) => {
    return `form-control ${errors[field] ? "required-field" : ""}`;
  };

  const renderError = (field: keyof ClientInput) => (
    errors[field] && <span className="required-note"><small>{t(errors[field]?.message || "")}</small></span>
  );

  return (
    <>
      {!clientData?.editable ?
        <div className='flex-grow-1 billing-detail custom-scrollbar'>
          <Row>
            <Col xl="6">
              <ClientInformation clientData={clientData} title="BillingInformation" />
            </Col>
            <Col xl="6">
              <h5 className="billing-info-title billing-project">{t("ProjectsWithThisClient")}</h5>
              <ClientProjectsTable clientId={projectData?.billingId} />
            </Col>
          </Row>
        </div> :
        <div className="edit-billing custom-scrollbar">
          <Form onSubmit={handleSubmit(handleBillingUpdate)}>
            <label className="edit-title">{t("BillingInformationForProject")}</label>
            <div className="billing-information custom-scroll">
              <div className="edit-billing-form">
                <Row className="g-sm-4 g-3">
                  <Col xl="6">
                    <Row className="g-3 mb-3">
                      <Col xs="12">
                        <FormGroup className='mb-0'>
                          <label>{t("InvoiceReceiverName")}</label>
                          <input type="text" {...register('name')} className={classNameError('name')} id="businessName" placeholder={t("EnterPlaceHolder", { fieldName: t("InvoiceReceiverName") })} autoComplete="off" />
                          {renderError('name')}
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <FormGroup className='mb-0'>
                          <label>{t("EmailId")}</label>
                          <input type="email" {...register('email')} className={classNameError('email')} id="emailAddress" placeholder={t("EnterEmailAddress")} disabled={clientData?.verified || false} autoComplete="off" />
                          {renderError('email')}
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <FormGroup className="phone-detail mb-0">
                          <label>{t("PhoneNumberId")}</label>
                          <InputGroup>
                            <div className="dropdown-box">
                              <CommonSelect
                                placeholder={t('Select')}
                                options={phoneCountryCodeOptions!}
                                selectedOptions={selectedCountryCodeOptions}
                                setSelectedOptions={(value) => {
                                  setSelectedCountryCodeOptions(value);
                                  setValue('phoneCountryCode', value);
                                  trigger('phoneCountryCode');
                                }}
                                multiSelect={false}
                                search={true}
                              />
                              <input type="number" {...register('phone')} className="form-control" id="phone" placeholder={t("EnterPhoneNumber")} autoComplete="off" />
                            </div>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <label>{t("TaxOffice")}</label>
                          <input type="text" {...register('taxOffice')} className="form-control" id="taxOffice" placeholder={t("EnterTaxOffice")} autoComplete="off" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <label>{t("RegistrationNumber")}</label>
                          <input type="text" {...register('registrationNumber')} className="form-control" id="registrationNumber" placeholder={t("EnterRegistrationNumber")} autoComplete="off" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <label>{t("VatNumber")}</label>
                          <input type="text" {...register('vatNumber')} className="form-control" id="vatNumber" placeholder={t("EnterVATNumber")} autoComplete="off" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <label>{t("Address")}</label>
                    <Row className="g-3">
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <CommonSelect
                            placeholder={t('Select')}
                            options={countryOptions!}
                            selectedOptions={selectedCountryOptions}
                            setSelectedOptions={(value) => {
                              setSelectedCountryOptions(value);
                              setValue('countryCode', value);
                              trigger('countryCode');
                            }}
                            multiSelect={false}
                            search={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <input type="text" {...register('state')} className={classNameError('state')} id="state" placeholder={t("EnterState")} autoComplete="off" />
                          {renderError('state')}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <input type="text" {...register('city')} className={classNameError('city')} id="city" placeholder={t("EnterCity")} autoComplete="off" />
                          {renderError('city')}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <input type="text" {...register('postalCode')} className={classNameError('postalCode')} id="postalCode" placeholder={t("ZipPostalCode")} autoComplete="off" />
                          {renderError('postalCode')}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup className='mb-0'>
                          <input type="text" {...register('addressLine1')} className={classNameError('addressLine1')} id="addressLine1" placeholder={t("AddressLine1")} autoComplete="off" />
                          {renderError('addressLine1')}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup className='mb-0'>
                          <input type="text" {...register('addressLine2')} className="form-control" id="addressLine2" placeholder={t("AddressLine2")} autoComplete="off" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>

                  <Col xl="6">
                    <h5 className="billing-info-title billing-project">{t("ProjectsWithThisClient")}</h5>
                    <ClientProjectsTable clientId={projectData?.billingId} />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="footer-buttons-main">
              <div className="footer-buttons">
                <Button color="success" type='submit' className='justify-content-center'>{t("Update")}</Button>
              </div>
            </div>
          </Form>
        </div>}
    </>
  )
}
export default Billing;

