import { useState, useEffect, useRef } from 'react';

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState('up');
  const prevScrollY = useRef(window.scrollY || document.documentElement.scrollTop);
  const ticking = useRef(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY || document.documentElement.scrollTop;

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        if (currentScrollY > prevScrollY.current) {
          setScrollDirection('down');
        } else if (currentScrollY < prevScrollY.current) {
          setScrollDirection('up');
        }
        prevScrollY.current = currentScrollY > 0 ? currentScrollY : 0;
        ticking.current = false;
      });

      ticking.current = true;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrollDirection;
};

export default useScrollDirection;
