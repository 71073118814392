import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { SolidButton } from '../../../../../AbstractElement';
import { convertToReadableAmount } from '../../../../../utils';
import { CalculateAmount } from '../../../../../Types';
import { useLayoutContext } from '../../../../../views';

interface CurrencyExchangeProp {
    currencyExchange: boolean
    toggleCurrencyExchange: VoidFunction
    calculateAmount?: CalculateAmount
}

const CurrencyExchange: React.FC<CurrencyExchangeProp> = ({ calculateAmount, currencyExchange, toggleCurrencyExchange }) => {
    const { t } = useTranslation();
    const { modalPosition } = useLayoutContext()
    const handleClose = () => {
        toggleCurrencyExchange();
    };

    return (
        <Modal centered dir={modalPosition} onClosed={handleClose} isOpen={currencyExchange} toggle={handleClose} className="theme-modal">
            <ModalHeader toggle={handleClose}>{t("CurrencyExchange.Title")}</ModalHeader>
            <ModalBody>
                <div className="h-100 currency-content">
                    <p dangerouslySetInnerHTML={{ __html: t("CurrencyExchange.PayoutInformationMessage", { ...calculateAmount }) as string }}>
                    </p>
                    <ul className="currency-data">
                        <li>
                            <span>{t("InvoiceAmount")}</span>
                            <p>{convertToReadableAmount(calculateAmount?.amount!, calculateAmount?.currencyCode!)}</p>
                        </li>
                        <li>
                            <span>{t("EstimatedPayoutAmount")}</span>
                            <p>{convertToReadableAmount(calculateAmount?.estimatedPayoutAmount!, calculateAmount?.estimatedPayoutCurrency!)}</p>
                        </li>
                    </ul>
                    <div className="modal-footer">
                        <SolidButton onClick={handleClose} className="justify-content-center btn-solid">{t("Close")}</SolidButton>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}
export default CurrencyExchange; 