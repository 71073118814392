import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { Image, LinkButton, SolidButton } from "../../../AbstractElement";
import { dynamicImage } from "../../../utils";
import { useTranslation } from "react-i18next";

interface FinanceApplyProps {
    applyFinance: boolean;
    actionFinance:boolean;
    toggleApplyFinance: VoidFunction;
    toggleActionFinance:VoidFunction;
}

const FinanceApply: React.FC<FinanceApplyProps> = ({ toggleApplyFinance, applyFinance,toggleActionFinance }) => {

    const { t } = useTranslation();

    return (
        <Offcanvas isOpen={applyFinance} toggle={toggleApplyFinance} direction="end" className="finance-modal">
            <OffcanvasHeader toggle={toggleApplyFinance}>{t("InvoiceFinancingApplication")}</OffcanvasHeader>
            <OffcanvasBody className="custom-scroll">
                <div className="flex-grow-1">
                    <h5 className="mt-0"> {t("InvoiceDetails")} </h5>
                    <div className="invoice-finance">
                        <ul className="invoice-data">
                            <li>
                                <span>{t("Project")}</span>
                                <p>Art Direction Project <small>(Web Design & Development)</small></p>
                            </li>
                            <li>
                                <span>{t("Client")}</span>
                                <p>Jonathan Drake <small>(jonathan@company.com)</small></p>
                            </li>
                            <li>
                                <span>{t("InvoiceNumber")}</span>
                                <p>2024010092</p>
                            </li>
                            <li>
                                <span>{t("InvoiceDate")}</span>
                                <p>Jan 25, 2024</p>
                            </li>
                            <li>
                                <span>{t("Amount")}</span>
                                <p>$1,250.00</p>
                            </li>
                            <li>
                                <span>{t("DepositTo")}</span>
                                <p>IBAN - USD <small> (TR5055***)</small></p>
                            </li>
                            <li>
                                <span>{t("Status")}</span>
                                <p><Image src={dynamicImage("svg/in-transfer.svg")} alt="arrow" />Waiting approval <small>(Jan 23, 2024)</small></p>
                            </li>
                        </ul>
                    </div>
                    <h5> {t("FinancingOptions")} </h5>
                </div>
                <div className="footer-buttons mt-5">
                    <LinkButton onClick={toggleApplyFinance}>{t("Cancel")}</LinkButton>
                    <SolidButton onClick={toggleActionFinance}>{t("Apply")}</SolidButton>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    )
}

export default FinanceApply
