import React, { Fragment, useEffect, useMemo, useState } from "react";
import NotificationSection from "../Layout/Header/NotificationSection";
import ProfileSection from "../Layout/Header/ProfileSection";
import DatePicker from "react-datepicker";
import { Image } from "../AbstractElement";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { dynamicImage, formatDate, formatTime } from "../utils";
import { useTranslation } from "react-i18next";
import { HeaderContent, HeaderRenderProps } from "../Types/Layout/Layout";
import { useAuth } from "../Hooks";
import { queries } from "../api";
import { USER_ROLE } from "../Types";
import { useStateContext, useLayoutContext } from "../views";
import { Button } from "reactstrap";
import SVG from "../CommonElement/SVG/Svg";

interface HeaderProps {
  isVisible: boolean;
}

const CommonHeader: React.FC<HeaderProps> = ({ isVisible }) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate()
  const { toggleProjectModal } = useStateContext();
  const hiddenTooltip = true;

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const [currentTime, setCurrentTime] = useState<string>(formatTime(new Date()));
  const [content, setContent] = useState<HeaderContent>({});
  const { user } = useAuth();
  const { isMobile } = useLayoutContext()
  const { data: userDetails } = queries.useGetUserDetails();
  const { refetch: refetchCalendarActivities } = queries.useGetCalendarActivities();
  const { data: projectData } = queries.useGetProject(selectedProjectId);

  const path = useMemo(() => { return location.pathname.split('/'); }, [location.pathname]);
  const pathRoute = useMemo(() => { return path.length > 0 ? path[1] : ""; }, [path]);
  const fullName = useMemo(() => {
    if (!userDetails) return null;
    if (user?.role === USER_ROLE.Business) {
      return userDetails?.firstName ? `${userDetails?.firstName} ${userDetails?.lastName}` : userDetails.companyName;
    } else if (userDetails.firstName && userDetails.lastName) {
      return `${userDetails.firstName} ${userDetails.lastName}`;
    }
    return null;
  }, [user, userDetails]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(formatTime(new Date()));
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (id && pathRoute === "project") {
      setSelectedProjectId(id);
    }
    else {
      setSelectedProjectId(undefined);
    }
  }, [id, path]);

  useEffect(() => {
    const pathSegments = path.filter(Boolean);
    const headerKey = pathSegments[0] === "project" && pathSegments.length > 1 ? "subProjects" :
      pathSegments.length > 0 ? pathSegments[0] : "dashboard";

    let content: HeaderContent = headerTopTitle[headerKey] || {};
    if (headerKey === "subProjects") {
      content.title = projectData?.projectName;
    }
    if (headerKey === "dashboard" && fullName) {
      content.title = `Hello, ${fullName}`;
    }
    setContent(content);
  }, [path, projectData, fullName]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      refetchCalendarActivities();
    }
  };

  const renderIconAndTitle: React.FC<Partial<HeaderRenderProps>> = ({ content }) => (
    <>
      {content?.title && (
        <div className="page-title">
          <div className={`mobile-logo ${isMobile ? "show" : "hide"}`}>
            {isMobile && content.image && pathRoute === "dashboard" && <Image src={content.image} alt="logo" className="mobile-logo-image img-fluid" />}
            <div className={`mobile-title ${isMobile && content.image ? "show" : "hide"} ${isVisible ? 'page-header--visible' : 'page-header--hidden'} `}>
              {path.length === 3 && <Button color="transparent" className="back-btn" onClick={() => navigate(-1)}>
                <Image src={dynamicImage("svg/red-right-arrow.svg")} alt="arrow" className="next" />
              </Button>}
              <div>
                {content.title && (
                  <h2 className="f-w-700">
                    {t(content.title)}
                    {selectedProjectId && projectData?.projectCategory && projectData.inactive === false && <span>({projectData?.projectCategory})</span>}
                    {selectedProjectId && projectData?.projectCategory && projectData.inactive === true && <span className="dark-badge justify-content-center ms-5 badge d-inline rounded-pill"> Inactive </span>}
                  </h2>
                )}
                {content.description && <p>{t(content.description)}</p>}
                {selectedProjectId && projectData?.companyName && user?.role === USER_ROLE.Freelancer && (
                  <p className={`${pathRoute === "project" ? "common-f-flex" : ""}`}>
                    <span className="initial-letter d-sm-flex d-none">
                      <span>
                        {projectData?.companyName
                          ?.split(" ")
                          ?.map((letter: string) => letter.charAt(0).toUpperCase())
                          ?.join("")?.slice(0, 2)}
                      </span>
                    </span>
                    <span>{projectData?.companyName}</span>
                    {projectData?.companyVerified && <Image src={dynamicImage("svg/blue-tick.svg")} className="blue-tick-icon" alt="edit" />}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );


  const renderTooltip: React.FC<Partial<HeaderRenderProps>> = ({ className, iconSrc, text, linkText }) => (
    <>
      {/* //TODO: It's not ready yet and has been shut down for now. Once the relevant information arrives, it will be connected to Helpscout here.*/}
      {!hiddenTooltip && !!text && !!iconSrc && !!linkText && !!linkText && (
        <li className={`${className} form-control d-xl-flex d-none`}>
          <div className="d-flex align-items-center left-title">
            <div className="img-box">{iconSrc && <Image src={dynamicImage(iconSrc)} className="nav-icon" alt={text} />}</div>
            <span>{t(text)}</span>
          </div>
          {linkText && (
            <div className="d-flex align-items-center right-title">
              {t(linkText)}
              <SVG iconId="stroke-short-right-arrow" />
            </div>
          )}
        </li>
      )}
    </>
  );

  const onEditProject = () => {
    if (selectedProjectId) {
      toggleProjectModal(selectedProjectId);
    }
  }

  return (
    <>
      <div className="common-header">
        {renderIconAndTitle({ content })}
        <ul className="nav-right">
          <Fragment>
            {pathRoute === "dashboard" && (
              <li className="datepicker-box">
                <div className="datepicker-container">
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MMMM dd, yyyy"
                    customInput={
                      <button className="change-date-button">
                        <div className="date-display">
                          <div className="calendar-icon"></div>
                          <span>{selectedDate ? formatDate(selectedDate) : "Select Date"}</span>
                        </div>
                        <div>
                          <span>{currentTime}</span>
                        </div>
                      </button>
                    }
                  />
                </div>
              </li>
            )}
            {user?.role === USER_ROLE.Business &&
              pathRoute === "projects" &&
              path.length === 2 &&
              renderTooltip({
                className: "recurring-invoice",
                iconSrc: "svg/star.svg",
                text: "YouCanInviteFreelancersToYourProjectsByClickingTheActionMenu",
                linkText: "ShowMe",
              })}
            {user?.role === USER_ROLE.Freelancer &&
              pathRoute === "projects" &&
              path.length === 2 &&
              renderTooltip({
                className: "recurring-invoice",
                iconSrc: "svg/star.svg",
                text: "DidYouKnowYouCanCreateRecurringInvoices",
                linkText: "ShowMe",
              })}
            {pathRoute === "money" &&
              renderTooltip({
                className: "recurring-invoice",
                iconSrc: "svg/star.svg",
                text: "DidYouKnowYouCanCreateRecurringInvoices",
                linkText: "ShowMe",
              })}
            {projectData?.editable && pathRoute === "project" && path.length === 3 && (
              <li className="project-box form-control d-xl-flex d-none cursor-pointer" onClick={onEditProject}>
                <Button color="transparent" className={`${pathRoute === "project" ? "p-0 d-flex align-items-center" : "d-flex align-items-center gap-4 px-3"}`}>
                  <Image src={dynamicImage("svg/edit.svg")} className="edit-icon" alt="arrow" />
                  <h6>{t("EditProject")}</h6>
                </Button>
              </li>
            )}
            {pathRoute === "new-invoice" &&
              renderTooltip({
                className: "recurring-invoice",
                iconSrc: "svg/vector-stroke.svg",
                text: "DoYouNeedHelp",
              })}
          </Fragment>
          {!(isMobile && (path.length === 3 || pathRoute === "new-invoice")) && <><NotificationSection /><ProfileSection /></>}
        </ul>
      </div>
    </>
  );
};

const headerTopTitle: Record<string, HeaderContent> = {
  dashboard: {
    image: dynamicImage("svg/logo/logo.svg"),
    title: `Welcome`,
    description: "HereIsYourActivitySoFar",
  },
  projects: {
    title: "Projects",
  },
  subProjects: {
    title: "title",
  },
  invoices: {
    title: "Invoices",
  },
  'new-invoice': {
    title: "CreateNewInvoice",
  },
  client: {
    title: "Clients",
  },
  freelancer: {
    title: "Freelancers",
  },
  finance: {
    title: "FinanceInvoice",
  },
  money: {
    title: "Money",
  },
  payment: {
    title: "Payment",
  },
  'payment-agreement': {
    title: "FinalizePayment",
  },
};


export default CommonHeader;
