export const currency = [
  { currencyCode: "AFN", currencySymbol: "؋" },    // Afghan Afghani
  { currencyCode: "ALL", currencySymbol: "Lek" },  // Albanian Lek
  { currencyCode: "DZD", currencySymbol: "دج" },   // Algerian Dinar
  { currencyCode: "AOA", currencySymbol: "Kz" },   // Angolan Kwanza
  { currencyCode: "ARS", currencySymbol: "$" },    // Argentine Peso
  { currencyCode: "AMD", currencySymbol: "֏" },    // Armenian Dram
  { currencyCode: "AWG", currencySymbol: "ƒ" },    // Aruban Florin
  { currencyCode: "AUD", currencySymbol: "$" },    // Australian Dollar
  { currencyCode: "AZN", currencySymbol: "₼" },    // Azerbaijani Manat
  { currencyCode: "BSD", currencySymbol: "$" },    // Bahamian Dollar
  { currencyCode: "BHD", currencySymbol: ".د.ب" }, // Bahraini Dinar
  { currencyCode: "BDT", currencySymbol: "৳" },    // Bangladeshi Taka
  { currencyCode: "BBD", currencySymbol: "$" },    // Barbadian Dollar
  { currencyCode: "BYN", currencySymbol: "Br" },    // Belarusian Ruble
  { currencyCode: "BZD", currencySymbol: "BZ$" },  // Belize Dollar
  { currencyCode: "BMD", currencySymbol: "$" },    // Bermudian Dollar
  { currencyCode: "BTN", currencySymbol: "Nu." },   // Bhutanese Ngultrum
  { currencyCode: "BOB", currencySymbol: "Bs." },   // Bolivian Boliviano
  { currencyCode: "BAM", currencySymbol: "KM" },   // Bosnia-Herzegovina Convertible Mark
  { currencyCode: "BWP", currencySymbol: "P" },    // Botswanan Pula
  { currencyCode: "BRL", currencySymbol: "R$" },   // Brazilian Real
  { currencyCode: "BND", currencySymbol: "$" },    // Brunei Dollar
  { currencyCode: "BGN", currencySymbol: "лв" },   // Bulgarian Lev
  { currencyCode: "BIF", currencySymbol: "FBu" },  // Burundian Franc
  { currencyCode: "KHR", currencySymbol: "៛" },    // Cambodian Riel
  { currencyCode: "CAD", currencySymbol: "$" },    // Canadian Dollar
  { currencyCode: "CVE", currencySymbol: "$" },    // Cape Verdean Escudo
  { currencyCode: "KYD", currencySymbol: "$" },    // Cayman Islands Dollar
  { currencyCode: "XAF", currencySymbol: "FCFA" }, // Central African CFA Franc
  { currencyCode: "CLP", currencySymbol: "$" },    // Chilean Peso
  { currencyCode: "CNY", currencySymbol: "¥" },    // Chinese Yuan
  { currencyCode: "COP", currencySymbol: "$" },    // Colombian Peso
  { currencyCode: "KMF", currencySymbol: "CF" },   // Comorian Franc
  { currencyCode: "CDF", currencySymbol: "FC" },   // Congolese Franc
  { currencyCode: "CRC", currencySymbol: "₡" },    // Costa Rican Colón
  { currencyCode: "HRK", currencySymbol: "kn" },   // Croatian Kuna
  { currencyCode: "CUP", currencySymbol: "₱" },    // Cuban Peso
  { currencyCode: "CZK", currencySymbol: "Kč" },   // Czech Koruna
  { currencyCode: "DKK", currencySymbol: "kr" },   // Danish Krone
  { currencyCode: "DJF", currencySymbol: "Fdj" },  // Djiboutian Franc
  { currencyCode: "DOP", currencySymbol: "RD$" },  // Dominican Peso
  { currencyCode: "XCD", currencySymbol: "$" },    // East Caribbean Dollar
  { currencyCode: "EGP", currencySymbol: "£" },    // Egyptian Pound
  { currencyCode: "ERN", currencySymbol: "Nfk" },  // Eritrean Nakfa
  { currencyCode: "ETB", currencySymbol: "Br" },   // Ethiopian Birr
  { currencyCode: "EUR", currencySymbol: "€" },    // Euro
  { currencyCode: "FJD", currencySymbol: "$" },    // Fijian Dollar
  { currencyCode: "FKP", currencySymbol: "£" },    // Falkland Islands Pound
  { currencyCode: "FOK", currencySymbol: "kr" },   // Faroese Króna
  { currencyCode: "GMD", currencySymbol: "D" },    // Gambian Dalasi
  { currencyCode: "GEL", currencySymbol: "₾" },    // Georgian Lari
  { currencyCode: "GHS", currencySymbol: "₵" },    // Ghanaian Cedi
  { currencyCode: "GIP", currencySymbol: "£" },    // Gibraltar Pound
  { currencyCode: "GTQ", currencySymbol: "Q" },    // Guatemalan Quetzal
  { currencyCode: "GNF", currencySymbol: "FG" },   // Guinean Franc
  { currencyCode: "GYD", currencySymbol: "$" },    // Guyanese Dollar
  { currencyCode: "HTG", currencySymbol: "G" },    // Haitian Gourde
  { currencyCode: "HNL", currencySymbol: "L" },    // Honduran Lempira
  { currencyCode: "HKD", currencySymbol: "$" },    // Hong Kong Dollar
  { currencyCode: "HUF", currencySymbol: "Ft" },   // Hungarian Forint
  { currencyCode: "ISK", currencySymbol: "kr" },   // Icelandic Króna
  { currencyCode: "INR", currencySymbol: "₹" },    // Indian Rupee
  { currencyCode: "IDR", currencySymbol: "Rp" },   // Indonesian Rupiah
  { currencyCode: "IRR", currencySymbol: "﷼" },    // Iranian Rial
  { currencyCode: "IQD", currencySymbol: "ع.د" },  // Iraqi Dinar
  { currencyCode: "ILS", currencySymbol: "₪" },    // Israeli New Shekel
  { currencyCode: "JMD", currencySymbol: "$" },    // Jamaican Dollar
  { currencyCode: "JPY", currencySymbol: "¥" },    // Japanese Yen
  { currencyCode: "JOD", currencySymbol: "JD" },   // Jordanian Dinar
  { currencyCode: "KZT", currencySymbol: "₸" },    // Kazakhstani Tenge
  { currencyCode: "KES", currencySymbol: "KSh" },  // Kenyan Shilling
  { currencyCode: "KWD", currencySymbol: "KD" },   // Kuwaiti Dinar
  { currencyCode: "KGS", currencySymbol: "лв" },   // Kyrgyzstani Som
  { currencyCode: "LAK", currencySymbol: "₭" },    // Lao Kip
  { currencyCode: "LBP", currencySymbol: "ل.ل" },  // Lebanese Pound
  { currencyCode: "LSL", currencySymbol: "L" },    // Lesotho Loti
  { currencyCode: "LRD", currencySymbol: "$" },    // Liberian Dollar
  { currencyCode: "LYD", currencySymbol: "LD" },   // Libyan Dinar
  { currencyCode: "MOP", currencySymbol: "MOP$" }, // Macanese Pataca
  { currencyCode: "MKD", currencySymbol: "ден" },  // Macedonian Denar
  { currencyCode: "MGA", currencySymbol: "Ar" },   // Malagasy Ariary
  { currencyCode: "MWK", currencySymbol: "MK" },   // Malawian Kwacha
  { currencyCode: "MYR", currencySymbol: "RM" },   // Malaysian Ringgit
  { currencyCode: "MVR", currencySymbol: "Rf" },   // Maldivian Rufiyaa
  { currencyCode: "MRU", currencySymbol: "UM" },   // Mauritanian Ouguiya
  { currencyCode: "MUR", currencySymbol: "₨" },    // Mauritian Rupee
  { currencyCode: "MXN", currencySymbol: "$" },    // Mexican Peso
  { currencyCode: "MDL", currencySymbol: "L" },    // Moldovan Leu
  { currencyCode: "MNT", currencySymbol: "₮" },    // Mongolian Tögrög
  { currencyCode: "MAD", currencySymbol: "د.م." },  // Moroccan Dirham
  { currencyCode: "MZN", currencySymbol: "MT" },   // Mozambican Metical
  { currencyCode: "MMK", currencySymbol: "K" },    // Myanmar Kyat
  { currencyCode: "NAD", currencySymbol: "$" },    // Namibian Dollar
  { currencyCode: "NPR", currencySymbol: "₨" },    // Nepalese Rupee
  { currencyCode: "ANG", currencySymbol: "ƒ" },    // Netherlands Antillean Guilder
  { currencyCode: "NZD", currencySymbol: "$" },    // New Zealand Dollar
  { currencyCode: "NIO", currencySymbol: "C$" },   // Nicaraguan Córdoba
  { currencyCode: "NGN", currencySymbol: "₦" },    // Nigerian Naira
  { currencyCode: "NOK", currencySymbol: "kr" },   // Norwegian Krone
  { currencyCode: "OMR", currencySymbol: "﷼" },    // Omani Rial
  { currencyCode: "PKR", currencySymbol: "₨" },    // Pakistani Rupee
  { currencyCode: "PAB", currencySymbol: "B/." },  // Panamanian Balboa
  { currencyCode: "PGK", currencySymbol: "K" },    // Papua New Guinean Kina
  { currencyCode: "PYG", currencySymbol: "₲" },    // Paraguayan Guarani
  { currencyCode: "PEN", currencySymbol: "S/." },  // Peruvian Sol
  { currencyCode: "PHP", currencySymbol: "₱" },    // Philippine Peso
  { currencyCode: "PLN", currencySymbol: "zł" },   // Polish Zloty
  { currencyCode: "QAR", currencySymbol: "﷼" },    // Qatari Riyal
  { currencyCode: "RON", currencySymbol: "lei" },  // Romanian Leu
  { currencyCode: "RUB", currencySymbol: "₽" },    // Russian Ruble
  { currencyCode: "RWF", currencySymbol: "FRw" },  // Rwandan Franc
  { currencyCode: "SHP", currencySymbol: "£" },    // Saint Helena Pound
  { currencyCode: "SVC", currencySymbol: "$" },    // Salvadoran Colón
  { currencyCode: "WST", currencySymbol: "T" },    // Samoan Tala
  { currencyCode: "SAR", currencySymbol: "﷼" },    // Saudi Riyal
  { currencyCode: "RSD", currencySymbol: "дин" },  // Serbian Dinar
  { currencyCode: "SCR", currencySymbol: "₨" },    // Seychellois Rupee
  { currencyCode: "SLL", currencySymbol: "Le" },   // Sierra Leonean Leone
  { currencyCode: "SGD", currencySymbol: "$" },    // Singapore Dollar
  { currencyCode: "SBD", currencySymbol: "$" },    // Solomon Islands Dollar
  { currencyCode: "SOS", currencySymbol: "Sh" },   // Somali Shilling
  { currencyCode: "ZAR", currencySymbol: "R" },    // South African Rand
  { currencyCode: "KRW", currencySymbol: "₩" },    // South Korean Won
  { currencyCode: "SSP", currencySymbol: "£" },    // South Sudanese Pound
  { currencyCode: "LKR", currencySymbol: "₨" },    // Sri Lankan Rupee
  { currencyCode: "SDG", currencySymbol: "ج.س." }, // Sudanese Pound
  { currencyCode: "SRD", currencySymbol: "$" },    // Surinamese Dollar
  { currencyCode: "SEK", currencySymbol: "kr" },   // Swedish Krona
  { currencyCode: "CHF", currencySymbol: "₣" },    // Swiss Franc
  { currencyCode: "SYP", currencySymbol: "£" },    // Syrian Pound
  { currencyCode: "TWD", currencySymbol: "NT$" },  // New Taiwan Dollar
  { currencyCode: "TJS", currencySymbol: "SM" },   // Tajikistani Somoni
  { currencyCode: "TZS", currencySymbol: "Sh" },   // Tanzanian Shilling
  { currencyCode: "THB", currencySymbol: "฿" },    // Thai Baht
  { currencyCode: "TOP", currencySymbol: "T$" },   // Tongan Paʻanga
  { currencyCode: "TTD", currencySymbol: "$" },    // Trinidad and Tobago Dollar
  { currencyCode: "TND", currencySymbol: "د.ت" },  // Tunisian Dinar
  { currencyCode: "TRY", currencySymbol: "₺" },    // Turkish Lira
  { currencyCode: "TMT", currencySymbol: "m" },    // Turkmenistani Manat
  { currencyCode: "UGX", currencySymbol: "Sh" },   // Ugandan Shilling
  { currencyCode: "UAH", currencySymbol: "₴" },    // Ukrainian Hryvnia
  { currencyCode: "AED", currencySymbol: "د.إ" },  // United Arab Emirates Dirham
  { currencyCode: "USD", currencySymbol: "$" },    // United States Dollar
  { currencyCode: "UYU", currencySymbol: "$U" },   // Uruguayan Peso
  { currencyCode: "UZS", currencySymbol: "сўм" },  // Uzbekistani Som
  { currencyCode: "VUV", currencySymbol: "Vt" },   // Vanuatu Vatu
  { currencyCode: "VES", currencySymbol: "Bs.S" }, // Venezuelan Bolívar
  { currencyCode: "VND", currencySymbol: "₫" },    // Vietnamese Dong
  { currencyCode: "YER", currencySymbol: "﷼" },    // Yemeni Rial
  { currencyCode: "ZMW", currencySymbol: "ZK" },   // Zambian Kwacha
  { currencyCode: "ZWL", currencySymbol: "Z$" },   // Zimbabwean Dollar
  { currencyCode: "GBP", currencySymbol: "£" },
  { currencyCode: "UCP", currencySvg: "svg/currency/ucp.svg" } //
];
