import { FC } from "react";
import { INVOICE_STATUS, InvoiceStatus } from "../../Types";
import { useAuth } from "../../Hooks";
import { dynamicImage } from "../../utils";
import { Image } from "../Image/Image"
import { useTranslation } from "react-i18next";
import { CancelInvoiceStatuses, WaitingInvoiceStatuses } from "../../Types/InvoiceStatus";

export const InvoiceStatusBadge: FC<{ invoiceStatus: InvoiceStatus, text?: boolean }> = ({ invoiceStatus, text = false }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  function getClassName(status: InvoiceStatus) {
    switch (status) {
      case INVOICE_STATUS.CompletedTransferToFreelancer:
        return "success-badge";
      case INVOICE_STATUS.Draft:
        return "draft-badge";
      default:
        if (WaitingInvoiceStatuses.includes(status))
          return "in-transfer-badge";
        else if (CancelInvoiceStatuses.includes(status))
          return "cancel-badge";
        return "";
    }
  }

  function getImageName(status: InvoiceStatus) {
    switch (status) {
      case INVOICE_STATUS.CompletedTransferToFreelancer:
        return "success";
      case INVOICE_STATUS.Draft:
        return "draft";
      default:
        if (WaitingInvoiceStatuses.includes(status))
          return "in-transfer";
        else if (CancelInvoiceStatuses.includes(status))
          return "cancel";
        throw "not found status as " + status;
    }
  }

  return (
    <>
      {invoiceStatus &&
        (
          text ?
            <>
              <Image src={dynamicImage(`svg/${getImageName(invoiceStatus)}.svg`)} alt={`${invoiceStatus} image`} />
              {t(`${user?.role}.Short.${invoiceStatus}`)}
            </>
            :
            <div className={`badge text-capitalize ${getClassName(invoiceStatus)}`}>
              <Image src={dynamicImage(`svg/${getImageName(invoiceStatus)}.svg`)} alt={`${invoiceStatus} image`} />
              {t(`${user?.role}.Short.${invoiceStatus}`)}
            </div>
        )

      }
    </>
  );
};
