import { Col, Row } from "reactstrap";
import { LogoWrapper, GoogleLinkedinMedia, ImageSection } from "../../Widgets";
import SignupForm from "./SignupForm";
import { useLayoutContext } from "../../../../views";
import { USER_ROLE } from "../../../../Types";

const FreelancerSignup = () => {

  const { isMobile } = useLayoutContext();

  return (
    <div className="log-in-section freelancer-signup">
      <Row className="login-content g-0">
        <Col xl="5" lg="6" md="8" sm="10" className="auth-left">
          <div className="login-main">
            <LogoWrapper freelancer tabBadge />
            <SignupForm />
            <ul className="social-media">
              <GoogleLinkedinMedia googleText={isMobile ? "Google" : "LoginWithGoogle"} linkedinText={isMobile ? "LinkedIn" : "LoginWithLinkedIn"} />
            </ul>
          </div>
        </Col>
        <ImageSection userName={USER_ROLE.Freelancer} />
      </Row>
    </div>
  );
};

export default FreelancerSignup;