import { useMemo } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Constants";
import { Image, SolidButton, InvoiceStatusBadge } from "../../../AbstractElement";
import { dynamicImage } from "../../../utils";
import { CommonTable } from "../../../CommonComponent";
import { queries } from "../../../api";
import { INVOICE_STATUS, InvoiceScope } from "../../../Types";
import { TableConfig } from "../../../Types/CommonComponent";
import { useTableFilterHelper } from "../../../Hooks";
import { useStateContext } from "../../../views";

const LatestInvoices = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: invoice } = queries.useGetLatestInvoiceScopes();
  const { openInvoiceDetailModal, openInvoiceViewModal } = useStateContext();

  const { toggleSortType, handleSetSortBy } = useTableFilterHelper({ archiveStatus: false });

  const handleButtonClick = (data: InvoiceScope) => {
    if (data.id && data.invoiceStatus === INVOICE_STATUS.WaitingForPayment) {
      navigate(`${ROUTES.Payment.replace(':id', data.id.toString())}`);
    } else if (data.id && data.invoiceStatus === INVOICE_STATUS.WaitingForApproval) {
      openInvoiceViewModal(data.id)
    } else {
      openInvoiceDetailModal(data.id)
    }
  };

  const tableConfig = useMemo<TableConfig<InvoiceScope>>(() => ({
    columns: [
      { title: "Invoice", dataField: [{ field: "documentNo", class: 'top', type: '', renderer: (data: InvoiceScope) => { return <>{`#${data.documentNo}`}</> } }, { field: "projectName", renderer: (data: InvoiceScope) => { return <><span>{`(${data.projectName})`}</span></> } }], sortable: false, sortField: 'documentNo', sortDirection: 'desc' },
      { title: "Freelancer", dataField: [{ field: 'freelancerName', class: 'top', type: '' }, { field: 'freelancerEmail', renderer: (data: InvoiceScope) => { return <><span>{`(${data.freelancerEmail})`}</span></> } }], sortable: false, sortField: 'freelancerName', sortDirection: 'desc' },
      { title: "InvoiceStatus", dataField: [{ field: 'invoiceStatus', class: '', type: '', renderer: (data: InvoiceScope) => { return <InvoiceStatusBadge invoiceStatus={data.invoiceStatus} />; } }, { field: 'modifiedAt', class: 'date', type: 'date', params: { format: "long" } }], sortable: false, sortField: 'invoiceStatus', sortDirection: 'desc' },
      { title: "Amount", class: 'amount-header', dataField: [{ field: 'totalIncludingTaxes', class: 'amount', type: 'amount', params: { currency: "currencyCode" } }], sortable: false, sortField: 'totalIncludingTaxes', sortDirection: 'desc' },
      {
        title: "", dataField: [{
          field: "id", class: '', type: '',
          renderer: (data: InvoiceScope) => {
            const buttonLabel = data.invoiceStatus === INVOICE_STATUS.WaitingForPayment ? t("Pay") : data.invoiceStatus === INVOICE_STATUS.WaitingForApproval ? t("Approve") : t("View");
            return (<SolidButton className="btn btn-gray common-add-btn right" onClick={() => handleButtonClick(data)}>{buttonLabel}</SolidButton>);
          },
        }]
      },
    ],
    actionsDropDown: [],
    data: invoice?.items ?? [],
    total: invoice?.totalResults ?? 0,
    isColumnChooser: false,
  }), [invoice]);


  return (
    <>
      <Col xs="12" className='col-height'>
        <Card className="radius-bottom-0 h-100 recent-invoice-padding">
          <div className="recent-invoice-card business-recent-invoice">
            <CardHeader>
              <h6>{t("LatestInvoicesReceived")}</h6>
              {/*
              //TODO:It can be connected later.
              <SolidButton>
                <Image src={dynamicImage("svg/plus-white.svg")} alt="invoice image" className="d-sm-none d-inline-flex" />
                <Image src={dynamicImage("svg/paper-white.svg")} alt="invoice image" className="d-sm-inline-flex d-none" />
                <span className="d-sm-inline-flex d-none" onClick={handleRequest}>{t("RequestNewInvoice")}</span>
              </SolidButton> */}
            </CardHeader>
            <CardBody>
              {tableConfig.data.length > 0 ? (
                <CommonTable tableConfiguration={tableConfig} pagingDisabled={true} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} />
              ) : (
                <div className="no-data-found">
                  <div>
                    <Image src={dynamicImage("svg/no-data/no-data-invoice.svg")} alt="invoice image" className="img-fluid" />
                    <h5 className='mt-3'>{t("YouHaventCreatedAnInvoiceYet")}</h5>
                  </div>
                </div>
              )}
            </CardBody>
          </div>
        </Card>
      </Col>
    </>
  );
};
export default LatestInvoices;