import { Col, Container, Row } from "reactstrap";
import TabSection from "./TabSection";
import LatestTransactions from "./InvoiceSection/LatestTransactions";
import LatestInvoices from "./LatestInvoices";
import LastInvoice from "../Widgets/LastInvoice";

const BusinessDashboard = () => {

  return (
    <Container fluid className="order-dashboard business">
      <Row className="g-2 pt-0 main-row custom-row">
        <Col xl="9">
          <Row className="d-block g-2 h-100 dashboard-custom-height">
            <TabSection />
            <LatestInvoices />
          </Row>
        </Col>
        <Col xl="3">
          <Row className="d-block g-2 h-100 dashboard-custom-height-2">
            <LastInvoice />
            <LatestTransactions />
          </Row>
        </Col>
      </Row>
    </Container>
  )
};

export default BusinessDashboard;
