import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Image } from "../../AbstractElement";
import { MenuList } from "../../Data/Layout/Sidebar";
import { useAuth } from "../../Hooks";
import { dynamicImage } from "../../utils";
import { useLayoutContext, useStateContext } from "../../views";
import SidebarMenuBottom from "./SidebarMenuBottom/SidebarMenuBottom";

const SidebarMenu = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { handleSidebarItemClick } = useLayoutContext();
  const { dismissAll } = useStateContext();
  const filteredMenuList = MenuList.filter((x) => !x.role || x.role === user?.role);

  const isActiveMenuItem = (menuUrl: string) => {
    const menuUrlObj = new URL(menuUrl, window.location.origin);
    const locationUrlObj = new URL(window.location.href);

    if (menuUrlObj.pathname !== locationUrlObj.pathname) return false;

    const menuTab = menuUrlObj.searchParams.get("tab") || "home";
    const locationTab = locationUrlObj.searchParams.get("tab") || "home";

    return menuTab === locationTab;
  };


  return (
    <div className="sidebar-main custom-scroll">
      <div id="sidebar-menu" className="sidebar-content">
        <ul className="sidebar-links custom-scrollbar">
          {filteredMenuList &&
            filteredMenuList.map((mainMenu, i) => {
              const isActive = isActiveMenuItem(mainMenu.url);

              return (
                <Fragment key={i}>
                  <li className="sidebar-list" key={i}>
                    <Link
                      className={`sidebar-link ${isActive ? 'active' : ''}`}
                      to={mainMenu.url}
                      onClick={() => { handleSidebarItemClick(); dismissAll(); }}
                    >
                      <Image src={dynamicImage(`svg/admin/${isActive ? mainMenu.icon2 : mainMenu.icon}`)} alt={mainMenu.title} />
                      <span>{t(`${mainMenu.title}`)}</span>
                    </Link>
                  </li>
                </Fragment>
              );
            })}
        </ul>
        <SidebarMenuBottom />
      </div>
    </div>
  );
};

export default SidebarMenu;
