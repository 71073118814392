import { queries } from '../api';
import { CURRENCY_USAGE_TYPE, INVOICE_STATUS, LookupItem, Paginated, USER_ROLE, Country, Profession, Currency, Client } from '../Types';
import useAuth from './useAuth';
import { useTranslation } from 'react-i18next';
import { KEYS, URL_KEYS } from '../Constants';
import { useMemo } from 'react';

type CoreDataInit = {
  loadCountries?: boolean;
  loadLanguages?: boolean;
  loadTimeZones?: boolean;
  loadExpertiseAreas?: boolean;
  loadInvoiceFrequency?: boolean;
  loadInvoiceAmountEstimates?: boolean;
  loadProfessions?: boolean;
  loadIndustries?: boolean;
  loadProjectCategories?: boolean;
  loadUserAccountDeletionReasons?: boolean;
  loadInvoiceUnits?: boolean;
  loadCurrenciesPayOut?: boolean;
  loadCurrenciesPayIn?: boolean;
  loadCurrenciesPayInOut?: boolean;
  loadCustomers?: boolean;
  loadProjects?: boolean;
  loadUserDetails?: boolean;
};

const useCoreData = (init: CoreDataInit) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const convertOptions = <T extends LookupItem>(items: Paginated<T>): { label: string; value: string }[] =>
    items.items.map((data) => ({
      label: data.name,
      value: data.code,
    }));

  // Dynamic data queries using `URL_KEYS` with direct `data` access
  const { data: countries } = queries.useGetLookup<Country>(URL_KEYS.Lookup.Countries, !!init.loadCountries);
  const { data: languages } = queries.useGetLookup<LookupItem>(URL_KEYS.Lookup.Languages, !!init.loadLanguages);
  const { data: timeZones } = queries.useGetLookup<LookupItem>(URL_KEYS.Lookup.TimeZones, !!init.loadTimeZones);

  // Questionnaire:
  const { data: expertiseAreas } = queries.useGetLookup<LookupItem>(URL_KEYS.Lookup.ExpertiseAreas, !!init.loadExpertiseAreas);
  const { data: invoiceFrequency } = queries.useGetLookup<LookupItem>(URL_KEYS.Lookup.InvoiceFrequency, !!init.loadInvoiceFrequency);
  const { data: invoiceAmountEstimates } = queries.useGetLookup<LookupItem>(URL_KEYS.Lookup.InvoiceAmountEstimates, !!init.loadInvoiceAmountEstimates);

  const { data: professions } = queries.useGetLookup<Profession>(URL_KEYS.Lookup.Professions, !!init.loadProfessions);
  const { data: industries } = queries.useGetLookup<LookupItem>(URL_KEYS.Lookup.Industries, !!init.loadIndustries);
  const { data: projectCategories } = queries.useGetLookup<LookupItem>(URL_KEYS.Lookup.ProjectCategories, !!init.loadProjectCategories);
  const { data: userAccountDeletionReasons } = queries.useGetLookup<LookupItem>(URL_KEYS.Lookup.UserAccountDeletionReasons, !!init.loadUserAccountDeletionReasons);
  const { data: invoiceUnits } = queries.useGetLookup<LookupItem>(URL_KEYS.Lookup.InvoiceUnitCodes, !!init.loadInvoiceUnits);

  // Currencies options based on usage type
  const { data: currenciesPayOut } = queries.useGetLookup<Currency>(URL_KEYS.Lookup.Currencies, !!init.loadCurrenciesPayOut, { usageType: CURRENCY_USAGE_TYPE.PayOut }, KEYS.CURRENCIES_PAYOUT);
  const { data: currenciesPayIn } = queries.useGetLookup<Currency>(URL_KEYS.Lookup.Currencies, !!init.loadCurrenciesPayIn, { usageType: CURRENCY_USAGE_TYPE.PayIn }, KEYS.CURRENCIES_PAYIN);
  const { data: currenciesPayInOut } = queries.useGetLookup<Currency>(URL_KEYS.Lookup.Currencies, !!init.loadCurrenciesPayInOut, { usageType: CURRENCY_USAGE_TYPE.PayInOut }, KEYS.CURRENCIES_PAYINOUT);

  const { data: customers, isFetched: isFetchedCustomer } = queries.useGetLookupClient(URL_KEYS.Lookup.Customers, !!init.loadCustomers && user?.role !== USER_ROLE.Business, {});
  const { data: projects } = queries.useGetProjects({ archiveStatus: false }, !!init.loadProjects);
  const { data: userDetails } = queries.useGetUserDetails(!!init.loadUserDetails);

  // Options based on loaded data
  const countryOptions = countries?.items ? convertOptions(countries) : [];
  const timeZoneOptions = timeZones?.items ? convertOptions(timeZones) : [];
  const languageOptions = languages?.items ? convertOptions(languages) : [];
  const unitsCodeOptions = invoiceUnits?.items ? convertOptions(invoiceUnits) : [];
  const currencyPayOutOptions = currenciesPayOut?.items ? convertOptions(currenciesPayOut) : [];
  const currencyPayInOptions = currenciesPayIn?.items ? convertOptions(currenciesPayIn) : [];
  const currencyPayInOutOptions = currenciesPayInOut?.items ? convertOptions(currenciesPayInOut) : [];
  const projectCategoriesOptions = projectCategories?.items ? convertOptions(projectCategories) : [];
  const expertiseAreasOptions = expertiseAreas?.items ? convertOptions(expertiseAreas) : [];
  const invoiceFrequencyOptions = invoiceFrequency?.items ? convertOptions(invoiceFrequency) : [];
  const invoiceAmountEstimatesOptions = invoiceAmountEstimates?.items ? convertOptions(invoiceAmountEstimates) : [];
  const industryOptions = industries?.items ? convertOptions(industries) : [];
  const userAccountDeletionReasonOptions = userAccountDeletionReasons?.items ? convertOptions(userAccountDeletionReasons) : [];

  const fullName = useMemo(() => {
    if (!userDetails) return null;
    if (user?.role === USER_ROLE.Business) {
      return `${userDetails.companyName}`;
    } else if (userDetails.firstName && userDetails.lastName) {
      return `${userDetails.firstName} ${userDetails.lastName}`;
    }
    else {
      return null;
    }
  }, [userDetails]);

  // Special options with custom mapping logic
  const phoneCountryCodeOptions = countries?.items
    ? countries.items.map((data) => ({
      label: data.phoneCode,
      value: data.code,
    }))
      .filter((f) => f.label !== '')
      .sort((a, b) => a.label.localeCompare(b.label))
    : [];

  const customersSelect = useMemo(() =>
    customers?.items ? customers.items.map((data) => ({
      label: data.name,
      value: data.id,
      data: {
        email: data.email,
        verified: data.verified,
      }
    })) : [], [customers]
  );

  const projectsOptions = projects?.items
    ? projects.items.map((data) => ({
      label: data.projectName,
      value: data.id,
    }))
    : [];

  const professionCodeOptions = professions?.items
    ? professions.items.map((data) => ({
      label: data.name,
      value: data.code,
      data: {
        entryDescriptionIsRequired: data.entryDescriptionIsRequired,
      },
    }))
    : [];

  const invoiceStatusOptions = Object.values(INVOICE_STATUS)
    .filter((x) => x !== INVOICE_STATUS.Draft)
    .filter(
      (x) =>
        user?.role !== USER_ROLE.Business ||
        (x !== INVOICE_STATUS.DeclineByJuuli && x !== INVOICE_STATUS.WaitingForSentToBusiness)
    )
    .map((e) => ({
      label: t(`${user?.role}.Short.${e}`),
      value: e,
    }));

  // Return only the data needed based on the `init` configuration
  return {
    ...(init.loadCountries && { countryOptions }),
    ...(init.loadLanguages && { languageOptions }),
    ...(init.loadTimeZones && { timeZoneOptions }),
    ...(init.loadExpertiseAreas && { expertiseAreasOptions }),
    ...(init.loadInvoiceFrequency && { invoiceFrequencyOptions }),
    ...(init.loadInvoiceAmountEstimates && { invoiceAmountEstimatesOptions }),
    ...(init.loadProfessions && { professionCodeOptions }),
    ...(init.loadIndustries && { industryOptions }),
    ...(init.loadProjectCategories && { projectCategoriesOptions }),
    ...(init.loadUserAccountDeletionReasons && { userAccountDeletionReasonOptions }),
    ...(init.loadInvoiceUnits && { unitsCodeOptions }),
    ...(init.loadCurrenciesPayOut && { currencyPayOutOptions }),
    ...(init.loadCurrenciesPayIn && { currencyPayInOptions }),
    ...(init.loadCurrenciesPayInOut && { currencyPayInOutOptions }),
    ...(init.loadCustomers && { customersSelect }),
    ...(init.loadProjects && { projectsOptions }),
    ...(init.loadCountries && { phoneCountryCodeOptions }),
    invoiceStatusOptions,
    isFetchedCustomer,
    fullName
  };
};

export default useCoreData;
