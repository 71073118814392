import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Offcanvas, OffcanvasBody } from 'reactstrap';
import { Image, InvoiceStatusBadge, LinkButton, SolidButton } from '../../../AbstractElement';
import { mutations, queries } from '../../../api';
import { LayoutLoader } from '../../../CommonComponent';
import { ROUTES } from '../../../Constants';
import { useAuth, useCoreData } from '../../../Hooks';
import { INVOICE_STATUS, USER_ROLE } from '../../../Types';
import { convertToReadableAmount, convertToReadableDate, dynamicImage } from '../../../utils';
import { useLayoutContext, useLoader, useStateContext, useToast } from '../../../views';
import { CancelInvoiceStatuses, InvoiceStatus } from '../../../Types/InvoiceStatus';

const InvoiceDetailCanvas: React.FC<{ id: string, finalizeView?: boolean }> = ({ id, finalizeView }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { invoiceDetailModal, closeInvoiceDetailModal, openInvoiceViewModal } = useStateContext();
    const { projectCategoriesOptions } = useCoreData({ loadProjectCategories: true });
    const { user } = useAuth();
    const { showToast } = useToast();
    const { position } = useLayoutContext()
    const { mutate: downloadInvoicePdf, isLoading: isDownload } = mutations.useDownloadInvoiceScopePdf();
    const { mutate: archiveInvoiceScope, isLoading: isArchiving } = mutations.useArchiveInvoiceScope();
    const { mutate: refetchSendReminder } = mutations.useSendReminderInvoice();
    const { mutate: refetchDuplicate } = mutations.useDuplicateInvoice();
    const { isLoading } = useLoader();

    const ignoreStatuses: InvoiceStatus[] = [INVOICE_STATUS.Draft, INVOICE_STATUS.WaitingForSentToBusiness, INVOICE_STATUS.WaitingForPayment, INVOICE_STATUS.WaitingForPaymentAgreement, INVOICE_STATUS.WaitingForPaymentApprove, INVOICE_STATUS.TransferFailed, INVOICE_STATUS.TransferFailedPayment];

    const { data: invoiceScope } = queries.useGetInvoiceScope(id!);
    const { data: invoiceScopeLog } = queries.useGetInvoiceScopeLog(id!);

    const handleDownloadInvoice = async () => {
        downloadInvoicePdf({ id: invoiceScope?.id!, fileName: `Invoice_${invoiceScope?.documentNo}.pdf` }, {
            onSuccess: () => showToast("success", "InvoiceDetailCanvas.DownloadedInvoice")
        });
    };

    const handleArchive = async () => {
        if (!invoiceScope?.id) return;
        const newArchiveStatus = !invoiceScope.archived;
        archiveInvoiceScope({
            id: invoiceScope.id,
            archived: newArchiveStatus,
        }, {
            onSuccess: () => {
                closeInvoiceDetailModal()
                if (newArchiveStatus) {
                    showToast("delete", 'InvoiceDetailCanvas.InvoiceArchivedSuccessfully');
                } else {
                    showToast("success", 'InvoiceDetailCanvas.InvoiceUnarchivedSuccessfully');
                }

                if (finalizeView) navigate(ROUTES.Dashboard);
            },
        });
    };

    const sendReminder = () => {
        refetchSendReminder(invoiceScope?.id, {
            onSuccess: () => {
                showToast("success", "InvoiceScope.SendReminderSuccessfully");
            },
        });
    }

    const duplicate = () => {
        refetchDuplicate(invoiceScope?.id, {
            onSuccess: (response) => {
                const newInvoiceId = response?.invoiceId;
                if (newInvoiceId) {
                    closeInvoiceDetailModal()
                    navigate(`${ROUTES.InvoiceEdit.replace(':id', newInvoiceId)}`);
                } else {
                    console.error('No invoice ID found in the duplicate response');
                }
            },
        });
    }
    const closeDialog = () => {
        closeInvoiceDetailModal();
        if (finalizeView)
            navigate(ROUTES.Dashboard)
    }
    const actionItems = [
        { label: isDownload ? "Downloading" : "Download", svgName: "download", onClick: handleDownloadInvoice, isVisible: true },
        { label: "Duplicate", svgName: "duplicate", onClick: duplicate, isVisible: user?.role === USER_ROLE.Freelancer },
        {
            label: "SendReminder",
            svgName: "Vector-1",
            onClick: sendReminder,
            isVisible: user?.role === USER_ROLE.Freelancer &&
                (invoiceScope?.invoiceStatus === INVOICE_STATUS.WaitingForApproval ||
                    invoiceScope?.invoiceStatus === INVOICE_STATUS.WaitingForPayment),
        },
        {
            label: isArchiving ? (invoiceScope?.archived ? 'Unarchiving' : 'Archiving') : invoiceScope?.archived ? 'UnarchiveInvoice' : 'ArchiveInvoice',
            svgName: "archive",
            onClick: handleArchive,
            isVisible: true,
        }
    ];

    const visibleItems = actionItems.filter(item => item.isVisible);
    const showOnlyIcons = visibleItems.length === 4;

    return (
        <Offcanvas direction={position} isOpen={invoiceDetailModal.toggle} toggle={closeDialog} className="invoice-detail-modal">
            {isLoading && <LayoutLoader />}
            <Button color='transparent' onClick={closeDialog} typeof="button" className="btn-close d-md-block d-none" />
            <OffcanvasBody className="p-0">
                <div className="align-items-center h-100 d-md-flex">
                    <div className="h-100 status-content d-lg-flex d-none">
                        <div className="invoice-left-part">
                            <h2>{t("InvoiceJourney")}</h2>
                            <div className="invoice-detail">
                                {invoiceScopeLog && <ul>
                                    {
                                        invoiceScopeLog
                                            .filter(f => CancelInvoiceStatuses.includes(f.status) || f.status !== invoiceScope?.invoiceStatus || f.status === INVOICE_STATUS.CompletedTransferToFreelancer)
                                            .map((exist) => {
                                                return <li key={exist.id} className={CancelInvoiceStatuses.includes(exist.status) ? "cancel" : "completed"}>
                                                    <span>{t(`${user?.role}.Long.${exist.status}`)}</span>
                                                    <small>{convertToReadableDate(exist?.createdAt, "short")}</small>
                                                </li>
                                            })
                                    }
                                    {Object.entries(INVOICE_STATUS).map(([key, value]) => {
                                        let exist = value !== invoiceScope?.invoiceStatus && invoiceScopeLog.find(f => f.status === value);
                                        if (exist || CancelInvoiceStatuses.includes(value) || (ignoreStatuses.includes(value) && invoiceScope?.invoiceStatus !== value) || (invoiceScope?.invoiceStatus === value && value === INVOICE_STATUS.CompletedTransferToFreelancer))
                                            return null;
                                        return <li key={key} className={invoiceScope?.invoiceStatus === value ? "active" : ""}>
                                            <span>{t(`${user?.role}.Long.${value}`)}</span>
                                        </li>
                                    })}
                                </ul>}
                            </div>
                        </div>
                    </div>
                    <div className="invoice-content">
                        <span className="right-box-shadow"></span>
                        <div className="w-100 custom-scrollbar">
                            <div className="invoice-right-part">
                                <h2>
                                    {finalizeView ?
                                        (<>
                                            <Image src={dynamicImage("svg/invoice-created.svg")} alt="invoice send" />
                                            {t("InvoiceCreatedSuccessfully")
                                            }</>)
                                        : (<>{t("InvoiceDetails")}</>)
                                    }
                                </h2>


                                <div>
                                    <ul className="invoice-data custom-scroll">
                                        <li><span>{t("Project")}</span><p>{invoiceScope?.projectName}
                                            {invoiceScope?.projectCategoryCode && projectCategoriesOptions && <small>({projectCategoriesOptions.find(f => f.value === invoiceScope?.projectCategoryCode)?.label})</small>}</p></li>
                                        {user?.role === USER_ROLE.Freelancer && <li><span>{t("Client")}</span><p>{invoiceScope?.companyName}<small>({invoiceScope?.companyEmail})</small></p></li>}
                                        {user?.role === USER_ROLE.Business && <li><span>{t("Freelancer")}</span><p>{invoiceScope?.freelancerName}<small>({invoiceScope?.freelancerEmail})</small></p></li>}
                                        <li><span>{t("InvoiceNumber")}</span><p>{invoiceScope?.documentNo}</p></li>
                                        <li><span>{t("InvoiceDate")}</span><p>{convertToReadableDate(invoiceScope?.documentDate!, 'medium')}</p></li>
                                        <li><span>{t("DueDate")}</span><p>{convertToReadableDate(invoiceScope?.dueDate!, 'medium')}</p></li>
                                        <li><span>{t("Amount")}</span><p>{convertToReadableAmount(invoiceScope?.totalIncludingTaxes!, invoiceScope?.currencyCode!)}</p></li>
                                        {user?.role === USER_ROLE.Freelancer && <li><span>{t("DepositTo")}</span><p>{invoiceScope?.payoutBankAccountLabel} - {invoiceScope?.payoutCurrencyCode}</p></li>}
                                        <li><span className="text-capitalize">{t("Status")}</span><p><InvoiceStatusBadge invoiceStatus={invoiceScope?.invoiceStatus!} text={true}></InvoiceStatusBadge></p></li>
                                    </ul>
                                    {invoiceScopeLog?.some(s => s.status === INVOICE_STATUS.DeclineByBusiness) &&
                                        <div className="modal-note d-sm-block d-none">
                                            <h6>{t("CustomersRejectionReason")}</h6>
                                            <h6 className="text">{t(`${invoiceScopeLog.find(s => s.status === INVOICE_STATUS.DeclineByBusiness)?.invoiceStatusReason}`)}</h6>
                                            {invoiceScopeLog.find(s => s.status === INVOICE_STATUS.DeclineByBusiness)?.invoiceStatusReasonDescription &&
                                                <p>{invoiceScopeLog.find(s => s.status === INVOICE_STATUS.DeclineByBusiness)?.invoiceStatusReasonDescription}</p>
                                            }
                                        </div>
                                    }
                                    <ul className="invoice-action">
                                        {visibleItems.map((item, index) => (
                                            <li key={index} className="cursor-pointer" onClick={item.onClick}>
                                                {!showOnlyIcons && <Image src={dynamicImage(`svg/${item.svgName}.svg`)} alt={item.svgName} />}
                                                {t(item.label)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-buttons">
                            <SolidButton color="transparent" className="justify-content-center btn-gray" onClick={closeDialog}>{t("Close")}</SolidButton>
                            <SolidButton className='justify-content-center btn-solid' onClick={() => openInvoiceViewModal(id)}>
                                <Image src={dynamicImage("svg/preview-white.svg")} alt="eye" />
                                {t(user?.role === USER_ROLE.Business && invoiceScope?.invoiceStatus === INVOICE_STATUS.WaitingForApproval ? "ViewApproveInvoice" : "ViewInvoice")}
                            </SolidButton>
                            {invoiceScope?.id && user?.role === USER_ROLE.Business && invoiceScope?.invoiceStatus === INVOICE_STATUS.WaitingForPayment &&
                                <LinkButton className="justify-content-center btn-success pay-btn" onClick={closeDialog} to={ROUTES.Payment.replace(':id', invoiceScope?.id.toString())}>{t("Pay")}</LinkButton>
                            }

                            {invoiceScope?.id && user?.role === USER_ROLE.Business && invoiceScope?.invoiceStatus === INVOICE_STATUS.WaitingForPaymentAgreement &&
                                <LinkButton className="justify-content-center btn-success pay-btn" onClick={closeDialog} to={ROUTES.PaymentAgreement.replace(':id', invoiceScope?.id.toString())}>{t("ContinueToAgreement")}</LinkButton>
                            }
                        </div>
                    </div>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    )
}
export default InvoiceDetailCanvas


