import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { Image, SolidButton } from "../../../AbstractElement";
import { mutations, queries } from "../../../api";
import { useAuth } from "../../../Hooks";
import { ProjectDocument, USER_ROLE, INVITE_STATUS } from "../../../Types";
import { ProjectDocumentModalMode } from "../../../Types/CommonComponent";
import { dynamicImage } from "../../../utils";
import { useLayoutContext, useStateContext, useToast } from "../../../views";

const UploadDocCanvas = ({ projectId, action, documentId }: { projectId: string, action: ProjectDocumentModalMode, documentId?: string }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { showToast } = useToast();
    const { isMobile } = useLayoutContext();

    const { projectDocumentModal, toggleProjectDocumentModal } = useStateContext();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileName, setFileName] = useState(t("SelectFilesFromYourComputer"));
    const [file, setFile] = useState<File | null>(null);
    const [selectedFreelancers, setSelectedFreelancers] = useState<string[]>([]);

    const { data: project } = queries.useGetProject(projectId);
    const freelancers = user?.role === USER_ROLE.Business ? queries.useGetFreelancers({ projectId, inviteStatus: INVITE_STATUS.Accepted }).data : null;
    const { data: document } = queries.useGetProjectDocument(projectId, documentId, {
        onSuccess: (document: ProjectDocument) => {
            setFileName(document?.fileName);
            setSelectedFreelancers(document?.freelancers?.map((doc: any) => doc.freelancerId));
        },
    });

    const { mutate: uploadDocuments, isLoading: isLoadingUpload } = mutations.useUploadProjectDocument();
    const { mutate: shareFreelancers, isLoading: isLoadingShare } = mutations.useUpdateProjectDocument();

    const allowedFileTypes = [
        ".txt", ".xml", ".md", ".rtf", ".pdf", ".doc", ".docx", ".odt",
        ".xls", ".xlsx", ".ods", ".csv", ".ppt", ".pptx", ".odp", ".zip",
        ".gz", ".png", ".jpg", ".jpeg", ".gif", ".bmp", ".tiff", ".svg",
        ".webp", ".ico"
    ];

    const maxFileSize = 10 * 1024 * 1024; // 10 MB

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        const fileExtension = `.${selectedFile?.name?.split('.').pop().toLowerCase()}`;
        const isValidFileType = allowedFileTypes?.includes(fileExtension);
        const isValidFileSize = selectedFile.size <= maxFileSize;

        if (!isValidFileType) {
            showToast("error", "Invalid file type. Please upload a valid file.");
            return;
        }

        if (!isValidFileSize) {
            showToast("error", `File size exceeds the ${maxFileSize / 1024 / 1024}MB limit. Please upload a smaller file.`);
            return;
        }

        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        } else {
            setFileName(t("SelectFilesFromYourComputer"));
        }

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };


    const handleCheckboxChange = (id: string) => {
        setSelectedFreelancers((prevSelected = []) => {
            if (prevSelected?.includes(id)) {
                return prevSelected.filter((freelancerId) => freelancerId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    const handleUploadClick = () => {
        if (file && projectId) {
            uploadDocuments({ id: projectId, file: file }, {
                onSuccess: (res) => {
                    if (selectedFreelancers.length > 0) {
                        handleShareFreelancersClick(res?.id, () => {
                            setFile(null);
                            setFileName(t("SelectFilesFromYourComputer"));
                        })
                    }
                    else
                        toggleProjectDocumentModal();

                    showToast("success", "UploadDocCanvas.DocumentUploadedSuccessfully");
                },
            });
        }
    };


    // Function to share the document with selected freelancers
    const handleShareFreelancersClick = (id?: string, callback?: () => void) => {
        const value = {
            projectId: projectId,
            id: id ? id : document?.id,
            input: {
                fileName: fileName,
                freelancers: selectedFreelancers?.map(freelancerId => ({ freelancerId })),
            },
        };

        shareFreelancers(value, {
            onSuccess: (result) => {
                setSelectedFreelancers([])
                toggleProjectDocumentModal();
                callback?.();
                showToast("success", "Documents.DocumentSharedSuccessfully");
            },
        });
    };

    const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFileName(value);
    };



    return (
        <Offcanvas isOpen={projectDocumentModal.toggle} toggle={() => { toggleProjectDocumentModal(); setSelectedFreelancers([]) }} direction="end" className="theme-offcanvas-2 upload-document">
            <div className='theme-offcanvas-box '>
                <div className='w-100'>
                    <OffcanvasHeader toggle={() => { toggleProjectDocumentModal(); setSelectedFreelancers([]) }}>{action !== "edit" ? t("UploadDocumentsToProject") : t("EditDocument")}</OffcanvasHeader>
                    <Button className="btn-close" color="transparent" onClick={() => toggleProjectDocumentModal()}></Button>
                    <OffcanvasBody className="custom-scroll">
                        <Form className="form-mb-73" noValidate>
                            <div className="flex-grow-1">
                                <FormGroup className="mb-0">
                                    <ul className="invoice-data project-data">
                                        <li>
                                            <span>{t("ProjectName")}</span>
                                            <p>
                                                {project?.projectName}
                                                {
                                                    project?.projectCategory &&
                                                    <small>({project?.projectCategory})</small>
                                                }
                                            </p>
                                        </li>
                                        {user?.role === USER_ROLE.Freelancer &&
                                            <li>
                                                <span>{t("Client")}</span>
                                                <p>
                                                    {project?.companyName}
                                                    {
                                                        project?.companyEmail &&
                                                        <small>({project?.companyEmail})</small>
                                                    }
                                                </p>
                                            </li>
                                        }
                                    </ul>
                                </FormGroup>
                                {action !== "edit" &&
                                    <FormGroup className="document-detail mb-0">
                                        <div className="custom-file-input select-file w-100">
                                            <Image src={dynamicImage("svg/paper.svg")} alt="paper" />
                                            <input type="file" accept={allowedFileTypes.join()} ref={fileInputRef} className="form-control" id="file" style={{ display: "none" }} onChange={handleFileChange} />
                                            <button type="button" className="form-control text-start" onClick={() => fileInputRef?.current?.click()}>
                                                <span>{fileName}</span>
                                            </button>
                                            {file && (
                                                <button
                                                    type="button"
                                                    className="btn upload-close-btn btn-outline-secondary ms-2"
                                                    onClick={() => {
                                                        setFileName(t("SelectFilesFromYourComputer"));
                                                        setFile(null);
                                                    }}
                                                >
                                                </button>
                                            )}
                                        </div>
                                    </FormGroup>}
                                <FormGroup className="mb-0">
                                    {action === "edit" &&
                                        <div className="edit-document">
                                            <h5>{t("DocumentName")}</h5>
                                            <div className="d-flex align-items-center gap-2">
                                                <input type="text" className="form-control" name="file" value={fileName} onChange={handleTextInputChange} />
                                            </div>
                                        </div>
                                    }

                                </FormGroup>
                                <FormGroup className="freelancer-detail mb-0">
                                    {user?.role === USER_ROLE.Business &&
                                        <>
                                            <h5>{action !== "edit" ? t("ShareWithFreelancers") : t("FreelancersWhoCanAccess")}</h5>
                                            <ul className="freelancer-list"> {freelancers?.items?.map((freelancer, index) => (
                                                <li key={index}>
                                                    <div className="form-check">
                                                        {freelancer?.fullName ?
                                                            (<div className="initial-letter d-sm-flex d-none">
                                                                <span>{freelancer?.fullName.split(' ')?.map((letter: string) => letter.charAt(0).toUpperCase())?.join('')?.slice(0, 2)}</span>
                                                            </div>) :
                                                            (<Image src={dynamicImage("icon_user.png")} className="profile-image" alt="logo" />)
                                                        }
                                                        <p>
                                                            {freelancer?.fullName}
                                                            <span>({freelancer?.email})</span>
                                                        </p>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            onChange={() => handleCheckboxChange(freelancer?.id)}
                                                            checked={selectedFreelancers?.map((freelancerId: any) => freelancerId)?.includes(freelancer?.id)}
                                                        />
                                                    </div>
                                                </li>))}
                                            </ul>
                                        </>
                                    }
                                </FormGroup>
                            </div>
                        </Form>
                    </OffcanvasBody>
                    <div className="offcanvas-footer">
                        <SolidButton className="btn-gray justify-content-center" onClick={toggleProjectDocumentModal}>{t("Cancel")}</SolidButton>
                        <SolidButton
                            className="btn-secondary-color-3 justify-content-center btn-solid"
                            loading={isLoadingUpload || isLoadingShare}
                            onClick={action !== "edit" ? handleUploadClick : () => handleShareFreelancersClick(document?.id)}
                            disabled={action === "edit" ? !fileName : !file}
                        >
                            {action !== "edit" ? (
                                <>
                                    <Image src={dynamicImage("svg/upload.svg")} alt="arrow" className="d-flex" />
                                    {isMobile ? t("Upload") : t("UploadDocument")}
                                </>
                            ) : t("Update")}
                        </SolidButton>
                    </div>
                </div>
            </div>
        </Offcanvas>
    );
};
export default UploadDocCanvas;  