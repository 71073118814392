import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Image, InvoiceStatusBadge, LinkButton } from '../../../AbstractElement';
import { mutations, queries } from '../../../api';
import { CommonSelect, CommonTable, ConfirmationDialog } from '../../../CommonComponent';
import { ROUTES } from '../../../Constants';
import { useAuth, useCoreData, useDropdown, useTableFilterHelper } from '../../../Hooks';
import { INVOICE_STATUS, InvoiceScope, USER_ROLE } from '../../../Types';
import { Action, COLUMN_TYPE, TableClickedAction, TableConfig } from '../../../Types/CommonComponent';
import { dynamicImage } from '../../../utils';
import { useLayoutContext, useStateContext, useToast } from '../../../views';

const AllInvoices = ({ archived }: { archived: boolean }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { showToast } = useToast()
    const { isMobile, toggleState } = useLayoutContext()
    const { openInvoiceDetailModal, openInvoiceViewModal } = useStateContext();
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const { mutate: refetchSendReminder } = mutations.useSendReminderInvoice();
    const { mutate: refetchArchive } = mutations.useArchiveInvoiceScope();
    const { mutate: refetchDuplicate } = mutations.useDuplicateInvoice();

    const invoiceSearchDropdown = useDropdown<HTMLDivElement>(toggleState);
    const searchBar = document.getElementById(`invoiceSearch-${archived ? 'archived' : 'all'}`)

    const { mutate: refetchDownload } = mutations.useDownloadInvoiceScopePdf();

    const { projectCategoriesOptions, invoiceStatusOptions } = useCoreData({ loadProjectCategories: true });

    const { pageNumber, pageSize, searchTerm, params, goToPage, handleSetPageSize, handleSetSearch, toggleSortType, handleSetSortBy } = useTableFilterHelper({ search: "", archived });

    const { data: invoices, refetch: refetchInvoices } = queries.useGetInvoiceScopes(selectedOptions.length ? { ...params, invoiceStatuses: selectedOptions } : params);

    // Table configuration state  
    const tableConfig = useMemo<TableConfig<InvoiceScope>>(() => ({
        columns: [
            {
                title: "Project", dataField: [{ field: "projectName", class: '', type: '' }, {
                    field: "projectCategoryCode", class: '', type: '', renderer: (data: InvoiceScope) => {
                        const projectLabel = projectCategoriesOptions?.find(f => f.value === data.projectCategoryCode)?.label ?? "";
                        return <>{projectLabel && <span>({projectLabel})</span>}</>;
                    }
                }], class: '', sortable: true, sortField: 'projectName', sortDirection: 'desc'
            },
            user?.role === USER_ROLE.Freelancer
                ? {
                    title: "Client", class: 'name-box', dataField: [{ field: "companyName", class: 'top', type: '' }, {
                        field: "companyEmail", class: '', type: '', renderer: (data: InvoiceScope) => {
                            return <>{data?.companyEmail && <span>({data?.companyEmail})</span>}</>;
                        }
                    }], sortable: true, sortField: 'companyName', sortDirection: 'desc'
                }
                : {
                    title: "Freelancer", class: 'name-box', dataField: [{ field: "freelancerName", class: 'top', type: '', renderer: (data: InvoiceScope) => { return <span className="text">{`${data.companyName}`}</span> } }, {
                        field: "freelancerEmail", class: '', type: '', renderer: (data: InvoiceScope) => {
                            return <>{data?.freelancerEmail && <span>({data?.freelancerEmail})</span>}</>;
                        }
                    }], sortable: true, sortField: 'freelancerName', sortDirection: 'desc'
                },
            { title: "CreationDate", dataField: [{ field: "createdAt", class: '', type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'createdAt', sortDirection: 'desc', hidden: true },
            {
                title: "InvoiceNumber", class: 'invoice-number', dataField: [{ field: "documentNo", class: 'top hover-link', type: '', renderer: (data: InvoiceScope) => { return <span className="text top hover-link">{`#${data.documentNo}`}</span> } }], sortable: true, sortField: 'documentNo', sortDirection: 'desc'
            },

            { title: "InvoiceDate", dataField: [{ field: "documentDate", class: 'text', type: COLUMN_TYPE.Date, params: { format: "medium" } }], sortable: true, sortField: 'documentDate', sortDirection: 'desc' },
            { title: "DueDate", dataField: [{ field: "dueDate", class: '', type: COLUMN_TYPE.Date, params: { format: "medium" } }], sortable: true, sortField: 'dueDate', sortDirection: 'desc', hidden: true },
            { title: "Status", class: 'status-table', dataField: [{ field: "invoiceStatus", class: '', type: '', renderer: (data: InvoiceScope) => { return <InvoiceStatusBadge invoiceStatus={data.invoiceStatus} />; } }, { field: "modifiedAt", class: 'badge-text', type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'invoiceStatus', sortDirection: 'desc' },
            { title: "InvoiceAmount", dataField: [{ field: "total", class: '', type: 'amount', params: { currency: "currencyCode" } }], sortable: true, sortField: 'total', sortDirection: 'desc', hidden: true },
            { title: "TotalTax", dataField: [{ field: "vatAmount", class: '', type: 'amount', params: { currency: "currencyCode" } }], sortable: true, sortField: 'vatAmount', sortDirection: 'desc', hidden: true },
            { title: "Amount", class: 'amount-header', dataField: [{ field: "totalIncludingTaxes", class: 'amount', type: 'amount', params: { currency: "currencyCode" } }], sortable: true, sortField: 'totalIncludingTaxes', sortDirection: 'desc' },
        ],
        actionsDropDown:
            [
                ...(user?.role === USER_ROLE.Business ?
                    [
                        { label: "Approve", actionToPerform: "approve", conditional: { field: 'invoiceStatus', condition: 'include', conditionValue: [INVOICE_STATUS.WaitingForApproval] } } as Action<InvoiceScope>,
                        { label: "Pay", getNavigateUrl: (data: InvoiceScope) => `${ROUTES.Payment.replace(':id', data.id.toString())}`, conditional: { field: 'invoiceStatus', condition: 'include', conditionValue: [INVOICE_STATUS.WaitingForPayment] } } as Action<InvoiceScope>,
                        { label: "Continue The Process", getNavigateUrl: (data: InvoiceScope) => `${ROUTES.PaymentAgreement.replace(':id', data.id.toString())}`, conditional: { field: 'invoiceStatus', condition: 'include', conditionValue: [INVOICE_STATUS.WaitingForPaymentAgreement] } } as Action<InvoiceScope>
                    ] : []),

                { label: "InvoiceDetails", actionToPerform: "invoiceDetails" },
                { label: "ProjectDetails", getNavigateUrl: (data: InvoiceScope) => `${ROUTES.ProjectDetails.replace(':id', data.projectId.toString())}` },
                { label: "View", actionToPerform: "view" },
                { label: "Download", actionToPerform: "download" },
                ...(user?.role === USER_ROLE.Freelancer
                    ? [
                        { label: "Duplicate", actionToPerform: "duplicate" } as Action<InvoiceScope>,
                    ]
                    : []),
                ...(user?.role === USER_ROLE.Freelancer ?
                    [{
                        label: "SendReminder", actionToPerform: "sendReminder",
                        conditional: {
                            field: 'invoiceStatus' as keyof InvoiceScope,
                            condition: 'include',
                            conditionValue: ['WaitingForApproval', 'WaitingForPayment']
                        }
                    } as Action<InvoiceScope>] : []),
                { label: !archived ? "Archive" : "Unarchive", actionToPerform: "archive" },
            ],
        data: invoices?.items ?? [],
        total: invoices?.totalResults ?? 0,
        isColumnChooser: true,
    }), [invoices, selectedOptions, projectCategoriesOptions]);

    const handleActionPerform = (action: TableClickedAction<InvoiceScope>) => {
        switch (action.actionToPerform) {
            case 'invoiceDetails':
                openInvoiceDetailModal(action.data.id);
                break;
            case 'download':
                refetchDownload({ id: action.data.id, fileName: `Invoice_${action.data.id}.pdf` }, {
                    onSuccess: () => showToast("success", "InvoiceDetailCanvas.DownloadedInvoice")
                });
                break;
            case 'sendReminder':
                ConfirmationDialog({
                    title: "Send reminder",
                    text: "Are you sure you want to send a reminder for this invoice?",
                    confirmButtonText: "Send reminder",
                    confirmBtnClass: "orange",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            refetchSendReminder(action.data.id, {
                                onSuccess: () => {
                                    showToast("success", "InvoiceScope.SendReminderSuccessfully");
                                },
                            });
                        }
                    }
                });

                break;
            case 'archive':
                const isArchived = action.data.archived;
                ConfirmationDialog({
                    title: isArchived ? "Unarchive invoice" : "Archive invoice",
                    text: isArchived ? "Are you sure you want to unarchive this invoice?" : "Are you sure you want to archive this invoice?",
                    confirmButtonText: isArchived ? "Unarchive invoice" : "Archive invoice",
                    confirmBtnClass: isArchived ? "orange" : "black",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            refetchArchive({ id: action.data.id, archived: !isArchived }, {
                                onSuccess: () => {
                                    refetchInvoices();
                                    if (!isArchived) {
                                        showToast("delete", 'InvoiceDetailCanvas.InvoiceArchivedSuccessfully');
                                    }
                                    else {
                                        showToast("success", 'InvoiceDetailCanvas.InvoiceUnarchivedSuccessfully');
                                    }
                                },
                            });
                        }
                    }
                });

                break;
            case 'view':
            case 'approve':
                openInvoiceViewModal(action.data.id);
                break;
            case 'duplicate':
                ConfirmationDialog({
                    title: "Duplicate Invoice",
                    text: "Are you sure you want to duplicate this invoice?",
                    confirmButtonText: "Duplicate",
                    confirmBtnClass: "orange",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            refetchDuplicate(action.data.id, {
                                onSuccess: (response) => {
                                    const newInvoiceId = response?.invoiceId;
                                    if (newInvoiceId) {
                                        navigate(`${ROUTES.InvoiceEdit.replace(':id', newInvoiceId)}`);
                                    } else {
                                        console.error('No invoice ID found in the duplicate response');
                                    }
                                },
                            });
                        }
                    }
                });
                break;
        }
    }

    // Calculate the invoice status
    const statusDisplayText = selectedOptions.length === 1
        ? invoiceStatusOptions.find(option => option.value === selectedOptions[0])?.label || t("FilterByStatus")
        : selectedOptions.length > 1
            ? `${selectedOptions.length} ${t("Selected")}`
            : t("FilterByStatus");

    return (
        <>
            <div className="invoice-head d-flex flex-sm-row flex-column align-items-sm-center align-items-start gap-sm-4 gap-0 justify-content-between w-100">
                {!isMobile && <div className="total-data">
                    <span>{invoices?.totalResults} <small className="d-sm-flex d-none">{t("Total")}</small></span>
                </div>
                }
                <div className="tab-right-filter">
                    <div className="search-input-form" ref={invoiceSearchDropdown.ref}>
                        <div className="mobile-search-img d-lg-none d-inline-flex" onClick={invoiceSearchDropdown.toggle}>
                            <Image src={dynamicImage("svg/search.svg")} alt="search" />
                        </div>
                        {(!isMobile || invoiceSearchDropdown.isOpen) && (
                            <div className={`search-input d-lg-inline-flex d-none ${invoiceSearchDropdown.isOpen ? "open" : ""}`} id={`invoiceSearch-${archived ? 'archived' : 'all'}`}>
                                <input onFocus={() => { searchBar?.classList.add('full-search') }} className="form-control" placeholder={t("SearchByProjectName")} value={searchTerm} onChange={(e) => handleSetSearch(e.target.value)} autoComplete="off" />
                                <Image src={dynamicImage("svg/search.svg")} alt="search" className="search-img" />
                                <Image src={dynamicImage("svg/search-red.svg")} alt="search" className="search-img-hover" />
                                {searchTerm.length > 0 && (<div className="close-img" onClick={() => { handleSetSearch(""); searchBar?.classList.remove('full-search') }}><Image src={dynamicImage("svg/close-red.svg")} alt="close" /></div>)}
                            </div>)}
                    </div>
                    <CommonSelect
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        options={invoiceStatusOptions}
                        placeholder={statusDisplayText}
                        hidePlaceholder={false}
                        inputSearch={false}
                        multiSelect={true}
                    />
                    {
                        user?.role === USER_ROLE.Freelancer &&
                        <LinkButton className="text-nowrap btn-solid" to={ROUTES.Invoice}>
                            <Image src={dynamicImage("svg/plus-white.svg")} alt="arrow" />
                            <span className="text-white d-xxl-inline-flex d-none">
                                {t("NewInvoice")}
                            </span>
                        </LinkButton>
                    }
                </div>
            </div>
            <div className="project-all-invoice-table archived-table">
                {tableConfig.total > 0 ? (
                    <CommonTable tableConfiguration={tableConfig} currentPage={pageNumber} itemsPerPage={pageSize} goToPage={goToPage} handleSetPageSize={handleSetPageSize} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} onActionPerform={handleActionPerform} />
                ) : (
                    <div className="table-no-data">
                        <Image src={dynamicImage("svg/no-data/no-data-project-invoice.svg")} alt="no data" className="img-fluid" />
                        <h5 dangerouslySetInnerHTML={{
                            __html: t(
                                user?.role === USER_ROLE.Freelancer && !archived ? "NoData.FreelancerNoInvoiceInGeneralList" :
                                    user?.role === USER_ROLE.Freelancer && archived ? "NoData.FreelancerNoArchivedInvoiceInGeneralList" :
                                        user?.role === USER_ROLE.Business && !archived ? "NoData.BusinessNoInvoiceInGeneralList" :
                                            user?.role === USER_ROLE.Business && archived ? "NoData.BusinessNoArchivedInvoiceInGeneralList"
                                                : "")
                        }}></h5>
                    </div>
                )}
            </div>
        </>
    )
}
export default AllInvoices;