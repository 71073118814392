import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Col, Form, FormGroup, Row } from 'reactstrap'
import { Image, SolidButton } from '../../../../AbstractElement'
import { mutations, queries } from '../../../../api'
import { ROUTES } from '../../../../Constants'
import { useDebounce } from '../../../../Hooks'
import { SERVICE_FEE_PAYER, ServiceFeePayer, SummaryProps } from '../../../../Types'
import { convertToReadableAmount, dynamicImage, formatPercentage, getMessages } from '../../../../utils'
import useToastMessage from '../../../../utils/UseToast'
import { useStateContext } from '../../../../views'
import { useForm } from 'react-hook-form'
import { ConfirmationDialog } from '../../../../CommonComponent'

const Summary: React.FC<SummaryProps> = ({ updateInvoiceInput, setUpdateInvoiceInput, navActive, setNavActive, calculateAmount, setPromoCode, promoCode, loading, refetchCalculate, updateInvoice, invoiceCreate }) => {
    const { t } = useTranslation();
    const [promoErr, showPromoError] = useToastMessage();
    const navigate = useNavigate();

    const { openInvoiceDraftViewModal, toggleAddNewCustomer } = useStateContext();

    const [notes, setNotes] = useState('');
    const [promoCodeInput, setPromoCodeInput] = useState('');

    const notesInput = useDebounce(notes, 500);
    const [serviceFee, setServiceFee] = useState<number | null>(null);
    const [selectedOption, setSelectedOption] = useState<ServiceFeePayer>(updateInvoiceInput?.serviceFeePayer ? updateInvoiceInput?.serviceFeePayer : SERVICE_FEE_PAYER.Freelancer);

    const { mutate: changeService } = mutations.useChangeServiceFeePayer();
    const { mutate: checkPromotionCode } = mutations.useCheckPromotionCode();
    const { mutate: sendInvoiceDetails } = mutations.useSendInvoiceDraft();
    const { mutate: updateInvoiceDraftNotes } = mutations.useUpdateInvoiceDraftNotes()
    const { data: projectData } = queries.useGetProject(updateInvoiceInput?.projectId!)
    const { data: clientInfo } = queries.useGetCustomer(updateInvoiceInput.customerId!);
    const [promoError, setPromoError] = useState(false);

    const { handleSubmit } = useForm();

    useEffect(() => {
        if ((notesInput ?? '') != (updateInvoiceInput.notes ?? '')) {
            updateInvoiceDraftNotes({ id: updateInvoiceInput.id!, notes: notes })
        }
    }, [notesInput, updateInvoiceDraftNotes]);

    useEffect(() => {
        if (updateInvoiceInput?.notes !== notes) {
            setNotes(updateInvoiceInput?.notes || '');
        }
    }, [updateInvoiceInput?.notes]);

    const applyPromoCode = async () => {
        checkPromotionCode({ id: updateInvoiceInput.id, input: { promotionCode: promoCodeInput } }, {
            onSuccess: (data) => {
                setServiceFee(data * 100);
                setPromoError(false);
                setPromoCode(promoCodeInput);
            },
            onError: (err) => {
                setPromoError(true);
                showPromoError("error", getMessages(err));
            },
        });
    };

    const undoPromoCode = async () => {
        setPromoCode('');
    };

    const handleOptionChange = (payer: ServiceFeePayer) => {
        setSelectedOption(payer);
        changeService({ id: updateInvoiceInput.id!, input: { serviceFeePayer: payer } }, {
            onSuccess: () => {
                refetchCalculate();
            }
        })
    };

    const handleFinalInvoice = () => {
        ConfirmationDialog({
            title: "Finalize invoice",
            text: "Are you sure you want to finalize this invoice? Once finalized, it cannot be edited.",
            confirmButtonText: "Finalize invoice",
            confirmBtnClass: "orange",
            showCheckbox: true,
            onConfirm: (isConfirmed, checkValue) => {
                if (isConfirmed) {
                    if (checkValue) {
                        sendInvoiceDetails({ invoiceId: updateInvoiceInput.id!, promoCode }, {
                            onSuccess: async (data) => {
                                navigate(`${ROUTES.InvoiceFinalizeDetail.replace(":id", data.invoiceScopeId)}`)
                                setUpdateInvoiceInput({});
                            },
                            onError: (error) => {
                                if (Array.isArray(error.data)) {
                                    if (error.data[0].code && (error.data[0].code.startsWith("Customer") || error.data[0].code === "Invoice.AlreadySavedAsFreelancerUser")) {
                                        toggleAddNewCustomer(updateInvoiceInput.customerId!);
                                    }
                                    else if (error.data[0].code === "Invoice.MustUserVerification" || error.data[0].code === "Invoice.MustAproveAgreement") {
                                        const targetTab = error.data[0].code === "Invoice.MustUserVerification" ? "verification" : "agreements";

                                        ConfirmationDialog({
                                            title: "Action required to finalize invoice",
                                            text: targetTab === "verification"
                                                ? "To finalize this invoice, please complete the user verification process."
                                                : "To finalize this invoice, you need to review and approve the required agreements.",
                                            confirmButtonText: t("Suggestion.CompleteProfile"),
                                            confirmBtnClass: "orange",
                                            onConfirm: (isConfirmed) => {
                                                if (isConfirmed) {
                                                    navigate(`${ROUTES.FreelancerProfile}?tab=${targetTab}`);
                                                }
                                            }
                                        });

                                    }
                                }
                            }
                        });
                    }

                }
            }
        });

    }

    const draftInvoice = async () => {
        // Update the invoice and handle success
        updateInvoice(invoiceCreate, updateInvoiceInput, (response) => {
            undoPromoCode()
            // After refetching, navigate to the draft tab
            setUpdateInvoiceInput({})
            navigate(`${ROUTES.Invoices}?tab=draft`);
        });
    };

    const handlePrevious = () => {
        if (navActive > 1) {
            setNavActive(navActive - 1);
        }
    };

    return (
        <>
            <div className={`summary-review d-lg-flex d-none ${loading ? 'disabled' : ''}`} onClick={() => { if (!loading) openInvoiceDraftViewModal(updateInvoiceInput.id!) }}>
                <Image src={dynamicImage("svg/icon_preview.svg")} alt="arrow" />
                {t("Preview")}
            </div>
            <Row className="h-100">
                <Col xs="12">
                    <div className="invoice-main-content custom-scrollbar">
                        <Form noValidate onSubmit={handleSubmit(handleFinalInvoice)} className="h-100">
                            <div className="h-100 d-flex flex-column justify-content-between gap-2">
                                <div className="invoice-height custom-scroll">
                                    <Row className="g-sm-4 g-3">
                                        <Col xs="12">
                                            <FormGroup className='mb-0'>
                                                <div className="summary-note">
                                                    <Row className="g-4">
                                                        <Col lg="6">
                                                            <ul>
                                                                <li>
                                                                    <span>{t("InvoiceNumber")}</span>
                                                                    <p className="digit">{updateInvoiceInput.documentNo}</p>
                                                                </li>
                                                                <li>
                                                                    <span>{t("ProjectName")}</span>
                                                                    <p>
                                                                        <strong>{projectData?.projectName}</strong> {projectData?.projectCategory && `(${projectData?.projectCategory})`}
                                                                    </p>
                                                                </li>
                                                                {
                                                                    projectData?.projectDetails &&
                                                                    <li>
                                                                        <span>{t("ProjectDetails")}</span>
                                                                        <p>{projectData?.projectDetails}</p>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </Col>


                                                        <Col lg="6">
                                                            <ul>
                                                                <li>
                                                                    <span>{t("Client")}</span>
                                                                    <p>
                                                                        <strong>{updateInvoiceInput.customerName}</strong> {updateInvoiceInput.customerEmail && `(${updateInvoiceInput.customerEmail})`}
                                                                    </p>
                                                                </li>
                                                                {
                                                                    clientInfo?.addressLine1 &&
                                                                    <li>
                                                                        <span>{t("Address")}</span>
                                                                        <p>{clientInfo?.addressLine1}, {clientInfo?.city}, {clientInfo?.state}, {clientInfo?.countryName} </p>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <Row className="g-2">
                                                <Col xl={calculateAmount && calculateAmount.vatAmount > 0 ? "8" : "12"} lg={calculateAmount && calculateAmount.vatAmount > 0 ? "7" : "12"}>
                                                    <label>{t("TotalAmount")}</label>
                                                    <FormGroup className='mb-0'>
                                                        <input className="form-control text-end opacity-100 digit" id="totalAmount" type="text" value={convertToReadableAmount(calculateAmount?.totalIncludingTaxes!, calculateAmount?.currencyCode!)} readOnly />
                                                    </FormGroup>
                                                </Col>
                                                {calculateAmount && calculateAmount.vatAmount > 0 &&
                                                    <Col xl="4" lg="5">
                                                        <label>{t("TotalTax")}</label>
                                                        <FormGroup className='mb-0'>
                                                            <input className="form-control text-end opacity-100 digit" id="totalTax" type="text" value={convertToReadableAmount(calculateAmount?.vatAmount!, calculateAmount?.currencyCode!)} readOnly />
                                                        </FormGroup>
                                                    </Col>
                                                }
                                            </Row>
                                        </Col>
                                        <Col md="6">
                                            <Row className="g-2">
                                                <Col xxl="8" xl="7" lg="6">
                                                    <label>{t('ServiceFeeValue', { fee: formatPercentage(calculateAmount?.serviceFeeRate! * 100) })}</label>
                                                    <FormGroup className='mb-0'>
                                                        <input
                                                            className="form-control text-end opacity-100 digit"
                                                            id="serviceFee"
                                                            placeholder=""
                                                            type="text"
                                                            value={convertToReadableAmount(calculateAmount?.serviceFeeAmount!, calculateAmount?.currencyCode!)}
                                                            readOnly
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xxl="4" xl="5" lg="6">
                                                    <label>{t("PaidBy")}</label>
                                                    <FormGroup className='mb-0'>
                                                        <div className="switch-field form-control">
                                                            <input
                                                                type="radio"
                                                                name="service-radio"
                                                                id="Freelancer"
                                                                checked={selectedOption === SERVICE_FEE_PAYER.Freelancer}
                                                                onChange={() => handleOptionChange(SERVICE_FEE_PAYER.Freelancer)}
                                                            />
                                                            <label htmlFor="Freelancer">{t("Me")}</label>
                                                            <input
                                                                type="radio"
                                                                name="service-radio"
                                                                id="Company"
                                                                checked={selectedOption === SERVICE_FEE_PAYER.Company}
                                                                onChange={() => handleOptionChange(SERVICE_FEE_PAYER.Company)}
                                                            />
                                                            <label htmlFor="Company">{t("Client")}</label>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg="6">
                                            <div className="promocode-box">
                                                <label>{t("PromotionCode")}</label>
                                                <FormGroup className="promocode-input-box mb-0">
                                                    <div className="promocode">
                                                        <input
                                                            type="text"
                                                            placeholder={t('EnterPromoCode')}
                                                            value={promoCodeInput}
                                                            onChange={(e) => setPromoCodeInput(e.target.value)}
                                                            className={`promocode-input ${promoError ? 'error-code shake' : ''}`}
                                                            disabled={!!promoCode}
                                                            autoComplete="off"
                                                            onAnimationEnd={() => setPromoError(false)}  // Remove shake class when animation ends
                                                        />
                                                        <div className="promo-service-box">
                                                            {!!promoCode ? (
                                                                <>
                                                                    <p className="d-sm-flex d-none promo-text">
                                                                        {t("ServiceFeeApplied", { value: serviceFee ?? 0 })}
                                                                    </p>
                                                                    <div className='promo-close' onClick={undoPromoCode}>
                                                                        <Image src={dynamicImage("svg/close.svg")} alt="link" className="close" />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <SolidButton className="btn-secondary-3 btn-solid" onClick={applyPromoCode}>
                                                                    {t("Apply")}
                                                                </SolidButton>
                                                            )}
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <label>{t(calculateAmount?.estimatedPayoutCurrency ? "EstimatedPayoutAmount" : "FinalPayout")}</label>
                                            <FormGroup className='mb-0'>
                                                <input className="form-control text-end opacity-100 digit" id="payout-amount" placeholder="" type="text" value={calculateAmount?.estimatedPayoutCurrency ? convertToReadableAmount(calculateAmount?.estimatedPayoutAmount!, calculateAmount?.estimatedPayoutCurrency!) : convertToReadableAmount(calculateAmount?.amount!, calculateAmount?.currencyCode!)} readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" xl="6" className='ms-auto'>
                                            <label>{t("Notes")}</label>
                                            <FormGroup className='mb-0'>
                                                <textarea name="" rows={4} className="form-control min-h-96" id="note" value={notes} onChange={(e) => setNotes(e.target.value)} placeholder={t("EnterYourNotes")} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="footer-buttons-main summary-footer d-flex align-items-center justify-content-between">
                                    <div className="responsive-note">
                                        <FormGroup className="form-group mb-0">
                                            {/* 
                                            //TODO:Checkbox moved to confirmation dialog
                                            <div className="form-check align-items-baseline">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="term-check"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor="term-check" dangerouslySetInnerHTML={{
                                                    __html: t("IReadAndAgreeToTheServiceContractTerms", {
                                                        serviceContract: SITE_SETTINGS.SERVICE_CONTRACT
                                                    })
                                                }}></label>
                                            </div> */}
                                        </FormGroup>
                                    </div>
                                    <div className="footer-buttons">
                                        <SolidButton className="btn-gray" onClick={handlePrevious}>
                                            <Image src={dynamicImage("svg/left-arrow.svg")} alt="arrow" />
                                            {t("Previous")}
                                        </SolidButton>
                                        <SolidButton className="btn-secondary-2 justify-content-start save-btn" loading={loading} onClick={draftInvoice}>
                                            <Image src={dynamicImage("svg/invoice-white.svg")} alt="arrow" className="next" />
                                            {t("SaveAsDraft")}
                                        </SolidButton>
                                        <SolidButton className="btn-success justify-content-center" type="submit" loading={loading}>
                                            <Image src={dynamicImage("svg/upload.svg")} alt="arrow" className="next" />
                                            {t("FinalizeInvoice")}
                                        </SolidButton>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default Summary