import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { ClientDetailsProps } from "../../../Types";
import { Image, SolidButton } from "../../../AbstractElement";
import { queries } from "../../../api";
import { useTranslation } from "react-i18next";
import { useLayoutContext, useLoader, useStateContext } from "../../../views";
import { ClientInformation, ClientProjectsTable, LayoutLoader } from "../../../CommonComponent";
import { dynamicImage } from "../../../utils";

const ClientDetails: React.FC<ClientDetailsProps> = ({ customerId }) => {
  const { t } = useTranslation();
  const { toggleAddNewCustomer, clientDetails, toggleClientDetails } = useStateContext();
  const { isLoading } = useLoader();
  const { data: customerData } = queries.useGetCustomer(customerId);
  const { position } = useLayoutContext()
  const onClose = () => {
    toggleClientDetails();
  }

  return (
    <Offcanvas isOpen={clientDetails.toggle} toggle={onClose} direction={position} className="theme-offcanvas-2">
      <div className="theme-offcanvas-box">
        {isLoading && <LayoutLoader />}
        <div className="w-100">
          <OffcanvasHeader toggle={() => toggleClientDetails()}>{t("ClientDetails")}</OffcanvasHeader>
          <Button className="btn-close d-md-block d-none" onClick={onClose} color="transparent"></Button>
          <OffcanvasBody className="custom-scroll">
            <div className="w-100">
              <div className="client-offcanvas-box">
                {customerData && customerData?.editable && (
                  <span
                    className="client-edit-icon"
                    onClick={() => {
                      toggleAddNewCustomer(customerId);
                    }}
                  >
                    <Image src={dynamicImage("svg/edit.svg")} className="edit-icon" alt="Edit" />
                  </span>
                )}
                <ClientInformation clientData={customerData} />
              </div>
              <h5>{t("ProjectsWithThisClient")}</h5>
              <ClientProjectsTable clientId={customerData?.id} />
            </div>
            <div className="flex-grow-1">
              <div className="client-custom-height">
              </div>
            </div>
          </OffcanvasBody>
          <div className="offcanvas-footer">
            <SolidButton className="btn-gray w-100 justify-content-center" onClick={onClose}>{t("Close")}</SolidButton>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};

export default ClientDetails;
