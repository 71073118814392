import React from "react";
import { useTranslation } from "react-i18next";
import { Image, LinkButton } from "../../../AbstractElement";
import { ImageSectionProp, USER_ROLE } from "../../../Types/Auth/Auth";
import { convertToReadableDate, dynamicImage } from "../../../utils";
import { Link } from "react-router-dom";

const ImageSection: React.FC<ImageSectionProp> = ({ userName, bannerClass }) => {
  const { t } = useTranslation();
  return (
    <div className="d-xl-flex d-none auth-right col-xxl-7 col-xl-6" >
      <div className="image-main">
        <div className={`image-contain ${bannerClass ? bannerClass : ""}`}>
          {userName === USER_ROLE.Business && <h2 className="image-title">{t("Login.BusinessImageMessage")}</h2>}
          {userName === USER_ROLE.Freelancer && <h2 className="image-title">{t("Login.FreelancerImageMessage")}</h2>}
          <Image src={dynamicImage(`svg/banner/${userName === USER_ROLE.Freelancer ? "freelencer" : "business"}-profile.png`)} alt="profile" className="img-fluid freelance-profile" />
          <div className="auth-content">
            <Image src={dynamicImage("svg/quote.svg")} className="quote" alt="quotes" />
            <p>{t("Login.TrustpilotMessage")}</p>
            <div className="review">
              <ul className="rate">
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
              </ul>
              <span className="review-time">{convertToReadableDate("2024-07-23", "medium")}</span>
            </div>
            <div className="divider"></div>
            <Link className="trusted" to={"https://www.trustpilot.com/reviews/669f5b5af3b4765ec0de5f79"}>
              <Image src={dynamicImage("svg/star-fill.svg")} alt="fill-star" />
              {t("Login.Trustpilot")}
            </Link>
          </div>
          <span className="help-div" >
            <Image src={dynamicImage("svg/icon_help1.svg")} alt="fill-star" onClick={() => { (window as any)?.Beacon('open'); }} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
