import { InvoiceStatus } from "./InvoiceStatus";

export interface Freelancer {
    id: string;
    fullName: string;
    email: string;
    professionCode: string;
    otherProfessionName: string;
    createdAt: string;
    archived: boolean;
}

export interface ProjectFreelancer extends Freelancer {
    projectId: string;
    status: InviteStatus;
    declineReason: string;
}

export interface ProjectFreelancerItem extends ProjectFreelancer {
    lastInvoiceStatus: InvoiceStatus;
    lastInvoiceModifiedDate: string;
    awaitingInvoiceStatus: InvoiceStatus;
    awaitingInvoiceModifiedDate: string;
}

export interface FreelancerDetailsProps {
    freelancerId: string | null;
}

export const INVITE_STATUS = {
    NA: "NA",
    Invited: "Invited",
    Declined: "Declined",
    Accepted: "Accepted"
};

export type InviteStatus = ObjectValues<typeof INVITE_STATUS>;

export type FreelancerFormInput = {
    email: string;
    professionCode?: string;
    fullName: string;
    projectId: string;
    otherProfessionName?: string;
}

export type FreelancerUpdateFormInput = {
    fullName?: string;
    professionCode?: string;
    otherProfessionName?: string;
}
