import React from 'react'
import { InvoiceDetailsProp } from '../../../../Types/InvoiceFast';
import { convertToReadableAmount, formatPercentage } from '../../../../utils';
import { useTranslation } from 'react-i18next';

export const InvoiceDetails: React.FC<InvoiceDetailsProp> = ({ invoiceDetails }) => {

    const { t } = useTranslation();

    if (!invoiceDetails) return null;

    return (
        <ul className="invoice-data">
            <li><span>{t("Project")}</span><p>{invoiceDetails.projectName} {invoiceDetails.projectCategory && <small>({invoiceDetails.projectCategory})</small>}</p></li>
            <li><span>{t("InvoiceReceiver")}</span><p>{invoiceDetails.companyName}</p></li>
            <li><span>{t("Freelancer")}</span><p>{invoiceDetails.freelancerName}</p></li>
            <li><span>{t("InvoiceAmount")}</span><p>{convertToReadableAmount(invoiceDetails.total, invoiceDetails.currencyCode)}</p></li>
            <li><span>{t("VATAmount", { vatAmount: formatPercentage(invoiceDetails.vatRate * 100) })}</span><p>{convertToReadableAmount(invoiceDetails.vatAmount, invoiceDetails.currencyCode)}</p></li>
            <li><span>{t("TotalAmount")}</span><p>{convertToReadableAmount(invoiceDetails.totalIncludingTaxes, invoiceDetails.currencyCode)}</p></li>
            <li><span>{t("Notes")}</span><p>{invoiceDetails.notes ?? ""}</p></li>
        </ul>
    )
}

