import { useTranslation } from "react-i18next"
import { useLayoutContext, useStateContext } from "../../../../../views"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { SolidButton } from "../../../../../AbstractElement"
import { useEffect, useState } from "react"
import { OTPInput } from "../../../../Auth/Widgets"
import { SITE_SETTINGS } from "../../../../../Constants"
import { mutations } from "../../../../../api"

const EmailChangeOtp: React.FC<{ verificationToken: string; email: string }> = ({ verificationToken, email }) => {

    const { emailChangeOtpModal, toggleEmailChangeOtpModal, toggleEmailChangeSuccessModal } = useStateContext()
    const { modalPosition } = useLayoutContext()
    const { t } = useTranslation()
    const [val, setVal] = useState(Array(6).fill(""));
    const [isError, setIsError] = useState(false);
    const isSubmitDisabled = val.some((value) => value === "" || value === undefined);
    const [timer, setTimer] = useState<number>(SITE_SETTINGS.RESEND_TIMEOUT);
    const { mutate: changeEmail, isLoading } = mutations.useChangeEmail()

    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearTimeout(countdown);
        }
    }, [timer]);

    const handleContinue = () => {
        const pin = val.join('');
        changeEmail({ verificationToken, pin }, {
            onSuccess: () => {
                toggleEmailChangeSuccessModal();
                toggleEmailChangeOtpModal()
            },
            onError: () => {
                setIsError(true)
            }
        })
    }

    return (
        <Modal centered dir={modalPosition} isOpen={emailChangeOtpModal.toggle} toggle={() => toggleEmailChangeOtpModal()} className="theme-modal change-email account-modal">
            <ModalHeader toggle={() => toggleEmailChangeOtpModal()}>{t("ChangeEmailAddress")}</ModalHeader>
            <ModalBody>
                <div className="deletion custom-scrollbar">
                    <h4 dangerouslySetInnerHTML={{ __html: t("PleaseEnterTheVerificationCodeSentToEmail", { email: email }) }}></h4>
                    <OTPInput val={val} setVal={setVal} isError={isError} setIsError={setIsError} />
                    <div className="resend-code custom-w">
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: t("TheCodeWeSentYouWillExpireInSeconds", { timer: timer }) }} />
                            <p>{t("IfYourPinDoesNotWorkRequestNewOne")}</p>
                        </div>
                        <SolidButton color="transparent" className="resend">{t("ResendCode")}</SolidButton>
                    </div>
                </div>
            </ModalBody>
            <div className="modal-footer">
                <SolidButton className='btn-gray justify-content-center' onClick={() => toggleEmailChangeOtpModal()}>{t("Cancel")}</SolidButton>
                <SolidButton type="submit" loading={isLoading} onClick={handleContinue} disabled={isSubmitDisabled}>{t("Submit")}</SolidButton>
            </div>
        </Modal>
    )
}

export default EmailChangeOtp
