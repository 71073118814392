import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

import { Image } from '../../../../AbstractElement';
import { dynamicImage } from '../../../../utils';
import { useLayoutContext } from '../../../../views';
import { Header } from '../../Widgets';
import VerificationsEmailForm from './VerificationsEmailForm';

const VerificationsEmail = () => {

    const { t } = useTranslation();
    const { isMobile } = useLayoutContext()

    return (
        <div className="vh-100">
            <div className="auth-main h-100">
                <Image src={dynamicImage("svg/flower.svg")} alt="logo" className="flower" />
                {!isMobile && <span className="help-div">
                    <Image src={dynamicImage("svg/icon_help1.svg")} alt="fill-star" />
                </span>}
                <Header />
                <div className="auth-body h-100">
                    <Container className="h-100">
                        <div className="auth-body-title t-padding b-padding">
                            <div className="auth-sub-title">
                                <h1>{t("Verifications")}</h1>
                            </div>
                        </div>
                        <VerificationsEmailForm />
                        <div className="help-div">
                            <Image src={dynamicImage("svg/icon_help1.svg")} alt="fill-star" onClick={() => { (window as any)?.Beacon('open'); }}/>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default VerificationsEmail;
