import React, { useEffect, useRef } from 'react';
import { useAuth } from '../Hooks';

interface PdfViewerProps {
  pdfEndpointUrl: string;
  defaultFileName: string;
}

// Define the types for the AdobeDC object
interface AdobeDCView {
  previewFile: (fileData: any, options: any) => void;
}

declare global {
  interface Window {
    AdobeDC?: {
      View: new (config: { clientId: string; divId: string, locale: string }) => AdobeDCView;
    };
  }
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfEndpointUrl, defaultFileName }) => {
  const adobeDCViewRef = useRef<AdobeDCView | null>(null);

  const clientId = process.env.REACT_APP_ADOBE_CLIENT_ID as string;
  const { user } = useAuth()

  useEffect(() => {
    if (!pdfEndpointUrl)
      return;

    const initializeAdobeDCView = () => {
      if (window.AdobeDC) {
        if (!adobeDCViewRef.current) {
          // Initialize the AdobeDC View object
          adobeDCViewRef.current = new window.AdobeDC.View({
            clientId,
            divId: 'adobe-dc-view',
            locale: 'en-US'
          });
        }

        // Preview the PDF file with authorization headers
        adobeDCViewRef.current.previewFile(
          {
            content: {
              location: {
                url: pdfEndpointUrl,
                headers: [
                  { key: 'Authorization', value: `Bearer ${user?.authToken!}` },
                ],
              },
            },
            metaData: { fileName: defaultFileName },
          },
          {
            embedMode: 'IN_LINE'
          }
        );
      } else {
        console.error('AdobeDC SDK is not ready.');
      }
    };

    const fetchPdfData = async () => {
      try {
        // Initialize AdobeDCView with the resolved filename
        initializeAdobeDCView();
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    if (window.AdobeDC) {
      fetchPdfData();
    } else {
      document.addEventListener('adobe_dc_view_sdk.ready', fetchPdfData);
    }
    return () => {
      document.removeEventListener('adobe_dc_view_sdk.ready', fetchPdfData);
    };

  }, [pdfEndpointUrl]);

  return (
    <div id="adobe-dc-view"></div>
  );
};

export default PdfViewer;