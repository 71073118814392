import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Image } from '../../AbstractElement';
import { mutations, queries } from '../../api';
import { ROUTES } from '../../Constants';
import { DraftInvoice, DraftInvoiceCreateRequest, DraftInvoiceUpdateRequest, INVOICE_STATUS } from '../../Types';
import { dynamicImage } from '../../utils';
import { useStateContext } from '../../views';
import Financial from './Tab/Financial';
import Project from './Tab/Project';
import Summary from './Tab/Summary';
import WorkItem from './Tab/WorkItem';

const Invoices = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [navActive, setNavActive] = useState(1);
  const location = useLocation();
  const { id: invoiceId } = useParams<{ id: string }>();
  const { invoiceDetailModal } = useStateContext();

  const [updateInvoiceInput, setUpdateInvoiceInput] = useState<Partial<DraftInvoice>>({});
  const [invoiceCreate, setInvoiceCreate] = useState<Partial<DraftInvoiceCreateRequest>>({});
  const [promoCode, setPromoCode] = useState<string>('');
  const [marginLeft, setMarginLeft] = useState(0);

  const { data: invoice } = queries.useGetDraftInvoice(invoiceId);
  const { mutate: updateInvoiceDraft, isLoading: loadingUpdate } = mutations.useUpdateInvoiceDraft();
  const { mutate: createInvoiceDraft, isLoading: loadingCreate } = mutations.useCreateInvoiceDraft();
  const { data: calculateAmount, refetch: refetchCalculate, isLoading: loadingCalculate } = queries.useGetCalculateAmount(updateInvoiceInput.id, promoCode ? { PromotionCode: promoCode } : {});

  const tabItems = [
    { id: 1, key: "Client & Project", title: "Client & Project", validate: ((!!updateInvoiceInput.id && !!invoiceCreate.projectId) || (!updateInvoiceInput.id && (invoiceCreate.customerId || (invoiceCreate.customerName && invoiceCreate.email && invoiceCreate.countryCode)) && (invoiceCreate.projectId || invoiceCreate.projectName))) },
    { id: 2, key: "workItems", title: "WorkItems", validate: updateInvoiceInput.currencyCode },
    { id: 3, key: "financials", title: "Financials", validate: updateInvoiceInput.payOutCurrencyCode },
    { id: 4, key: "summary", title: "Summary", validate: updateInvoiceInput.payOutCurrencyCode },
  ];

  const scrollTabs = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      setMarginLeft((prevMargin) => prevMargin + 50);
    } else {
      setMarginLeft((prevMargin) => prevMargin - 50);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (invoiceId) {
      const tab = query.get('tab');
      if (tab === 'summary') {
        setNavActive(4);
      } else if (tab === 'financials') {
        setNavActive(3);
      } else if (tab === 'workItems') {
        setNavActive(2);
      }
    }
    else {
      setNavActive(1);

      const customerId = query.get('customerId');
      const projectId = query.get('projectId');
      setUpdateInvoiceInput({});
      if (projectId && customerId) {
        setInvoiceCreate((prev: any) => ({
          ...prev,
          customerId: customerId,
          projectId: projectId
        }))
      } else
        setInvoiceCreate({});

      setPromoCode('')
      query.delete('tab');
      query.delete('projectId');
      query.delete('customerId');
    }

  }, [invoiceId]);


  useEffect(() => {
    const query = new URLSearchParams(location.search);
  }, [invoiceId]);

  useEffect(() => {
    if (invoice) {
      if (invoice.invoiceStatus !== INVOICE_STATUS.Draft && !invoiceDetailModal.toggle)
        navigate(ROUTES.Dashboard, { replace: true });

      setInvoiceCreate({
        customerId: invoice.customerId ?? undefined,
        customerName: invoice.customerName,
        email: invoice.customerEmail,
        currencyCode: invoice.currencyCode,
        projectId: invoice.projectId ?? undefined,
        projectName: invoice.projectName,
      });
      setUpdateInvoiceInput(invoice);
    }
  }, [invoice]);


  const activeTabPane = (id: number) => {
    if (id > navActive) {
      for (let i = navActive; i < id; i++) {
        const tab = tabItems.find(item => item.id === i);
        if (tab && !tab.validate) {
          return;
        }
      }
    }

    setNavActive(id);
  };
  const updateInvoice = useCallback((invoiceCreateValue: Partial<DraftInvoiceCreateRequest>, input: Partial<DraftInvoice>, callback?: (response: DraftInvoice) => void) => {
    setUpdateInvoiceInput(input);

    // Prepare input for DraftInvoiceUpdateRequest
    const updateRequestInput: Partial<DraftInvoiceUpdateRequest> = {
      projectId: invoiceCreateValue.projectId!,
      documentDate: input.documentDate,
      dueDate: input.dueDate,
      currencyCode: input.currencyCode,
      payOutBankAccountId: input.payOutBankAccountId ?? undefined,
    };

    // Update invoice
    updateInvoiceDraft({ id: input.id!, input: updateRequestInput }, {
      onSuccess: (response) => {
        setUpdateInvoiceInput(response);
        refetchCalculate();
        if (callback) {
          callback(response);
        }
      },
    });
  }, []);

  const createInvoice = useCallback((input: Partial<DraftInvoiceCreateRequest>) => {
    setInvoiceCreate(input);
    // Create invoice
    createInvoiceDraft(input, {
      onSuccess: (response) => {
        navigate(`${ROUTES.InvoiceEdit.replace(':id', response.id)}?tab=workItems`, { replace: true });
        // Update input with the new ids (projectId and customerId from response)
        setUpdateInvoiceInput(response);
      },
    });
  }, []);

  return (
    <Container fluid>
      <div className="invoice-main custom-scrollbar">
        <div className="invoice-nav-tab-box position-relative">
          <button
            className={`scroll-arrow left-arrow ${marginLeft === 0 ? 'disabled' : ''}`}
            onClick={() => scrollTabs('left')}
          >
            <Image src={dynamicImage(`svg/left.svg`)} />
          </button>
          <Nav pills className="invoice-tab custom-scrollbar" id="pills-tab" style={{ marginLeft: `${marginLeft}px` }}>
            {tabItems.map(item => (
              <NavItem key={item.id}>
                <NavLink
                  className={navActive === item.id ? "active" : ""}
                  onClick={() => activeTabPane(item.id)}
                  id={`${item.key}-tab`}>
                  {t(item.title)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <button
            className={`scroll-arrow right-arrow ${marginLeft === -250 ? 'disabled' : ''}`}
            onClick={() => scrollTabs('right')}
          >
            <Image src={dynamicImage(`svg/right.svg`)} />
          </button>
        </div>
        <TabContent className="invoice-tab-content" activeTab={navActive} id="pills-tabContent">
          <TabPane tabId={1} id="pills-projectClient" className="position-relative h-100">
            <Project
              tabItems={tabItems}
              setNavActive={activeTabPane}
              navActive={navActive}
              invoiceCreate={invoiceCreate}
              setInvoiceCreate={setInvoiceCreate}
              invoiceId={invoiceId}
              loading={loadingCreate || loadingUpdate}
            />
          </TabPane>
          <TabPane tabId={2} className='completed invoice-work-tab'>
            <WorkItem
              tabItems={tabItems}
              setNavActive={activeTabPane}
              navActive={navActive}
              invoiceId={invoiceId}
              updateInvoiceInput={updateInvoiceInput}
              setUpdateInvoiceInput={setUpdateInvoiceInput}
              calculateAmount={calculateAmount}
              refetchCalculate={refetchCalculate}
              updateInvoice={updateInvoice}
              invoiceCreate={invoiceCreate}
              setInvoiceCreate={setInvoiceCreate}
              createInvoice={createInvoice}
              loading={loadingCreate || loadingUpdate}
            />
          </TabPane>
          <TabPane tabId={3} className='financial-tabs'>
            <Financial
              tabItems={tabItems}
              setNavActive={activeTabPane}
              navActive={navActive}
              updateInvoiceInput={updateInvoiceInput!}
              setUpdateInvoiceInput={setUpdateInvoiceInput}
              calculateAmount={calculateAmount}
              updateInvoice={updateInvoice}
              invoiceCreate={invoiceCreate}
              loading={loadingCreate || loadingUpdate || loadingCalculate}
            />
          </TabPane>
          <TabPane tabId={4} className='summary-tabs'>
            <Summary
              setNavActive={activeTabPane}
              navActive={navActive}
              updateInvoiceInput={updateInvoiceInput!}
              setUpdateInvoiceInput={setUpdateInvoiceInput}
              refetchCalculate={refetchCalculate}
              calculateAmount={calculateAmount}
              setPromoCode={setPromoCode}
              promoCode={promoCode}
              updateInvoice={updateInvoice}
              invoiceCreate={invoiceCreate}
              tabItems={tabItems}
              loading={loadingCreate || loadingUpdate}
            />
          </TabPane>
        </TabContent>
      </div>
    </Container>
  );
};

export default Invoices;
