import React from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { Image, LinkButton, SolidButton } from "../../../AbstractElement";
import { dynamicImage } from "../../../utils";
import { useTranslation } from "react-i18next";

interface FinanceActionProps {
    actionFinance: boolean;
    toggleActionFinance: VoidFunction;
}
const ActionFinance: React.FC<FinanceActionProps> = ({ toggleActionFinance, actionFinance }) => {

    const { t } = useTranslation();

    return (
        <Offcanvas isOpen={actionFinance} toggle={toggleActionFinance} direction="end" className="finance-modal">
            <OffcanvasHeader className="p-0" toggle={toggleActionFinance} />
            <OffcanvasBody className="custom-scroll">
                <div className="flex-grow-1">
                    <div className="application-received">
                        <Image src={dynamicImage("svg/hifive.svg")} alt="hfive" />
                        <h2>{t("ApplicationReceived")}</h2>
                    </div>
                    <p>{t("YourFinancingApplicationHasBeenReceivedSuccessfullyForTheFollowingInvoiceWeWillGetBackToYouShortly")}</p>

                    <h5> {t("InvoiceDetails")} </h5>
                    <div className="invoice-finance">
                        <div>
                            <ul className="invoice-data">
                                <li>
                                    <span>{t("Project")}</span>
                                    <p>
                                        Art Direction Project <small>(Web Design & Development)</small>
                                    </p>
                                </li>
                                <li>
                                    <span>{t("Client")}</span>
                                    <p>
                                        Jonathan Drake <small>(jonathan@company.com)</small>
                                    </p>
                                </li>
                                <li>
                                    <span>{t("InvoiceNumber")}</span>
                                    <p>2024010092</p>
                                </li>
                                <li>
                                    <span>{t("InvoiceDate")}</span>
                                    <p>Jan 25, 2024</p>
                                </li>
                                <li>
                                    <span>{t("Amount")}</span>
                                    <p>$1,250.00</p>
                                </li>
                                <li>
                                    <span>{t("DepositTo")}</span>
                                    <p>
                                        IBAN - USD <small> (TR5055***)</small>
                                    </p>
                                </li>
                                <li>
                                    <span>{t("Status")}</span>
                                    <p>
                                        <Image src={dynamicImage("svg/in-transfer.svg")} alt="arrow" />
                                        Waiting approval <small>(Jan 23, 2024)</small>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-buttons mt-5">
                    <LinkButton onClick={toggleActionFinance}>
                        {t("Close")}
                    </LinkButton>
                    <SolidButton>{t("Action")}</SolidButton>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    )
}

export default ActionFinance
