import { useState } from "react";
import { Button, Card, Col, Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";
import { convertToReadableAmount, convertToReadableDate, dynamicImage } from "../../../utils";
import { queries } from "../../../api";
import { INVOICE_STATUS, USER_ROLE } from "../../../Types";
import { useAuth } from "../../../Hooks";
import { Image } from "../../../AbstractElement";
import { CancelInvoiceStatuses, InvoiceStatus } from "../../../Types/InvoiceStatus";

const LastInvoice = () => {
  const ignoreStatuses: InvoiceStatus[] = [INVOICE_STATUS.Draft, INVOICE_STATUS.WaitingForSentToBusiness, INVOICE_STATUS.WaitingForPayment, INVOICE_STATUS.WaitingForPaymentAgreement, INVOICE_STATUS.WaitingForPaymentApprove, INVOICE_STATUS.TransferFailed, INVOICE_STATUS.TransferFailedPayment];
  const { t } = useTranslation();
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const { data: lastInvoice } = queries.useGetInvoiceScopeLastInvoice();

  return (
    <>
      {lastInvoice && (
        <Col xs="12" className="min-h-content">
          <Card>
            <div className="invoice-status-card">
              <Button color="transparent" onClick={toggle} className={`invoice ${!isOpen ? "hide" : ""}`}>
                <h6>{t("LastInvoiceJourney")}</h6>
                <div className="invoice-arrow">
                  <Image src={dynamicImage("svg/icon_arrow_down.svg")} alt="arrow" className="tab-image" />
                </div>
              </Button>
              <Collapse isOpen={isOpen}>
                <Card className="p-0">
                  {lastInvoice ? (
                    <div className="invoice-detail">
                      <div>
                        <div className="invoice-title">
                          <h6>
                            {user?.role === USER_ROLE.Business
                              ? lastInvoice.invoiceScope.freelancerName
                              : lastInvoice.invoiceScope.companyName}
                          </h6>
                          <div className="d-flex align-items-center gap-1 justify-content-between">
                            <span>{convertToReadableDate(lastInvoice.invoiceScope.modifiedAt, "medium", { month: "long" })}</span>
                            <small>{convertToReadableAmount(lastInvoice.invoiceScope.totalIncludingTaxes, lastInvoice.invoiceScope.currencyCode)}</small>
                          </div>
                        </div>
                        {lastInvoice && <ul>
                          {lastInvoice.logs
                            .filter(f => CancelInvoiceStatuses.includes(f.status) || (!ignoreStatuses.includes(f.status) && f.status !== lastInvoice?.invoiceScope.invoiceStatus) || f.status === INVOICE_STATUS.CompletedTransferToFreelancer)
                            .map((exist) => {
                              return <li key={exist.id} className={CancelInvoiceStatuses.includes(exist.status) ? "cancel" : "completed"}>
                                <span>{t(`${user?.role}.Long.${exist.status}`)}</span>
                                <small>{convertToReadableDate(exist.createdAt, "short")}</small>
                              </li>
                            })
                          }
                          {!CancelInvoiceStatuses.includes(lastInvoice.invoiceScope.invoiceStatus) &&
                            Object.entries(INVOICE_STATUS)
                              .map(([key, value]) => {
                                let exist = value !== lastInvoice?.invoiceScope?.invoiceStatus && lastInvoice.logs.find(f => f.status === value);
                                if (exist || CancelInvoiceStatuses.includes(value) || (ignoreStatuses.includes(value) && lastInvoice?.invoiceScope.invoiceStatus !== value) || (lastInvoice?.invoiceScope?.invoiceStatus === value && value === INVOICE_STATUS.CompletedTransferToFreelancer))
                                  return null;
                                return <li key={key} className={lastInvoice?.invoiceScope.invoiceStatus === value ? "active" : ""}>
                                  <span>{t(`${user?.role}.Long.${value}`)}</span>
                                </li>
                              })}
                        </ul>}
                      </div>
                    </div>
                  ) : (
                    <div className="no-data-found">
                      <div>
                        <Image src={dynamicImage("svg/no-data/no-data-invoice.svg")} alt="no data" className="img-fluid" />
                        <h5 className='mt-3'>{t("YouHaventCreatedAnInvoiceYet")}</h5>
                      </div>
                    </div>
                  )}
                </Card>
              </Collapse>
            </div>
          </Card>
        </Col >
      )}
    </>
  );
};
export default LastInvoice;