import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../Constants';
import { useAuth } from '../../../../Hooks';
import Loader from '../../../../Layout/Loader';
import { getMessages, getParam } from '../../../../utils';
import { useToast } from '../../../../views';

export const BusinessSignin = () => {
    const { businessSignin, signout } = useAuth();
    const navigate = useNavigate();
    const { showToast } = useToast();

    useEffect(() => {
        const code = getParam("code");
        if (!code) {
            navigate(ROUTES.BusinessLogin);
            return;
        }
        signout(() => {
            businessSignin(
                code,
                (e) => {
                    navigate(ROUTES.VerifyInvoice + `?invoiceScopeId=${e}`);
                },
                (err) => {
                    if (Array.isArray(err.data)) {
                        showToast("error", getMessages(err));
                    }
                    navigate(ROUTES.BusinessLogin);
                }
            );
        });
    }, []);

    return <Loader />;
};