import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          "WelcomeAboard": "Welcome aboard!",
          "LoginOr": "Login or",
          "CreateAnAccount": "Create an account",
          "CreateNewAccount": "Create new account",
          "Next": "Next",
          "BusinessProfile": "Business profile",
          "BusinessEmailAddress": "Business email address...",
          "LoginWithGoogle": "Login with Google",
          "LoginWithLinkedIn": "Login with LinkedIn",
          "SignUpWithGoogle": "Sign up with Google",
          "SignUpWithLinkedIn": "Sign up with LinkedIn",
          "UploadBusinessIncorporationDocument": "Upload business incorporation document",
          "BusinessLogin": "Business login",
          "FundsReleasedToJuuli": "Funds released to Juuli",
          "LetsGetYourBusinessSetUp": "Let’s get your business set up",
          "FreelancerLogin": "Freelancer login",
          "Login.BusinessImageMessage": "We bring companies and freelancers together",
          "Login.FreelancerImageMessage": "We work for 50 million freelancers.",
          "Login.Trustpilot": "Trustpilot",
          "Login.TrustpilotMessage": "I was skeptical but I decided to try, well it is a great service!",
          "YourPasswordHasBeenResetSuccessfully": "Your password has been reset successfully.",
          "PaymentSentToFreelancer": "Payment sent to freelancer",
          "Select": "Select",
          "InvalidEmail": "Invalid email!",
          "ViewInvoiceModal.InvoiceApproved": "Invoice approved",
          "InternationalSwiftTransfer": "International Swift transfer",
          "PaymentInitiated": "Payment initiated",
          "Country": "Country",
          "BankAccountCountry": "Bank account country",
          "ItemsPerPage": "Items per page",
          "Freelancers": "Freelancers",
          "Currency": "Currency",
          "IBANNumber": "IBAN number",
          "IBANNumbers": "IBAN number ...",
          "ACHAndWireRoutingNumber": "ACH and wire routing number",
          "ACHAndWireRoutingNumbers": "ACH and wire routing number ...",
          "AccountType": "Account type",
          "AddAccount": "Add account",
          "EmailAddress": "Email address ...",
          "PhoneNumbers": "Phone number ...",
          "Passwords": "Password ...",
          "ConfirmPassword": "Confirm password ...",
          "LogIn": "Login",
          "SignUp": "Sign up",
          "RequestNewLink": "Request new link",
          "Cancel": "Cancel",
          "Submit": "Submit",
          "PleaseEnterTheSecurityPinWeSentToYourEmailAddress": "Please enter the security pin we sent to your email address",
          "VerificationStatusIsNotAvailable": "Verification status is not available.",
          "VerificationTab.VerificationApproved": "Verification approved",
          "Confirm": "Confirm",
          "Back": "Back",
          "ResendCode": "Resend code",
          "ForgotPassword": "Forgot password?",
          "ResetYourPassword": "Reset your password",
          "Verifications": "Verifications",
          "TheCodeWeSentYouWillExpireInSeconds": "The code we sent you will expire in <span class='sec'>{{timer}}</span> seconds",
          "IfYourPinDoesNotWorkRequestNewOne": "If your pin doesn't work, request a new one.",
          "CreateNewPassword": "Create a new password",
          "Weak": "Weak",
          "Medium": "Medium",
          "Strong": "Strong",
          "PasswordHint": "Use 8 or more characters with a mix of lowercase and uppercase letters, and symbols.",
          "PrivacyPolicy": "Privacy policy",
          "TermsAndConditions": "Terms and Conditions",
          "CookiePreferences": "Cookie preferences",
          "FreelancerAgreement": "Freelancer agreement",
          "ServiceContracts": "Service contracts",
          "AlreadyAMember": "Already a member?",
          "LoginHere": "Login here",
          "PleaseEnterTheVerificationCodeSentToEmail": "Please enter the verification code sent to <span class='sec'>{{email}}</span>",
          "PleaseEnterTheSMSCodeSentTo": "Please enter the SMS code sent to",
          "SubmitProceed": "Submit & proceed",
          "WelcomeToJuuli": "You’re all set, welcome to Juuli!",
          "AlmostFinished": "Almost finished!",
          "LetsGetYouSetUp": "Let’s get you set up",
          "Profile": "Profile",
          "Verification": "Verification",
          "BankAccount": "Bank account",
          "Agreements": "Agreements",
          "ToCompleteYourRegistrationAnswerFewQuestionsAboutYourBusiness": "To complete your registration answer a few questions about your business.",
          "WhatTypeOfBusinessDoYouHave": "What type of business do you have?",
          "Freelancer": "Freelancer",
          "SoleTrader": "Sole-trader",
          "SoleTraderTaxInformation": "Sole-trader tax information",
          "VATNumber": "VAT number ...",
          "Previous": "Previous",
          "ReviewInvoice": "Review invoice",
          "IdentityVerification": "Identity verification",
          "IdentityVerificationHelpsUsKeepJuuliSafeForEveryone": "Identity verification helps us keep Juuli safe for everyone.",
          "SetupBankAccount": "Setup bank account",
          "AddYourBankAccountInformationToReceivePayments": "Add your bank account information to receive payments.",
          "DownloadAsPDF": "Download as PDF",
          "IAcceptTheTermsConditions": "I accept the terms & conditions",
          "Finish": "Finish",
          "EnterThePinCodeWeJustSentYourEmail": "Enter the pin code we just sent to your email address",
          "Suggestions": "Suggestions",
          "JuuliStatus": "Juuli stats",
          "HereIsYourActivitySoFar": "Here is your activity so far! ☀️",
          "Projects": "Projects",
          "CreateNewInvoice": "Create new invoice",
          "Clients": "Clients",
          "FinanceInvoice": "Finance invoice",
          "Money": "Money",
          "ProfileSetting": "Profile settings",
          "SignOut": "Sign out",
          "VerifiedCodeSuccessful": "Verified code successful!",
          "InvalidVerificationCodePleaseCheckYourEmail": "Invalid verification code, please check your email",
          "VerificationTab.FailedToGetAValidResponseFromTheServer.": "Failed to get a valid response from the server.",
          "ANetworkErrorOccurredPleaseTryAgain": "A network error occurred. Please try again.",
          "VerificationTab.OnboardingProcessFailedToObtainAnAccessToken": "Onboarding process failed to obtain an access token.",
          "InvitationSentSuccessfullyTo": "Invitation sent successfully to",
          "Continue": "Continue",
          "Dashboard": "Dashboard",
          "Invoices": "Invoices",
          "ANewCodeHasBeenSentYourEmailAddress": "A new code has been sent your email address",
          "LinkHasBeenSentYourEmailAddress": "Link has been sent your email address",
          "Finance": "Finance",
          "ReferToFriend": "Refer to a friend",
          "InvalidEmailOrPassword": "Invalid email or password!",
          "Help": "Help",
          "Settings": "Settings",
          "TotalProjects": "Total projects",
          "ActiveProject": "Active projects",
          "PaidInvoices": "Paid invoices",
          "UnpaidInvoices": "Unpaid invoices",
          "TotalInvoices": "Total invoices",
          "RecentInvoices": "Recent invoices",
          "QuickInvoice": "Quick invoice",
          "InvoiceNumber": "Invoice number",
          "InvoiceDate": "Invoice date",
          "Project": "Project",
          "Client": "Client",
          "Status": "Status",
          "InvoiceStatus": "Invoice status",
          "Amount": "Amount",
          "ANewCodeHasBeenSentYourPhoneNumber": "A new code has been sent your phone number",
          "AllProjects": "All projects",
          "DraftInvoices": "Draft invoices",
          "AllInvoices": "All invoices",
          "ArchivedInvoices": "Archived invoices",
          "AllTransactions": "All transactions",
          "Total": "Total",
          "Active": "Active",
          "SearchByProjectName": "Search by project name ...",
          "FilterByStatus": "Filter by status",
          "NewProject": "New project",
          "ProjectName": "Project name",
          "ProjectStatus": "Project status",
          "Edit": "Edit",
          "Actions": "Actions",
          "ProjectDetails": "Project details",
          "ShareProject": "Share project",
          "CompleteProject": "Complete project",
          "Archive": "Archive",
          "TaxCountry": "Tax country",
          "TaxOffice": "Tax office",
          "Registry": "Registry",
          "EnterRegistrationNumber": "Enter registration number...",
          "VatNumber": "VAT number",
          "EnterVATNumber": "Enter VAT number...",
          "NewInvoice": "New invoice",
          "AddProjectName": "Add project name ...",
          "SelectCustomer": "Select customer ...",
          "Category": "Category",
          "AddYourProjectDetails": "Add your project details ...",
          "ProjectDocument": "Project document",
          "SelectCreateNewDocument": "Select or create new document ...",
          "CreateNew": "Create new",
          "CreateProject": "Create project",
          "UpdateProject": "Update project",
          "InvoiceDetails": "Invoice details",
          "View": "View",
          "FinanceApproved": "Finance approved",
          "Duplicate": "Duplicate",
          "Delete": "Delete",
          "SendReminder": "Send reminder",
          "Customers": "Customers",
          "InvoiceJourney": "Invoice journey",
          "SelectFilesFromYourComputer": "Select files from your computer ...",
          "UploadDocuments": "Upload documents",
          "DepositTo": "Deposit to",
          "CustomersRejectionReason": "Customer’s rejection reason",
          "Other": "Other",
          "Download": "Download",
          "WhereAreTheHeadquartersOfYourBusiness": "Where are the headquarters of your business?",
          "Close": "Close",
          "Pay": "Pay",
          "ViewInvoice": "View invoice",
          "ReviewAndFinalize": "Review and Finalize",
          "FilterByType": "Filter by type ...",
          "CreationDate": "Creation date",
          "TransactionType": "Transaction type",
          "Invoice": "Invoice",
          "InviteFreelancers": "Invite freelancers",
          "RequestNewInvoice": "Request new invoice",
          "UploadDocument": "Upload document",
          "RequestEdit": "Request edit",
          "Approve": "Approve",
          "LastInvoiceJourney": "Last invoice journey",
          "Reject": "Reject",
          "ViewApproveInvoice": "View & Approve invoice",
          "ContinueToAgreement": "Continue to agreement",
          "ProjectsInvoices": "Projects invoices",
          "Transactions": "Transactions",
          "LastUpdate": "Last update",
          "FreelancerName": "Freelancer name",
          "EmailId": "Email address",
          "InvitationDate": "Invitation date",
          "EditDocument": "Edit document",
          "Document": "Document",
          "SelectProject": "Select project...",
          "LastTransaction": "Last transaction",
          "OngoingTransaction": "Ongoing transaction",
          "AllFreelancers": "All freelancers",
          "InviteNewFreelancer": "Invite new freelancer",
          "InvitationsSent": "Invitations sent",
          "InvitedDate": "Invited date",
          "InvitedStatus": "Invited status",
          "SendInvitation": "Send invitation",
          "PhoneNumberId": "Phone number",
          "Address": "Address",
          "RegistrationNumber": "Registration number",
          "Add": "Add",
          "CompleteClientDetailsToInvoiceThem": "Complete client details to invoice them",
          "Profession": "Profession",
          "WorkItems": "Work items",
          "Financials": "Financials",
          "FreelancerCountry": "Freelancer country",
          "InvoiceCurrency": "Invoice currency",
          "AddNewItem": "Add new item",
          "ItemName": "Item name",
          "Description": "Description",
          "QTY": "QTY",
          "Price": "Price",
          "Subtotal": "Subtotal",
          "VatValue": "VAT {{value}}%",
          "TotalAmount": "Total amount",
          "DueDate": "Due date",
          "DueDateBig": "Due date",
          "PayoutCurrency": "Payout currency",
          "Preview": "Preview",
          "WorkHasBeenDelivered": "Work has been delivered?",
          "TotalTax": "Total tax",
          "ServiceFeeValue": "Service fee {{fee}}%",
          "PaidBy": "Paid by",
          "0": "0",
          "1": "1",
          "2": "2",
          "3": "3",
          "4": "4",
          "5": "5",
          "6": "6",
          "7": "7",
          "8": "8",
          "9": "9",
          "TheOTPHasExpiredPleaseRequestANewOne": "The OTP has expired please request a new one",
          "PromotionCode": "Promotion code",
          "FinalPayout": "Final payout",
          "EstimatedPayoutAmount": "Estimated payout amount",
          "Notes": "Notes",
          "RequestInvoice": "Request invoice",
          "InvoiceCreatedSuccessfully": "Invoice created successfully!",
          "AttachDocumentsToTheProject": "Attach documents to the project",
          "JuuliWillNowReviewYourInvoiceAndNotifyTheFreelancer": "Juuli will now review your invoice and notify the freelancer",
          "InvoiceRequestSent": "Invoice request sent",
          "IReadAndAgreeToTheServiceContractTerms": "I read and agree to <a href={{serviceContract}} target='_blank' class='text-primary-color-1'> The Service contract terms</a>",
          "SelectPaymentType": "Select payment type",
          "CreditCard": "Credit card",
          "BankTransfer": "Bank transfer",
          "ContinueWithOnlinePayment": "Continue with online payment",
          "AccountHolder": "Account holder",
          "BankAddress": "Bank address",
          "SWIFTBIC": "SWIFT / BIC",
          "IBAN": "IBAN",
          "Me": "Me",
          "Apply": "Apply",
          "YouHaveApprovedThisInvoiceAndYouCanMakeThePaymentUsingTheBankDetailsProvidedBelow": "You have approved this invoice and you can make the payment using the bank details provided below",
          "AfterMakingThePaymentYouDontNeedToTakeAnyFurtherAction": "After making the payment you don’t need to take any further action.",
          "LatestInvoicesReceived": "Latest invoices received",
          "ClientName": "Client name",
          "SelectDate": "Select date ...",
          "SelectDueDate": "Select due date ...",
          "EnterYourNotes": "Enter your notes...",
          "CreateNewProject": "Create new project",
          "InvoiceCreated": "Invoice created",
          "Name": "Name",
          "Quantity": "Quantity",
          "Rate": "Rate",
          "VatSmall": "Vat",
          "ProjectBeenDelivered": "Project been delivered ?",
          "ProjectDetail": "Project detail",
          "ConfirmInvoiceApproval": "Confirm invoice approval",
          "ConfirmInvoiceRejection": "Confirm invoice rejection",
          "EnterRejectionReasonForTheFreelancer": "Enter rejection reason for the freelancer",
          "EnterAnotherReason": "Enter another reason...",
          "Events": "Events",
          "Paid": "Paid",
          "Search": "Search ...",
          "Date": "Date",
          "Documents": "Documents",
          "SearchDocument": "Search document ...",
          "UploadDocumentsToProject": "Upload documents to project",
          "ShareWithFreelancers": "Share with freelancers",
          "Details": "Details",
          "Activity": "Activity",
          "PaymentSent": "Payment sent",
          "YouApprovedTheInvoice": "You approved the invoice",
          "YouSharedNewDocument": "You shared a new document",
          "YouAreCreatedTheProject": "You are created the project",
          "Comments": "Comments",
          "Unread": "Unread",
          "TypeNewComment": "Type new comment...",
          "FinancialInvoices": "Financial invoices",
          "BelowAreTheInvoicesYouCanApplyForFinancingAndGetPaidEarlyClickApplyToStartFinancingProcess": "Below are the invoices you can apply for financing and get paid early, click “apply” to start financing process.",
          "PreApprovedInvoices": "Pre-approved invoices",
          "InvoiceFinancingApplication": "Invoice financing application",
          "FinancingOptions": "Financing options",
          "ApplicationReceived": "Application received!",
          "YourFinancingApplicationHasBeenReceivedSuccessfullyForTheFollowingInvoiceWeWillGetBackToYouShortly": "Your financing application has been received successfully for the following invoice. We will get back to you shortly.",
          "Action": "Action",
          "JuuliIBAN": "Juuli IBAN",
          "JuuliDebitCard": "Juuli Debit Card",
          "ConvertYourPaymentsToCashInstantly": "Convert your payments to cash instantly!",
          "AtJuuliWeCommittedToMakingFreelancersFinancialJourneySeamlessAndEmpoweringGetReadyToExperienceFreedomWithOurUpcomingDebitCard": "At Juuli, we're committed to making freelancers' financial journey seamless and empowering. Get ready to experience freedom with our upcoming debit card!",
          "JuuliDebitCardsAreOnTheWay": "Juuli Debit Card is on the way!",
          "InternationalUsage": "International usage",
          "100Free": "100% free",
          "ZeroMaintenanceFees": "Zero maintenance fees",
          "JoinTheWaitingList": "Join the waiting list",
          "Localization": "Localization",
          "Language": "Language",
          "Save": "Save",
          "YouCanInviteFreelancersToYourProjectsByClickingTheActionMenu": "You can invite freelancers to your projects by clicking the action menu",
          "InvoiceWaitingApproval": "You have <strong>{{invoiceNum}} invoice</strong> waiting for approval by <strong>{{name}}</strong>",
          "YorAreAboutToPerformIrreversibleActionInvoicePleaseConfirm": 'You are about to perform an <strong  style={{ fontWeight: "700" }}>irreversible action</strong> to this invoice! Please type <strong  style={{ fontWeight: "700" }}>0248</strong> to confirm.',
          "ShowMe": "Show me",
          "EditProject": "Edit project",
          "Complete": "Complete",
          "DoYouNeedHelp": "Do you need help?",
          "NewActivity": "New activity",
          "ProfileSettings": "Profile settings",
          "Personal": "Personal",
          "InvoiceSettings": "Invoice settings",
          "BankAccounts": "Bank accounts",
          "Password": "Password",
          "FirstName": "First name",
          "LastName": "Last name",
          "Citizenship": "Citizenship",
          "Website": "Website",
          "EnterWebAddress": "Enter web address ...",
          "Update": "Update",
          "Street": "Street",
          "ApartmentNumber": "Apartment number",
          "EnterApartmentName": "Enter apartment name ...",
          "BuildingNumber": "Building number",
          "EnterBuildingName": "Enter building name ...",
          "City": "City",
          "State": "State",
          "PostalCode": "Postal code",
          "EnterPostalCode": "Enter postal code ...",
          "BusinessType": "Business type",
          "Privacy": "Privacy",
          "DisplayMyNameOnInvoices": "Display my name on invoices",
          "BusinessName": "Business name",
          "EnterBusinessName": "Enter business name...",
          "EnterPlaceHolder": "Enter {{fieldName}}...",
          "EnterEmailAddress": "Enter email address ...",
          "Industry": "Industry",
          "AboutYourInvoicing": "About your invoicing",
          "AreaOfExpertise": "Area of expertise",
          "CanYouEstimateAnnualInvoiceTotalWithJuuli": "Can you estimate annual invoice total with Juuli?",
          "EnterEstimatedTotal": "Enter estimated total...",
          "WhatYourMonthlyInvoiceFrequencyEstimate": "What's your monthly invoice frequency estimate?",
          "DeleteAccount": "Delete account",
          "ForgotOldPasswordGetNewPassword": "If you forgot your old password or don't even have one, get a new password through <0>this link</0>",
          "UpdatePassword": "Update password",
          "OldPassword": "Old password",
          "EnterOldPassword": "Old password...",
          "EnterConfirmPassword": "Confirm password...",
          "NewPassword": "New password",
          "EnterPassword": "Password...",
          "ConfirmNewPassword": "Confirm new password",
          "CurrentPassword": "Current password",
          "EnterCurrentPassword": "Enter current password...",
          "BusinessProfileSettings": "Business profile settings",
          "Contact": "Contact",
          "BusinessProfileBig": "Business profile",
          "EnterBusinessVatNumber": "Enter business VAT number...",
          "PreferredCurrency": "Preferred currency",
          "EnterStreetName": "Enter street name ...",
          "Invited": "Invited",
          "FilterBy": "Filter by ...",
          "SmallAddNewCustomer": "Add new customer",
          "ResendInvitation": "Resend invitation",
          "Revoke": "Revoke",
          "InvitationToProject": "Invitation to project",
          "Accept": "Accept",
          "IAccept": "I accept",
          "EnterCustomerName": "Enter customer name ...",
          "EnterCustomerEmail": "Enter customer email ...",
          "EnterPhoneNumber": "Enter phone number ...",
          "ZipPostalCode": "Zip / Postal code ...",
          "AddressLine1": "Address line 1 ...",
          "AddressLine2": "Address line 2 ...",
          "SelectCountry": "Country ...",
          "StateProvince": "State / Province",
          "EnterCity": "City ...",
          "EnterTaxOffice": "Enter tax office...",
          "EnterClientVatNumber": "Enter client VAT number ...",
          "EditCustomerDetails": "Edit customer details",
          "YourProfileNotCompletedAddBankDetails": "Your profile is <strong>70% complete</strong>, add your bank account information to receive payments.",
          "JuuliQuestionnaire": "Juuli Questionnaire",
          "WhatIsYourAreaOfExpertise": "What is your area of expertise?",
          "DoYouHaveAnEstimateForTheFrequencyOfMonthlyInvoiceCreation": "Do you have an estimate for the frequency of monthly invoice creation?",
          "CanYouProvideAnEstimatedAnnualInvoiceAmountWithJuuli": "Can you provide an estimated annual invoice amount with Juuli?",
          "HowManyClientsDoYouUsuallyWorkFor": "How many clients do you usually work for?",
          "Summary": "Summary",
          "EnterItemName": "Enter item name",
          "EnterDescription": "Enter description",
          "Wallet": "Wallet",
          "NewInvoice.YouDontHaveJuuliIbanYet": "You don’t have a Juuli IBAN yet...",
          "NewInvoice.YouDontHaveBankAccountYet": "You didn’t set up a bank account yet...",
          "GetAJuuliIban": "Get a Juuli IBAN",
          "CurrencyExchange.Title": "Payout information",
          "CurrencyExchange.PayoutInformationMessage": "Your invoice was originally priced at <strong>{{amount}} {{currencyCode}}</strong>. You would like to convert your payout to {{estimatedPayoutCurrency}}, expecting a payment of approximately <strong>{{estimatedPayoutAmount}} {{estimatedPayoutCurrency}}</strong>.",
          "InvoiceAmount": "Invoice amount",
          "ExchangeRate": "Exchange rate",
          "SaveAsDraft": "Save as draft",
          "FinalizeInvoice": "Finalize invoice",
          "ApprovedByClient": "Approved by client",
          "ClientInitiatedThePayment": "Client initiated the payment",
          "JuuliWillNowReviewYourInvoiceAndShareItWithYourClient": "Juuli will now review your invoice and share it with your client.",
          "AreYouSureYouWantToDeleteThisInvoice": "Are you sure you want to delete this invoice?",
          "SearchByName": "Search by name ...",
          "EditDetails": "Edit details",
          "EnterFreelancerEmail": "Enter freelancer email ...",
          "EnterFreelancerName": "Enter freelancer name ...",
          "YouCanPayThisInvoiceByCreditCard": "You can pay this invoice by credit card.",
          "InvitationToastText": "{{text}}<strong> {{mail}}</strong>",
          "ViewDetails": "View details",
          "ShowAll": "Show all",
          "WaitingForTransfer": "In transfer",
          "YourAccountBeingVerifiedWeWillLetYouKnowWhenItCompleted": "Your account is being verified. We will let you know when it's completed.",
          "YourAccountIsVerified": "Your account is verified.",
          "YourAccountIsDeclined": "Your account is declined.",
          "AgreementsTab.AgreementApprovalFailed": "Agreement approval failed",

          // api errors
          "Login.RequiredValue": "There is a required value that is missing",
          "Login.InvalidValue": "Email or password is invalid",
          "Login.InvalidLink": "Invalid login link. Please check the link provided for business login.",
          "Login.InvalidVerifyCode": "Invalid verification code. Please check the code provided PIN entry.",
          "Password.MinLength": "Password too short.",
          "Password.MissingLowerCase": "Your password must include a lowercase letter.",
          "Password.MissingUpperCase": "Your password must include an uppercase letter.",
          "Password.NoWhiteSpace": "Passwords cannot contain whitespace.",
          "Password.RequiredSpecialChar": "Your password must include a special character.",
          "Password.RequiredValue": "Password is required.",
          "UpdatePassword.OldPasswordIsNotCorrected": "The old password provided is incorrect.",
          "SignUp.EmailAlreadyExist": "This email address is already in use",
          "SignUp.RequiredValues": "All required fields must be filled in to sign up.",
          "SignUp.VerificationCodeAlreadyBeenSent": "Verification code has already been sent.",
          "SignUp.InvalidPin": "Invalid PIN code.",
          "ResetPassword.RequiredValues": "Please provide all necessary information to reset your password.",
          "ResetPassword.InvalidLink": "Invalid password reset link. Please check the link or request a new password reset.",
          "Email.Empty": "Email address field cannot be empty.",
          "Email.InvalidFormat": "Email format you entered is invalid.",
          "Email.TooLong": "The email address provided is too long.",
          "Email.Unverified": "Please ensure you have entered a valid email address. Double-check for any typos or errors.",
          "PhoneNumber.Empty": "Phone number field is required.",
          "PhoneNumber.InvalidFormat": "Invalid phone number format.",
          "UserVerification.NotMatchedEmail": "The email does not match our records for user verification.",
          "UserVerification.Declined": "It appears there has been an issue during the verification of your identity. Please contact our support team.",
          "SystemLookupCode.TooLong": "Code is too long.",
          "SystemLookupCode.InvalidValue": "Code value is invalid.",

          "Invoice.AlreadySavedAsFreelancerUser": "The email address {{1}} for the recipient is already registered as a freelancer account.",
          "Invoice.AlreadySent": "The invoice has already been sent.",
          "Invoice.CannotInvoiceThisCountry": "Unfortunately, we are not able to send invoices to the selected country.",
          "Invoice.DocumentDateMustBeGreaterThanMinDate": "Document date cannot be older than one month.",
          "Invoice.DocumentDateMustBeSmallerThanMaxDate": "Document date must be within the next eleven months.",
          "Invoice.DueDateMustBeGreaterThanDocumentDate": "Due date must be greater than the document date.",
          "Invoice.DueDateMustBeGreaterThanToday": "The due date must be greater than today.",
          "Invoice.DueDateMustBeSmallerThanMaxDate": "Due date must be within the next eleven months after the document date.",
          "Invoice.InternationalBranchIsRequired": "An international branch is required for this invoice.",
          "Invoice.PayOutBankAccountIsRequired": "Payout bank account is required.",
          "Invoice.LinesIsRequired": "You should add at least one invoice item before you finalize the invoice!",
          "Invoice.MustAproveAgreement": "You must approve the agreement before issuing an invoice.",
          "Invoice.MustBankAccount": "Select or add a bank account in {{1}} currency to proceed, or choose the deposit account instead.",
          "Invoice.MustUserEmailVerification": "You need to verify your email address in order to complete this action.",
          "Invoice.MustUserVerification": "You must complete the user verification step if you wish to complete this action.",
          "Invoice.TotalMustBeGreaterThanMinAmount": "Total amount must be greater than {{1}} {{2}}.",
          "Invoice.TotalMustBeGreaterThanZero": "Invoice total must be greater than zero.",

          "InvoiceScope.CannotChangedBankAccountDetail": "Bank account details cannot be changed for this invoice scope.",
          "InvoiceScope.MustInvoiceStatusReason": "A reason must be provided for this invoice status.",
          "InvoiceScope.NotSupportedStatus": "It is not supported to switch to this status.",
          "InvoiceScope.ReminderSevenDayLimitReached": "The seven-day reminder limit has been reached for this invoice scope.",
          "InvoiceScope.ReminderTotalLimitReached": "The total reminder limit has been reached for this invoice scope.",
          "InvoiceScope.TryAgainLater": "Please try again later.",
          "InvoiceScope.SendReminderSuccessfully": "Send reminder successfully",
          "Customer.RequiredTaxOfficeThisCountry": "Tax office is required for this country: {{1}}",
          "Customer.RequiredTaxNumberThisCountry": "Tax number is required for this country: {{1}}",
          "Customer.RequiredRegistrationNumberThisCountry": "Registration number is required for this country: {{1}}",
          "Customer.MissingRequiredField": "Client information is incomplete. Please fill in the missing details to send the invoice.",
          "Customer.CustomerHasProjects": "The client has associated projects and cannot be deleted.",
          "Customer.CustomerHasInvoices": "The client has associated invoices and cannot be deleted.",
          "Customer.UnverifiedVatNumberByProvider": "The VAT number provided could not be verified.",
          "BankAccount.LeastOneBankAccountIsRequired": "At least one bank account is required.",
          "BankAccount.HasActiveInvoiceWithCurrency": "You have an invoice with this bank account's currency",
          "PromotionCode.HasAlreadyUsed": "This promotion code has already been used.",
          "PromotionCode.HasExpired": "This promotion code has expired.",
          "PromotionCode.Invalid": "This is not a valid promotion code.",
          "PromotionCode.NotSuitable": "This promotion code is not valid for this invoice.",
          "Payment.PaymentNotYetCompleted": "The payment has not yet been completed.",
          "Project.InvoiceInProjectCannotDelete": "The project contains invoices and cannot be deleted.",
          "Contract.ElectronicSignatureIsRequired": "Electronic signature is required.",
          "Contract.AlreadySigned": "The contract has already been signed.",
          "Freelancer.AlreadySavedAsBusinessUser": "The freelancer has already been saved as a business user.",
          "Freelancer.AlreadyInvited": "The freelancer has already been invited.",

          "NotFound": "The requested item was not found.",
          "Forbid": "Access forbidden.",
          "ServiceIsNotEnabled": "Service is not enabled.",
          "OccurredUnknownError": "An unknown error has occurred.",
          "PermissionError": "You do not have permission to access this.",
          "TooLong": "Value is too long. The maximum length is {{1}} characters.",
          "InvalidValue": "The value for {{1}} is invalid.",
          "InvalidFileType": "The file type is not supported.",
          "IsRequiredValue": "{{1}} is a required field.",

          // invoice status for business
          "Business.Long.CompletedTransferToTalent": "Funds transferred to the freelancer",
          "Business.Long.DeclineByBusiness": "Invoice declined by you",
          "Business.Long.InvoiceCancelled": "Invoice cancelled",
          "Business.Long.TransferFailed": "Transfer to the freelancer failed",
          "Business.Long.TransferFailedPayment": "Payment failed, review and try again.",
          "Business.Long.WaitingForApproval": "Invoice awaiting your approval",
          "Business.Long.WaitingForPayment": "Invoice awaiting your payment",
          "Business.Long.WaitingForPaymentAgreement": "Payment agreement is awaiting",
          "Business.Long.WaitingForPaymentApprove": "Confirming your payment",
          "Business.Long.WaitingForTransfer": "Funds are transferring",

          "Business.Short.InvoiceCancelled": "Invoice cancelled",
          "Business.Short.TransferFailed": "Processing payment",
          "Business.Short.TransferFailedPayment": "Processing payment",
          "Business.Short.DeclineByBusiness": "Invoice is declined",
          "Business.Short.WaitingForApproval": "Needs approval",
          "Business.Short.WaitingForPayment": "Awaiting payment",
          "Business.Short.WaitingForPaymentAgreement": "Processing payment agreement",
          "Business.Short.WaitingForPaymentApprove": "Processing payment",
          "Business.Short.WaitingForTransfer": "In transfer",
          "Business.Short.CompletedTransferToTalent": "Paid and Completed",

          "Business.Notification.WaitingForTransfer": "Invoice #{{documentNo}} has been approved by Juuli. Funds are pending transfer to {{freelancerName}}.",
          "Business.Notification.WaitingForPaymentApprove": "We are confirming your payment for invoice #{{documentNo}} via {{paymentMethod}}.",
          "Business.Notification.WaitingForPayment": "Invoice #{{documentNo}} is awaiting your payment.",
          "Business.Notification.WaitingForPaymentAgreement": "Invoice #{{documentNo}} requires your signature to finalize the payment agreement.",
          "Business.Notification.WaitingForApproval": "Invoice #{{documentNo}} is awaiting your approval.",
          "Business.Notification.TransferFailedPayment": "Payment for invoice #{{documentNo}} via {{paymentMethod}} has failed. Please review your payment details or contact support for assistance.",
          "Business.Notification.DeclineByBusiness": "You have declined invoice #{{documentNo}}.",
          "Business.Notification.InvoiceCancelled": "Invoice #{{documentNo}} has been cancelled. No further action is needed.",
          "Business.Notification.TransferFailed": "The transfer to {{freelancerName}} for invoice #{{documentNo}} has failed. Please contact support for assistance.",
          "Business.Notification.CompletedTransferToTalent": "Funds for invoice #{{documentNo}} have been successfully transferred to {{freelancerName}}.",
          "Business.Notification.DocumentSharing": "{{freelancerName}} has shared a document with you in Project {{projectName}}.",

          // invoice status for freelancer
          "Freelancer.Long.CompletedTransferToTalent": "Payment completed",
          "Freelancer.Long.DeclineByBusiness": "Invoice declined by the client",
          "Freelancer.Long.DeclineByJuuli": "Invoice declined by Juuli",
          "Freelancer.Long.InvoiceCancelled": "Invoice is cancelled",
          "Freelancer.Long.TransferFailed": "Transfer failed",
          "Freelancer.Long.TransferFailedPayment": "Payment failed",
          "Freelancer.Long.WaitingForApproval": "Awaiting client's approval",
          "Freelancer.Long.WaitingForPayment": "Awaiting client's payment",
          "Freelancer.Long.WaitingForPaymentAgreement": "Awaiting payment agreement",
          "Freelancer.Long.WaitingForPaymentApprove": "Awaiting payment approval",
          "Freelancer.Long.WaitingForSentToBusiness": "Awaiting Juuli's approval",
          "Freelancer.Long.WaitingForTransfer": "Your transfer has begun",


          "Freelancer.Short.InvoiceCancelled": "Invoice is cancelled",
          "Freelancer.Short.TransferFailed": "Transfer failed",
          "Freelancer.Short.TransferFailedPayment": "Verifying payment",
          "Freelancer.Short.DeclineByBusiness": "Declined by client",
          "Freelancer.Short.DeclineByJuuli": "Declined by Juuli",
          "Freelancer.Short.WaitingForSentToBusiness": "Awaiting Juuli's approval",
          "Freelancer.Short.WaitingForApproval": "Awaiting client's approval",
          "Freelancer.Short.WaitingForPayment": "Awaiting client's payment",
          "Freelancer.Short.WaitingForPaymentAgreement": "Processing payment agreement",
          "Freelancer.Short.WaitingForPaymentApprove": "Processing payment",
          "Freelancer.Short.WaitingForTransfer": "Your transfer has begun",
          "Freelancer.Short.CompletedTransferToTalent": "Payment completed",

          "Freelancer.Notification.InvoiceCancelled": "Invoice #{{documentNo}} has been cancelled. No further action is needed.",
          "Freelancer.Notification.TransferFailed": "The transfer for invoice #{{documentNo}} has failed. Please contact support for assistance.",
          "Freelancer.Notification.TransferFailedPayment": "Payment for invoice #{{documentNo}} has failed. Please wait or contact support for assistance.",
          "Freelancer.Notification.DeclineByBusiness": "{{companyName}} declined invoice #{{documentNo}}. Please contact them for details.",
          "Freelancer.Notification.DeclineByJuuli": "Juuli declined invoice #{{documentNo}}. Please contact Juuli support for help.",
          "Freelancer.Notification.WaitingForSentToBusiness": "Invoice #{{documentNo}} is pending and will be sent to {{companyName}} for approval.",
          "Freelancer.Notification.WaitingForApproval": "Invoice #{{documentNo}} has been sent to {{companyName}} and is awaiting approval.",
          "Freelancer.Notification.WaitingForPayment": "Invoice #{{documentNo}} has been sent to {{companyName}} and is awaiting payment.",
          "Freelancer.Notification.WaitingForPaymentAgreement": "Invoice #{{documentNo}} requires a payment agreement to be signed by {{companyName}} before proceeding.",
          "Freelancer.Notification.WaitingForPaymentApprove": "Payment for invoice #{{documentNo}} has been made and is awaiting Juuli's approval.",
          "Freelancer.Notification.WaitingForTransfer": "Juuli approved invoice #{{documentNo}}. Funds are pending transfer to your account.",
          "Freelancer.Notification.CompletedTransferToTalent": "Funds for invoice #{{documentNo}} have been transferred to your account.",
          "Freelancer.Notification.DocumentShared": "{{companyName}} has shared a document with you in Project {{projectName}}.",

          // document status
          "DocumentStatus.NA": "Approved",
          "DocumentStatus.Sent": "Sent",
          "DocumentStatus.Generating": "Generating",
          "DocumentStatus.Signed": "Signed",

          // document status
          "InviteStatus.NA": "NA",
          "InviteStatus.Invited": "Invited",
          "InviteStatus.Accepted": "Accepted",
          "InviteStatus.Declined": "Declined",

          //Lookups:  invoice status reasons
          "InvoiceStatusReason.DoubleBilling": "Double billing",
          "InvoiceStatusReason.IncorrectAmountCharged": "Incorrect amount charged",
          "InvoiceStatusReason.IncorrectBillingAddress": "Incorrect billing address",
          "InvoiceStatusReason.IncorrectServiceRate": "Incorrect service rate",
          "InvoiceStatusReason.IncorrectTaxAmount": "Incorrect tax amount",
          "InvoiceStatusReason.Other": "Other",
          "InvoiceStatusReason.PromotionalDiscountNotApplied": "Promotional discount not applied",
          "InvoiceStatusReason.ServiceNotProvided": "Service not provided",

          "EnterAccountHolderName": "Enter account holder name",

          "GetYourDedicatedIban": "Get your dedicated IBAN",
          "FastGlobalTransactionsInMultipleCurrenciesWithLowFees": "Fast global transactions in multiple currencies, with low fees.",
          "ManageYourInternationalPaymentsEasilyWithYourOwnDedicatedIbanAccountStayTunedForTheUpcomingReleaseOfYourPersonalizedIbanServiceMakingGlobalTransactionsSmootherThanEver": "Manage your international payments easily with your own dedicated IBAN account. Stay tuned for the upcoming release of your personalized IBAN service, making global transactions smoother than ever!",
          "ProjectDocuments": "Project documents",
          "DocumentName": "Document name",
          "TimeZone": "Time zone",
          "CongratulationsYouSuccessfullyAppliedToBeOnTheWaitlistOn": "Congratulations! You successfully applied to be on the waitlist on ",
          "ServiceFeeApplied": "{{value}}% service fee applied!",
          "Business.Short.WaitingForSentToBusiness": "Need approval",
          "EnterState": "Enter state...",
          "ReceivedPayments": "Received payments",
          "LatestPayments": "Latest payments",
          "SearchClients": "Search clients",
          "AddNewClient": "New client",
          "EditClientDetails": "Edit client details",
          "ClientDetails": "Client details",
          "Last3Months": "Last 3 months",

          "NoData.Projects": "You haven't created a project yet. Start by clicking <span>“New project”</span> button above.",
          "NoData.Clients": "You haven’t added any clients yet. Start by clicking the <span>“Add new client”</span> button above.",
          "NoData.Freelancers": "You haven’t added any freelancers yet. Start by clicking the <span>“Invite new freelancer”</span> button above.",
          "NoData.Documents": "No documents found. Start by clicking the <span>“Upload document”</span> button to add a document.",
          "NoData.FreelancerNoInvoiceInProject": "You haven’t created any invoices for this project yet. Start by clicking the <span>“New invoice”</span> button.",
          "NoData.FreelancerNoArchivedInvoiceInProject": "You haven’t archived any invoices for this project yet.",
          "NoData.BusinessNoInvoiceInProject": "Invoices for this project have not been created yet. Contact your freelancers to create one.",
          "NoData.BusinessNoArchivedInvoiceInProject": "No invoices have been archived for this project yet.",

          "NoData.FreelancerNoInvoiceInGeneralList": "You haven’t created any invoices yet. Start by clicking the <span>“New invoice”</span> button.",
          "NoData.FreelancerNoArchivedInvoiceInGeneralList": "You haven’t archived any invoices yet.",
          "NoData.BusinessNoInvoiceInGeneralList": "No invoices have been created yet. Contact your freelancers to create one.",
          "NoData.BusinessNoArchivedInvoiceInGeneralList": "No invoices have been archived yet. Please check with your freelancers to create one if needed.",

          "Google": "Google",
          "LinkedIn": "LinkedIn",
          "InvoicesReceivedFromFreelancers": "Invoices received from freelancers",
          "PaymentMade": "Payments made",
          "IAgreeToPrivacyPolicyAndTermsAndConditions": "I agree to <a href={{privacyPolicy}} target='_blank' class='fw-bold'>privacy policy</a> and <a href={{termsAndConditions}} target='_blank' class='fw-bold'>terms and conditions</a>",
          "PasswordMustBeAtLeast8Characters": "Password must be at least 8 characters",
          "PasswordsMustMatch": "Confirm password must match {{password}}",
          "PasswordIsTooWeak": "Password is too weak",
          "ForgotPasswordForm.ANewCodeHasBeenSentToYourEmailAddress": "A new code has been sent to your email address",
          "ForgotPasswordForm.InstructionsAreSentPleaseCheckYourMail": "Instructions are sent, please check your mail",
          "ForgotPasswordForm.EnterYourEmailAddressSoWeCanSendYouInstructionsToHelpResetYourPassword": "Enter your email address so we can send you instructions to help reset your password",
          "ForgotPasswordForm.WeHaveSentInstructionsToTheEmailAddressYouProvided": "We have sent instructions to the email address you provided.",
          "ForgotPasswordForm.IfYouHavenNotReceivedAnEmailFromJuuliPleaseDoubleCheckTheEmailEnteredAndRequestLinkAgain": "If you haven’t received an email from Juuli, please double check the email entered and request a link again. <span class='sec'>{{timer}}</span>",
          "ContinueToJuuli": "Continue to Juuli",
          "UpdatePasswordForm.YourPasswordHasBeenSuccessfullyReset": "Your password has been successfully reset!",
          "VerificationEmailForm.YourEmailIsNowVerified": "Your email is now verified!",
          "AgreementPolicy.ErrorApprovingTheAgreementPleaseTryAgain": "Error approving the agreement. Please try again.",
          "YouHaventCreatedAnInvoiceYet": "You haven't created an invoice yet.",
          "YourLatestTransactionsWillBeDisplayedHere": "Your latest transactions will be displayed here.",
          "ProjectCanvas.StatusUpdated": "Status updated",
          "YouAreAlreayTheOwnerOfThisProject": "You are already the owner of this project.",
          "TheOTPMustBeExactly6Characters": "The OTP must be exactly 6 characters",
          "OTPIsRequired": "OTP is required",
          "ProfileSettings.UpdatedSuccessfully": "Updated successfully",

          "EnterPromoCode": "Enter promotion code",
          "DidYouKnowYouCanCreateRecurringInvoices": "Did you know you can create recurring invoices",
          "CompleteMissingClientInformationToBeAbleToInvoiceThem": "Complete missing client information to be able to invoice them",
          "BillingInformationForProject": "Billing information for project",
          "BillingInformation": "Billing information",
          "InvoiceReceiverName": "Invoice receiver name",
          "FreelancerDetails": "Freelancer details",
          "BusinessEmailAddressShort": "Business email address",
          "EnterBusinessEmailAddress": "Enter business email address...",
          "BusinessPhoneNumber": "Business phone number",
          "EnterCityLong": "Enter city...",
          "CreateYourBusinessAccount": "Create your business account",
          "BusinessNameIsRequired": "Business name is required",
          "AddressLine1IsRequired": "Address line 1 is required",
          "PostalCodeIsRequired": "Postal code is required",
          "CityIsRequired": "City is required",
          "StateIsRequired": "State is required",
          "NoDataFound": "No data found",
          "EmailVerificationForm.VerificationSuccessful": "Verification successful",
          "EmailVerificationForm.VerificationFailedPleaseTryAgain": "Verification failed, please try again",
          "Business.LoginForm.WeSentANewPinToTheEmailAddressYouProvided": "We sent a new PIN to the email address you provided!",
          "SomethingWentWrongDuringStripeRedirection": "Something went wrong during Stripe redirection",
          "GoToDashboard": "Go to Dashboard",
          "VerifyForm.EmailIsMissingPleaseCheckYourRequest": "Email is missing. Please check your request.",
          "VerifyForm.PleaseEnterTheFullOtpCode": "Please enter the full OTP code.",
          "IConfirmThatIHaveReceivedTheCompletedWorkAndApproveTheInvoice": "I confirm that I have received the completed work and approve the invoice",
          "PleaseSelectYourReasonToReject": "Please select your reason to reject.",
          "SelectAReason": "Select a reason",
          "ConfirmRejection": "Confirm rejection",
          "GreatToHear": "Great to hear!",
          "ThankyouForAcceptingTheInvoiceWehaveInformedYourEmployeeYouCanLogInToTheDashboardForMoreDetailsOnYourInvoicesAndPaymentInfoWehaveAlsoSentYouAnEmailWithTheInvoiceAttached": "Thank you for accepting the invoice. We’ve informed your employee. You can log in to the dashboard for more details on your invoices and payment info. We’ve also sent you an email with the invoice attached.",
          "IfYouWantToPayByCreditCardPleaseLogInToTheDashboard": "If you want to pay by credit card, please log in to the dashboard.",
          "WeAreSorryToHearThatYouRejectedTheInvoice": "We’re sorry to hear that you rejected the invoice.",
          "WeHaveNotifiedYourEmployeeAndTheyWillHandleTheMatterYouCanLogInToYourBusinessPanelToGetMoreDetailsAboutYourInvoices": "We’ve notified your employee, and they will handle the matter. You can log in to your business panel to get more details about your invoices.",
          "YouAreAlreadyTheOwnerOfThisProject": "You are already the owner of this project.",
          "WeHaveAlsoNotifiedYourEmployeeLogInToTheDashboardForMoreDetailsWeHaveEmailedYouTheInvoiceEarlierAndPaymentDetailsAreAttachedIfYouHaveAlreadyPaidYouCanIgnoreThisMessage": "We’ve also notified your employee. Log in to the dashboard for more details. We’ve emailed you the invoice earlier, and payment details are attached. If you’ve already paid, you can ignore this message.",
          "YouRejectedThisInvoiceOnDate": "You rejected this invoice on {{date}}.",
          "YouApprovedThisInvoiceOnDate": "You approved this invoice on {{date}}.",
          "PleaseLogInToYourBusinessPanelToSeeTheReasonForTheRejectionIfThisWasAMistakeOrYouNeedMoreInfoContactYourEmployee": "Please log in to your business panel to see the reason for the rejection. If this was a mistake or you need more info, contact your employee.",
          "InvoiceReceiver": "Invoice receiver",
          "Note": "Note",
          "Agreement1": "Agreement 1",
          "*Required": "*Required",
          "EnterClientName": "Enter client name ...",
          "EnterClientEmail": "Enter client email ...",
          "EnterClientRegistrationNumber": "Enter client registration number...",
          "5005000000": "500 500 00 00",
          "ProjectsWithThisClient": "Projects with this client",
          "Clients.ClientAddedSuccessfully": "Client added successfully",
          "SelectClient": "Select client...",
          "ClientNameId": "Client name...",
          "ClientCountry": "Client country...",
          "ClientEmailAddress": "Client email address...",
          "WorkItemName": "Work item name...",
          "PriceId": "Price...",
          "CurrencyId": "Currency...",
          "CreateInvoice": "Create invoice",
          "JobTitle": "Job title",
          "ProjectsWithThisFreelancer": "Projects with this freelancer",
          "SelectOrEnterNewClientName": "Select or enter new client name",
          "EnterNewClientName": "Enter new client name...",
          "NewClientName": "New client name",
          "NewClientEmail": "New client email",
          "EnterEmail": "Enter an email...",
          "NewClientCountry": "New client country",
          "ClientInformation": "Client information",
          "EnterNameForTheProject": "Enter a name for the project...",
          "Money.CongratulationsYouHaveSuccessfullyJoinedTheWaitlist": "Congratulations! You have successfully joined the waitlist!",
          "Title": "Title",
          "IsRequired": "{{name}} is required",
          "YouMustAcceptTheTermsAndConditions": "You must accept the terms and conditions",
          "SelectQuestionnaireCode": "Please select the {{code}}",
          "ArchiveInvoice": "Archive invoice",
          "UnarchiveInvoice": "Unarchive invoice",

          "EnterFirstName": "Enter first name ...",
          "EnterLastName": "Enter last name ...",
          "EnterTitle": "Enter title...",
          "EnterWebSite": "Enter web site...",
          "EnterCitizenship": "Enter citizenship...",
          "CompleteTheProject": "Complete the project",
          "ArchiveThisProject": "Archive this project",
          "UnarchiveThisProject": "Unarchive this project",
          "LoadingProjectData": "Loading project data...",
          "Invoice.InvoiceDeletedSuccessfully": "Invoice deleted successfully",
          "VATAmount": "VAT amount (%{{vatAmount}})",
          "EditClient": "Edit client",
          "SelectId": "Select...",
          "ToChangeTheEmailAddressLinkedToYourAccount": "To change the email address linked to your account follow <0>this link</0>",
          "IfYouWishToDeleteYourAccount": "If you wish to delete your account you can follow <0>this link</0>",
          "BackToLogin": "Back to login",
          "EmailAddressChange": "Email address change",
          "WeAreSorryToSeeYouGoWouldYouLikeToHelpUsImproveJuuliByDescribingWhyYouWantToDeleteYourAccount": "We are sorry to see you go :( Would you like to help us improve Juuli by describing why you want to delete your account?",
          "AccountDeletionModal.Title": "Account deletion",
          "AccountDeletionModal.OneLastThingDontDeleteYourAccountAndGetADiscountForYourNextInvoiceUsingTheCodeBelow": "One last thing, don’t delete your account and get a discount for your next invoice using the code below.",
          "AccountDeletionModal.AreYouSureYouWantToDeleteYourAccount": "Are you sure you want to delete your account?",
          "NewClient": "New client",
          "AnUnexpectedErrorOccurredPleaseTryAgain": "An unexpected error occurred. Please try again.",
          "Loading": "Loading...",
          "SearchInItems": "Search in items...",
          "Archived": "Archived",
          "AccountDeletionModal.DeleteAccountInstead": "Delete account instead",
          "EnterALabelForThisAccount": "Enter a label for this account...",
          "AccountDetails.AddedSuccessfully": "Added successfully",
          "AccountDetails.BankAccountDeletedSuccessfully": "Bank account deleted successfully",
          "AccountDetails.DisplayFormat": "{{currencyCode}} account ending with {{displayValue}}",
          "FieldNameMustBeAtLeastLengthCharactersLong": "{{name}} must be at least {{length}} characters long",
          "FieldNameMustNotExceedLengthCharacters": "{{name}} must not exceed {{length}} characters",
          "IsInvalid": "{{name}} is invalid",
          "EnterYourFieldName": "Enter your {{name}}",
          "JuuliAccount": "Juuli account",
          "AgreementsTab.AgreementApprovedSuccessfully": "Agreement approved successfully",
          "VerificationTab.OnboardingProcessIsGoingOn": "Onboarding process is going on",
          "SkipForNow": "Skip for now",
          "OpenInNewTab": "Open in new tab",
          "LastInvoice": "Last invoice",
          "OngoingInvoice": "Ongoing invoice",
          "ProjectInvoices": "Project invoices",
          "Drafts": "Drafts",
          "Billing": "Billing",

          "InvoiceDetailCanvas.DownloadedInvoice": "Invoice downloaded successfully",
          "InvoiceDetailCanvas.ErrorDownloadingInvoice": "Error downloading invoice",
          "InvoiceDetailCanvas.InvoiceArchivedSuccessfully": "Invoice archived successfully",
          "InvoiceDetailCanvas.InvoiceUnarchivedSuccessfully": "Invoice unarchived successfully",
          "InvoiceDetailCanvas.ErrorArchivingInvoice": "Error archiving invoice:",
          "Downloading": "Downloading...",
          "Archiving": "Archiving...",
          "ViewInvoiceModal.InvoiceRejected": "Invoice rejected",
          "DraftInvoices.DownloadFailed": "Download failed:",
          "Unarchive": "Unarchive",
          "LastInvoiceStatus": "Last invoice status",
          "Projects.ProjectStatusUpdatedSuccessfully": "Project status updated successfully",
          "Projects.ProjectsDeletedSuccessfully": "Project deleted successfully",
          "Billing.BillingDataUpdatedSuccessfully": "Billing data updated successfully",
          "Clients.ClientUpdatedSuccessfully": "Client updated successfully",
          "Clients.ClientDeletedSuccessfully": "Client deleted successfully",
          "Clients.ClientArchivedSuccessfully": "Client archived successfully",
          "Clients.ClientUnarchivedSuccessfully": "Client unarchived successfully",
          "ProjectCanvas.ProjectAddedSuccessfully": "Project added successfully",
          "ProjectCanvas.ProjectUpdatedSuccessfully": "Project updated successfully",
          "Documents.DocumentDeletedSuccessfully": "Document deleted successfully",
          "Documents.DocumentDownloadedSuccessfully": "Document downloaded successfully",
          "UploadDocCanvas.DocumentUploadedSuccessfully": "Document uploaded successfully",
          "Questionnaire.QuestionnaireSubmittedSuccessfully": "Questionnaire submitted successfully",
          "Freelancer.FreelancerAddedSuccessfully": "Freelancer added successfully",
          "Freelancer.FreelancerUpdatedSucessfully": "Freelancer updated successfully",
          "EditFreelancerDetails": "Edit freelancer details",
          "ApprovedInvoices": "Approved invoices",
          "FreelancersWhoCanAccess": "Freelancers who can access",
          "SettingsCanvas.UpdatedSuccessfully": "Updated successfully",
          "PleaseWait": "Please wait...",
          "EnterPrice": "Enter price",
          "Unarchiving": "Unarchiving...",
          "Documents.DocumentSharedSuccessfully": "Document shared successfully",
          "Freelancers.FreelancerArchivedSuccessfully": "Freelancer archived successfully",
          "Freelancers.FreelancerUnarchivedSuccessfully": "Freelancer unarchived successfully",
          "ChangeEmailAddress": "Change email address",
          "EnterNewEmailAddressYouWantToAssociateWithYourAccount": "Enter new email address you want to associate with your account.",
          "EmailAddressSuccesfullyChanged": "Email address successfully changed.",
          "InvalidEmailMessage": "Invalid email message",
          "ChangeProfilePhoto": "Change profile photo",
          "FinalizePayment": "Finalize payment",
          "CompleteThePaymentNow": "Complete the payment now",
          "ProfileSettings.ProfileImageUploadedSuccessfully": "Profile image uploaded successfully",
          "ProfileSettings.ProfileImageDeletedSuccessfully": "Profile image deleted successfully",

          // payment page
          "YouCanPayTheInvoiceWithYourCard": "You can pay the invoice with your card.",
          "AdditionalVerificationDocumentsMayBeRequestedFromYourBusiness": "Additional verification documents may be requested from your business.",
          "CardPaymentsWillIncurExtraFeesAndMayTakeBusinessDaysToReachYourFreelancer": "Card payments will incur extra fees and may take 5-15 business days to reach your freelancer.",
          "YouCanEasilyCreateYourFirstInvoiceUsingTheFormBelow": "You can easily create your first invoice using the form below.",

          // bank details
          "TransferType": "Transfer type",

          // Suggestions
          "Suggestion.ReviewInvoice": "Review the invoice",
          "Suggestion.MakePayment": "Make a payment",
          "Suggestion.JoinTheProject": "Join the project",
          "Suggestion.CompleteProfile": "Complete profile",
          "Suggestion.CreateProject": "Create a new project",
          "Suggestion.CreateDraftInvoice": "Create a new invoice",
          "Suggestion.GoToDraftInvoices": "Go to draft invoices",
          "Suggestion.CompleteQuestionnaire": "Start questionnaire",
          "Suggestion.JoinTheWaitingList": "Join the waiting list",
          "Suggestion.ReferToFriend": "Refer to a friend",
          "Suggestion.Continue": "Continue",

          "Suggestion.WaitingForApproval": "You have an invoice waiting for approval by {{1}}.",
          "Suggestion.WaitingForPayment": "You have an invoice awaiting payment for {{1}}.",
          "Suggestion.WaitingForPaymentAgreement": "There is a document awaiting your signature to complete your payment process.",
          "Suggestion.BusinessHasInvitedYouToJoinProject": "{{1}} has invited you to join a {{2}}.",
          "Suggestion.BusinessHasInvitedYouToJoinProject.Modal": "{{1}} invited you to the project \"<strong>{{2}}</strong>\"",
          "Suggestion.VerifyYourIdentityToContinue": "Verify your identity to continue.",
          "Suggestion.ProvideYourBankDetailsToReceiveFunds": "Provide your bank details to receive funds.",
          "Suggestion.CheckYourAgreementsForAccuracyAndRelevance": "Check your agreements for accuracy and relevance.",

          "Suggestion.StartNewProjectKeepYourDeckOrganized": "Start a new project, keep your deck organized.",
          "Suggestion.CreateInvoiceToRequestPayment": "Create an invoice to request payment.",
          "Suggestion.FinishYourDraftInvoiceAndSendIt": "Finish your draft invoice and send it.",
          "Suggestion.FillInTheQuestionnaire": "Complete our short questionnaire",
          "Suggestion.RegisterTheToDebitCardWaitingList": "Register to the debit card waiting list.",
          "Suggestion.InviteFriendAndEarnRewards": "Invite a friend and earn rewards.",
          "Suggestion.GetYourJuuliDebitCard": "Get your Juuli Debit Card",

          // Confirmation Dialog
          "ArchiveProject": "Archive project",
          "AreYouSureYouWantToArchiveThisProject": "Are you sure you want to archive this project?",
          "DeleteClient": "Delete client",
          "AreYouSureYouWantToDeleteThisClientThisActionCannotBeUndoneAndMayAffectAssociatedData": "Are you sure you want to delete this client? This action cannot be undone and may affect associated data.",

          // rotate message
          "PleaseRotateYourScreenBackToPortraitMode": "Please rotate your screen back to portrait mode."
        },
      },
    },
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
