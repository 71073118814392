/* freelancer & business pages routes */

export const ROUTES = {
    Home: "/",
    FreelancerLogIn: "/freelancer-login",
    FreelancerSignUp: "/freelancer-signup",
    ForgotPassword: "/forgot-password",
    CreatePassword: "/reset-password",
    EmailVerification: "/verification-email",
    PhoneVerification: "/verification-phone",
    FreelancerProfile: "/freelancer-profile",
    BusinessLogin: "/business-login",
    BusinessSignUp: "/business-signup",
    VerifyAccount: "/verify-account",
    VerifyInvoice: "/verify-invoice",
    AgreementPolicy: "/agreements",
    Dashboard: "/dashboard",
    Project: "/projects",
    ProjectDetails: "/project/:id",
    Invoices: "/invoices",
    InvoiceDetail: "/invoices/:id",
    InvoiceFinalizeDetail: "/finalize-invoices/:id",
    Invoice: "/new-invoice",
    InvoiceEdit: "/new-invoice/:id",
    Client: "/client",
    Freelancer: "/freelancer",
    Payment: "/payment/:id",
    PaymentAgreement: "/payment-agreement/:id",
    Money: "/money",
    Finance: "/finance",
    GoogleSignin: "/google-sign-in",
    LinkedInSignin: "/linkedin-sign-in",
    BusinessSignin: "/business-sign-in",
    BusinessPinLogin: "/business-pin-login",
    StripeRedirect: "/stripe-redirect",
    BusinessVerificationPin: "/business-signup-verify",
} as const;
