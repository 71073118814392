import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, InputGroup, Row } from 'reactstrap';

import { SolidButton } from '../../../../../AbstractElement';
import { mutations, queries } from '../../../../../api';
import { CommonSelect } from '../../../../../CommonComponent';
import { useCoreData } from '../../../../../Hooks';
import { FreelancerAccountInput } from '../../../../../Types';
import { useStateContext, useToast } from '../../../../../views';

const InvoiceSettings = () => {

  const { t } = useTranslation();
  const { showToast } = useToast();
  const { toggleProfileSetting } = useStateContext();

  const [profileType, setProfileType] = useState("freelancer");

  const { phoneCountryCodeOptions, expertiseAreasOptions, invoiceAmountEstimatesOptions, invoiceFrequencyOptions, industryOptions } = useCoreData({ loadCountries: true, loadExpertiseAreas: true, loadInvoiceAmountEstimates: true, loadInvoiceFrequency: true, loadIndustries: true });

  const { data: userDetails } = queries.useGetUserDetails();
  const { mutate: updateAccount, isLoading: updateLoading } = mutations.useUpdateAccount();

  const [selectedExpertiseAreas, setSelectedExpertiseAreasOptions] = useState<string>('');
  const [selectedInvoiceAmountEstimateCode, setSelectedInvoiceAmountEstimateOptions] = useState<string>('');
  const [selectedInvoiceFrequency, setSelectedInvoiceFrequencyOptions] = useState<string>('');
  const [selectedUsualCustomerCount, setSelectedUsualCustomerCount] = useState<string>('');
  const [selectedCountryCodeOptions, setSelectedCountryCodeOptions] = useState<string[] | string>('');

  const { register, handleSubmit, setValue } = useForm<FreelancerAccountInput>({
    defaultValues: {
      displayMyNameOnInvoices: false,
      expertiseAreaCode: '',
      invoiceFrequencyCode: '',
      invoiceAmountEstimateCode: '',
      usualCustomerCountCode: ''
    }
  });

  useEffect(() => {
    if (userDetails) {
      setValue('displayMyNameOnInvoices', userDetails.displayMyNameOnInvoices!);
      setValue('expertiseAreaCode', userDetails.expertiseAreaCode);
      setValue('invoiceFrequencyCode', userDetails.invoiceFrequencyCode);
      setValue('invoiceAmountEstimateCode', userDetails.invoiceAmountEstimateCode);
      setValue('usualCustomerCountCode', userDetails.usualCustomerCountCode);

      setSelectedExpertiseAreasOptions(userDetails.expertiseAreaCode!);
      setSelectedInvoiceAmountEstimateOptions(userDetails.invoiceAmountEstimateCode!);
      setSelectedInvoiceFrequencyOptions(userDetails.invoiceFrequencyCode!)
      setSelectedUsualCustomerCount(userDetails.usualCustomerCountCode!)
    }
  }, [userDetails, setValue]);

  const onSubmit: SubmitHandler<FreelancerAccountInput> = async (data) => {

    updateAccount({ ...userDetails, ...data }, {
      onSuccess: () => {
        showToast("success", "ProfileSettings.UpdatedSuccessfully")
      }
    })

  };
  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-md-5">
        <Col xs="12">
          <div className="setting-main">
            <Row className='g-sm-4 g-3'>
              <Col md="6">
                <h6 className="mb-3 mt-0 top-title">{t("BusinessType")}</h6>
                <Row className="g-sm-3 g-2">
                  <Col xs="6">
                    <FormGroup className="form-control form-control-white">
                      <div className="form-check">
                        <input type="radio" name="profile" className="form-radio-input form-check-input" id="freelancer" onChange={() => setProfileType("freelancer")} checked={profileType === "freelancer"} />
                        <label htmlFor="freelancer" className="cursor-pointer text-nowrap">
                          <span className="circle"></span>
                          <span className="text">{t("Freelancer")}</span>
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <div className="form-control form-control-white mb-0">
                      <div className="form-check">
                        <input type="radio" name="profile" className="form-radio-input form-check-input" disabled id="sole-trader" onChange={() => setProfileType("sole-trader")} checked={profileType === "sole-trader"} />
                        <label htmlFor="sole-trader" className="cursor-pointer text-nowrap">
                          <span className="circle"></span>
                          <span className="text">{t("SoleTrader")}</span>
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <h6 className="mb-3 mt-0 top-title">{t("Privacy")}</h6>
                <Row>
                  <Col xs="12">
                    <FormGroup className="d-flex align-items-center gap-4">
                      <label className="mb-0 fw-500">{t("DisplayMyNameOnInvoices")}</label>
                      <label className="switch mb-0">
                        <input className="form-check-input" type="checkbox" id="displayMyNameOnInvoices" {...register("displayMyNameOnInvoices")} />
                        <span className="switch-state"></span>
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              {
                profileType === "sole-trader" && (
                  <Col>
                    <h6 className='mb-3 mt-0 top-title'>{t("SoleTraderTaxInformation")}</h6>
                    <Row className='g-sm-4 g-3'>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <label>{t("BusinessName")}</label>
                          <input type="text" className="form-control" id="businessname" placeholder={t("EnterBusinessName")} defaultValue="" autoComplete="off" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <label>{t("EmailId")}</label>
                          <input type="text" className="form-control" id="email" placeholder={t("EnterEmailAddress")} defaultValue="" autoComplete="off" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="phone-detail mb-0">
                          <label>{t("PhoneNumberId")}</label>
                          <InputGroup>
                            <div className="dropdown-box">
                              <CommonSelect
                                placeholder={t('Select')}
                                options={phoneCountryCodeOptions!}
                                selectedOptions={selectedCountryCodeOptions}
                                setSelectedOptions={setSelectedCountryCodeOptions}
                                multiSelect={false}
                                search={true}
                              />
                              <input type="number" className="form-control" id="contact" placeholder={t("5005000000")} autoComplete="off" />
                            </div>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <label>{t("Industry")}</label>
                          <CommonSelect
                            placeholder={t('Select')}
                            options={industryOptions!}
                            selectedOptions={selectedCountryCodeOptions}
                            setSelectedOptions={setSelectedCountryCodeOptions}
                            multiSelect={false}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <label>{t("TaxOffice")}</label>
                          <input type="text" className="form-control" id="taxOffice" placeholder={t("EnterTaxOffice")} autoComplete="off" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <label>{t("RegistrationNumber")}</label>
                          <input type="text" className="form-control" id="registrationNumber" placeholder={t("EnterRegistrationNumber")} autoComplete="off" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className='mb-0'>
                          <label>{t("VatNumber")}</label>
                          <input type="text" className="form-control" id="vatNumber" placeholder={t("EnterVATNumber")} autoComplete="off" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                )}
              <Col xs="12">
                <h6 className='mb-3 mt-0 top-title'>{t("AboutYourInvoicing")}</h6>
                <Row className='g-sm-4 g-3'>
                  <Col md="6">
                    <FormGroup className='mb-0'>
                      <label>{t("AreaOfExpertise")}</label>
                      <CommonSelect
                        placeholder={t('Select')}
                        options={expertiseAreasOptions!}
                        selectedOptions={selectedExpertiseAreas}
                        setSelectedOptions={(value) => {
                          setSelectedExpertiseAreasOptions(value);
                          setValue('expertiseAreaCode', value);
                        }}
                        multiSelect={false}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className='mb-0'>
                      <label>{t("CanYouEstimateAnnualInvoiceTotalWithJuuli")}</label>
                      <CommonSelect
                        placeholder={t('Select')}
                        options={invoiceAmountEstimatesOptions!}
                        selectedOptions={selectedInvoiceAmountEstimateCode}
                        setSelectedOptions={(value) => {
                          setSelectedInvoiceAmountEstimateOptions(value);
                          setValue('invoiceAmountEstimateCode', value);
                        }}
                        multiSelect={false}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className='mb-0'>
                      <label>{t("WhatYourMonthlyInvoiceFrequencyEstimate")}</label>
                      <CommonSelect
                        placeholder={t('Select')}
                        options={invoiceFrequencyOptions!}
                        selectedOptions={selectedInvoiceFrequency}
                        setSelectedOptions={(value) => {
                          setSelectedInvoiceFrequencyOptions(value);
                          setValue('invoiceFrequencyCode', value);
                        }}
                        multiSelect={false}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup className='mb-0'>
                      <label>{t("HowManyClientsDoYouUsuallyWorkFor")}</label>
                      <CommonSelect
                        placeholder={t('Select')}
                        options={invoiceFrequencyOptions!}
                        selectedOptions={selectedUsualCustomerCount}
                        setSelectedOptions={(value) => {
                          setSelectedUsualCustomerCount(value);
                          setValue('usualCustomerCountCode', value);
                        }}
                        multiSelect={false}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

            </Row>
          </div>
        </Col>
      </Row>
      <div className="profile-setting-footer-main">
        <div className="profile-setting-footer">
          <SolidButton className='btn-gray' onClick={toggleProfileSetting}>{t("Cancel")}</SolidButton>
          <SolidButton type='submit' loading={updateLoading}>{t("Update")}</SolidButton>
        </div>
      </div>
    </Form>
  )
}
export default InvoiceSettings;