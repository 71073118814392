import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Collapse } from "reactstrap";
import { Image, LinkButton } from "../../AbstractElement";
import { ROUTES } from "../../Constants";
import { dynamicImage } from "../../utils";
import { useStateContext } from "../../views";

const WaitingList = () => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation();
  const { dismissAll } = useStateContext();

  return (
    <div className="offer-card">
      <Button className={`card-close ${!isOpen ? 'hide' : ""}`} color="primary" onClick={toggle}>
        <Image src={dynamicImage("svg/red-arrow.svg")} alt="arrow" />
      </Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <div className="detail">
              <Image src={dynamicImage("svg/admin/offer-cards.svg")} alt="arrow" className="offer-card-img" />
              <p>{t("JuuliDebitCardsAreOnTheWay")}</p>
              <LinkButton className="btn-white-primary" to={ROUTES.Money} onClick={dismissAll}>
                {t("JoinTheWaitingList")}
                <Image src={dynamicImage("svg/red-right-arrow.svg")} alt="arrow" />
              </LinkButton>
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default WaitingList;