import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useAuth } from '../../Hooks';
import { USER_ROLE } from "../../Types";
import { useLayoutContext } from '../../views';
import DraftInvoices from './Tab/DraftInvoices';
import AllInvoices from './Tab/Invoices';
import AllProjects from './Tab/Projects';
import AllTransactions from './Tab/Transactions';
import { ROUTES } from '../../Constants';

const Projects = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [navActive, setNavActive] = useState(1);
  const navigate = useNavigate();
  const activeTabPane = (id: number) => {
    setNavActive(id);
    if (!!query.get('tab')) {
      query.delete('tab');
      navigate(`${location.pathname}`);
    }
  };

  const location = useLocation();
  const { isMobile } = useLayoutContext();

  const query = new URLSearchParams(location.search);

  const tabItems = [
    { id: 1, key: "projects", title: "AllProjects", hidden: location.pathname == ROUTES.Invoices },
    { id: 2, key: "invoices", title: "AllInvoices" },
    { id: 3, key: "draft", title: isMobile ? "Drafts" : "DraftInvoices", role: USER_ROLE.Freelancer, hidden: location.pathname == ROUTES.Project },
    { id: 4, key: "archived", title: isMobile ? "Archived" : "ArchivedInvoices", hidden: location.pathname == ROUTES.Project },
    //TODO:It can be connected later
    // { id: 5, key: "transactions", title: "AllTransactions" }
  ];

  useEffect(() => {
    const tab = query.get('tab');
    if (tab && tab === 'draft') {
      setNavActive(3);
    }
    else if (location.pathname === ROUTES.Project) {
      setNavActive(1);
    }
    else if (navActive === 1) {
      setNavActive(2);
    }
  }, [location.pathname]);

  return (
    <Container fluid className="project-container project-detail-container">
      <div className="horizontal-main-tab">

        <div className="project-details-box">
          <Nav pills className="horizontal-tab bar d-sm-flex custom-scrollbar" id="pills-tab">
            {tabItems.filter(f => (!f.role || f.role === user?.role) && !f.hidden).map((item) => (
              <NavItem key={item.id}>
                <NavLink
                  className={navActive === item.id ? "active" : ""}
                  onClick={() => activeTabPane(item.id)}
                  id={`${item.key}-tab`}
                >
                  {t(item.title)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        <TabContent className="horizontal-tab-content" activeTab={navActive} id="pills-tabContent">
          <TabPane tabId={1} className='all-projects'><AllProjects /></TabPane>
          <TabPane tabId={2} className='all-invoice'><AllInvoices archived={false} /></TabPane>
          {user?.role === USER_ROLE.Freelancer && <TabPane tabId={3} className='all-invoice project-draft-tab'><DraftInvoices /></TabPane>}
          <TabPane tabId={4} className='all-invoice'><AllInvoices archived={true} /></TabPane>
          {/* //TODO:It can be connected later
           <TabPane tabId={5}><AllTransactions /></TabPane> 
           */}
        </TabContent>
      </div>
    </Container>
  )
}
export default Projects;