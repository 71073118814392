import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Image } from "../../../../AbstractElement";
import { convertToReadableAmount, convertToReadableDate, dynamicImage } from "../../../../utils";
import { queries } from "../../../../api";

const LatestTransactions = () => {
  const { t } = useTranslation();
  const { data: transaction } = queries.useGetLatestTransactions("Payment");
  const transactionContent = transaction?.items?.flat() || [];
  const hasTransactions = transactionContent.length > 0;

  return (
    <Col xs="12" className='col-height'>
      <Card className="radius-bottom-0 h-100">
        <div className="latest-transaction-card">
          <CardHeader>
            <h6>{t('LatestPayments')}</h6>
          
          </CardHeader>
          <CardBody>
            {hasTransactions ? (
              <ul className="latest-transaction-list">
                {transactionContent.map((transaction: any) => (
                  <li key={transaction.id}>
                    <h6>{transaction.freelancerName}</h6>
                    <div className="time-cash-box">
                      <span>{t("Invoice")}</span>
                      <small>
                        #{transaction.documentNo}
                      </small>
                    </div>
                    <div className="time-cash-box">
                      <span>{convertToReadableDate(transaction.createdAt, "short")}</span>
                      <small>
                        {convertToReadableAmount(transaction.amount, transaction.currencyCode)}
                      </small>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="no-data-found">
                <div>
                  <Image src={dynamicImage("svg/no-data/no-data-transaction.svg")} alt="invoice image" className="img-fluid" />
                  <h5 className='mt-3'>{t("YourLatestTransactionsWillBeDisplayedHere")}</h5>
                </div>
              </div>
            )}
          </CardBody>
        </div>
      </Card>
    </Col>
  );
};

export default LatestTransactions;