import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';

import { Image } from '../../../../../AbstractElement';
import { mutations, queries } from '../../../../../api';
import { LayoutLoader } from '../../../../../CommonComponent';
import { ContractType } from '../../../../../Types';
import { AgreementTabProp, STEP, STEP_STATE } from '../../../../../Types/Auth/Auth';
import { dynamicImage, getMessages } from '../../../../../utils';
import { useLoader, useToast } from '../../../../../views';
import Documents from '../../../Widgets/Documents';

export const AgreementTab: React.FC<AgreementTabProp> = ({ navActive, stepState, fetchCompleted }) => {

  const { t } = useTranslation();
  const { showToast } = useToast();
  const { data: missingAgreements } = queries.useGetMissingAgreements();
  const { isLoading } = useLoader();

  const { mutate: generateLink, isLoading: signatureLoading } = mutations.useSignatureLink();

  const [agreementUrl, setAgreementUrl] = useState<string | null>(null);
  const [contractType, setContractType] = useState<ContractType | undefined>();

  // Function to handle the ceremony completed event
  function handleCeremonyCompleted(event: any) {
    fetchCompleted(STEP.Agreements);
  }

  // Function to handle the ceremony canceled event
  function handleCeremonyCanceled(event: any) {
    showToast("delete", "Contract was canceled by the user.");
  }

  // Function to handle the ceremony failed event
  function handleCeremonyFailed(event: any) {
    const { error_type, error_message } = event.data;
    showToast('error', `Contract failed with error: ${error_type} - ${error_message}`);
  }

  // Function to handle incoming message events
  function handleMessage(event: any) {
    const { type } = event.data;

    switch (type) {
      case 'ceremony.completed':
        handleCeremonyCompleted(event);
        break;
      case 'ceremony.canceled':
        handleCeremonyCanceled(event);
        break;
      case 'ceremony.failed':
        if (event?.error_type === "already_completed")
          handleCeremonyCompleted(event);
        else
          handleCeremonyFailed(event);
        break;
      default:
        console.warn(`Unknown event type: ${type}`);
    }
  }

  useEffect(() => {
    // Add event listener to window for message events
    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    if (contractType) {
      generateLink({ contractType: contractType }, {
        onSuccess: (response) => {
          setAgreementUrl(response.url);
        },
        onError: (e) => {
          if (Array.isArray(e)) {
            showToast("error", getMessages(e));
          } else {
            showToast("error", t("AgreementsTab.AgreementApprovalFailed"));
          }
        },
      });
    }
  }, [contractType]);

  // Trigger handleAcceptAgreement when navActive becomes 4
  useEffect(() => {
    console.log(navActive, stepState, missingAgreements);
    if (navActive === 4 && stepState === STEP_STATE.Pending) {
      if (missingAgreements && missingAgreements?.length > 0) {
        setContractType(missingAgreements[0]);
      }

    }
    // else if (navActive === 4 && missingAgreements?.length === 0 && stepState === STEP_STATE.Completed) {
    //  fetchCompleted(STEP.Agreements);
    // }
  }, [navActive, stepState, missingAgreements]); // Track navActive, prevNavActive, and agreementUrl

  return (
    <div className="profile-setup pt-2 pb-3 h-100">
      {signatureLoading && <LayoutLoader />}
      {(stepState === STEP_STATE.Waiting || stepState === STEP_STATE.Pending) &&
        <Form className="agreement">
          {stepState === STEP_STATE.Waiting && (
            <div className='verification-box'>
              <div>
                <Image src={dynamicImage("svg/verification_inprogress.svg")} className="img-fluid" alt="verification_inprogress" />
                <p>{t("YourAccountBeingVerifiedWeWillLetYouKnowWhenItCompleted")}</p>
              </div>
            </div>
          )}
          {
            stepState === STEP_STATE.Pending &&
            <div className="agreement-data">
              {agreementUrl && !isLoading ? (
                <iframe
                  id="signature-ceremony"
                  title="Signature"
                  width="100%"
                  height="100%"
                  src={`${agreementUrl}&embedded=true&event_delivery=message&accent_color=ff4a29`}
                  style={{ border: 'none', marginTop: '0px' }}
                />
              ) : (<LayoutLoader />)}
            </div>
          }
        </Form>}

      {stepState === STEP_STATE.Completed &&
        <Documents />
      }
    </div>
  );
};
