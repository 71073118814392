import { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col } from 'reactstrap'
import { Image, InvoiceStatusBadge } from '../../../AbstractElement';
import { dynamicImage } from '../../../utils';
import { CommonTable } from '../../../CommonComponent';
import { queries } from '../../../api';
import { InvoiceScope } from '../../../Types';
import { COLUMN_TYPE, TableClickedAction, TableConfig } from '../../../Types/CommonComponent';
import { ROUTES } from '../../../Constants';
import { useStateContext } from '../../../views';

export const RecentInvoices = () => {
  const { t } = useTranslation();
  const { openInvoiceDetailModal } = useStateContext();
  const { data: invoice } = queries.useGetLatestInvoiceScopes();

  const tableConfig = useMemo<TableConfig<InvoiceScope>>(() => ({
    columns: [
      { title: "Invoice", dataField: [{ field: "documentNo", class: 'top hover-link', type: '', renderer: (data: InvoiceScope) => { return <>{`#${data.documentNo}`}</> } }, { field: "projectName", renderer: (data: InvoiceScope) => { return <><span>{`(${data.projectName})`}</span></> } }], sortable: false, sortField: 'documentNo', sortDirection: 'desc' },
      { title: "Client", dataField: [{ field: 'companyName', class: 'top', type: '' }, { field: 'companyEmail', renderer: (data: InvoiceScope) => { return <><span>{`(${data.companyEmail})`}</span></> } }], sortable: false, sortField: 'companyName', sortDirection: 'desc' },
      { title: "InvoiceStatus", dataField: [{ field: 'invoiceStatus', class: '', type: '', renderer: (data: InvoiceScope) => { return <InvoiceStatusBadge invoiceStatus={data.invoiceStatus} />; } }, { field: "modifiedAt", class: 'date', type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: false, sortField: 'invoiceStatus', sortDirection: 'desc' },
      { title: "Amount", class: 'amount-header', dataField: [{ field: "totalIncludingTaxes", class: 'amount', type: 'amount', params: { currency: "currencyCode" } }], sortable: false, sortField: 'totalIncludingTaxes', sortDirection: 'desc' },
    ],
    actionsDropDown: [
      { label: "InvoiceDetails", actionToPerform: "invoiceDetails" },
      { label: "ProjectDetails", getNavigateUrl: (data: InvoiceScope) => `${ROUTES.ProjectDetails.replace(':id', data.projectId.toString())}` },
    ],
    data: invoice?.items ?? [],
    total: invoice?.totalResults ?? 0,
    isColumnChooser: false,
  }), [invoice]);

  const handleActionPerform = (action: TableClickedAction<InvoiceScope>) => {
    switch (action.actionToPerform) {
      case 'invoiceDetails':
        openInvoiceDetailModal(action.data.id);
        break;
    }
  }

  return (
    <>
      <Col xs="12" className='col-height'>
        <Card className="radius-bottom-0 h-100 recent-invoice-padding">
          <div className="recent-invoice-card overflow-hidden">
            <CardHeader>
              <h6>{t("RecentInvoices")}</h6>
            </CardHeader>
            <CardBody>
              {tableConfig.data.length > 0 ? (
                <div className="custom-scrollbar table-responsive recent-table">
                  <CommonTable tableConfiguration={tableConfig} pagingDisabled={true} onActionPerform={handleActionPerform} />
                </div>
              ) : (
                <div className="no-data-found">
                  <div>
                    <Image src={dynamicImage("svg/no-data/no-data-invoice.svg")} alt="invoice image" className="img-fluid" />
                    <h5 className='mt-3'>{t("YouHaventCreatedAnInvoiceYet")}</h5>
                  </div>
                </div>
              )}
            </CardBody>
          </div>
        </Card>
      </Col>
    </>
  )
}
