import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Image } from '../../../AbstractElement';
import { appInsights, queries } from '../../../api';
import { ROUTES } from '../../../Constants';
import { SUGGESTION_TYPE, SuggestionType } from '../../../Types';
import { dynamicImage } from '../../../utils';
import { useLayoutContext, useStateContext } from '../../../views';
import ProjectInvitation from '../../Projects/Canvas/ProjectInvitation';

const Suggestion = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { toggleProjectModal, toggleInvitationPopup, invitationPopup, openInvoiceViewModal, setActiveDashboardTab } = useStateContext();
    const { toggleQuestionnaireModal } = useLayoutContext();

    const { data: suggestion } = queries.useGetSuggestion({
        onSuccess: (data: any) => {
            setActiveDashboardTab((prev) => data.privilegedAction === true ? 2 : prev);
        }
    });

    const getActionIcon = (action: SuggestionType) => {
        switch (action) {
            case SUGGESTION_TYPE.WaitingForApproval:
                return dynamicImage("svg/suggestion/graphic_waitingapproval.svg")
            case SUGGESTION_TYPE.WaitingForPayment:
                return dynamicImage("svg/suggestion/graphic_waitingpayment.svg")
            case SUGGESTION_TYPE.JoinTheProject:
                return dynamicImage("svg/suggestion/graphics_invitation.svg")
            case SUGGESTION_TYPE.CompleteIdentityVerification:
                return dynamicImage("svg/suggestion/verification.svg")
            case SUGGESTION_TYPE.EnterBankAccountDetails:
                return dynamicImage("svg/suggestion/graphic_bankdetails.svg")
            case SUGGESTION_TYPE.ReviewTheAgreements:
                return dynamicImage("svg/suggestion/graphics_invoice_payment.svg")
            case SUGGESTION_TYPE.CreateProject:
                return dynamicImage("svg/suggestion/graphic_startproject.svg")
            case SUGGESTION_TYPE.CreateDraftInvoice:
                return dynamicImage("svg/suggestion/graphic_review_invoice.svg")
            case SUGGESTION_TYPE.GoToDraftInvoices:
                return dynamicImage("svg/suggestion/graphic_review_invoice.svg")
            case SUGGESTION_TYPE.Questionnaire:
                return dynamicImage("svg/suggestion/graphics_note.svg")
            case SUGGESTION_TYPE.SignUpDebitCardWaitingList:
                return dynamicImage("svg/suggestion/graphic_debit.svg")
            case SUGGESTION_TYPE.CreateDebitCard:
                return dynamicImage("svg/suggestion/graphic_debit.svg")
            case SUGGESTION_TYPE.ReferToFriend:
                return dynamicImage("svg/suggestion/graphic_hifive.svg")
            default:
                return dynamicImage("svg/suggestion/graphic_bird.svg")
        }
    }

    const handleAction = async (action: SuggestionType) => {
        switch (action) {
            case SUGGESTION_TYPE.WaitingForApproval:
                openInvoiceViewModal(suggestion?.targetRecordId)
                break;
            case SUGGESTION_TYPE.WaitingForPayment:
                navigate(`${ROUTES.Payment.replace(':id', suggestion?.targetRecordId)}`)
                break;
            case SUGGESTION_TYPE.WaitingForPaymentAgreement:
                navigate(`${ROUTES.PaymentAgreement.replace(':id', suggestion?.targetRecordId)}`)
                break;
            case SUGGESTION_TYPE.JoinTheProject:
                toggleInvitationPopup()
                break;
            case SUGGESTION_TYPE.CompleteIdentityVerification:
                navigate(`${ROUTES.FreelancerProfile}?tab=verification`)
                break;
            case SUGGESTION_TYPE.EnterBankAccountDetails:
                navigate(`${ROUTES.FreelancerProfile}?tab=bank-account`)
                break;
            case SUGGESTION_TYPE.ReviewTheAgreements:
                navigate(`${ROUTES.FreelancerProfile}?tab=agreements`)
                break;
            case SUGGESTION_TYPE.CreateProject:
                toggleProjectModal()
                break;
            case SUGGESTION_TYPE.CreateDraftInvoice:
                navigate(ROUTES.Invoice)
                break;
            case SUGGESTION_TYPE.GoToDraftInvoices:
                navigate(`${ROUTES.Invoices}?tab=draft`)
                break;
            case SUGGESTION_TYPE.SignUpDebitCardWaitingList:
            case SUGGESTION_TYPE.CreateDebitCard:
                navigate(ROUTES.Money)
                break;
            case SUGGESTION_TYPE.ReferToFriend:
                const AFFILIATE_URL = process.env.REACT_APP_AFFILIATE_URL ?? "";
                appInsights.trackEvent({
                    name: `Clicked button: Refer to a friend`,
                    properties: { RedirectUrl: AFFILIATE_URL }
                });

                window.open(AFFILIATE_URL, '_blank', 'noopener,noreferrer');
                break;
            case SUGGESTION_TYPE.Questionnaire:
                toggleQuestionnaireModal();
                break;
            default:
                break;
        }
    }

    const suggestionMessageValues = (suggestion?.messageValues || []).reduce((acc: { [x: string]: any; }, value: any, index: number) => {
        acc[index + 1] = value;
        return acc;
    }, {} as Record<number, string>);

    return (
        <>
            <div className="d-flex flex-sm-row flex-column align-items-center gap-sm-4 gap-0 text-sm-start text-center">
                <div className="profile-chart">
                    <Image src={getActionIcon(suggestion?.suggestionType)} alt="leaf" className="graphic_review_invoice" />
                    <div id="html-dist"></div>
                </div>
                <p>{t(suggestion?.message, suggestionMessageValues) as ReactNode}</p>
            </div>
            <Button className="ms-sm-auto me-sm-0 mx-auto text-nowrap" onClick={() => handleAction(suggestion?.suggestionType)}>
                {t(suggestion?.actionName)}
                <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt="arrow" />
            </Button>
            {invitationPopup && <ProjectInvitation id={suggestion?.targetRecordId} messageValues={suggestionMessageValues} />}
        </>
    )
}

export default Suggestion
