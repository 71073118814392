import React from 'react';
import { HeaderProps } from '../../../Types/Auth/Auth';
import { LogoWrapper } from './LogoWrapper';
import { useTranslation } from 'react-i18next';
import NotificationSection from '../../../Layout/Header/NotificationSection';
import ProfileSection from '../../../Layout/Header/ProfileSection';

// const NotificationSection = lazy(() => import('../../../Layout/Header/NotificationSection'));
// const ProfileSection = lazy(() => import('../../../Layout/Header/ProfileSection'));

export const Header: React.FC<HeaderProps> = ({ showNav, tabBadge }) => {
  const { t } = useTranslation()
  return (
    <div className="auth-header">
      <LogoWrapper tabBadge={tabBadge} />
      {showNav && (
        <ul className="nav-right">
          <NotificationSection />
          <ProfileSection hideSettings={true} />
        </ul>
      )}
    </div>
  );
}
