export const INVOICE_STATUS = {
    Draft: "Draft",
    WaitingForSentToBusiness: "WaitingForSentToBusiness",
    DeclineByJuuli: "DeclineByJuuli",
    WaitingForApproval: "WaitingForApproval",
    DeclineByBusiness: "DeclineByBusiness",
    WaitingForPayment: "WaitingForPayment",
    WaitingForPaymentAgreement: "WaitingForPaymentAgreement",
    WaitingForPaymentApprove: "WaitingForPaymentApprove",
    TransferFailedPayment: "TransferFailedPayment",
    WaitingForTransfer: "WaitingForTransfer",
    TransferFailed: "TransferFailed",
    CompletedTransferToFreelancer: "CompletedTransferToTalent",
    InvoiceCancelled: "InvoiceCancelled",
} as const;

export const CancelInvoiceStatuses: InvoiceStatus[] = [
    INVOICE_STATUS.InvoiceCancelled,
    INVOICE_STATUS.DeclineByBusiness,
    INVOICE_STATUS.DeclineByJuuli,
];

export const WaitingInvoiceStatuses: InvoiceStatus[] = [
    INVOICE_STATUS.WaitingForApproval,
    INVOICE_STATUS.WaitingForPayment,
    INVOICE_STATUS.WaitingForPaymentAgreement,
    INVOICE_STATUS.WaitingForPaymentApprove,
    INVOICE_STATUS.WaitingForSentToBusiness,
    INVOICE_STATUS.WaitingForTransfer,
    INVOICE_STATUS.TransferFailed,
    INVOICE_STATUS.TransferFailedPayment,
];

export type InvoiceStatus = ObjectValues<typeof INVOICE_STATUS>;
