import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Image, LinkButton, SolidButton } from '../../../../AbstractElement';
import { queries } from '../../../../api';
import { CommonSelect } from '../../../../CommonComponent';
import { useCoreData } from '../../../../Hooks';
import { FinancialProps } from '../../../../Types';
import { dynamicImage } from '../../../../utils';
import { useForm } from 'react-hook-form';
import { useLayoutContext, useStateContext } from '../../../../views';
import { ROUTES } from '../../../../Constants';
import CurrencyExchange from './Modal/CurrencyExchange';
import { useNavigate } from 'react-router-dom';

const DEPOSIT_TYPE = {
    BankAccount: "bank-account",
    JuuliIBAN: "juuli"
} as const;
type DepositType = ObjectValues<typeof DEPOSIT_TYPE>;

const Financial: React.FC<FinancialProps> = ({ tabItems, navActive, setNavActive, updateInvoiceInput, updateInvoice, loading, calculateAmount, invoiceCreate }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isMobile } = useLayoutContext()
    const { toggleProfileSetting } = useStateContext()
    const { currencyPayOutOptions } = useCoreData({ loadCurrenciesPayOut: true });
    const { data: bankAccounts } = queries.useGetBankAccounts();

    const [currencyExchange, setCurrencyExchange] = useState(false);
    const toggleCurrencyExchange = () => setCurrencyExchange(prev => !prev);

    const { handleSubmit } = useForm()
    const [formValue, setFormValue] = useState<{ startDate?: Date; dueDate?: Date; payoutCurrency: string; depositToType: DepositType; depositToAccountId: string }>({
        startDate: updateInvoiceInput.documentDate,
        dueDate: updateInvoiceInput.dueDate,
        payoutCurrency: updateInvoiceInput.payOutCurrencyCode ?? '',
        depositToType: DEPOSIT_TYPE.BankAccount,
        depositToAccountId: updateInvoiceInput.payOutBankAccountId ?? '',
    });

    // bank accounts filter by payout currency code
    const bankAccountOptions: { value: string; label: string }[] | undefined = useMemo(() => {
        return bankAccounts?.items?.filter((account) => account.currencyCode === formValue.payoutCurrency)
            ?.map((acc) => (
                {
                    value: acc?.id,
                    label: `${acc.label} (${acc?.currencyCode})`
                }))
    }, [bankAccounts, formValue, formValue.payoutCurrency]);


    useEffect(() => {
        setFormValue({
            startDate: updateInvoiceInput.documentDate,
            dueDate: updateInvoiceInput.dueDate,
            payoutCurrency: updateInvoiceInput.payOutCurrencyCode ?? '',
            depositToType: DEPOSIT_TYPE.BankAccount,
            depositToAccountId: updateInvoiceInput.payOutBankAccountId ?? '',
        })
    }, [updateInvoiceInput]);

    useEffect(() => {
        if (bankAccounts && (!formValue.payoutCurrency || bankAccounts?.items.find(f => f.id === formValue.depositToAccountId)?.currencyCode !== formValue.payoutCurrency)) {
            setFormValue({
                ...formValue,
                depositToAccountId: !formValue.payoutCurrency ? '' : bankAccounts?.items?.find(f => f.currencyCode == formValue.payoutCurrency)?.id || ''
            });
        }
    }, [bankAccounts, formValue.payoutCurrency]);

    const handleDepositChange = (value: DepositType) => {
        setFormValue({ ...formValue, depositToType: value })
    };

    const submitHandler = () => {
        updateInvoice(invoiceCreate, {
            ...updateInvoiceInput,
            dueDate: formValue.dueDate,
            documentDate: formValue.startDate,
            payOutBankAccountId: formValue.depositToAccountId,
            payOutCurrencyCode: formValue.payoutCurrency
        }, (response) => {
            if (navActive === 3) {
                if (response?.payOutCurrencyCode && response?.payOutCurrencyCode !== response.currencyCode) {
                    toggleCurrencyExchange();
                }
                setNavActive(4);
            }
        });
    };

    const handlePrevious = () => {
        if (navActive > 1) {
            setNavActive(navActive - 1);
        }
    };
    const isNextDisabled = !formValue.payoutCurrency || !formValue.depositToAccountId;

    return (
        <>
            <div className="invoice-main-content">
                <div className="invoice-main-box custom-scrollbar">
                    <Form noValidate onSubmit={handleSubmit(submitHandler)} className="h-100">
                        <div className="financial-main-box custom-scrollbar">
                            <Row className="g-md-4 g-3">
                                <Col md="6">
                                    <Row className="g-md-4 g-3">
                                        <Col xs="12">
                                            <Label>{t('PayoutCurrency')}</Label>
                                            <FormGroup className='mb-0'>
                                                <CommonSelect
                                                    selectedOptions={formValue.payoutCurrency}
                                                    options={currencyPayOutOptions}
                                                    setSelectedOptions={(value) => {
                                                        setFormValue({ ...formValue, payoutCurrency: value });
                                                    }}
                                                    multiSelect={false}
                                                    placeholder={t('Select')}
                                                    className="max-dropdown"
                                                    search={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">
                                            <Label>{t('InvoiceDate')}</Label>
                                            <div className="datepicker-div d-block">
                                                <FormGroup className="form-group position-relative mb-0 datepicker-form">
                                                    <DatePicker
                                                        {...(isMobile && { withPortal: true })}
                                                        placeholderText={t('SelectDate')}
                                                        className="form-control w-100"
                                                        selected={formValue.startDate}
                                                        onChange={(date) => setFormValue({ ...formValue, startDate: date ?? undefined })}
                                                        onKeyDown={(e: React.KeyboardEvent) => e.preventDefault()}
                                                        minDate={new Date(new Date().setDate(new Date().getDate() - 30))}
                                                        maxDate={formValue.dueDate ? new Date(formValue.dueDate) : new Date()}
                                                    />
                                                    <Image src={dynamicImage('svg/icon_calendar_red.svg')} className="date-picker-img" alt="calendar" />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xs="12">
                                            <Label>{t('DueDate')}</Label>
                                            <div className="datepicker-div d-block">
                                                <FormGroup className="form-group datepicker-form">
                                                    <DatePicker
                                                        {...(isMobile && { withPortal: true })}
                                                        placeholderText={t('SelectDueDate')}
                                                        className="form-control w-100"
                                                        selected={formValue.dueDate}
                                                        onChange={(date) => setFormValue({ ...formValue, dueDate: date ?? undefined })}
                                                        onKeyDown={(e: React.KeyboardEvent) => e.preventDefault()}
                                                        minDate={new Date()}
                                                    />
                                                    <Image src={dynamicImage('svg/icon_calendar_red.svg')} className="date-picker-img" alt="calendar" />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6">
                                    <Row className="g-sm-4 g-3">
                                        <Col xs="12">
                                            <Label>{t('DepositTo')}</Label>
                                            <FormGroup className="mb-0 form-control form-control-white">
                                                <div className="form-check">
                                                    <Input
                                                        type="radio"
                                                        name="deposit-radio"
                                                        className="form-radio-input"
                                                        id="iban-radio"
                                                        checked={formValue.depositToType === DEPOSIT_TYPE.BankAccount}
                                                        onChange={() => handleDepositChange(DEPOSIT_TYPE.BankAccount)}
                                                    />
                                                    <Label className="cursor-pointer w-100" htmlFor="iban-radio">
                                                        {t('BankAccount')}
                                                    </Label>
                                                </div>
                                                {bankAccountOptions && formValue.depositToType === DEPOSIT_TYPE.BankAccount && (
                                                    bankAccountOptions.length > 0 ?
                                                        <CommonSelect
                                                            selectedOptions={formValue.depositToAccountId}
                                                            options={bankAccountOptions}
                                                            setSelectedOptions={(selected) => setFormValue({ ...formValue, depositToAccountId: selected })}
                                                            multiSelect={false}
                                                            placeholder={t('Select')}
                                                            className="max-dropdown invoice-dropdown-2"
                                                        />
                                                        :
                                                        <div className="juuli-account" onClick={() => { }}>
                                                            <p>{t("NewInvoice.YouDontHaveBankAccountYet")}</p>
                                                            <SolidButton onClick={() => { toggleProfileSetting(5); }} className="juuli-account-link">
                                                                {t('AddAccount')}
                                                                <Image src={dynamicImage('svg/red-right-arrow.svg')} alt="right arrow" />
                                                            </SolidButton>
                                                        </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">
                                            <FormGroup className="mb-0 form-control form-control-white">
                                                <div className="form-check">
                                                    <Input
                                                        type="radio"
                                                        name="deposit-radio"
                                                        className="form-radio-input"
                                                        id="juuli-radio"
                                                        checked={formValue.depositToType === DEPOSIT_TYPE.JuuliIBAN}
                                                        onChange={() => handleDepositChange(DEPOSIT_TYPE.JuuliIBAN)}
                                                    />
                                                    <Label className="cursor-pointer w-100" htmlFor="juuli-radio">
                                                        {t('JuuliIBAN')}
                                                    </Label>
                                                </div>
                                                {formValue.depositToType === DEPOSIT_TYPE.JuuliIBAN && (
                                                    <div className="juuli-account">
                                                        <p>{t("NewInvoice.YouDontHaveJuuliIbanYet")}</p>
                                                        <LinkButton to={ROUTES.Money} className="juuli-account-link">
                                                            {t('GetAJuuliIban')}
                                                            <Image src={dynamicImage('svg/red-right-arrow.svg')} alt="right arrow" />
                                                        </LinkButton>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        {/* TODO:  It’s not active right now; it will be opened later.
                                        <Col xs="12">
                                            <FormGroup className="mb-0 form-control form-control-white">
                                                <div className="form-check">
                                                    <Input
                                                        type="radio"
                                                        name="deposit-radio"
                                                        className="form-radio-input"
                                                        id="wallet-radio"
                                                        checked={depositTo === 'wallet'}
                                                        onChange={() => handleDepositChange('wallet')}
                                                    />
                                                    <Label className="cursor-pointer w-100" htmlFor="wallet-radio">
                                                        {t('Wallet')}
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="footer-buttons-main financial-footer">
                            <div className="footer-buttons">
                                <SolidButton className="btn-gray" onClick={handlePrevious}>
                                    <Image src={dynamicImage("svg/left-arrow.svg")} alt="arrow" />
                                    {t("Previous")}
                                </SolidButton>
                                <SolidButton type="submit" loading={loading}
                                    disabled={isNextDisabled || navActive >= tabItems.length}>
                                    {t("Next")}
                                    {!loading && <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt="arrow" className="next" />}
                                </SolidButton>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            {currencyExchange && !loading && (
                <CurrencyExchange
                    currencyExchange={currencyExchange}
                    toggleCurrencyExchange={toggleCurrencyExchange}
                    calculateAmount={calculateAmount}
                />
            )}
        </>
    );
};

export default Financial;
