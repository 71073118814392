import { useTranslation } from 'react-i18next'
import { Col, FormGroup, Row } from 'reactstrap'
import { useEffect, useState } from 'react'
import { Image, LinkButton, SolidButton } from '../../../../AbstractElement'
import { CommonSelect } from '../../../../CommonComponent'
import { useCoreData } from '../../../../Hooks'
import { InvoiceProjectProps } from '../../../../Types'
import { dynamicImage } from '../../../../utils'
import { queries } from '../../../../api'
import { useNavigate } from 'react-router-dom'

const Project: React.FC<InvoiceProjectProps> = ({ invoiceId, tabItems, invoiceCreate, setInvoiceCreate, setNavActive, navActive }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { customersSelect, countryOptions, isFetchedCustomer } = useCoreData({ loadCustomers: true, loadCountries: true });
    const [selectedCountryOptions, setSelectedCountryOptions] = useState<string | null>(null);

    const { data: clientInfo } = queries.useGetCustomer(invoiceCreate.customerId!);
    const { data: customerProjects } = queries.useGetCustomerProject(invoiceCreate?.customerId!);
    const [emailError, setEmailError] = useState("");
    const [entryNewClient, setEntryNewClient] = useState<boolean>(false);
    const [createNewProject, setCreateNewProject] = useState<boolean>(false);

    const handleNext = () => {
        if (navActive < tabItems.length) {
            setNavActive(navActive + 1);
        }
    };

    useEffect(() => {
        if (invoiceId && invoiceCreate?.customerId && entryNewClient) {
            setEntryNewClient(false);
        }
    }, [invoiceId, invoiceCreate?.customerId])

    useEffect(() => {
        if (customerProjects) {
            if (customerProjects?.items?.length > 0 && !!invoiceCreate.customerId) {
                // If projectId exists in invoiceCreate, select it
                setCreateNewProject(false);
                const project = customerProjects?.items.find(res => res.id === invoiceCreate?.projectId)
                if (!!invoiceCreate?.projectId && !!project) {
                    setInvoiceCreate({ ...invoiceCreate, projectId: invoiceCreate.projectId });
                }
                else {
                    const defaultProjectId = customerProjects?.items[0].id;
                    setInvoiceCreate({ ...invoiceCreate, projectId: defaultProjectId });
                }
            }
            // If no customer projects or user is creating a new client
            else if (customerProjects?.items?.length === 0 || !invoiceCreate.customerId) {
                setInvoiceCreate({ ...invoiceCreate, projectId: '' });
            }
        }
        // Run whenever customerProjects or customerId changes
    }, [customerProjects]);

    // Modify handleRadioChange to properly reset projectName when "create-new" is selected
    const handleRadioChange = (e: any) => {
        document.body.classList.add('no-loader')
        const value = e.target.value;

        if (value === 'create-new') {
            setCreateNewProject(true)
            setInvoiceCreate({ ...invoiceCreate, projectId: undefined, projectName: clientInfo?.name ?? invoiceCreate?.customerName ?? '' });
        } else {
            setCreateNewProject(false)
            setInvoiceCreate({ ...invoiceCreate, projectId: value });
        }
    };

    useEffect(() => {
        setEntryNewClient(!invoiceId && isFetchedCustomer && !!customersSelect && customersSelect.length === 0);
    }, [isFetchedCustomer, customersSelect]);

    useEffect(() => {
        if (entryNewClient === true)
            setCreateNewProject(true);
    }, [entryNewClient]);

    const isNextDisabled = !((!!invoiceId && !!invoiceCreate.projectId) || (!invoiceId && (invoiceCreate?.customerId || (invoiceCreate?.customerName && invoiceCreate?.email && !emailError && invoiceCreate?.countryCode)) && (invoiceCreate?.projectId || !!invoiceCreate?.projectName)))
    return (
        <div className="invoice-main-content">
            <div className="invoice-main-box custom-scrollbar">
                <Row className="gy-xl-3 g-3 gy-5">
                    <Col xl="6">
                        <Row className="gy-xl-3 g-3 gy-4">
                            <Col xl="12">
                                <div className="client-dropdown-box">
                                    <label>{t(entryNewClient ? "NewClientName" : "Client")}</label>
                                    <FormGroup className='mb-0'>
                                        <CommonSelect
                                            selectedOptions={invoiceCreate.customerId}
                                            setSelectedOptions={(value) => {
                                                if (invoiceCreate.customerId !== value) {
                                                    setEntryNewClient(!invoiceId && !value);
                                                    setInvoiceCreate({ ...invoiceCreate, customerId: value, customerName: '', email: '', countryCode: undefined, projectId: undefined });
                                                }
                                            }}
                                            options={customersSelect}
                                            placeholder={t(entryNewClient ? "EnterNewClientName" : invoiceId ? "SelectClient" : "SelectOrEnterNewClientName")}
                                            className='max-dropdown'
                                            multiSelect={false}
                                            search={false}
                                            inputSearch={true}
                                            buttonText={invoiceId || entryNewClient ? "" : t("NewClient")}
                                            buttonClass='create-btn'
                                            onClick={(value: string) => {
                                                if (!invoiceId) {
                                                    setEntryNewClient(true);
                                                    setInvoiceCreate({ ...invoiceCreate, customerId: undefined, customerName: value })
                                                }
                                            }}
                                            onChangeInputSearch={(value: string) => {
                                                if (entryNewClient === true)
                                                    setInvoiceCreate({ ...invoiceCreate, customerId: undefined, customerName: value })
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </Col>
                            {entryNewClient &&
                                <>
                                    <Col xl="12">
                                        <label>{t("NewClientEmail")}</label>
                                        <FormGroup className='mb-0'>
                                            <input
                                                className="form-control opacity-100 digit"
                                                id="email"
                                                type="email"
                                                placeholder={t("EnterEmail")}
                                                onChange={(e) => {
                                                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                    const isValidEmail = emailRegex.test(e.target.value);

                                                    // Set error message if email is invalid
                                                    setEmailError(isValidEmail ? "" : t("InvalidEmailMessage"));
                                                    setInvoiceCreate({
                                                        ...invoiceCreate,
                                                        email: e.target.value,
                                                    });
                                                }}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                        {emailError && (
                                            <span className="required-note">
                                                <small>{emailError}</small>
                                            </span>
                                        )}
                                    </Col>
                                    <Col xl="12">
                                        <FormGroup className='mb-0'>
                                            <label>{t("NewClientCountry")}</label>
                                            <CommonSelect
                                                selectedOptions={selectedCountryOptions}
                                                options={countryOptions}
                                                setSelectedOptions={(value) => {
                                                    setSelectedCountryOptions(value)
                                                    setInvoiceCreate({ ...invoiceCreate, customerId: undefined, countryCode: value })
                                                }}
                                                multiSelect={false}
                                                placeholder={t('Select')}
                                                className="max-dropdown"
                                                search={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                        </Row>
                        {
                            clientInfo && invoiceCreate?.customerId &&
                            <div className="inform-box">
                                <label>{t("ClientInformation")}</label>

                                <div className="project common-f-flex">
                                    <span className="initial-letter"> {clientInfo?.name
                                        ?.split(" ")
                                        ?.map((letter: string) => letter.charAt(0).toUpperCase())
                                        ?.join("")?.slice(0, 2)}</span>
                                    <div className='project-name'>
                                        <h4>{clientInfo?.name} {clientInfo?.verified && <Image src={dynamicImage("svg/blue-tick.svg")} className="blue-tick-icon" alt="edit" />}</h4>
                                        <span>({clientInfo?.email})</span>
                                    </div>
                                </div>

                                <ul className="client-inform-list custom-scroll">
                                    {
                                        clientInfo?.name &&
                                        <li>
                                            <h5>{t("InvoiceReceiverName")}</h5>
                                            <h6>{clientInfo?.name}</h6>
                                        </li>
                                    }
                                    {
                                        clientInfo?.phone &&
                                        <li>
                                            <h5>{t("PhoneNumberId")}</h5>
                                            <h6>{clientInfo?.phone}</h6>
                                        </li>
                                    }
                                    {
                                        clientInfo?.addressLine1 &&
                                        <li>
                                            <h5>{t("Address")}</h5>
                                            <h6>{clientInfo?.addressLine1}, {clientInfo?.city}, {clientInfo?.state}</h6>
                                        </li>
                                    }
                                    {
                                        clientInfo?.countryName &&
                                        <li>
                                            <h5>{t("Country")}</h5>
                                            <h6>{clientInfo?.countryName}</h6>
                                        </li>
                                    }
                                    {
                                        clientInfo?.registrationNumber &&
                                        <li>
                                            <h5>{t("RegistrationNumber")}</h5>
                                            <h6>{clientInfo?.registrationNumber}</h6>
                                        </li>
                                    }
                                    {
                                        clientInfo?.taxOffice &&
                                        <li>
                                            <h5>{t("TaxOffice")}</h5>
                                            <h6>{clientInfo?.taxOffice}</h6>
                                        </li>
                                    }
                                    {
                                        clientInfo?.vatNumber &&
                                        <li>
                                            <h5>{t("VatNumber")}</h5>
                                            <h6>{clientInfo?.vatNumber}</h6>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                    </Col>
                    {(!!invoiceCreate.customerId || !!createNewProject) && <Col xl="6">
                        <label>{t("ProjectsWithThisClient")}</label>
                        <div className="project-right-box custom-scroll">
                            <div className="project-right-list">
                                <ul className='custom-scroll'>
                                    {invoiceCreate.customerId && customerProjects?.items
                                        // .sort((a, b) => (a.id === invoiceCreate.projectId ? -1 : 1))
                                        .map((project, index) => (
                                            <li key={index} className="">
                                                <div className="">
                                                    <input
                                                        name="project-radio"
                                                        id={project.id}
                                                        type="radio"
                                                        className="form-radio-input form-check-input"
                                                        value={project.id}
                                                        checked={invoiceCreate.projectId === project.id}
                                                        onChange={handleRadioChange}
                                                    />

                                                    <label htmlFor={project.id} className="cursor-pointer w-100 form-label"><div className="circle"></div> {project?.projectName}</label>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                                {
                                    !invoiceId &&
                                    <>
                                        <div className="form-control form-control-white rounded-form create-new-project">
                                            <div className="form-check">
                                                <input
                                                    name="project-radio"
                                                    id="create-new"
                                                    type="radio"
                                                    value="create-new"
                                                    className="form-radio-input form-check-input"
                                                    checked={createNewProject}
                                                    onChange={handleRadioChange}
                                                />
                                                <label htmlFor="create-new" className="cursor-pointer w-100 form-label"><div className="circle"></div> {t("CreateNewProject")}</label>
                                            </div>

                                            <div className="project-input-box">
                                                <div className="form-check">
                                                    <input
                                                        id="projectName"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Enter a name for the project...'
                                                        value={invoiceCreate.projectName}
                                                        onChange={(e) => {
                                                            setInvoiceCreate({ ...invoiceCreate, projectName: e.target.value.toString() })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </Col>
                    }
                </Row>
            </div>
            <div className="footer-buttons-main">
                <div className="footer-buttons">
                    <SolidButton className="justify-content-center btn-gray" onClick={() => navigate(-1)}>{t("Cancel")}</SolidButton>
                    <SolidButton type="submit" onClick={handleNext} disabled={isNextDisabled || navActive >= tabItems.length}>
                        {t("Next")}
                        <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt="arrow" className="next" />
                    </SolidButton>
                </div>
            </div>
        </div >
    )
}
export default Project