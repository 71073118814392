import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, SolidButton } from "../../../../AbstractElement";
import { mutations } from "../../../../api";
import { CommonSelect, LayoutLoader } from "../../../../CommonComponent";
import { useCoreData } from "../../../../Hooks";
import { InvoiceLine } from "../../../../Types";
import { convertToReadableAmount, dynamicImage } from "../../../../utils";

const WorkItemForm: React.FC<any> = ({ item, setAction, editableRowId, setEditableRowId, invoiceId, selectedCurrencyOptions, calculateAmount, refetchCalculate, invoiceLines, setInvoiceLines }) => {
    const { t } = useTranslation();
    const { unitsCodeOptions } = useCoreData({ loadInvoiceUnits: true });

    const [selectedUnitCodeOptions, setSelectedUnitCodeOptions] = useState<string>(item.unitCode || null);
    const [errors, setErrors] = useState({ itemName: "", quantity: "", price: "", unitCode: "" });
    const [show, setShow] = useState(false)

    const [formData, setFormData] = useState<InvoiceLine>();
    const quantityInputRef = useRef<HTMLInputElement>(null);
    const priceInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (item) {
            setFormData({
                id: item.id,
                itemName: item.itemName,
                description: item.description,
                unitCode: item.unitCode,
                quantity: item.quantity,
                price: item.price
            });
        }
    }, [item]);

    const { mutate: createInvoiceLine, isLoading: creating } = mutations.usePostInvoiceLine();
    const { mutate: updateInvoiceLine, isLoading: updating } = mutations.usePutInvoiceLine();
    const { mutate: deleteInvoiceLine, isLoading: deleting } = mutations.useDeleteInvoiceLine();

    const validateField = (name: string, value: any) => {

        let error = "";

        switch (name) {
            case "itemName":
                if (!value) {
                    error = t("IsRequired", { name: "Item name" });
                }
                break;
            case "unitCode":
                if (!value || value == null) {
                    error = t("IsRequired", { name: "Unit code" });
                }
                break;
            case "quantity":
                if (value == null || value <= 0) {
                    error = t("IsRequired", { name: "Quantity" });
                }
                break;
            case "price":
                if (value == null || value <= 0) {
                    error = t("IsRequired", { name: "Price" });
                }
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
        return error === "";
    };

    const validateForm = () => {
        const newErrors = { itemName: "", quantity: "", price: "", unitCode: "" };

        if (!formData?.itemName) {
            newErrors.itemName = t("IsRequired", { name: "Item name" });
        }

        if (formData?.quantity == null || formData?.quantity <= 0) {
            newErrors.quantity = t("IsRequired", { name: "Quantity" });
        }

        if (!formData?.unitCode || formData.unitCode == null) {
            newErrors.unitCode = t("IsRequired", { name: "Unit Code" });
        }

        if (formData?.price == null || formData?.price <= 0) {
            newErrors.price = t("IsRequired", { name: "Price" });
        }

        setErrors(newErrors);

        return !newErrors.itemName && !newErrors.quantity && !newErrors.price && !newErrors.unitCode;
    };

    const actionToPerform = (action: 'close' | 'post' | 'edit' | 'delete') => {
        setShow(true)
        setAction(action);
        if (!validateForm() && action !== "close" || !formData) {
            return;
        }
        if (action === "post") {
            setEditableRowId(null); // Stop editing after saving

            if (!formData?.id) {
                createInvoiceLine({ id: invoiceId, input: { ...formData, unitCode: selectedUnitCodeOptions } }, {
                    onSuccess: (res: InvoiceLine) => {
                        setFormData({ ...formData, id: res.id })
                        setEditableRowId(null)
                    },
                })
            }

            if (formData?.id) {
                setEditableRowId(formData.id);
                updateInvoiceLine({ invoiceId: invoiceId, id: editableRowId, input: formData }, {
                    onSuccess: () => {
                        setEditableRowId(null)
                    },
                })
            }
        }

        if (action === 'edit') {
            setEditableRowId(formData.id);
        }

        if (action === "close") {
            if (formData.id) {
                setEditableRowId(null); // Stop editing only if item has an id
            } else {
                const items = invoiceLines?.slice(0, -1);
                setInvoiceLines(items!);
                setEditableRowId(null)
            }
        }

        if (action === "delete") {
            deleteInvoiceLine({ invoiceId: invoiceId, id: formData.id, input: formData }, {
                onSuccess: () => {
                    setEditableRowId(null)
                }
            })
        }
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        let formattedValue = value;

        if (name === "price") {
            // Allow numbers and a single decimal point during input
            const regex = /^\d*\.?\d{0,2}$/;
            if (regex.test(value)) {
                formattedValue = value;
            } else {
                return; // Exit if the value doesn't match the regex
            }
        }

        setFormData({
            ...formData,
            [name]: formattedValue,
        });
        show && validateField(name, formattedValue);
    };

    if (!formData) {
        return (<>
            <LayoutLoader />
        </>)
    }

    return (
        <tr key={formData.id} className={(editableRowId === formData.id && invoiceId) || !formData.id ? "editing" : ""}>
            <td>
                <div className="table-border-color">
                    <span className="responsive-span">{t("ItemName")}</span>
                    <div>
                        <h6>{t("ItemName")}</h6>
                        <input
                            type="text"
                            name="itemName"
                            placeholder={t("EnterItemName")}
                            className="w-100"
                            autoComplete="off"
                            value={formData?.itemName}
                            onChange={handleInputChange}
                            disabled={(editableRowId !== formData.id && !!formData.id) || !invoiceId}
                        />
                    </div>
                </div>
                {errors.itemName && (
                    <span className="required-note">
                        <small>{t(errors.itemName)}</small>
                    </span>
                )}
            </td>
            <td>
                <div className="table-border-color">
                    <span className="responsive-span">{t("Description")}</span>
                    <div>
                        <h6>{t("Description")}</h6>
                        <input
                            type="text"
                            name="description"
                            placeholder={t("EnterDescription")}
                            autoComplete="off"
                            className="w-100"
                            value={formData?.description}
                            onChange={handleInputChange}
                            disabled={(editableRowId !== formData.id && !!formData.id) || !invoiceId}
                        />
                    </div>
                </div>
            </td>
            <td className="qty-data">
                <div className="table-border-color">
                    <div className="d-flex justify-content-between gap-3 align-items-center">
                        <span className="responsive-span m-0">{t("QTY")}</span>
                        <div className="w-full">
                            <h6>{t("QTY")}</h6>
                            {unitsCodeOptions && <CommonSelect
                                options={unitsCodeOptions}
                                selectedOptions={selectedUnitCodeOptions}
                                setSelectedOptions={(value) => {
                                    setSelectedUnitCodeOptions(value);
                                    setFormData({ ...formData, unitCode: value })
                                    validateField("unitCode", value)
                                }}
                                multiSelect={false}
                                placeholder={t('Select')}
                                className={'max-filter-dropdown work-dropdown body'}
                                disabled={(editableRowId !== formData.id && !!formData.id) || !invoiceId}
                            />}
                            <div className="d-flex align-items-center gap-2">
                                <input
                                    type="number"
                                    name="quantity"
                                    className="fw-bolder"
                                    value={formData?.quantity}
                                    autoComplete="off"
                                    disabled={(editableRowId !== formData.id && !!formData.id) || !invoiceId}
                                    onChange={handleInputChange}
                                    ref={quantityInputRef}
                                    onFocus={() => { quantityInputRef.current && quantityInputRef.current.select() }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {errors.quantity && (
                    <span className="required-note">
                        <small>{t(errors.quantity)}</small>
                    </span>
                )}
                {!errors.quantity && errors.unitCode && (
                    <span className="required-note">
                        <small>{t(errors.unitCode)}</small>
                    </span>
                )}
            </td>
            <td>
                <div className="d-sm-block d-flex justify-content-between gap-3 align-items-center table-border-color">
                    <span className="responsive-span m-0">{t("Price")}</span>
                    <div className="d-flex justify-content-end w-full">
                        <h6>{t("Price")}</h6>
                        <input
                            type="text"
                            name="price"
                            placeholder={t("EnterPrice")}
                            className="w-100"
                            value={
                                editableRowId === formData.id || !formData.id
                                    ? formData?.price
                                    : convertToReadableAmount(Number(formData?.price), calculateAmount?.currencyCode)
                            }
                            onChange={handleInputChange}
                            ref={priceInputRef}
                            onFocus={() => { priceInputRef.current && priceInputRef.current.select() }}
                            disabled={(editableRowId !== formData.id && !!formData.id) || !invoiceId}
                            autoComplete="off"
                        />
                    </div>
                </div>
                {errors.price && (
                    <span className="required-note">
                        <small>{t(errors.price)}</small>
                    </span>
                )}
            </td>
            <td>
                <div className="d-sm-block d-flex justify-content-between gap-3 align-items-center table-border-color">
                    <span className="responsive-span m-0">{t("Total")}</span>
                    <div className="price-data-box">
                        <h6>{t("Total")}</h6>
                        <input
                            type="text"
                            name="price"
                            placeholder={t("EnterPrice")}
                            className="w-100"
                            value={
                                convertToReadableAmount(Number(formData?.quantity || 0) * Number(formData?.price || 0), calculateAmount?.currencyCode)
                            }
                            onChange={handleInputChange}
                            disabled={(editableRowId !== formData.id && !!formData.id) || !invoiceId}
                            autoComplete="off"
                        />
                    </div>
                </div>
            </td>
            <td>
                <div className="table-border-color work-item-option">
                    {formData.id ? (
                        editableRowId === formData.id ? (
                            <SolidButton className="list-action p-0" color="transparent" onClick={() => actionToPerform("close")} disabled={creating || updating || deleting}>
                                <Image src={dynamicImage("svg/close.svg")} alt="link" className="close" />
                            </SolidButton>
                        ) : (
                            <SolidButton className="list-action p-0" color="transparent" onClick={() => actionToPerform("delete")} loading={deleting} disabled={creating || updating || deleting}>
                                {!deleting && <Image src={dynamicImage("svg/delete.svg")} alt="link" />}
                            </SolidButton>
                        )
                    ) : (
                        <SolidButton className={`list-action p-0`} color="transparent" onClick={() => actionToPerform("close")} disabled={invoiceLines.length <= 1}>
                            <Image src={dynamicImage("svg/close.svg")} alt="link" />
                        </SolidButton>
                    )}
                    {editableRowId === formData.id || !formData.id ? (
                        <SolidButton color="transparent" className={`list-action bg-success-medium p-0 ${!invoiceId ? 'disabled' : ''}`} onClick={() => actionToPerform("post")} loading={creating || updating} disabled={(!!formData.id && !editableRowId) || creating || updating || deleting}>
                            {!(creating || updating) && <Image src={dynamicImage("svg/tick.svg")} alt="link" />}
                        </SolidButton>
                    ) : (
                        <SolidButton className="list-action p-0" color="transparent" onClick={() => actionToPerform("edit")} loading={updating} disabled={creating || updating || deleting}>
                            {!updating && <Image src={dynamicImage("svg/edit.svg")} alt="link" />}
                        </SolidButton>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default WorkItemForm;