import { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useLayoutContext, useStateContext } from "../../../views"
import { useTranslation } from "react-i18next"
import { Image, SolidButton } from "../../../AbstractElement"
import { dynamicImage } from "../../../utils"
import { mutations } from "../../../api"
import { ConfirmationDialog } from "../../../CommonComponent"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../../Constants"

const ProjectInvitation: React.FC<{ id: string, messageValues: Record<number, string> }> = ({ id, messageValues }) => {

    const { invitationPopup, toggleInvitationPopup } = useStateContext()
    const { modalPosition } = useLayoutContext()
    const { t } = useTranslation()
    const { mutate: projectInvitation } = mutations.usePutProjectInvitation();
    const [declineReason, setDeclineReason] = useState('');
    const navigate = useNavigate();

    const handleInvitationResponse = (status: string) => {
        if (status === "Declined") {
            ConfirmationDialog({
                title: "Reject Invitation",
                text: "Are you sure you want to reject the invitation to join this project?",
                confirmButtonText: "Reject",
                onConfirm: (isConfirmed) => {
                    if (isConfirmed) {
                        projectInvitation({ id: id, input: { status: status, declineReason: declineReason } }, {
                            onSuccess: () => {
                                console.log("success")
                            },
                        })
                    }
                }
            });
        }
        else {
            projectInvitation({ id: id, input: { status: status } }, {
                onSuccess: () => {
                    navigate(`${ROUTES.ProjectDetails.replace(':id', id.toString())}`);
                },
            })
        }
        toggleInvitationPopup()
    }

    return (
        <Modal centered dir={modalPosition} isOpen={invitationPopup} toggle={toggleInvitationPopup} className="invited-modal theme-modal">
            <ModalHeader toggle={toggleInvitationPopup}>{t("InvitationToProject")}</ModalHeader>
            <ModalBody>
                <div>
                    <div className="maintance-image">
                        <Image src={dynamicImage("svg/invitation.svg")} alt="invitation" />
                        <p dangerouslySetInnerHTML={{ __html: t("Suggestion.BusinessHasInvitedYouToJoinProject.Modal", { ...messageValues }) as string }}></p>
                        <textarea className="form-control" placeholder="Send a message(optional)..."
                            value={declineReason}
                            onChange={(e) => setDeclineReason(e.target.value)}
                            autoComplete="off"
                            rows={3} />
                    </div>
                    <div className="footer">
                        <SolidButton className="btn-gray justify-content-center" onClick={toggleInvitationPopup}>
                            {t("Close")}
                        </SolidButton>
                        <SolidButton color="" className="custom-confirm-black justify-content-center" onClick={() => handleInvitationResponse("Declined")}>
                            {t("Reject")}
                        </SolidButton>
                        <SolidButton color="success" className="justify-content-center" onClick={() => handleInvitationResponse("Accepted")}>
                            {t("Accept")}
                        </SolidButton>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ProjectInvitation
