import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Image, LinkButton } from '../../../AbstractElement';
import { mutations, queries } from '../../../api';
import { CommonTable, ConfirmationDialog } from '../../../CommonComponent';
import { ROUTES } from '../../../Constants';
import { useAuth, useCoreData, useTableFilterHelper } from '../../../Hooks';
import { DraftInvoiceItem, USER_ROLE } from '../../../Types';
import { Column, COLUMN_TYPE, TableClickedAction, TableConfig } from '../../../Types/CommonComponent';
import { dynamicImage } from '../../../utils';
import { useLayoutContext, useStateContext, useToast } from '../../../views';

const DraftInvoices = ({ detailPage }: { detailPage?: boolean; }) => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { user } = useAuth();
    const { isMobile } = useLayoutContext()
    const { openInvoiceDraftViewModal } = useStateContext();
    const searchBar = document.getElementById('draftSearch')

    const { mutate: refetchDownload } = mutations.useDownloadDraftInvoicePdf();
    const { mutate: refetchDeleteInvoice } = mutations.useDeleteInvoiceDraft();

    const { pageNumber, pageSize, searchTerm, params, goToPage, handleSetPageSize, handleSetSearch, toggleSortType, handleSetSortBy } = useTableFilterHelper(id ? { projectId: id } : { archiveStatus: false, projectId: id });

    const { projectCategoriesOptions } = useCoreData({ loadProjectCategories: true });
    const { data: invoices, refetch: refetchInvoice } = queries.useGetDraftInvoices(params);
    const { data: project } = queries.useGetProject(id);
    const { showToast } = useToast()

    // Table configuration state  
    const tableConfig = useMemo<TableConfig<DraftInvoiceItem>>(() => ({
        columns: [
            ...(!detailPage ? [
                { title: "ProjectName", class: 'project-name-box', dataField: [{ field: "projectName", class: '', type: '' }, { field: "projectCategoryCode", class: '', type: '', renderer: (data: DraftInvoiceItem) => <span>{projectCategoriesOptions?.find(f => f.value === data.projectCategoryCode)?.label}</span> }], sortable: true, sortField: 'projectName', sortDirection: 'desc' } as Column<DraftInvoiceItem>
            ] : []),
            { title: "Client", class: 'client-box', dataField: [{ field: "customerName", class: 'top', type: '' }, { field: "customerEmail", class: '', type: '' }], sortable: true, sortField: 'customerName', sortDirection: 'desc', hidden: detailPage },
            { title: "CreationDate", dataField: [{ field: "createdAt", class: '', type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'createdAt', sortDirection: 'desc', hidden: true },
            { title: "LastUpdate", dataField: [{ field: "modifiedAt", class: '', type: COLUMN_TYPE.Date, params: { format: "long" } }], sortable: true, sortField: 'modifiedAt', sortDirection: 'desc', hidden: true },
            { title: "InvoiceNumber", class: "invoice-number", dataField: [{ field: "documentNo", class: 'hover-link', type: '', renderer: (data: DraftInvoiceItem) => { return <span className='text hover-link'>{`#${data.documentNo}`}</span> } }], sortable: true, sortField: 'documentNo', sortDirection: 'desc' },
            { title: "InvoiceDate", class: 'invoice-date', dataField: [{ field: "documentDate", class: 'text', type: COLUMN_TYPE.Date, params: { format: "medium" } }], sortable: true, sortField: 'documentDate', sortDirection: 'desc' },
            { title: "DueDate", dataField: [{ field: "dueDate", class: '', type: COLUMN_TYPE.Date, params: { format: "medium" } }], sortable: true, sortField: 'dueDate', sortDirection: 'desc', hidden: true },
            { title: "InvoiceAmount", dataField: [{ field: "total", class: 'adsd', type: 'amount', params: { currency: "currencyCode" } }], sortable: true, sortField: 'total', sortDirection: 'desc', hidden: true },
            { title: "TotalTax", dataField: [{ field: "vatAmount", class: '', type: 'amount', params: { currency: "currencyCode" } }], sortable: true, sortField: 'vatAmount', sortDirection: 'desc', hidden: true },
            { title: "Amount", class: 'amount-header', dataField: [{ field: "totalIncludingTaxes", class: 'amount', type: 'amount', params: { currency: "currencyCode" } }], sortable: true, sortField: 'totalIncludingTaxes', sortDirection: 'desc' },
        ],
        actionsDropDown: user?.role === USER_ROLE.Freelancer
            ? [
                { label: "Edit", getNavigateUrl: (data: DraftInvoiceItem) => `${ROUTES.InvoiceEdit.replace(':id', data.id.toString())}` },
                { label: "ReviewAndFinalize", getNavigateUrl: (data: DraftInvoiceItem) => `${ROUTES.InvoiceEdit.replace(':id', data.id.toString())}?tab=summary` },
                { label: "Preview", actionToPerform: "preview" },
                ...(!detailPage ? [{ label: "ProjectDetails", getNavigateUrl: (data: DraftInvoiceItem) => ROUTES.ProjectDetails.replace(':id', data.projectId.toString()) }] : []),
                { label: "Download", actionToPerform: "download" },
                { label: "Delete", actionToPerform: "delete" },
            ]
            : [],
        data: invoices?.items ?? [],
        total: invoices?.totalResults ?? 0,
        isColumnChooser: true,
    }), [invoices]);

    const handleActionPerform = async (action: TableClickedAction<DraftInvoiceItem>) => {

        switch (action.actionToPerform) {
            case 'preview':
                openInvoiceDraftViewModal(action.data.id);
                break;
            case 'download':
                refetchDownload({ id: action.data.id }, { onSuccess: () => showToast("success", "InvoiceDetailCanvas.DownloadedInvoice") });
                break;
            case 'delete':
                ConfirmationDialog({
                    title: "Delete Draft Invoice",
                    text: "Are you sure you want to delete this draft invoice? This action cannot be undone.",
                    confirmButtonText: "Delete",
                    onConfirm: (isConfirmed) => {
                        if (isConfirmed) {
                            refetchDeleteInvoice(action.data.id, {
                                onSuccess: (result) => {
                                    refetchInvoice();
                                    showToast("delete", "Invoice.InvoiceDeletedSuccessfully")
                                },
                            })
                        }
                    }
                });
                break;
            default:
                throw new Error(`Unknown action: ${action.actionToPerform}`);
        }
    };

    return (
        <>
            <div className="invoice-head draft-head d-flex flex-sm-row flex-column align-items-sm-center align-items-start gap-sm-4 gap-0 justify-content-between w-100">
                {!isMobile && <div className="total-data">
                    <span>{invoices?.totalResults} <small className="d-sm-flex d-none">{t("Total")}</small></span>
                </div>
                }
                <div className="tab-right-filter">
                    <div className="search-input-form">
                        <div className="search-input d-flex" id="draftSearch">
                            <input onFocus={() => { searchBar?.classList.add('full-search') }} className="form-control" placeholder={t("SearchByProjectName")} value={searchTerm} onChange={(e) => handleSetSearch(e.target.value)} autoComplete="off" />
                            <Image src={dynamicImage("svg/search.svg")} alt="search" className="search-img" />
                            <Image src={dynamicImage("svg/search-red.svg")} alt="search" className="search-img-hover" />
                            {searchTerm.length > 0 && (<div className="close-img" onClick={() => { handleSetSearch(""); searchBar?.classList.remove('full-search') }}><Image src={dynamicImage("svg/close-red.svg")} alt="close" /></div>)}
                        </div>
                    </div>
                    <LinkButton className="text-nowrap btn-solid" to={`${ROUTES.Invoice}${detailPage ? `?projectId=${id}&customerId=${project?.billingId}` : ''}`}>
                        <Image src={dynamicImage("svg/plus-white.svg")} alt="arrow" />
                        <span className="text-white d-xxl-inline-flex d-none">{t("NewInvoice")}</span>
                    </LinkButton>
                </div>
            </div>
            <div className={`project-all-invoice-table ${detailPage ? 'draft-details-table' : 'project-draft-table'}`}>
                {tableConfig.total > 0 ? (
                    <CommonTable tableConfiguration={tableConfig} currentPage={pageNumber} itemsPerPage={pageSize} goToPage={goToPage} handleSetPageSize={handleSetPageSize} toggleSortType={toggleSortType} handleSetSortBy={handleSetSortBy} onActionPerform={handleActionPerform} />
                ) : (
                    <div className="table-no-data">
                        <Image src={dynamicImage("svg/no-data/no-data-project-invoice.svg")} alt="no data" className="img-fluid" />
                        <h5 dangerouslySetInnerHTML={{
                            __html: t(
                                detailPage ? "NoData.FreelancerNoInvoiceInProject" : "NoData.FreelancerNoInvoiceInGeneralList")
                        }}></h5>
                    </div>
                )}
            </div >
        </>
    )
}
export default DraftInvoices;