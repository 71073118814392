import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, FormGroup, InputGroup, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { Image, SolidButton } from "../../../AbstractElement";
import { mutations, queries } from "../../../api";
import { CommonSelect, LayoutLoader } from "../../../CommonComponent";
import { useCoreData } from "../../../Hooks";
import { CustomerSchema } from "../../../Schemas";
import { ClientDetailsProps, ClientInput } from "../../../Types";
import { dynamicImage } from "../../../utils";
import { useLoader, useStateContext, useToast } from "../../../views";

const AddNewClient: React.FC<ClientDetailsProps> = ({ customerId }) => {
  const { t } = useTranslation();

  const { Customer } = CustomerSchema();
  const { clientDetails, toggleAddNewCustomer } = useStateContext();

  const [action, setAction] = useState<"create" | "edit">("create");
  const { isLoading } = useLoader();

  const { phoneCountryCodeOptions, countryOptions } = useCoreData({ loadCountries: true });

  const { showToast } = useToast();

  const { data: customer } = queries.useGetCustomer(customerId);

  const { mutate: createCustomer, isLoading: createLoading } = mutations.useCreateCustomer();
  const { mutate: updateCustomer, isLoading: editLoading } = mutations.useUpdateCustomer();

  const [selectedCountryOptions, setSelectedCountryOptions] = useState<string>();
  const [selectedPhoneCountryCodeOptions, setSelectedPhoneCountryCodeOptions] = useState<string>();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<ClientInput>({
    resolver: yupResolver(Customer),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      phoneCountryCode: selectedPhoneCountryCodeOptions,
      addressLine1: "",
      city: "",
      state: "",
      countryCode: selectedCountryOptions,
      postalCode: "",
      taxOffice: "",
      registrationNumber: "",
      addressLine2: "",
      vatNumber: "",
    },
  });

  useEffect(() => {
    if (customer) {
      setAction("edit");
      setValue("name", customer.name);
      setValue("email", customer.email);
      setValue("phone", customer.phone);
      setValue("phoneCountryCode", customer.phoneCountryCode);
      setValue("addressLine1", customer.addressLine1);
      setValue("city", customer.city);
      setValue("state", customer.state);
      setValue("countryCode", customer.countryCode);
      setValue("postalCode", customer.postalCode);
      setValue("taxOffice", customer.taxOffice);
      setValue("registrationNumber", customer.registrationNumber);
      setValue("addressLine2", customer.addressLine2);
      setValue("vatNumber", customer.vatNumber);
      setSelectedPhoneCountryCodeOptions(customer.phoneCountryCode!);
      setSelectedCountryOptions(customer.countryCode);
    }
  }, [customer]);

  const onSubmit: SubmitHandler<ClientInput> = async (data) => {
    if (action === "create") {
      createCustomer(data, {
        onSuccess: () => {
          setAction("create");
          closeCanvas();
          showToast("success", "Clients.ClientAddedSuccessfully");
        },
      });
    } else if (action === "edit") {
      updateCustomer(
        { id: customer?.id!, input: data },
        {
          onSuccess: () => {
            setAction("create");
            closeCanvas();
            showToast("success", "Clients.ClientUpdatedSuccessfully");
          },
        }
      );
    }
  };

  const closeCanvas = () => {
    toggleAddNewCustomer();
  };

  const classNameError = (field: keyof ClientInput) => {
    return `form-control ${action === "edit" && field in Customer.fields ? "edit-mode" : ""} ${errors[field] ? "required-field" : ""}`;
  };

  const renderError = (field: keyof ClientInput) =>
    errors[field] && (
      <span className="required-note">
        <small>{t(errors[field]?.message || "")}</small>
      </span>
    );

  return (
    <Offcanvas isOpen={clientDetails.toggle} toggle={closeCanvas} direction="end" className="theme-offcanvas-2">
      <div className="theme-offcanvas-box">
        <div className="w-100">
          {isLoading && <LayoutLoader />}
          <>
            <OffcanvasHeader toggle={closeCanvas}>{action === "create" ? t("AddNewClient") : t("EditClient")}</OffcanvasHeader>
            <Button className="btn-close" onClick={closeCanvas} color="transparent"></Button>
            <OffcanvasBody className="custom-scroll flex-column">
              {action === "edit" && (
                <div className="invoice-box">
                  <div className="invoice-note mb-4 mt-0">
                    <div className="invoice-img">
                      <Image src={dynamicImage("svg/star.svg")} alt="star" />
                    </div>
                    <h6>{t("CompleteMissingClientInformationToBeAbleToInvoiceThem")}</h6>
                  </div>
                </div>
              )}
              <Form className="form-mb-73" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row className="g-sm-4 g-3">
                  <Col xs="12">
                    <FormGroup className="mb-0">
                      <label>
                        {t("ClientName")}
                        {action === "create" && <span className="required-data">{t("*Required")}</span>}
                      </label>
                      <input type="text" className={classNameError("name")} id="clientName" placeholder={t("EnterClientName")} {...register("name")} autoComplete="off" />
                      {renderError("name")}
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup className="mb-0">
                      <label>
                        {t("EmailId")}
                        {action === "create" && <span className="required-data">{t("*Required")}</span>}
                      </label>
                      <input type="email" className={classNameError("email")} id="email" placeholder={t("EnterClientEmail")} {...register("email")} disabled={customer?.verified || false} autoComplete="off" />
                      {renderError("email")}
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup className="phone-detail mb-0">
                      <label>{t("PhoneNumberId")}</label>
                      <InputGroup>
                        <div className="dropdown-box">
                          <CommonSelect
                            placeholder={t("Select")}
                            options={phoneCountryCodeOptions}
                            selectedOptions={selectedPhoneCountryCodeOptions}
                            search={true}
                            setSelectedOptions={(value) => {
                              setSelectedPhoneCountryCodeOptions(value);
                              setValue("phoneCountryCode", value);
                              trigger("phoneCountryCode");
                            }}
                            multiSelect={false}
                          />
                          <input type="number" className="form-control" id="phone" placeholder={t("5005000000")} {...register("phone")} autoComplete="off" />
                        </div>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <label>{t("Address")}</label>
                    <Row className="g-sm-4 g-3">
                      <Col md="6">
                        <FormGroup className="mb-0">
                          <CommonSelect
                            placeholder={t("SelectCountry")}
                            options={countryOptions!}
                            selectedOptions={selectedCountryOptions}
                            search={true}
                            setSelectedOptions={(value) => {
                              setSelectedCountryOptions(value);
                              setValue("countryCode", value);
                              trigger("countryCode");
                            }}
                            multiSelect={false}
                            className={` ${errors.countryCode ? "required-field" : ""}`}
                          />
                          {renderError("countryCode")}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-0">
                          <input type="text" className={classNameError("state")} id="state" placeholder={t("StateProvince")} {...register("state")} autoComplete="off" />
                          {renderError("state")}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-0">
                          <input type="text" className={classNameError("city")} id="city" placeholder={t("EnterCity")} {...register("city")} autoComplete="off" />
                          {renderError("city")}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-0">
                          <input type="text" className={classNameError("postalCode")} id="zipPostalCode" placeholder={t("ZipPostalCode")} {...register("postalCode")} autoComplete="off" />
                          {renderError("postalCode")}
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <FormGroup className="mb-0">
                          <input type="text" className={classNameError("addressLine1")} id="addressLine1" placeholder={t("AddressLine1")} {...register("addressLine1")} autoComplete="off" />
                          {renderError("addressLine1")}
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <FormGroup className="mb-0">
                          <input type="text" className={classNameError("addressLine2")} id="addressLine2" placeholder={t("AddressLine2")} {...register("addressLine2")} autoComplete="off" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-0">
                      <label>{t("TaxOffice")}</label>
                      <input type="text" className={classNameError("taxOffice")} id="taxOffice" placeholder="Enter tax office ..." {...register("taxOffice")} autoComplete="off" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-0">
                      <label>{t("RegistrationNumber")}</label>
                      <input type="text" className={classNameError("registrationNumber")} id="registrationNumber" placeholder={t("EnterClientRegistrationNumber")} {...register("registrationNumber")} autoComplete="off" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="mb-0">
                      <label>{t("VatNumber")}</label>
                      <input type="text" className={classNameError("vatNumber")} id="vatNumber" placeholder={t("EnterVATNumber")} {...register("vatNumber")} autoComplete="off" />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="offcanvas-footer">
                  <SolidButton className="btn-gray d-flex justify-content-center" onClick={closeCanvas}>
                    {t("Cancel")}
                  </SolidButton>
                  <SolidButton loading={action === "create" ? createLoading : editLoading} color="success" type="submit" className="w-100 d-flex justify-content-center">
                    {action === "create" ? t("Add") : t("Update")}
                  </SolidButton>
                </div>
              </Form>
            </OffcanvasBody>
          </>
        </div>
      </div>
    </Offcanvas>
  );
};
export default AddNewClient;
