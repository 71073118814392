import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { Image, SolidButton } from '../../../../AbstractElement';
import { queries } from '../../../../api';
import { CommonSelect } from '../../../../CommonComponent';
import { useCoreData } from '../../../../Hooks';
import { InvoiceLine, WorkItemProps } from '../../../../Types';
import { convertToReadableAmount, dynamicImage, formatPercentage } from '../../../../utils';
import WorkItemForm from './WorkItemForm';

const WorkItem: React.FC<WorkItemProps> = ({ tabItems, setNavActive, navActive, updateInvoiceInput, calculateAmount, refetchCalculate, updateInvoice, createInvoice, invoiceCreate, loading }) => {
  const { t } = useTranslation()
  const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[] | []>([]);
  const { currencyPayInOptions } = useCoreData({ loadCurrenciesPayIn: true });

  const [selectedCurrencyOptions, setSelectedCurrencyOptions] = useState<string>('');
  const [editableRowId, setEditableRowId] = useState<string | null>(null);
  const [action, setAction] = useState<string>('add');
  const [triedOneTime, setTriedOneTime] = useState<boolean | null>(null);

  const { data: invoiceItems } = queries.useGetInvoiceItem(updateInvoiceInput.id!);

  const newItem: InvoiceLine = {
    itemName: '',
    description: '',
    unitCode: null,
    quantity: 1,
    price: 0
  };

  const addNewItem = () => {
    setInvoiceLines([...invoiceLines!, newItem]);
  };

  useEffect(() => {
    if (updateInvoiceInput.id) {
      setInvoiceLines(invoiceItems?.totalResults! > 0 ? invoiceItems?.items! : [newItem])
    }
  }, [invoiceItems?.items, invoiceItems?.totalResults, setInvoiceLines, updateInvoiceInput.id])


  useEffect(() => {
    if (invoiceCreate?.currencyCode) {
      setSelectedCurrencyOptions(invoiceCreate?.currencyCode);
    } else {
      setSelectedCurrencyOptions('')
    }
  }, [invoiceCreate?.currencyCode]);

  useEffect(() => {
    if (loading && triedOneTime === null) {
      setTriedOneTime(false);
    }
    else if (!loading && triedOneTime === false) {
      setTriedOneTime(true);
    }
  }, [loading, triedOneTime]);

  const selectCurrency = (value: string) => {
    if (value && (invoiceCreate?.projectId || invoiceCreate.projectName)) {
      if (!updateInvoiceInput.id) {
        // Set selected currency option
        setSelectedCurrencyOptions(value);
        // Use functional state update for invoice input
        createInvoice({ ...invoiceCreate, currencyCode: value });
      } else {
        // Set selected currency option
        setSelectedCurrencyOptions(value);
        // Use functional state update for invoice input
        updateInvoice(invoiceCreate, { ...updateInvoiceInput, currencyCode: value, });
      }
    }
  };

  const handleNext = () => {
    if (navActive < tabItems.length) {
      setNavActive(navActive + 1);
    }
  };
  const handlePrevious = () => {
    if (navActive > 1) {
      setNavActive(navActive - 1);
    }
  };

  const isNextDisabled = !(invoiceLines[0]?.id || (updateInvoiceInput?.customerId && updateInvoiceInput?.projectId && updateInvoiceInput?.currencyCode));

  return (
    <>
      <div className="items-height">
        <div className="invoice-main-content required-box custom-scroll">
          <label>{t("InvoiceCurrency")}<span className="required-data">{t("*Required")}</span></label>
          <CommonSelect
            selectedOptions={selectedCurrencyOptions}
            options={currencyPayInOptions}
            setSelectedOptions={selectCurrency}
            multiSelect={false}
            placeholder={t('SelectId')}
            className={'max-dropdown'}
            search={true}
            disabled={loading}
            buttonText={!updateInvoiceInput.id && selectedCurrencyOptions && triedOneTime ? t("CreateInvoice") : undefined}
            buttonClass='create-btn'
            onClick={() => {
              selectCurrency(selectedCurrencyOptions);
            }}
          />

          {

            invoiceLines.length > 0 && (invoiceLines[0]?.id || updateInvoiceInput.id) &&
            <>
              <div className="d-flex align-items-center gap-2 justify-content-between mt-4">
                <label>{t("WorkItems")}</label>
              </div>
              <div className=" d-flex flex-column gap-2 invoice-table-main">
                <div className="table-responsive invoice-work-responsive custom-scroll">
                  <Table borderless={true} className="invoice-work-table">
                    <thead>
                      <tr>
                        <th><span>{t("ItemName")}</span></th>
                        <th><span>{t("Description")}</span></th>
                        <th><span>{t("QTY")}</span></th>
                        <th className="text-end"><span>{t("Price")}</span></th>
                        <th className="text-end"><span>{t("Total")}</span></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="responsive-body">
                      {
                        invoiceLines?.filter(f => !f.serviceFee).map((item: any) => (
                          <WorkItemForm key={item.id} item={item} action={action} setAction={setAction} editableRowId={editableRowId} setEditableRowId={setEditableRowId} invoiceId={updateInvoiceInput.id} selectedCurrencyOptions={selectedCurrencyOptions} calculateAmount={calculateAmount} refetchCalculate={refetchCalculate} invoiceLines={invoiceLines} setInvoiceLines={setInvoiceLines} />
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
                <div className="invoice-bottom-box">
                  <SolidButton type="button" className="w-100 justify-content-center  btn-solid" disabled={!invoiceLines[invoiceLines.length - 1]?.id || !!editableRowId} onClick={addNewItem} >
                    <Image src={dynamicImage("svg/plus-white.svg")} alt="arrow" />
                    <span>{t("AddNewItem")}</span>
                  </SolidButton>
                  {calculateAmount &&
                    <div className="d-flex justify-content-end mt-sm-3 invoice-work-total-main">
                      <ul className="invoice-work-total">
                        <li>
                          <span>{t("Subtotal")}</span>
                          <small>{convertToReadableAmount(calculateAmount.total!, calculateAmount.currencyCode)}</small>
                        </li>
                        <li>
                          <span>{t('VatValue', { value: formatPercentage(calculateAmount.vatRate * 100) })}</span>
                          <small>{convertToReadableAmount(calculateAmount.vatAmount!, calculateAmount.currencyCode)}</small>
                        </li>
                        <li>
                          <span>{t("TotalAmount")}</span>
                          <small>{convertToReadableAmount(calculateAmount.totalIncludingTaxes!, calculateAmount.currencyCode)}</small>
                        </li>
                      </ul>
                    </div>
                  }
                </div>
              </div>
            </>
          }
        </div>
      </div>
      <div className="footer-buttons-main">
        <div className="footer-buttons">
          <SolidButton className="btn-gray" onClick={handlePrevious}>
            <Image src={dynamicImage("svg/left-arrow.svg")} alt="arrow" />
            {t("Previous")}
          </SolidButton>
          <SolidButton type="submit"
            onClick={handleNext} disabled={isNextDisabled || navActive >= tabItems.length || !!editableRowId || !invoiceLines[invoiceLines.length - 1]?.id}>
            {t("Next")}
            <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt="arrow" className="next" />
          </SolidButton>
        </div>
      </div>
    </>
  )
}
export default WorkItem