import React from 'react'
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { Image, SolidButton } from '../../../AbstractElement';
import { useTranslation } from 'react-i18next';
import { useStateContext, useLoader, useLayoutContext } from '../../../views';
import { queries } from '../../../api';
import { dynamicImage } from '../../../utils';
import { FreelancerDetailsProps } from '../../../Types';
import { LayoutLoader, ClientProjectsTable } from '../../../CommonComponent';
import { useCoreData } from '../../../Hooks';

const FreelancerDetails: React.FC<FreelancerDetailsProps> = ({ freelancerId }) => {
    const { t } = useTranslation();
    const { freelancerModal, openEditFreelancerModal, closeFreelancerModal } = useStateContext();
    const { isLoading } = useLoader()
    const { data: freelancerData } = queries.useGetFreelancer(freelancerId!);
    const { position } = useLayoutContext()
    const { professionCodeOptions } = useCoreData({ loadProfessions: true });

    const professionLabel = professionCodeOptions?.find(f => f.value === freelancerData?.professionCode)?.label ?? "";


    return (
        <Offcanvas isOpen={freelancerModal.toggle} toggle={closeFreelancerModal} direction={position} className="theme-offcanvas-2">
            <div className='theme-offcanvas-box '>
                <div className="w-100">
                    {isLoading && <LayoutLoader />}
                    <OffcanvasHeader toggle={closeFreelancerModal}>{t("FreelancerDetails")}</OffcanvasHeader>
                    <Button className="btn-close d-md-block d-none" onClick={closeFreelancerModal} color='transparent'></Button>
                    <OffcanvasBody className="custom-scroll">
                        <div className="flex-grow-1">
                            <div className='client-custom-height custom-scroll'>
                                <div className='client-offcanvas-box'>
                                    <div>
                                        <h4 className='details-title'>{t("FreelancerName")}</h4>
                                        {freelancerModal.mode === "view" && <span className='client-edit-icon' onClick={() => { openEditFreelancerModal(freelancerId!) }}><Image src={dynamicImage("svg/edit.svg")} className="edit-icon" alt="Edit" /></span>}
                                    </div>
                                    <div>
                                        <p className="common-f-flex">
                                            <span className="initial-letter">
                                                <span>{freelancerData?.fullName.split(' ')?.map((letter: string) => letter.charAt(0).toUpperCase())?.join('')?.slice(0, 2)}</span>
                                            </span>
                                            <div>
                                                <span>{freelancerData?.fullName}</span>
                                                <p>({freelancerData?.email})</p>
                                            </div>
                                        </p>
                                    </div>
                                    <div className="freelancer-details-box">
                                        {(freelancerData?.otherProfessionName || professionLabel) &&
                                            <>
                                                <h5>{t("JobTitle")}</h5>
                                                <p>{freelancerData?.otherProfessionName ? freelancerData?.otherProfessionName : professionLabel}</p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <h5 className='title'>{t("ProjectsWithThisFreelancer")}</h5>
                                <ClientProjectsTable freelancerId={freelancerId} />
                            </div>
                            <div className="offcanvas-footer">
                                <SolidButton className="btn-gray w-100 justify-content-center" onClick={closeFreelancerModal}>{t("Close")}</SolidButton>
                            </div>
                        </div>
                    </OffcanvasBody>

                </div>
            </div>
        </Offcanvas>
    )
}
export default FreelancerDetails;