import { Col, Row } from 'reactstrap'
import { CommonTable } from '../../CommonComponent'
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { TableConfig } from '../../Types/CommonComponent';
import { FinanceScope} from '../../Types/Finance';
import { Image, InvoiceStatusBadge, LinkButton, SolidButton } from '../../AbstractElement';
import { dynamicImage } from '../../utils';
import { INVOICE_STATUS } from '../../Types';
import FinanceApply from './Canvas/ApplyFinance';
import ActionFinance from './Canvas/ActionFinance';

const Finance = () => {
  const [applyFinance, setApplyFinance] = useState(false);
  const [ actionFinance, setActionFinance] = useState(false);
  
  const toggleApplyFinance = () => setApplyFinance(!applyFinance);
  const toggleActionFinance = () => setActionFinance(!actionFinance);

  const { t } = useTranslation();

  const tableConfig1 = useMemo<TableConfig<Omit<FinanceScope, "financeApproved">>>(() => ({
    columns: [
      {
        title: "Project", dataField: [{
          field: 'projectName', type: '', renderer: (data: FinanceScope) => {
            return (<><div className="responsive-box"><h6>{data.projectName}</h6><span>{data.projectCategory}</span></div></>)
          }
        }], sortable: false
      },
      {
        title: "Client", dataField: [{
          field: 'customerName', type: '',
          renderer: (data: FinanceScope) => {
            return (
              <>
                <div className="responsive-box">
                  <h6>{data.customerName}</h6>
                  <span>{data.customerEmail}</span>
                </div>
              </>

            )
          }
        }], sortable: false
      },
      {
        title: "Invoice number", dataField: [{
          field: 'invoiceNumber', type: '', renderer: (data: FinanceScope) => {
            return (<div>#{data.invoiceNumber}</div>)
          }
        }], sortable: false
      },
      {
        title: "Invoice date", dataField: [{
          field: 'invoiceDate', type: '', renderer: (data: FinanceScope) => {
            return (<div>{data.invoiceDate}</div>)
          }
        }], sortable: false
      },
      {
        title: "Status", dataField: [{
          field: 'invoiceStatus', type: '', renderer: (data: FinanceScope) => {
            return (<InvoiceStatusBadge invoiceStatus={data.invoiceStatus} />)
          }
        }], sortable: false
      },
      {
        title: "Amount", dataField: [{
          field: 'amount', type: '', renderer: (data: FinanceScope) => {
            return (<div className="fw-bolder">${data.amount}</div>)
          }
        }], sortable: false
      },
      {
        title: "",
        dataField: [{
          field: 'action',
          type: '',
          renderer: (data: FinanceScope) => (
            <div className="d-flex align-items-center justify-content-end">
              <SolidButton onClick={toggleApplyFinance}>
                {t("Apply")}
                <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt={`${data.invoiceStatus} image`} />
              </SolidButton>
            </div>
          )
        }],
        sortable: false
      }
    ],
    data: [{ projectName: "Art Direction Project", projectCategory: "(Web Design & Development)", customerName: "Jonathan Drake", customerEmail: "jonathan@company.com", invoiceNumber: "2024010092", invoiceDate: "Jan 25, 2024", invoiceStatus: INVOICE_STATUS.WaitingForApproval, amount: "1250.00" }],
    isColumnChooser: true,
    actionsDropDown: [],
    total: 1
  }), []);

  const tableConfig2 = useMemo<TableConfig<Omit<FinanceScope, "invoiceStatus">>>(() => ({
    columns: [
      {
        title: "Project", dataField: [{
          field: 'projectName', type: '', renderer: (data: FinanceScope) => {
            return (<><div className="responsive-box"><h6>{data.projectName}</h6><span>{data.projectCategory}</span></div></>)
          }
        }], sortable: false
      },
      {
        title: "Customer", dataField: [{
          field: 'customerName', type: '',
          renderer: (data: FinanceScope) => {
            return (
              <>
                <div className="responsive-box">
                  <h6>{data.customerName}</h6>
                  <span>{data.customerEmail}</span>
                </div>
              </>

            )
          }
        }], sortable: false
      },
      {
        title: "Invoice number", dataField: [{
          field: 'invoiceNumber', type: '', renderer: (data: FinanceScope) => {
            return (<div>#{data.invoiceNumber}</div>)
          }
        }], sortable: false
      },
      {
        title: "Invoice date", dataField: [{
          field: 'invoiceDate', type: '', renderer: (data: FinanceScope) => {
            return (<div>{data.invoiceDate}</div>)
          }
        }], sortable: false
      },
      {
        title: "Amount", dataField: [{
          field: 'amount', type: '', renderer: (data: FinanceScope) => {
            return (<div className="fw-bolder">${data.amount}</div>)
          }
        }], sortable: false
      },
      {
        title: "Finance Approved", dataField: [{
          field: 'financeApproved', type: '', renderer: (data: FinanceScope) => {
            return (<div>{data.invoiceDate}</div>)
          }
        }], sortable: false
      },
      {
        title: "",
        dataField: [{
          field: 'action',
          type: '',
          renderer: () => (
            <div className="d-flex align-items-center justify-content-end">
              <LinkButton  className="justify-content-center">{t("Details")}</LinkButton>
            </div>
          )
        }],
        sortable: false
      },
    ],
    data: [{ projectName: "Art Direction Project", projectCategory: "(Web Design & Development)", customerName: "Jonathan Drake", customerEmail: "jonathan@company.com", invoiceNumber: "2024010092", invoiceDate: "Jan 25, 2024", amount: "1250.00", financeApproved: "Jan 28, 2024" }],
    isColumnChooser: true,
    actionsDropDown: [],
    total: 1
  }), []);

  return (
    <div className='finance-main'>
      <Row className="g-sm-5 g-2">
        <Col xs="12">
          <h4 className="mb-lg-4 mb-sm-3 mb-2">{t("FinancialInvoices")}</h4>
          <p>{t("BelowAreTheInvoicesYouCanApplyForFinancingAndGetPaidEarlyClickApplyToStartFinancingProcess")}</p>
          <div>
            {tableConfig1.data.length > 0 ? (
              <div className="custom-scrollbar table-responsive">
                <CommonTable tableConfiguration={tableConfig1} pagingDisabled={true} />
              </div>
            ) : (
              <div className="no-data-found">
                <div>
                  <h5 className='mt-3'>{t("You haven't created an finance yet.")}</h5>
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col xs="12">
          <h4>{t("PreApprovedInvoices")}</h4>
          <div>
            {tableConfig2.data.length > 0 ? (
              <div className="custom-scrollbar table-responsive finance-table-1">
                <CommonTable tableConfiguration={tableConfig2} pagingDisabled={true} />
              </div>
            ) : (
              <div className="no-data-found">
                <div>
                  <h5 className='mt-3'>{t("You haven't created an finance yet.")}</h5>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {applyFinance && <FinanceApply toggleApplyFinance={toggleApplyFinance} applyFinance={applyFinance} toggleActionFinance={toggleActionFinance} actionFinance={actionFinance}/>}
      {actionFinance && <ActionFinance toggleActionFinance={toggleActionFinance} actionFinance={actionFinance}/>}
    </div>
  )
}
export default Finance;
