import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, FormGroup, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { Image, LinkButton, SolidButton } from "../../../../AbstractElement";
import { dynamicImage, getMessages, getParam } from "../../../../utils";
import { ROUTES } from "../../../../Constants";
import { mutations } from "../../../../api";
import { LoginSchema } from "../../../../Schemas";
import { PassStrength } from "../../Widgets";
import { usePasswordStrength } from "../../../../Hooks";
import { useToast } from "../../../../views";

const UpdatePasswordForm = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState([false, false]);
  const { CreateNewPasswordSchema } = LoginSchema();
  const { mutate: updatePassword, isLoading } = mutations.useResetPassword();
  const { showToast } = useToast()
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(CreateNewPasswordSchema),
  });

  const togglePasswordVisibility = (index: number) => {
    setPasswordVisibility((prevState) => {
      const newVisibility = [...prevState];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };

  const password = watch('password', '');
  const passwordStrength = usePasswordStrength(password);

  const onSubmit = (data: { password: string, confirmPassword: string }) => {
    if (!data.password) return;
    const token = getParam("token");
    if (!token) return;

    updatePassword(
      {
        password: data.password,
        token,
      },
      {
        onSuccess: () => {
          showToast("success", "UpdatePasswordForm.YourPasswordHasBeenSuccessfullyReset");
          navigate(ROUTES.FreelancerLogIn);
        },
        onError: (e) => {
          if (!Array.isArray(e)) {
            showToast("error", getMessages(e));
          }
        },
      }
    );
  };

  // Check if token exists when the page loads
  useEffect(() => {
    const token = getParam("token");
    if (!token) {
      navigate(ROUTES.Home); // Redirect to dashboard if token is missing
    }
  }, [navigate]);


  return (
    <Row>
      <Col xxl="6" lg="7" md="8" xs="12" className="mx-auto mb-4">
        <div className="verification-form">
          <div className="title">
            <h3>{t("CreateNewPassword")}</h3>
          </div>
          <Form noValidate onSubmit={handleSubmit(onSubmit)} className="custom-form-w">
            <FormGroup className="form-group">
              <div className="position-relative">
                <input
                  {...register("password")}
                  className={`form-control pr-45 ${errors.password ? "required-field" : ""}`}
                  placeholder={t("Passwords")}
                  type={passwordVisibility[0] ? "text" : "password"}
                />
                <div className="toggle-password" onClick={() => togglePasswordVisibility(0)}>
                  <Image
                    src={dynamicImage(`svg/${passwordVisibility[0] ? "icon_showpasson.svg" : "icon_showpassoff.svg"}`)}
                    className="auth-image"
                    alt="show-password"
                  />
                </div>
              </div>
              {errors.password && (
                <span className="required-note">
                  <small>{t(errors.password.message!)}</small>
                </span>
              )}
              {password.length > 0 && passwordStrength && <PassStrength passwordStrength={passwordStrength} />}
            </FormGroup>

            <FormGroup className="form-group">
              <div className="position-relative">
                <input
                  {...register("confirmPassword")}
                  className={`form-control pr-45 ${errors.confirmPassword ? "required-field" : ""}`}
                  placeholder={t("ConfirmPassword")}
                  type={passwordVisibility[1] ? "text" : "password"}
                />
                <div className="toggle-password" onClick={() => togglePasswordVisibility(1)}>
                  <Image
                    src={dynamicImage(`svg/${passwordVisibility[1] ? "icon_showpasson.svg" : "icon_showpassoff.svg"}`)}
                    className="auth-image"
                    alt="show-password"
                  />
                </div>
              </div>
              {errors.confirmPassword && (
                <span className="required-note">
                  <small>{t(errors.confirmPassword.message!)}</small>
                </span>
              )}
            </FormGroup>
            <div className="action-btn">
              <SolidButton type="submit" loading={isLoading}>{t("Confirm")}</SolidButton>
              <LinkButton to={ROUTES.FreelancerLogIn}>{t("Cancel")}</LinkButton>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default UpdatePasswordForm;
