import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, FormGroup } from "reactstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import { Image, SolidButton } from "../../../../AbstractElement";
import { dynamicImage, getMessages } from "../../../../utils";
import { ROUTES } from "../../../../Constants";
import { LoginFormData } from "../../../../Types";
import { useAuth } from "../../../../Hooks";
import { LoginSchema } from "../../../../Schemas";
import { useToast } from "../../../../views";

const LoginForm = () => {
  const { t } = useTranslation();
  const { signin } = useAuth();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const togglePassword = () => setShowPassword(!showPassword);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const { FreelancerLoginSchema } = LoginSchema();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(FreelancerLoginSchema),
  });

  const handleSignin: SubmitHandler<LoginFormData> = async (data) => {
    setLoading(true);
    try {
      await signin({ ...data });
    } catch (err: any) {
      showToast("error", getMessages(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-card">
      <div className="auth-title">
        <h2>{t("WelcomeAboard")}</h2>
        <div className="login-account">
          {t("LoginOr")}
          <Link to={ROUTES.FreelancerSignUp}>{t("CreateAnAccount")}</Link>
        </div>
      </div>
      <div className="login-detail mb-0">
        <Form onSubmit={handleSubmit(handleSignin)} noValidate>
          <div className="mb-20">
            <input {...register("email")} className={`form-control ${errors.email ? "required-field" : ""}`} id="email" placeholder={t("EmailAddress")} type="email" />
            {errors.email && (
              <span className="required-note">
                <small>{t(errors.email.message as string)}</small>
              </span>
            )}
          </div>
          <FormGroup className="form-group">
            <div className="position-relative">
              <input {...register("password")} className={`form-control ${errors.password ? "required-field" : ""}`} id="password" placeholder={t("Passwords")} type={showPassword ? "text" : "password"} />
              <div className="toggle-password" onClick={togglePassword}>
                <Image src={dynamicImage(`svg/${showPassword ? "icon_showpasson.svg" : "icon_showpassoff.svg"}`)} className="auth-image" alt="show-password" />
              </div>
            </div>
            {errors.password && (
              <span className="required-note">
                <small>{t(errors.password.message as string)}</small>
              </span>
            )}
          </FormGroup>

          <FormGroup>
            <SolidButton type="submit" loading={loading}>
              {t("LogIn")}
            </SolidButton>
          </FormGroup>

          <div className="forgot-pass">
            <Link to={ROUTES.ForgotPassword} className="text-dark">
              {t("ForgotPassword")}
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
