import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../../Constants';
import { useAuth } from '../../../../Hooks';
import Loader from '../../../../Layout/Loader';
import { getMessages, getParam } from '../../../../utils';
import { useToast } from '../../../../views';

const LinkedInSignin = () => {
    const { linkedInSignin } = useAuth();
    const navigate = useNavigate();
    const { showToast } = useToast();

    useEffect(() => {
        const code = getParam("code");

        if (!code) {
            navigate(ROUTES.FreelancerLogIn);
            return;
        }
        linkedInSignin(
            { code },
            () => { },
            (err) => {
                showToast("error", getMessages(err));
                navigate(ROUTES.FreelancerLogIn);
            }
        );
    }, []);

    return <Loader />;
}
export default LinkedInSignin;