import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

import { Image } from '../../../../../AbstractElement'
import { dynamicImage } from '../../../../../utils'
import { Header } from '../../../../Auth/Widgets'

export const WelcomeApp = () => {

  const { t } = useTranslation();

  return (
    <div className="vh-100">
      <div className="auth-main h-100">
        <Image src={dynamicImage("svg/leaf.svg")} alt="logo" className="leaf" />
        <Header showNav />
        <div className="auth-body h-100">
          <Container className="h-100">
            <div className="auth-body-title welcome">
              <Image src={dynamicImage("svg/hifive.svg")} alt="logo" className="hifive" />
              <h3>{t("WelcomeToJuuli")}</h3>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}
