import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Image, SolidButton } from '../../AbstractElement';
import { mutations, queries } from '../../api';
import { convertToReadableDate, dynamicImage } from '../../utils';
import { useToast } from '../../views';

const Money = () => {

  const { t } = useTranslation();
  const { showToast } = useToast()
  const { mutate: applyForDebitCard } = mutations.useApplyForDebitCard();
  const { mutate: applyForIban } = mutations.useApplyForIban();
  const { data: debitWaitlistStatus } = queries.useGetDebitCardStatus();
  const { data: ibanWaitlistStatus } = queries.useIbanApplicationStatus();

  const isInDebitWaitlistAlready = !!debitWaitlistStatus?.applicationDate;
  const isInIbanWaitlistAlready = !!ibanWaitlistStatus?.applicationDate;

  const handleDebitApply = () => {
    applyForDebitCard(undefined, {
      onSuccess: () => {
        showToast("success", "Money.CongratulationsYouHaveSuccessfullyJoinedTheWaitlist");
      }
    });
  }
  const handleIbanApply = () => {
    applyForIban(undefined, {
      onSuccess: () => {
        showToast("success", "Money.CongratulationsYouHaveSuccessfullyJoinedTheWaitlist");
      }
    });
  }
  return (
    <div className="money-main overflow-x-hidden">
      <Row className="g-3">
        <Col xs="12">
          <div className="money-content info-light">
            <div className="money-img">
              <Image src={dynamicImage("money/iban.png")} alt="iban" />
            </div>
            <div className="d-flex flex-column">
              <h2>{t("JuuliIBAN")}</h2>
              <h3>{t("GetYourDedicatedIban")}</h3>
              {!isInIbanWaitlistAlready && (
                <p>{t("ManageYourInternationalPaymentsEasilyWithYourOwnDedicatedIbanAccountStayTunedForTheUpcomingReleaseOfYourPersonalizedIbanServiceMakingGlobalTransactionsSmootherThanEver")}</p>
              )}
              {isInIbanWaitlistAlready && (
                <p className="font-normal max-w-[432px] mt-8">
                  <span>
                    {t("CongratulationsYouSuccessfullyAppliedToBeOnTheWaitlistOn")}
                    <b className="italic ml-1">
                      {convertToReadableDate(ibanWaitlistStatus.applicationDate, "long")}
                    </b>
                  </span>
                </p>
              )}
              <div className="d-flex flex-xxl-row flex-column-reverse align-items-xxl-center align-items-start gap-4 mt-3">
                {!isInIbanWaitlistAlready && (
                  <SolidButton onClick={handleIbanApply}>
                    {t("JoinTheWaitingList")}
                    <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt="arrow" />
                  </SolidButton>
                )}
                <ul>
                  <li>
                    <Image src={dynamicImage("svg/check.svg")} alt="arrow" />
                    {t("FastGlobalTransactionsInMultipleCurrenciesWithLowFees")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col xs="12">
          <div className="money-content primary-light">
            <div className="money-img">
              <Image src={dynamicImage("money/debit.png")} alt="iban" />
            </div>
            <div className="d-flex flex-column">
              <h2>{t("JuuliDebitCard")}</h2>
              <h3>{t("ConvertYourPaymentsToCashInstantly")}</h3>
              {!isInDebitWaitlistAlready && (
                <p>{t("AtJuuliWeCommittedToMakingFreelancersFinancialJourneySeamlessAndEmpoweringGetReadyToExperienceFreedomWithOurUpcomingDebitCard")}</p>
              )}
              {isInDebitWaitlistAlready && (
                <p className="font-normal max-w-[432px] mt-8">
                  <span>
                    {t("CongratulationsYouSuccessfullyAppliedToBeOnTheWaitlistOn")}
                    <b className="italic ml-1">
                      {convertToReadableDate(debitWaitlistStatus.applicationDate, "long")}
                    </b>
                  </span>
                </p>
              )}
              <div className="d-flex flex-xxl-row flex-column-reverse align-items-xxl-center align-items-start gap-4 mt-3">
                {!isInDebitWaitlistAlready && (
                  <SolidButton onClick={handleDebitApply}>
                    {t("JoinTheWaitingList")}
                    <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt="arrow" />
                  </SolidButton>
                )}
                <ul>
                  <li>
                    <Image src={dynamicImage("svg/check.svg")} alt="arrow" />
                    {t("InternationalUsage")}
                  </li>
                  <li>
                    <Image src={dynamicImage("svg/check.svg")} alt="arrow" />
                    {t("100Free")}
                  </li>
                  <li>
                    <Image src={dynamicImage("svg/check.svg")} alt="arrow" />
                    {t("ZeroMaintenanceFees")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default Money;