import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { Image, SolidButton } from '../../../../../AbstractElement';
import { dynamicImage } from '../../../../../utils';
import { PassStrength } from '../../../../Auth/Widgets';
import { usePasswordStrength } from '../../../../../Hooks';
import { mutations } from '../../../../../api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from '../../../../../Schemas';
import { useToast, useStateContext } from '../../../../../views';
import { AccountDeletionReason, AccountDeletionConfirm, EmailChange, EmailChangeOtp, EmailChangeSuccess } from '../Modals';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../Constants';
import { UpdatePasswordInput } from '../../../../../Types';

const Password = () => {

  const { showToast } = useToast()
  const { t } = useTranslation();
  const { mutate: updateOldPassword, isLoading: updateLoading } = mutations.useUpdatePassword();
  const { UpdatePasswordSchema } = LoginSchema()
  const navigate = useNavigate()
  const { accountDeleteReasonModal, toggleAccountDeleteReasonModal, accountDeleteConfirmModal, accountDeleteConfirmReason, toggleProfileSetting, emailChangeModal, toggleEmailChangeModal, emailChangeOtpModal, emailChangeSuccessModal } = useStateContext()

  const [passwordVisibility, setPasswordVisibility] = useState([false, false, false]);

  const togglePasswordVisibility = (index: number) => {
    setPasswordVisibility((prevState) => {
      const newVisibility = [...prevState];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };

  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm<UpdatePasswordInput>({ resolver: yupResolver(UpdatePasswordSchema) })

  const submitHandler: SubmitHandler<UpdatePasswordInput> = async (data) => {
    updateOldPassword({ oldPassword: data.oldPassword, password: data.newPassword }, {
      onSuccess: () => {
        showToast("success", "ProfileSettings.UpdatedSuccessfully");
        reset();
      },
    });
  };

  const handleForgotPassword = () => {
    toggleProfileSetting()
    navigate(ROUTES.ForgotPassword)
  }

  const newPassword = watch('newPassword', '');
  const passwordStrength = usePasswordStrength(newPassword);

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(submitHandler)}>
        <Row className="mb-md-5">
          <Col xs="12">
            <div className="setting-main">
              <Trans
                i18nKey="ForgotOldPasswordGetNewPassword"
                components={[
                  <span
                    className="text-primary-color-1 fw-bold cursor-pointer"
                    onClick={handleForgotPassword}
                  />
                ]}
              />
              <h6 className="top-title">{t("UpdatePassword")}</h6>
              <Row>
                <Col lg="7">
                  <Row className="g-sm-2 g-1">
                    <Col xs="12">
                      <div className="form-group position-relative mb-0">
                        <label>{t("OldPassword")}</label>
                        <div className='position-relative'>
                          <input {...register("oldPassword")}
                            className={`form-control pr-45 ${errors.oldPassword ? "required-field" : ""}`}
                            placeholder={t("EnterOldPassword")}
                            type={passwordVisibility[0] ? "text" : "password"}
                            autoComplete="off"
                          />
                          <div className="toggle-password" onClick={() => togglePasswordVisibility(0)}>
                            <Image src={dynamicImage(`svg/${passwordVisibility[0] ? "icon_showpasson.svg" : "icon_showpassoff.svg"}`)} className="auth-image" alt="show-password" />
                          </div>

                        </div>
                        {errors.oldPassword && (
                          <span className="required-note">
                            <small>{errors.oldPassword.message}</small>
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className="form-group position-relative mb-0">
                        <label>{t("NewPassword")}</label>
                        <div className='position-relative'>
                          <input {...register("newPassword")}
                            className={`form-control pr-45 ${errors.newPassword ? "required-field" : ""}`}
                            placeholder={t("EnterPassword")}
                            type={passwordVisibility[1] ? "text" : "password"}
                            autoComplete="off"
                          />

                          <div className="toggle-password" onClick={() => togglePasswordVisibility(1)}>
                            <Image src={dynamicImage(`svg/${passwordVisibility[1] ? "icon_showpasson.svg" : "icon_showpassoff.svg"}`)} className="auth-image" alt="show-password" />
                          </div>
                          {newPassword.length > 0 && passwordStrength && <PassStrength passwordStrength={passwordStrength} />}
                        </div>
                        {errors.newPassword && (
                          <span className="required-note">
                            <small>{errors.newPassword.message}</small>
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className="form-group position-relative mb-0">
                        <label>{t("ConfirmNewPassword")}</label>
                        <div className="position-relative">
                          <input {...register("confirmPassword")}
                            className={`form-control pr-45 ${errors.confirmPassword ? "required-field" : ""}`}
                            placeholder={t("ConfirmPassword")}
                            type={passwordVisibility[2] ? "text" : "password"}
                            autoComplete="off"
                          />
                          <div className="toggle-password" onClick={() => togglePasswordVisibility(2)}>
                            <Image src={dynamicImage(`svg/${passwordVisibility[2] ? "icon_showpasson.svg" : "icon_showpassoff.svg"}`)} className="auth-image" alt="show-password" />
                          </div>
                        </div>
                        {errors.confirmPassword && (
                          <span className="required-note">
                            <small>{errors.confirmPassword.message}</small>
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg="7" className='email-change'>
                  {/* 
                  // TODO:The email change feature has been disabled and will be activated later.
                  <h6 className="top-title">{t("EmailAddressChange")}</h6>
                  <Trans
                    i18nKey="ToChangeTheEmailAddressLinkedToYourAccount"
                    components={[
                      <span
                        className="text-primary-color-1 fw-bold"
                        onClick={toggleEmailChangeModal}
                      />
                    ]}
                  /> */}
                  <h6 className="top-title">{t("DeleteAccount")}</h6>
                  <Trans
                    i18nKey="IfYouWishToDeleteYourAccount"
                    components={[
                      <span
                        className="text-primary-color-1 fw-bold"
                        onClick={toggleAccountDeleteReasonModal}
                      />
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="profile-setting-footer-main">
          <div className="profile-setting-footer">
            <SolidButton className='btn-gray' onClick={toggleProfileSetting}>{t("Cancel")}</SolidButton>
            <SolidButton type='submit' loading={updateLoading}>{t("Update")}</SolidButton>
          </div>
        </div>
      </Form>
      {accountDeleteReasonModal && <AccountDeletionReason />}
      {accountDeleteConfirmModal && <AccountDeletionConfirm reason={accountDeleteConfirmReason} />}
      {emailChangeModal && <EmailChange />}
      {emailChangeOtpModal.toggle && <EmailChangeOtp verificationToken={emailChangeOtpModal.verificationToken!} email={emailChangeOtpModal.email!} />}
      {emailChangeSuccessModal && <EmailChangeSuccess />}
    </>
  );
}

export default Password;
