import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { USER_ROLE } from "../Types";
import { useAuth } from "../Hooks";

const ProjectSchema = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const ProjectFormSchema = Yup.object().shape({
    customerId: user?.role === USER_ROLE.Business ? Yup.string().nullable() : Yup.string().required(t("IsRequired", { name: t("Client") })),
    projectName: Yup.string().required(t("IsRequired", { name: t("ProjectName") })),
    projectCategoryCode: Yup.string().required(t("IsRequired", { name: t("Category") })),
  });

  return {
    ProjectFormSchema,
  };
};

export default ProjectSchema;
