import { Fragment } from "react";
import { Link } from "react-router-dom";

import { Image } from "../../AbstractElement";
import { ROUTES } from "../../Constants";
import { dynamicImage } from "../../utils";
import { useLayoutContext, useStateContext } from "../../views";

const SidebarLogoWrapper = () => {
  const { toggleSidebar } = useLayoutContext();
  const { dismissAll } = useStateContext();

  return (
    <Fragment>
      <div className="toggle-sidebar d-md-flex d-none" onClick={toggleSidebar}></div>
      <div className="logo-wrapper">
        <Link to={ROUTES.Dashboard} onClick={dismissAll}>
          <Image src={dynamicImage("svg/logo/logo.svg")} className="" alt="logo" />
        </Link>
        <div className="toggle-sidebar d-md-none d-flex" onClick={toggleSidebar}>
          <Image src={dynamicImage("svg/close-red.svg")} className="" alt="menu-icon" />
        </div>
      </div>
      <div className="logo-icon-wrapper">
        <Link to={ROUTES.Dashboard} onClick={dismissAll}>
          <Image src={dynamicImage("svg/logo/favicon.svg")} className="" alt="logo" />
        </Link>
      </div>
    </Fragment>
  );
};

export default SidebarLogoWrapper;