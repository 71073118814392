export interface BusinessDashboardTableType {
  id: number;
  clientName: string;
  clientMail: string;
  status: string;
  amount: number;
}

export interface TableRowProp {
  key?: number;
  item?: BusinessDashboardTableType;
  checkedState?: boolean[];
  currentItems?: BusinessDashboardTableType[];
}

export interface ColumnCheckboxDropdownProps {
  columns: string[];
  checkedState: boolean[];
  handleCheckboxChange: (index: number) => void;
}

export interface DashboardTabProps {
  navActive: number;
  activeTabPane: (tabIndex: number) => void;
}

export const SUGGESTION_TYPE = {
  WaitingForApproval: "WaitingForApproval",
  WaitingForPayment: "WaitingForPayment",
  WaitingForPaymentAgreement: "WaitingForPaymentAgreement",
  JoinTheProject: "JoinTheProject",
  CompleteIdentityVerification: "CompleteIdentityVerification",
  EnterBankAccountDetails: "EnterBankAccountDetails",
  ReviewTheAgreements: "ReviewTheAgreements",
  CreateProject: "CreateProject",
  CreateDraftInvoice: "CreateDraftInvoice",
  GoToDraftInvoices: "GoToDraftInvoices",
  Questionnaire: "Questionnaire",
  SignUpDebitCardWaitingList: "SignUpDebitCardWaitingList",
  ReferToFriend: "ReferToFriend",
  CreateDebitCard: "CreateDebitCard",
} as const;

export type SuggestionType = ObjectValues<typeof SUGGESTION_TYPE>;
