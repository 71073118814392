import { useTranslation } from "react-i18next";
import * as Yup from "yup";

type CustomerOption = {
    label: string;
    value: string;
    data: {
        email: string;
        verified: boolean;
    };
}[] | undefined

const QuickInvoiceSchema = (customersSelect:CustomerOption) => {
  const { t } = useTranslation();

  const QuickInvoice = Yup.object().shape({
    itemName: Yup.string().required(t("IsRequired", { name: "ItemName" })),
    price: Yup.string().required(t("IsRequired", { name: "Price" })),
    currencyCode: Yup.string().required(t("IsRequired", { name: "Currency" })),
    customerId: customersSelect?.length===0 ? Yup.string().nullable() : Yup.string().required(t("IsRequired", { name: "Client" })),
    customerName:customersSelect?.length!==0 ? Yup.string().nullable() : Yup.string().required(t("IsRequired", { name: "ClientName" })),
    email:customersSelect?.length!==0 ? Yup.string().nullable() : Yup.string()
      .email(t("Email.InvalidFormat"))
      .required(t("IsRequired", { name: "Email" })),
    countryCode:customersSelect?.length!==0 ? Yup.string().nullable() : Yup.string().required(t("IsRequired", { name: "Country" })),
  });

  return {
    QuickInvoice,
  };
};

export default QuickInvoiceSchema;
