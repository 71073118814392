import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../../Constants';
import { useAuth } from '../../../../Hooks';
import Loader from '../../../../Layout/Loader';
import { getMessages, getParam } from '../../../../utils';
import { useToast } from '../../../../views';

const GoogleSignin = () => {
    const { googleSignin } = useAuth();
    const navigate = useNavigate();
    const { showToast } = useToast();

    useEffect(() => {
        const code = getParam("code");

        if (!code) {
            navigate(ROUTES.FreelancerLogIn);
            return;
        }

        googleSignin({ code },
            () => { },
            (err) => {
                showToast("error", getMessages(err));
                setTimeout(() => {
                    navigate(ROUTES.FreelancerLogIn);
                }, 3000);
            })
    }, []);

    return <Loader />;
}
export default GoogleSignin